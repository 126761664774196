import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './NewYearFortuneDisplay.css';
import './FortuneReadingPage.css';
import { apiService } from '../utils/api'; 

const FortuneReadingPage = () => {
  const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0);
  const [fortuneData, setFortuneData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { shareId } = useParams();

  // 1. API 호출 함수
  const fetchFortuneReading = async () => {
    try {
    const response = await apiService.post('/fortune/reading/detail/', {
        share_id: shareId
        });
      setFortuneData(response.data);
      // 소유자인 경우 추가 기능 활성화
    //   if (response.data.is_owner) {
    //     // 예: 수정 버튼 표시, 공유 설정 변경 등
    //  }


    } catch (error) {
      console.error('운세 데이터 로딩 실패:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFortuneReading();
  }, [shareId]);

  // 새로운 useEffect 추가
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentDisplayIndex]);

  // 그리고 핸들러에서는 스크롤 제거
  const handlePrevDisplay = () => {
    setCurrentDisplayIndex(prev => Math.max(0, prev - 1));
  };

  const handleNextDisplay = () => {
    if (fortuneData?.displays && currentDisplayIndex < fortuneData.displays.length - 1) {
      setCurrentDisplayIndex(prev => prev + 1);
    }
  };
  
  useEffect(() => {
    const handleScroll = () => {
      const displaySection = document.querySelector('.fortune-display-section');
      const pageIndicator = document.querySelector('.page-indicator');
      const navButtons = document.querySelectorAll('.nav-button');
      const isMobile = window.innerWidth <= 600;
      
      if (!displaySection || !pageIndicator) return;
      
      if (isMobile) {
        // 페이지 인디케이터의 bottom 위치가 현재 스크롤 위치보다 위에 있으면 버튼을 숨김
        const indicatorBottom = pageIndicator.getBoundingClientRect().bottom;
        const scrollPosition = window.innerHeight - 100; // 버튼 높이와 여백을 고려한 위치
        
        const shouldShowButtons = indicatorBottom < scrollPosition;
        
        navButtons.forEach(button => {
          button.classList.toggle('mobile-visible', !shouldShowButtons); // 반전된 조건 사용
        });
      } else {
        // PC/태블릿에서는 기존 로직 유지
        const viewportMiddle = window.scrollY + window.innerHeight / 2;
        const indicatorBottom = pageIndicator.getBoundingClientRect().bottom + window.scrollY;
        const displayTop = displaySection.getBoundingClientRect().top + window.scrollY;
        const shouldShowButtons = viewportMiddle >= displayTop && viewportMiddle <= indicatorBottom;
        
        navButtons.forEach(button => {
          button.classList.toggle('visible', shouldShowButtons);
        });
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    handleScroll(); // 초기 실행
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  if (isLoading) {
    return <div className="fortune-loading">Loading...</div>;
  }

  return (
    <div className="fortune-page">
      {/* 2. Display 슬라이더 섹션 */}
      <div className="fortune-display-section">
        <button 
          className="nav-button prev"
          onClick={handlePrevDisplay}
          disabled={currentDisplayIndex === 0}
        >
          ←
        </button>

        <div className="display-content">
          {fortuneData?.displays && fortuneData.displays[currentDisplayIndex] && (
            <div 
              dangerouslySetInnerHTML={{ 
                __html: fortuneData.displays[currentDisplayIndex].content_html 
              }} 
            />
          )}
        </div>

        <button 
          className="nav-button next"
          onClick={handleNextDisplay}
          disabled={!fortuneData?.displays || currentDisplayIndex === fortuneData.displays.length - 1}
        >
          →
        </button>

        {/* 페이지 인디케이터 */}
        <div className="page-indicator">
          {fortuneData?.displays && fortuneData.displays.map((_, index) => (
            <span 
              key={index} 
              className={`indicator ${index === currentDisplayIndex ? 'active' : ''}`}
              onClick={() => setCurrentDisplayIndex(index)}
            />
          ))}
        </div>
      </div>

      {/* 3. Q&A 섹션 */}
      <div className="fortune-qa-section">
        <h2>추가 상담 내용</h2>
        <div className="qa-list">
          {fortuneData?.qas && fortuneData.qas.map((qa, index) => (
            <div key={index} className="qa-item">
              <div className="question">
                <span className="q-icon">Q.</span>
                <p>{qa.question}</p>
              </div>
              <div className="answer">
                <span className="a-icon">A.</span>
                <p>{qa.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FortuneReadingPage;