import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

import newYearImage from '../images/new_year_image.jpg';
import generalImage from '../images/general_image.jpg';
import pairImage from '../images/pair_image.jpg';
import backgroundImage from '../images/at.png'

import { useInView } from 'react-intersection-observer'; 
import { handleKakaoLogin } from '../utils/SocialAuth';
import kakaoIcon from '../images/kakao_icon_1.png';  
import { useAuth } from '../contexts/AuthContext';

// 목적지 URL을 저장하기 위한 상수
const REDIRECT_URL_KEY = 'intended_redirect_url';

function FeatureCard({ feature, index }) {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`feature-card ${index % 2 === 0 ? 'left' : 'right'} ${inView ? 'visible' : ''}`}
    >
      <div className="feature-icon">{feature.icon}</div>
      <div className="feature-content">
        <h3 className="feature-title">{feature.title}</h3>
        <p className="feature-description">{feature.description}</p>
      </div>
    </div>
  );
}

function TestimonialCard({ testimonial }) {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`testimonial-card ${inView ? 'visible' : ''}`}
    >
      <div className="testimonial-rating">
        {"★".repeat(testimonial.rating)}
      </div>
      <p className="testimonial-text">{testimonial.text}</p>
      <div className="testimonial-info">
        <span className="testimonial-name">{testimonial.name}</span>
        <span className="testimonial-type">{testimonial.type}</span>
      </div>
    </div>
  );
}

// useTypewriter 훅을 다음과 같이 수정
function useTypewriter(initialText) {
  const [state, setState] = useState({
    displayText: '',
    isComplete: false,
    index: 0
  });

  useEffect(() => {
    // 초기화
    setState({
      displayText: '',
      isComplete: false,
      index: 0
    });

    // 유효성 검사
    if (!initialText) {
      setState(prev => ({ ...prev, isComplete: true }));
      return;
    }

    const text = String(initialText);
    
    const typeNextChar = () => {
      setState(prev => {
        // 마지막 글자 직전인 경우
        if (prev.index === text.length - 1) {
          return {
            displayText: text, // 전체 텍스트를 한번에 표시
            index: text.length,
            isComplete: true
          };
        }

        // 마지막 글자가 아닌 경우 기존 로직 유지
        if (prev.index >= text.length) {
          return { ...prev, isComplete: true };
        }

        return {
          displayText: text.slice(0, prev.index + 1),
          index: prev.index + 1,
          isComplete: false
        };
      });
    };

    const timer = setInterval(() => {
      setState(prev => {
        if (prev.index >= text.length) {
          clearInterval(timer);
          return { ...prev, isComplete: true };
        }
        return prev;
      });
      typeNextChar();
    }, Math.random() * 70 + 35);

    return () => clearInterval(timer);
  }, [initialText]);

  return {
    displayText: state.displayText,
    isComplete: state.isComplete
  };
}

function throttle(func, limit) {
  let inThrottle;
  return function(...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      requestAnimationFrame(() => inThrottle = false);
    }
  }
}


function NewFeatureCard({ feature, index }) {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`new-feature-card ${index % 2 === 0 ? 'left' : 'right'} ${inView ? 'visible' : ''}`}
    >
      <div className="new-feature-icon">
        <img 
          src={feature.imageUrl} 
          alt={feature.title}
          className="feature-emoji-image"
        />
      </div>
      <div className="feature-content">
        <h3 className="feature-title">{feature.title}</h3>
        <p className="feature-description">{feature.description}</p>
      </div>
    </div>
  );
}


function LandingPage() {

  const { isAuthenticated, user, login, logout, loading, checkAuthStatus} = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // for kakao initial login in button section
  const navigate = useNavigate();


  // 텍스트에 정확한 띄어쓰기 포함
  const titleText = {
    desktop: "MIRI | 당신의 운세를 읽다",
    mobile: "운세를\n MIRI 읽다" // 또는 "MIRI | 당신의\n운명을 읽다"
  };
  
  const { displayText, isComplete } = useTypewriter(isMobile ? titleText.mobile : titleText.desktop);

  // URL 파라미터에서 invitation 코드와 special_code 체크
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const invitationCode = params.get('invitation');
    const specialCode = params.get('special_code');
    
    if (invitationCode) {
      localStorage.setItem('referral_code', invitationCode);
    }
    if (specialCode) {
      localStorage.setItem('special_code', specialCode);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  //카카오 핸들 로그인
  const handleLogin = async () => {
    console.error('Kakao login trying..');
    setIsLoading(true);
    try {
      await handleKakaoLogin();
    } catch (error) {
      console.error('Kakao login failed:', error);
      // alert('로그인 중 오류가 발생했습니다. 다시 시도해주세요.');
    }
  };

  // 컴포넌트 마운트 시 인증 상태 확인
  useEffect(() => {
    checkAuthStatus(' landing page');
  }, []);

  // 로그아웃 핸들러 추가
  const handleLogout = async () => {
    await logout();
    // 로그아웃 후 필요한 처리 (예: 메시지 표시)
  };

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
      if (redirectUrl) {
        localStorage.removeItem(REDIRECT_URL_KEY);
        navigate(redirectUrl);
      }
    }
  }, [isAuthenticated, navigate]);

  const handleProtectedNavigation = async (targetPath) => {
    setIsLoading(true);
    try {
      await checkAuthStatus('navigation check');
      
      if (!isAuthenticated) {
        // 로그인 후 이동할 URL을 localStorage에 저장
        localStorage.setItem(REDIRECT_URL_KEY, targetPath);
        await handleLogin();
        // 여기서는 navigate를 호출하지 않음 - 카카오 로그인 페이지로 리다이렉트됨
      } else {
        // 이미 로그인된 경우 바로 이동
        navigate(targetPath);
      }
    } catch (error) {
      console.error('Navigation error:', error);
    } 
    // finally {
    //   setIsLoading(false);
    // }
  };


  const services = [
    {
      id: 1,
      title: "오늘의 운세",
      description: "사주팔자와 오늘의 날짜가 만나 만들어내는 특별한 기운을 해석해드려요.",
      image: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cloud%20with%20Snow.png",
      path: "/today-gate"
    },
    {
      id: 2,
      title: "사주 유형 테스트",
      description: "타고난 성향과 잠재력을 통해 인생의 방향을 제시해드립니다.",
      image: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Microsoft-Teams-Animated-Emojis/master/Emojis/Travel%20and%20places/Star.png",
      path: "/class-gate"
    },
    {
      id: 3,
      title: "60일주 테스트",
      description: "60가지 일주 중 당신의 타고난 성격과 기질을 알아보세요.",
      image: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Unicorn.png",
      path: "/day-pillar-gate"
    },
    {
      id: 4,
      title: "AI 신년운세",
      description: "AI와 채팅을 하면서 2025년의 직장운, 애정운, 나에게 다가올 행운을 확인해보세요",
      image: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png",
      path: "/new-year-gate"
    }
  ];

  
  const testimonials = [
    {
      id: 1,
      name: "김*정님",
      age: "28세",
      rating: 5,
      text: "요즘 챗지피티로 사주보는 사람들도 있고 비슷한 서비스가 많은 것 같은데 써본 것 중에 이게 제일 잘 맞았어요😊 재밌게 잘 봤습니당",
      type: "신년운세"
    },
    {
      id: 2,
      name: "이*현님",
      age: "35세",
      rating: 5,
      text: "잘 맞는거 같아요~ 다른 사주 앱들도 많이 써봤는데 이게 제일 정확도 높은 거 같아요",
      type: "신년운세"
    },
    {
      id: 3,
      name: "박*린님",
      age: "42세",
      rating: 5,
      text: "전 사주를 진짜 많이 본 편이에요~ 압구정 홍대 등등 유명하다는 곳 다 가봤는데 미리에서 본거랑 그 분들이 말씀해주신 거랑 거의 똑같아서 놀랐…다음에 또 보려구용",
      type: "신년운세"
    },
    {
      id: 4,
      name: "최*우님",
      age: "31세",
      rating: 5,
      text: "풀이하면서 몇 번 소름 돋았어요!! 솔로된지 오래됐는데 곧 인연이 찾아온대서 용기도 얻었고 기분 좋네요 ㅎㅎ 결혼하고 싶어요…",
      type: "신년운세"
    },
    {
      id: 5,
      name: "정*아님",
      age: "27세",
      rating: 5,
      text: "취업이 너무 안돼서 기분이라도 풀려고 봤는데 최근에 가고 싶었던 곳 서류 합격 ㅋㅋㅋ뭐지 어떻게 맞췄지",
      type: "신년운세"
    },
    {
      id: 6,
      name: "강*준님",
      age: "39세",
      rating: 5,
      text: "창업을 고민하고 있었는데, 올해가 적기라는 분석이 와닿았어요. 구체적인 시기까지 짚어주셔서 많은 도움이 됐습니다.",
      type: "신년운세"
    }
  ];

  const processSteps = [
    {
      id: 1,
      imageUrl: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Memo.webp",
      title: "기본 정보 입력",
      description: "생년월일시를 입력하여 사주팔자를 구합니다."
    },
    {
      id: 2,
      imageUrl: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Hamsa.png",
      title: "AI 데이터 분석",
      description: "30년간의 데이터베이스와 AI가 당신의 운세를 분석합니다."
    },
    {
      id: 3,
      imageUrl: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Crystal%20Ball.png",
      title: "맞춤형 결과 제공",
      description: "AI가 당신만을 위한 상세한 운세와 조언을 제공합니다."
    },
    {
      id: 4,
      imageUrl: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Symbols/Speech%20Balloon.webp",
      title: "AI 채팅 상담",
      description: "추가 질문과 고민에 대해 AI가 상세히 답변해드립니다."
    }
  ];

  const fortuneCategories = [
    { 
      icon: "💸", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Money%20Bag.webp",
      title: "재물운", 
      description: "2025년, 당신의 재물이 흐르는 방향은 어디일까요? 돈이 들어오는 시기와 나가는 시기, 투자해야 할 때와 지켜야 할 때를 정확히 짚어드립니다." 
    },
    { 
      icon: "💝", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Symbols/Heart%20On%20Fire.webp",
      title: "애정운", 
      description: "솔로라면 언제 인연이 찾아올지, 연인이 있다면 관계가 어떻게 발전할지, 결혼운은 어떨지 섬세하게 살펴드립니다." 
    },
    { 
      icon: "💼", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Briefcase.webp",
      title: "직장/명예운", 
      description: "승진? 이직? 창업? 2025년 당신의 커리어 흐름을 읽고, 최적의 선택지를 제시해드립니다." 
    },
    { 
      icon: "👨‍🎓", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Memo.webp",
      title: "학업/계약운", 
      description: "시험, 자격증, 계약... 중요한 결정의 순간에서 당신에게 가장 유리한 타이밍을 알려드립니다." 
    },
    { 
      icon: "🔮", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png",
      title: "2025년의 특별한 행운", 
      description: "매달 찾아올 특별한 행운의 시기, 조심해야 할 시기를 미리 알고 준비하세요." 
    }
  ];

  const features = [
    {
      id: 1,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Hundred%20Points.png",
      title: "3세대에 걸쳐 축적된 명리학의 정수",
      description: "3대에 걸쳐 축적된 역학 지식을 AI가 학습하여, 과학적이고 정확한 운세를 제시합니다.",
      icon: "🏛️"
    },
    {
      id: 2,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Robot.png",
      title: "과학적 알고리즘",
      description: "사주는 통계학입니다. 수십 년간의 데이터를 AI가 분석하여 정확도 높은 결과를 제공합니다.",
      icon: "🤖"
    },
    {
      id: 3,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ringed%20Planet.png",
      title: "전문적인 사주 분석",
      description: "태어난 월과 일간의 관계성, 팔자에 미치는 영향 등 심도 있는 분석으로 겉핥기식 풀이는 No!",
      icon: "📚"
    },
    {
      id: 4,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Books.webp",
      title: "방대한 데이터베이스",
      description: "명리학 고서부터 최신 논문까지, 수십 권의 전문 서적 데이터베이스가 AI에 탑재되어 있습니다.",
      icon: "💡"
    },
    {
      id: 5,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Hand%20gestures/Handshake.png",
      title: "AI 맞춤 상담",
      description: "상황과 고민에 특화된 AI 챗봇과 실시간 대화하며 당신만의 맞춤형 운세 상담을 받아보세요",
      icon: "🤝"
    },
    {
      id: 6,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Glowing%20Star.png",
      title: "쉽고 정확한 풀이",
      description: "어려운 사주 용어는 NO! 이해하기 쉽게 풀어서 설명해드립니다.",
      icon: "✨"
    }
  ];

  // useEffect(() => {
  //   // 모바일에서는 더 적은 횟수로 업데이트
  //   const scrollMultiplier = isMobile ? 0.05 : 0.1;
    
  //   const handleScroll = throttle(() => {
  //     const scrollY = window.scrollY;
  //     const viewportHeight = window.innerHeight;
      
  //     if (scrollY <= viewportHeight) {
  //       // requestAnimationFrame을 사용하여 부드러운 애니메이션
  //       requestAnimationFrame(() => {
  //         setScrollPosition(scrollY);
  //       });
  //     }
  //   }, 16); // 약 60fps

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [isMobile]);


  useEffect(() => {
    const scrollMultiplier = isMobile ? 0.05 : 0.1;
    
    const handleScroll = throttle(() => {
      // 뷰포트 높이 계산을 밖으로 빼서 매번 계산하지 않도록 함
      const viewportHeight = window.innerHeight;
      const scrollY = window.scrollY;
      
      // 스크롤 위치가 뷰포트 높이를 넘어가면 더 이상 계산하지 않음
      if (scrollY <= viewportHeight) {
        // transform 값을 미리 계산
        const translateX = scrollY * scrollMultiplier;
        
        // DOM 업데이트를 최소화
        requestAnimationFrame(() => {
          const mountainLayer = document.querySelector('.mountain-layer.with-image');
          if (mountainLayer) {
            mountainLayer.style.transform = `translateX(${translateX}px)`;
          }
        });
      }
    }, 16); // 약 60fps
  
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);


  const calculateTransforms = (scrollPos) => {
    const moveX = scrollPos * 0.1; // 좌우 이동
    const scale = 1 + (scrollPos * 0.0005); // 확대/축소
    return {
      transform: `
        translateX(${moveX}px) 
        scale(${scale})
      `
    };
  };


  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  // URL 파라미터를 확인하고 스크롤하는 useEffect 추가
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const section = params.get('section');
    
    if (section) {
      const element = document.querySelector(`.${section}-section`);
      if (element) {
        // 부드러운 스크롤 효과 적용
        element.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }
    }
  }, []);

  return (
    <div className="home-page">
      {/* Mountain parallax layers */}
      <div className="mountain-scene">
        <div 
          className={`mountain-layer ${backgroundImage ? 'with-image' : ''}`}
          style={{ 
            transform: isMobile ? 
              `translateX(${scrollPosition * 0.3}px)` : 
              `translateX(${scrollPosition * 0.2}px)`,
            '--bg-image': `url(${backgroundImage})`
          }}
        />
        {/* <div className="trees-layer" /> */}
      </div>
  
      {/* Content */}
      <div className="home-content">

        <div className="hero-section"> {/* 새로운 wrapper 추가 */}
          <div 
            className="title-section"
            >
            <h1 className={`main-title ${isComplete ? 'floating' : ''}`}>
              {displayText}
              {!isComplete && <span className="cursor"></span>}
            </h1>
            <p className="sub-title">AI와 명리학의 만남, 새로운 차원의 운세 챗봇 서비스</p>
            
            <div className="store-buttons">
              {isAuthenticated ? (
                <button 
                  onClick={handleLogout}
                  className="store-button logout-button"
                >
                  로그아웃
                </button>
              ) : (
                <button 
                onClick={handleLogin} 
                className={`store-button kakao-login ${isLoading ? 'loading' : ''}`}
                aria-label="카카오 로그인"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="loading-spinner"></span>
                ) : (
                  <>
                    <img src={kakaoIcon} alt="카카오 아이콘" className="kakao-icon" />
                    카카오 로그인
                  </>
                )}
              </button>
              )}
            </div>
          </div>
        </div>
  
        <div className="services-section"> 
          <div className="game-cards">
            {services.map((service) => (
              <div 
                key={service.id} 
                className="game-card"
                onClick={() => navigate(service.path)}
                style={{ cursor: 'pointer' }}
              >
                <div className="game-card-image">
                  <img src={service.image} alt={service.title} />
                </div>
                <div className="game-card-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="about-section">
          <div className="about-content">
            <h2 className={`about-title ${inView ? 'visible' : ''}`}>
              미리가 세상에 나오게 된 이유
            </h2>
            
            <div ref={ref} className={`about-text ${inView ? 'visible' : ''}`}>
              <p style={{'--i': 1}}>안녕하세요.</p>
              <p style={{'--i': 2}}>미리(Miri) 서비스를 개발하고 있는 미리랩스입니다.</p>
              <p style={{'--i': 3}}>저희는 20년차 역술가 허유 선생님과 함께 사주 AI 챗봇을 개발했습니다.</p>
              <p style={{'--i': 4}}>허유 선생님은 기업 CEO, 해외 정재계 인사, 정치인 등 지도계층을 주로 상담해오신 찐 전문가이십니다.</p>
              
              <div className="highlight-box">
                <p style={{'--i': 5}} className="highlight-text">
                  "왜 오프라인처럼 정확한 풀이를 내놓는 온라인 사주 서비스는 없을까?"
                </p>
                <p style={{'--i': 6}}>라는 물음에서 미리는 시작했어요.</p>
              </div>

              <p style={{'--i': 7}}>직접 찾아가서 사주 상담을 받으면 만족하는 경우가 많은 반면</p>
              <p style={{'--i': 8}}>시중에 있는 사주 앱 서비스를 사용해보면 만족도가 높지 않은 경우가 많거든요.</p>
              <p style={{'--i': 9}}>두루뭉술한 답변을 준다든지 앞에서 말한 내용과 뒤가 다르다든지 말이죠.</p>
              <p style={{'--i': 10}}>심지어는 다른 사람과 풀이가 똑같은 경우도 있고요.</p>

              <div className="highlight-box">
                <p style={{'--i': 11}} className="highlight-text">
                  그래서 오프라인처럼 퀄리티 높은 답변을 내놓는 사주 챗봇 서비스를 직접 만들기로 했어요.
                </p>
              </div>

              <p style={{'--i': 12}}><strong>〈자평진전〉</strong>, <strong>〈궁퉁보감〉</strong> 같이 수백년 동안 내려져온 고서부터</p>
              <p style={{'--i': 13}}>최근까지 연구되어온 논문, 이론까지 제대로 학습시켜서 말이에요.</p>
              <p style={{'--i': 14}}>수많은 저서들과 허유 선생님의 사주 풀이 노하우를 접목해서 미리가 탄생하게 됐습니다.</p>


              {/* 새로운 내용 추가 */}
              {/* <div className="highlight-box">
                <p style={{'--i': 15}} className="highlight-text">
                  미리랩스는 명리학과 AI의 결합된 서비스를 제공하여 더 많은 사람들의 자아성찰을 돕고자합니다.
                </p>
              </div>

              <p style={{'--i': 16}}>MBTI가 우리의 성격 유형을 이해하는 데 도움을 주듯이</p>
              <p style={{'--i': 17}}>명리학은 우리 삶의 더 본질적인 부분을 들여다보게 해줍니다.</p>
              <p style={{'--i': 18}}>타고난 기질과 잠재력, 시기별 운의 흐름까지.</p> */}
              
              <div className="highlight-box">
                <p style={{'--i': 19}} className="highlight-text">
                  미리(Miri)를 통해 자신을 더 깊이 이해하고
                  타인과 더 풍요로운 관계를 만들어가셨으면 해요.
                </p>
              </div>

            </div>
          </div>
        </div>

      {/* Legacy Section */}
      {/* <div className="legacy-section">
        <div className="legacy-content">
          <h2 className="section-title">3대째 이어온 명리학 집안의 지혜</h2>
          <p className="section-subtitle">전통과 현대, 그리고 미래가 만나는 곳</p>

          <div className="legacy-container">
            <div className="legacy-timeline-box">
              <div className="timeline-items">
                <div className="timeline-item">
                  <div className="timeline-icon">👴</div>
                  <span className="generation">할아버지</span>
                  <span className="contribution">전통 명리학</span>
                  <div className="timeline-line"></div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-icon">👨</div>
                  <span className="generation">아버지</span>
                  <span className="contribution">현대 사주론</span>
                  <div className="timeline-line"></div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-icon">🤖</div>
                  <span className="generation">AI 시대</span>
                  <span className="contribution">AI 알고리즘</span>
                </div>
              </div>
              
              <div className="legacy-message">
                <p>미리에는 할아버지께서 가르쳐주신 전통 명리학,</p>
                <p>아버지와 함께 연구한 현대 사주론,</p>
                <p>그리고 제가 개발한 사주 명리 AI 알고리즘이</p>
                <p>모두 녹아져있어요.</p>
              </div>
            </div>

            <div className="legacy-highlight">
              <p className="highlight-intro">어디서나 들을 수 있는 그런 사주 풀이가 아니라</p>
              <p className="highlight-main">정말 개개인에게 딱 맞는 풀이를 들려드릴게요.</p>
            </div>
          </div>
        </div>
      </div> */}



      <div className="features-section">
        <div className="features-content">
          <h2 className="section-title">AI 운세 서비스 MIRI</h2>
          <p className="section-subtitle">운세를 과학적으로 분석하는 새로운 방법</p>
          
          <div className="features-grid">
            {features.map((feature, index) => (
              <NewFeatureCard
              key={feature.id}
              feature={{
                imageUrl: feature.imageUrl,
                title: feature.title,
                description: feature.description
              }}
              index={index}
            />
            ))}
          </div>
        </div>
      </div>


      {/* <div className="how-miri-works">
        <div className="works-content">
          <h2 className="section-title">미리는 이렇게 풀어드려요</h2>
          <p className="section-subtitle">미리의 AI 챗봇과 대화를 나누며 풀어나가요</p>

          <div className="chat-preview">
            <div className="chat-steps">
              <div className="chat-step">
                <img src="/path/to/chat-image-1.jpg" alt="상담 과정 1단계" />
                <span className="step-label">기본 정보 입력</span>
              </div>
              <div className="chat-step">
                <img src="/path/to/chat-image-2.jpg" alt="상담 과정 2단계" />
                <span className="step-label">현재 상황 파악</span>
              </div>
              <div className="chat-step">
                <img src="/path/to/chat-image-3.jpg" alt="상담 과정 3단계" />
                <span className="step-label">맞춤형 분석</span>
              </div>
              <div className="chat-step">
                <img src="/path/to/chat-image-4.jpg" alt="상담 과정 4단계" />
                <span className="step-label">구체적 조언</span>
              </div>
            </div>

            <div className="miri-difference">
              <div className="difference-content">
                <div className="difference-text">
                  <p>다른 사주 서비스를 써보고 실망한적 있지 않으신가요?</p>
                  <div className="example-box">
                    <p>이미 직장을 다니고 있는데 취업운이 좋다든지,</p>
                    <p>결혼을 했는데 결혼 운이 좋다든지요.</p>
                  </div>
                  <div className="highlight-box emphasis">
                    <p>미리는 이렇게 엉뚱하게 풀이하지 않습니다.</p>
                    <p className="highlight-text">
                      당신이 처한 상황을 귀기울여 들으며<br />
                      그에 맞는 맞춤형 조언을 드려요.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 */}



      {/* New Process Section */}
      <div className="process-section">
        <div className="process-content">
          <h2 className="section-title">AI 상담 진행 과정</h2>
          <p className="section-subtitle">쉽고 정확한 4단계 프로세스</p>
          
          <div className="process-steps">
            {processSteps.map((step) => (
                <div key={step.id} className="process-step">
                  <div className="new-step-icon">
                    <img 
                      src={step.imageUrl} 
                      alt={step.title}
                      className="step-emoji-image"
                    />
                  </div>
                  <h3 className="step-title">{step.title}</h3>
                  <p className="step-description">{step.description}</p>
                </div>
              ))}
          </div>
        </div>
      </div>

      
      {/* // Testimonials Section in LandingPage component */}
      <div className="testimonials-section">
        <div className="testimonials-content">
          <h2 className="section-title">사용자 후기</h2>
          <p className="section-subtitle">MIRI와 함께한 분들의 이야기</p>
          
          <div className="testimonials-carousel">
            {testimonials.map((testimonial) => (
              <TestimonialCard 
                key={testimonial.id} 
                testimonial={testimonial}
              />
            ))}
          </div>
        </div>
      </div>

      {/*신년운세 CTA  */}
      <div className="cta-section">
        <div className="cta-content">
          <h2 className="section-title">2025년, 내 삶은 어떻게 펼쳐질까?</h2>
          <p className="section-subtitle">
            수십 년간 축적된 실제 상담 데이터를 바탕으로 정확한 운세를 풀어드립니다.
          </p>
          
          <div className="fortune-categories">
            {fortuneCategories.map((fortune, index) => (
              <NewFeatureCard
                key={fortune.id}
                feature={{
                  imageUrl: fortune.imageUrl,
                  title: fortune.title,
                  description: fortune.description
                }}
                index={index}
              />
            ))}
          </div>

          <div className="cta-button-container">
            <button 
              className="cta-button"
              onClick={() => navigate('/new-year-gate')}
            >
              2025년 신년운세 보러가기
            </button>
          </div>
        </div>
      </div>

      </div>
    </div>
  );
}

export default LandingPage;