import React, { useState, useEffect, useRef }  from 'react';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Navigate,
  useNavigate,
  useLocation
} from 'react-router-dom';
import { ThreadProvider } from './ThreadContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import LandingPage from './pages/LandingPage';
import HowSpecialPage from './pages/HowSpecialPage';
import NewYearPage from './pages/NewYearPage';
import RegistrationPage from './pages/RegistrationPage';
import FortuneReadingPage from './pages/FortuneReadingPage';
import NewYearGatePage from './pages/NewYearGatePage'; // NewYearGatePage 임포트 추가
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import {handleAuthProtection} from './utils/SocialAuth'
import Favicon from 'react-favicon';
import favicon from './images/favicon.ico';
import PaymentRedirectPage from './pages/PaymentRedirectPage';
import './App.css';
import TagManager from 'react-gtm-module';

import MyPage from './pages/MyPage';
import ProfileEditPage from './pages/ProfileEditPage';

import ClassPage from './pages/ClassPage';
import ClassGatePage from './pages/ClassGatePage';
import ClassDetailPage from './pages/ClassDetailPage';
import ClassRouterPage from './pages/ClassRouterPage';

import DayPillarGatePage from './pages/DayPillarGatePage';
import DayPillarDetailPage from './pages/DayPillarDetailPage';
import DayPillarRouterPage from './pages/DayPillarRouterPage';

import BasicGuidePage from './pages/BasicGuidePage';
import TodayGatePage from './pages/TodayGatePage';
import TodayResultPage from './pages/TodayResultPage';

import AdsNewYearPage from './pages/AdsNewYearPage';

// 목적지 URL을 저장하기 위한 상수
const REDIRECT_URL_KEY = 'intended_redirect_url';

// 인증되지 않은 사용자의 리다이렉트 매핑 추가
const UNAUTHORIZED_REDIRECTS = {
  '/today-result': '/today-gate',
  // 추후 다른 경로에 대한 매핑도 여기에 추가 가능
  // '/some-path': '/some-gate',
};

// GTM 초기화 설정
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
};

function ScrollToTop() {
  const location = useLocation();
  const prevPathRef = useRef(location.pathname);

  useEffect(() => {
    if (prevPathRef.current !== location.pathname || location.pathname === '/') {
      // window와 document.documentElement 모두 시도
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      document.documentElement.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      // body도 시도
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    prevPathRef.current = location.pathname;

  }, [location]);

  return null;
}

// GTM 이벤트 전송을 위한 커스텀 훅
function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    // 페이지뷰 이벤트 전송
    const pageViewData = {
      event: 'virtual_pageview',
    };

    console.log('🔍 GTM Page View:', pageViewData);

    TagManager.dataLayer({
      dataLayer: pageViewData
    });
  }, [location]);
}

function AppContent(){
  const routesWithoutFooter = ['/how-special-you-are', '/new-year-prospects', '/welcome-to-2025'];
  const location = useLocation();
  const shouldShowFooter = !routesWithoutFooter.includes(location.pathname);

  usePageTracking(); // 페이지 추적 훅 사용

  return (
    <>
      <NavBar />
      <div className="content-wrapper">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route 
            path="/profile-registration" 
            element={
              <ProtectedRoute requiresProfile={false}>
                <RegistrationPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/how-special-you-are" 
            element={
              <ProtectedRoute>
                <HowSpecialPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/new-year-prospects" 
            element={
              <ProtectedRoute>
                <NewYearPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/fortune-reading/:shareId" 
            element={<FortuneReadingPage />} 
          />
          <Route 
            path="/my-page" 
            element={
              <ProtectedRoute>
                <MyPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/new-year-gate" 
            element={<NewYearGatePage />} // NewYearGatePage 라우트 추가
          />

          <Route path="/payment-redirect" element={<PaymentRedirectPage/>} />

          <Route path="/basic-guide" element={<BasicGuidePage />} />

          <Route path="/class" element={<ClassPage />} />
          <Route path="/class-gate" element={<ClassGatePage />} />
          <Route path="/class/:id" element={<ClassDetailPage />} />
          <Route path="/class-router" element={<ClassRouterPage />} />
          
          <Route path="/day-pillar-gate" element={<DayPillarGatePage />} />
          <Route path="/day-pillar/:id" element={<DayPillarDetailPage />} />
          <Route path="/day-pillar-router" element={<DayPillarRouterPage />} />

          <Route path="/today-gate" element={<TodayGatePage/>}/>
          <Route path="/today-result" 
            element={
              <ProtectedRoute>
                <TodayResultPage />
              </ProtectedRoute>
            }
          />

          <Route 
            path="/profile-edit/:profileId"
            element={
              <ProtectedRoute>
                <ProfileEditPage />
              </ProtectedRoute>
            } 
          />
          
          <Route 
            path="/profile-add"
            element={
              <ProtectedRoute>
                <ProfileEditPage />
              </ProtectedRoute>
            } 
          />

        <Route path="/welcome-to-2025" element={<AdsNewYearPage/>}/>
        </Routes>
        {shouldShowFooter && <Footer />}
      </div>
    </>
  );
}

function App() {
  useEffect(() => {
    // 앱 마운트 시 GTM 초기화
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="App">
      <Favicon url={favicon} />
      <AuthProvider>
        <ThreadProvider>
          <Router>
            <ScrollToTop />
            <AppContent />
          </Router>
        </ThreadProvider>
      </AuthProvider>
    </div>
  );
}

// ProtectedRoute 컴포넌트 수정
function ProtectedRoute({ children, requiresProfile = true }) {
  const { isAuthenticated, loading, userMeta } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loading) {
      if (!isAuthenticated) {
        // 현재 경로를 저장
        localStorage.setItem(REDIRECT_URL_KEY, location.pathname);
        
        // 특정 경로에 대한 리다이렉트 처리
        const redirectPath = UNAUTHORIZED_REDIRECTS[location.pathname];
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          // 기본 인증 보호 로직 (홈으로 리다이렉트)
          handleAuthProtection(isAuthenticated, navigate);
        }
      } else if (requiresProfile && 
                location.pathname !== '/profile-registration' && 
                (!userMeta?.terms_agreed_at || !userMeta?.is_birth_info_registered)) {
        if (location.pathname !== '/') {
          localStorage.setItem(REDIRECT_URL_KEY, location.pathname);
        }
        navigate('/profile-registration');
      }
    }
  }, [isAuthenticated, loading, navigate, userMeta, location.pathname, requiresProfile]);

  if (loading) return <div>Loading...</div>;
  return isAuthenticated ? children : null;
}

export default App;
