import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useAuth } from '../contexts/AuthContext';
import { dayPillarContentsData } from '../contents/day_pillar_contents/dayPillarContentsData';
import { handleKakaoLogin } from '../utils/SocialAuth';
import './DayPillarDetailPage.css';
import { ohangDataV2, hanjaOhangMapping } from '../components/ohangData';

// 애니메이션 섹션 컴포넌트
const AnimatedSection = ({ children }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`day-pillar-detail__card animate-on-scroll ${inView ? 'visible' : ''}`}
    >
      {children}
    </div>
  );
};

// 이미지와 텍스트를 합치는 함수
const createShareImage = async (originalImage, text) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  const dpr = window.devicePixelRatio || 1;
  const CANVAS_WIDTH = 375;
  const VIEWPORT_HEIGHT = window.innerHeight;
  const TEXT_PADDING = 40;
  const BORDER_RADIUS = 16 * dpr;
  const TOP_MARGIN = 40;
  
  // 텍스트 높이 계산을 위한 임시 캔버스
  const tempCanvas = document.createElement('canvas');
  const tempCtx = tempCanvas.getContext('2d');
  tempCtx.font = '16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
  
  // 텍스트 높이 계산
  const maxWidth = CANVAS_WIDTH - (TEXT_PADDING * 2);
  const lineHeight = 22;
  let totalTextHeight = 0;
  
  const paragraphs = text.split('\n');
  paragraphs.forEach(paragraph => {
    if (paragraph.trim() === '') {
      totalTextHeight += lineHeight;
      return;
    }
    
    const words = paragraph.split(' ');
    let line = '';
    words.forEach(word => {
      const testLine = line + word + ' ';
      const metrics = tempCtx.measureText(testLine);
      if (metrics.width > maxWidth) {
        totalTextHeight += lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    });
    totalTextHeight += lineHeight * 1.5;
  });
  
  // 요소들의 위치 계산
  const SITE_URL_Y = TOP_MARGIN + 30;
  const TITLE_Y = SITE_URL_Y + 40;
  const HEADER_HEIGHT = TITLE_Y + 40;
  
  // 이미지 높이 계산
  const IMAGE_HEIGHT = VIEWPORT_HEIGHT * 0.33;
  
  // 전체 캔버스 높이 계산
  const TOTAL_HEIGHT = TOP_MARGIN + HEADER_HEIGHT + IMAGE_HEIGHT + totalTextHeight + (TEXT_PADDING * 2);
  
  canvas.width = CANVAS_WIDTH * dpr;
  canvas.height = TOTAL_HEIGHT * dpr;
  ctx.scale(dpr, dpr);
  
  canvas.style.width = CANVAS_WIDTH + 'px';
  canvas.style.height = TOTAL_HEIGHT + 'px';
  
  const img = new Image();
  img.crossOrigin = 'anonymous';
  
  return new Promise((resolve, reject) => {
    img.onload = () => {
      // 배경색 설정
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, CANVAS_WIDTH, TOTAL_HEIGHT);
      
      // 상단 사이트 주소 추가
      ctx.fillStyle = '#34776d';
      ctx.font = 'bold 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(process.env.REACT_APP_PUBLIC_URL, CANVAS_WIDTH / 2, SITE_URL_Y);
      
      // "60일주 테스트" 텍스트 추가
      ctx.fillStyle = '#2c3e50';
      ctx.font = 'bold 24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
      ctx.fillText('사주 유형(일주) 테스트', CANVAS_WIDTH / 2, TITLE_Y);
      
      // 이미지 그리기 (라운드 처리)
      ctx.save();
      
      const scale = IMAGE_HEIGHT / img.height;
      const scaledWidth = img.width * scale;
      const x = (CANVAS_WIDTH - scaledWidth) / 2;
      const y = HEADER_HEIGHT;
      
      // 라운드 처리를 위한 패스 생성
      ctx.beginPath();
      ctx.moveTo(x + BORDER_RADIUS/dpr, y);
      ctx.lineTo(x + scaledWidth - BORDER_RADIUS/dpr, y);
      ctx.quadraticCurveTo(x + scaledWidth, y, x + scaledWidth, y + BORDER_RADIUS/dpr);
      ctx.lineTo(x + scaledWidth, y + IMAGE_HEIGHT - BORDER_RADIUS/dpr);
      ctx.quadraticCurveTo(x + scaledWidth, y + IMAGE_HEIGHT, x + scaledWidth - BORDER_RADIUS/dpr, y + IMAGE_HEIGHT);
      ctx.lineTo(x + BORDER_RADIUS/dpr, y + IMAGE_HEIGHT);
      ctx.quadraticCurveTo(x, y + IMAGE_HEIGHT, x, y + IMAGE_HEIGHT - BORDER_RADIUS/dpr);
      ctx.lineTo(x, y + BORDER_RADIUS/dpr);
      ctx.quadraticCurveTo(x, y, x + BORDER_RADIUS/dpr, y);
      ctx.closePath();
      
      ctx.clip();
      
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, x, y, scaledWidth, IMAGE_HEIGHT);
      
      ctx.restore();
      
      // 텍스트 렌더링
      ctx.fillStyle = 'black';
      ctx.font = '16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
      ctx.textAlign = 'center';
      
      let currentY = HEADER_HEIGHT + IMAGE_HEIGHT + TEXT_PADDING;
      
      paragraphs.forEach(paragraph => {
        if (paragraph.trim() === '') {
          currentY += lineHeight;
          return;
        }
        
        const words = paragraph.split(' ');
        let line = '';
        
        words.forEach(word => {
          const testLine = line + word + ' ';
          const metrics = ctx.measureText(testLine);
          
          if (metrics.width > maxWidth) {
            ctx.fillText(line, CANVAS_WIDTH / 2, currentY);
            line = word + ' ';
            currentY += lineHeight;
          } else {
            line = testLine;
          }
        });
        
        if (line) {
          ctx.fillText(line.trim(), CANVAS_WIDTH / 2, currentY);
          currentY += lineHeight * 1.5;
        }
      });
      
      canvas.toBlob(blob => {
        resolve(new File([blob], 'share-image.png', { type: 'image/png' }));
      }, 'image/png', 1.0);
    };
    
    img.onerror = reject;
    img.src = originalImage;
  });
};

function DayPillarDetailPage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { id } = useParams();
  const [pillarData, setPillarData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const shareButtonsRef = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0.1,
  });
  
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get('invitation');

  useEffect(() => {
    const invitation = searchParams.get('invitation');
    if (invitation) {
      localStorage.setItem('referral_code', invitation);
    }
  }, [searchParams]);

  useEffect(() => {
    try {
      const foundPillar = dayPillarContentsData.common.find(p => p.id === id);
      if (!foundPillar) {
        throw new Error('해당하는 일주를 찾을 수 없습니다.');
      }
      setPillarData(foundPillar);
    } catch (err) {
      setError(err.message);
    }
  }, [id]);

  const handleTestButtonClick = async () => {
    if (!isAuthenticated) {
      try {
        setIsLoading(true);
        localStorage.setItem('intended_redirect_url', '/day-pillar-router');
        await handleKakaoLogin();
      } catch (error) {
        console.error('Kakao login failed:', error);
        setIsLoading(false);
      }
      return;
    }

    if (isAuthenticated ) {
      shareButtonsRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    navigate('/day-pillar-router');
  };

  const handleShare = async () => {
    const baseUrl = window.location.origin;
    const currentPath = window.location.pathname;
    const params = new URLSearchParams(searchParams);
    
    const shareUrl = `${baseUrl}${currentPath}?${params.toString()}`;
    
    // 문장 단위로 자르기
    const sentences = pillarData.personality.split(/[.!?]+/).filter(Boolean);
    const truncatedDesc = sentences.slice(0, 3).join('. ') + '.';
    const shareText = `${pillarData.name}\n\n${truncatedDesc}`;
    
    try {
      const imageUrl = pillarData.image;
      const combinedImageFile = await createShareImage(imageUrl, shareText);

      // 먼저 클립보드에 URL 복사 시도
      try {
        await navigator.clipboard.writeText(shareUrl);
      } catch (clipboardErr) {
        console.error('클립보드 복사 실패:', clipboardErr);
      }

      // 이미지 공유 시도
      if (navigator.share && navigator.canShare({ files: [combinedImageFile] })) {
        await navigator.share({
          files: [combinedImageFile],
          title: `${pillarData.name} - 나의 일주 결과`,
          url: shareUrl
        });
      } else {
        // 이미지 공유가 불가능한 경우 텍스트만 공유
        await navigator.share({
          title: `${pillarData.name} - 나의 일주 결과`,
          text: shareText,
          url: shareUrl
        });
      }
    } catch (err) {
      console.error('공유 실패:', err);
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert('링크가 클립보드에 복사되었습니다!');
      } catch (clipboardErr) {
        alert('링크 공유에 실패했습니다.');
      }
    }
  };

  const handleKakaoShare = () => {
    if (!window.Kakao) {
      console.error('Kakao SDK가 로드되지 않았습니다.');
      return;
    }

    const baseUrl = window.location.origin;
    const currentPath = window.location.pathname;
    const params = new URLSearchParams(searchParams);
    
    if (referralCode) {
      params.set('invitation', referralCode);
    }
    
    const resultUrl = `${baseUrl}${currentPath}?${params.toString()}`;
    const testUrl = `${baseUrl}/day-pillar-gate${referralCode ? `?invitation=${referralCode}` : ''}`;

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: `${pillarData.name} - 나의 일주 결과`,
        description: pillarData.personality.slice(0, 100) + '...',
        imageUrl: pillarData.image,
        link: {
          mobileWebUrl: resultUrl,
          webUrl: resultUrl,
        },
      },
      buttons: [
        {
          title: '결과보기',
          link: {
            mobileWebUrl: resultUrl,
            webUrl: resultUrl,
          },
        },
        {
          title: '테스트하기',
          link: {
            mobileWebUrl: testUrl,
            webUrl: testUrl,
          },
        },
      ],
    });
  };

  if (error) return <div className="error-message">에러: {error}</div>;
  if (!pillarData) return <div className="loading-message">로딩중...</div>;

  return (
    <div className="day-pillar-detail">
      <main className="day-pillar-detail__content">
        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <header className="day-pillar-detail__header">
              <h1 className="day-pillar-detail__title">{pillarData.name}</h1>
            </header>

            <div className="day-pillar-detail__image-wrapper">
              <img
                src={pillarData.image}
                alt={pillarData.name}
                className="day-pillar-detail__main-image"
              />
            </div>
          </AnimatedSection>
        </section>

        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <h2 className="day-pillar-detail__section-title">
              <img 
                src={ohangDataV2[hanjaOhangMapping[pillarData.heavenlyStem.char]].symbol} 
                alt={hanjaOhangMapping[pillarData.heavenlyStem.char]}
                className="day-pillar-detail__ohang-symbol"
              />
              천간 - {pillarData.heavenlyStem.char}
            </h2>
            <div className="day-pillar-detail__description">
              <p><strong>오행:</strong> {pillarData.heavenlyStem.element}</p>
              <p><strong>상징:</strong> {pillarData.heavenlyStem.symbol}</p>
              <p><strong>의미:</strong> {pillarData.heavenlyStem.meaning}</p>
            </div>
          </AnimatedSection>
        </section>

        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <h2 className="day-pillar-detail__section-title">
              <img 
                src={ohangDataV2[hanjaOhangMapping[pillarData.earthlyBranch.char]].symbol} 
                alt={hanjaOhangMapping[pillarData.earthlyBranch.char]}
                className="day-pillar-detail__ohang-symbol"
              />
              지지 - {pillarData.earthlyBranch.char}
            </h2>
            <div className="day-pillar-detail__description">
              <p><strong>오행:</strong> {pillarData.earthlyBranch.element}</p>
              <p><strong>동물:</strong> {pillarData.earthlyBranch.animal}</p>
              <p><strong>상징:</strong> {pillarData.earthlyBranch.symbol}</p>
              <p><strong>의미:</strong> {pillarData.earthlyBranch.meaning}</p>
            </div>
          </AnimatedSection>
        </section>

        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <h2 className="day-pillar-detail__section-title">👤 성격</h2>
            <p className="day-pillar-detail__description">{pillarData.personality}</p>
          </AnimatedSection>
        </section>

        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <h2 className="day-pillar-detail__section-title">💝 인간관계</h2>
            <p className="day-pillar-detail__description">{pillarData.relationship}</p>
          </AnimatedSection>
        </section>

        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <h2 className="day-pillar-detail__section-title">💼 적성 분야</h2>
            <p className="day-pillar-detail__description">{pillarData.career}</p>
          </AnimatedSection>
        </section>

        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <h2 className="day-pillar-detail__section-title">👔 어울리는 직업</h2>
            <div className="day-pillar-detail__career-tags">
              {pillarData.recommendedCareers.map((career, index) => (
                <span key={index} className="day-pillar-detail__tag">{career}</span>
              ))}
            </div>
          </AnimatedSection>
        </section>

        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <h2 className="day-pillar-detail__section-title">💵 재물운</h2>
            <p className="day-pillar-detail__description">{pillarData.wealth}</p>
          </AnimatedSection>
        </section>

        <section className="day-pillar-detail__section">
          <AnimatedSection>
            <h2 className="day-pillar-detail__section-title">💰 재물을 불리는 방식</h2>
            <div className="day-pillar-detail__career-tags">
              {pillarData.wealthKeywords.map((keyword, index) => (
                <span key={index} className="day-pillar-detail__tag">{keyword}</span>
              ))}
            </div>
          </AnimatedSection>
        </section>

        {isAuthenticated && (
          <section 
            ref={(element) => {
              shareButtonsRef.current = element;
              ref(element);
            }}
            className="day-pillar-detail__share-section"
          >
            <div className="day-pillar-detail__share-buttons day-pillar-detail__share-buttons--vertical">
              <button 
                className="day-pillar-detail__share-button"
                onClick={handleShare}
              >
                <span role="img" aria-label="share">📤</span>
                링크 공유하기
              </button>
              <button 
                className="day-pillar-detail__share-button"
                onClick={handleKakaoShare}
              >
                <span role="img" aria-label="kakao share">💬</span>
                카카오톡으로 공유하기
              </button>
              <p className="day-pillar-detail__share-reward-info">
                공유된 링크로 친구도 테스트를 진행할 경우<br />
                리워드 점수가 올라가요! <br/>
                점수를 모아 유료 운세 이용권을 받아보세요.<br/>
                마이페이지 메뉴에서 확인 가능합니다.
              </p>
              <button 
                  className="day-pillar-detail__share-button day-pillar-detail__share-button--newyear"
                  onClick={() => navigate('/?section=services')}
                >
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Microsoft-Teams-Animated-Emojis/master/Emojis/Travel%20and%20places/Star.png" 
                    alt="" 
                    className="day-pillar-detail__button-icon--left"
                  />
                  다른 서비스 둘러보기
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Microsoft-Teams-Animated-Emojis/master/Emojis/Travel%20and%20places/Star.png" 
                    alt="" 
                    className="day-pillar-detail__button-icon--right"
                  />
              </button>
              <button 
                  className="day-pillar-detail__share-button day-pillar-detail__share-button--newyear"
                  onClick={() => navigate('/my-page')}
                >
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Light%20Bulb.webp" 
                    alt="" 
                     className="day-pillar-detail__button-icon--left"
                  />
                  나의 사주명식 보러가기
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Light%20Bulb.webp" 
                    alt="" 
                   className="day-pillar-detail__button-icon--right"
                  />
              </button>
              <button 
                className="day-pillar-detail__share-button day-pillar-detail__share-button--newyear"
                onClick={() => navigate('/new-year-gate')}
              >
                <img 
                  src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png" 
                  alt="" 
                  className="day-pillar-detail__button-icon--left"
                />
                25년 신년운세 보러가기
                <img 
                  src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png" 
                  alt="" 
                  className="day-pillar-detail__button-icon--right"
                />
              </button>
            </div>
          </section>
        )}
      </main>

      {!inView && (
        <button 
          className={`day-pillar-detail__floating-button ${isLoading ? 'loading' : ''}`}
          onClick={handleTestButtonClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="day-pillar-detail__loading-spinner"></span>
          ) : (
            !isAuthenticated 
              ? '무료 테스트 해보기'
              : '내 결과 공유하기'
          )}
        </button>
      )}
    </div>
  );
}

export default DayPillarDetailPage; 