import React, { useState, useEffect, useMemo, useCallback, useRef} from 'react';
import ChatBot from 'react-simple-chatbot';
import '../App.css';
// import botAvatar from '../images/male_avatar_bot_profile-min.png'
import botAvatar from '../images/miri_teller_2.png'
import { ThemeProvider } from "styled-components"
import NewYearFortuneList from './contents/NewYearFortuneList';
import CustomOptionButtons from './contents/CustomOptionButton';
import DisplayQuestions from './contents/DisplayQuestions';
import DisplayQuestionsNewYear from './contents/DisplayQuestionsNewYear';
import DisplayPinnedChoices from './contents/DisplayPinnedChoices';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import CustomHeader from '../components/CustomHeader';
import headerLogo from '../images/seowha_logo.png'

import { FORTUNE_TYPES, isValidFortuneType } from '../constants/FortuneTypes';
import {saveFortuneResult} from '../services/SaveFortuneResult'
import { PERSON_TYPES, isValidPersonType, DEFAULT_PROFILE } from '../constants/PersonTypes';
import { PersonProfileManager } from '../models/PersonProfile';
import { apiService } from '../utils/api'; 
import DisplayQuestionsNewYearQnA from './contents/DisplayQuestionsNewYearQnA';
import { ohangData, ohangDataV2, skyHanjaData, landHanjaData, hanjaOhangMapping } from '../components/ohangData';

import CustomChatBot from '../components/CustomChatbot';

import messages from '../constants/messages.json'


const csrfToken = window.csrfToken;


const CATEGORY_CATEG_ID = "2025년_신년운세" // 모든 운세컨텐츠의 unique 값을 표현함


function NewYearPersonaPage() {

  const navigate = useNavigate();
  const chatbotRef = useRef(null);
  const ticketIdRef = useRef(null);

  const autoScrollRef = useRef(true);
  const forceScrollRef = useRef(false);

  const yearSummaryRef = useRef({
    favorable_periods: [],
    cautious_periods: [],
    luck_summary: ''
  });

  //처음 시작할땐 조회하고, 마지막 파트에서 차감이 필요하다면 차감해야함

  const [showNewMessagePopup, setShowNewMessagePopup] = useState(true);

  //뷰포트를 이용한 함수
  const scrollToBottom = () => {
    if (chatbotRef.current) {
      const chatbotNode = ReactDOM.findDOMNode(chatbotRef.current);
      if (chatbotNode) {
        const scrollContainer = chatbotNode.querySelector('.rsc-content');
        if (scrollContainer) {
          // 현재 스크롤 위치 계산
          const scrollPercentage = (scrollContainer.scrollTop + scrollContainer.clientHeight) / scrollContainer.scrollHeight * 100;
          
          // 뷰포트 높이를 기준으로 한 스크롤 거리 계산
          const viewportHeight = scrollContainer.clientHeight;
          const totalHeight = scrollContainer.scrollHeight;
          const currentScroll = scrollContainer.scrollTop;
          const distanceFromBottom = totalHeight - (currentScroll + viewportHeight);
          
          // 뷰포트 높이의 N% 이내에 있는지 확인
          const isNearBottom = distanceFromBottom <= (viewportHeight * 0.25);
          
          updateScrollInfo(scrollContainer);
  
          requestAnimationFrame(() => {
            if (forceScrollRef.current || (autoScrollRef.current !== false && isNearBottom)) {
              scrollContainer.scroll({
                top: scrollContainer.scrollHeight,
                left: 0,
                behavior: 'smooth'
              });
              
              setShowNewMessagePopup(false);
              autoScrollRef.current = true;
              forceScrollRef.current = false;
            } else {
              setShowNewMessagePopup(true);
              autoScrollRef.current = false;
            }
          });
        }
      }
    }
  };

  
  // 스크롤 퍼센트를 저장할 state 추가
  //DEV
  const [scrollInfo, setScrollInfo] = useState({
    distanceFromBottom: 0,    // 바닥까지 남은 픽셀
    viewportHeight: 0,        // 현재 뷰포트 높이
    distanceInViewports: 0,   // 바닥까지 남은 거리 (뷰포트 단위)
  });
  
  // scrollToBottom, handleScroll 등의 함수에서 스크롤 정보 업데이트
  const updateScrollInfo = (scrollContainer) => {
    const viewportHeight = scrollContainer.clientHeight;
    const totalHeight = scrollContainer.scrollHeight;
    const currentScroll = scrollContainer.scrollTop;
    const distanceFromBottom = totalHeight - (currentScroll + viewportHeight);
    
    setScrollInfo({
      distanceFromBottom: Math.round(distanceFromBottom),  // 픽셀 단위
      viewportHeight: Math.round(viewportHeight),          // 현재 뷰포트 높이
      distanceInViewports: Math.round((distanceFromBottom / viewportHeight) * 100) / 100  // 소수점 2자리까지
    });
  };


  // 팝업 클릭 시 스크롤 처리
  const handlePopupClick = () => {
    if (chatbotRef.current) {
      const chatbotNode = ReactDOM.findDOMNode(chatbotRef.current);
      if (chatbotNode) {
        const scrollContainer = chatbotNode.querySelector('.rsc-content');
        if (scrollContainer) {
          requestAnimationFrame(() => {
            scrollContainer.scrollTop = scrollContainer.scrollHeight;
            setShowNewMessagePopup(false);
            autoScrollRef.current = true;
          });
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (chatbotRef.current) {
        const chatbotNode = ReactDOM.findDOMNode(chatbotRef.current);
        if (chatbotNode) {
          const scrollContainer = chatbotNode.querySelector('.rsc-content');
          if (scrollContainer) {
            requestAnimationFrame(() => {
              // DEV
              updateScrollInfo(scrollContainer);
  
              // 뷰포트 기준 계산
              const viewportHeight = scrollContainer.clientHeight;
              const totalHeight = scrollContainer.scrollHeight;
              const currentScroll = scrollContainer.scrollTop;
              const distanceFromBottom = totalHeight - (currentScroll + viewportHeight);
              
              // scrollToBottom과 동일한 기준 (0.1) 사용
              const isNearBottom = distanceFromBottom <= (viewportHeight * 0.25);
              
              if (isNearBottom) {
                setShowNewMessagePopup(false);
                autoScrollRef.current = true;
              } else {
                autoScrollRef.current = false;
                //DEV
                // setShowNewMessagePopup(true);
              }
            });
          }
        }
      }
    };

  // iOS Safari에서 visualViewport API가 없는 경우 대체 처리
  if (!window.visualViewport) {
    window.visualViewport = {
      addEventListener: window.addEventListener.bind(window),
      removeEventListener: window.removeEventListener.bind(window),
      height: window.innerHeight,
    };
  }

  // 스크롤 이벤트 리스너 등록
  const chatbotNode = ReactDOM.findDOMNode(chatbotRef.current);
  if (chatbotNode) {
    const scrollContainer = chatbotNode.querySelector('.rsc-content');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll, true);
      
      // cleanup 함수
      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll, true);
      };
    }
  }
}, []); // 컴포넌트 마운트 시 한 번만 실행

  const scrollDownByPixels = (pixels) => {
    if (chatbotRef.current) {
      const chatbotNode = ReactDOM.findDOMNode(chatbotRef.current);
      if (chatbotNode) {
        const scrollContainer = chatbotNode.querySelector('.rsc-content');
        if (scrollContainer) {
          // 뷰포트 높이를 기준으로 한 스크롤 거리 계산
          const viewportHeight = scrollContainer.clientHeight;
          const totalHeight = scrollContainer.scrollHeight;
          const currentScroll = scrollContainer.scrollTop;
          const distanceFromBottom = totalHeight - (currentScroll + viewportHeight);
          
          // scrollToBottom과 동일한 기준 사용 (0.23)
          const isNearBottom = distanceFromBottom <= (viewportHeight * 0.25);
          
          updateScrollInfo(scrollContainer);

          requestAnimationFrame(() => {
              // 현재 스크롤 위치에서 지정된 픽셀만큼 부드럽게 스크롤
              const currentScrollPosition = scrollContainer.scrollTop;
              scrollContainer.scroll({
                top: currentScrollPosition + pixels,
                left: 0,
                behavior: 'smooth'
              });

              setShowNewMessagePopup(false);
              autoScrollRef.current = true;
              forceScrollRef.current = false;

          });
        } else {
          console.log('scrollContainer (.rsc-content) not found');
        }
      } else {
        console.log('chatbotNode is null');
      }
    } else {
      console.log('chatbotRef.current is null');
    }
  };

  // 

  //저장을 위해 사용될 함수
  // state 대신 ref 사용
  const selectedQAsRef = useRef(new Map());

  // 질문 선택 시 호출될 함수 || 아래 handleAnswer와 redundant하지 않은지 향후 체크 필요 DEV FIX
  const handleQuestionSelect = (category, question) => {
    console.log(`Selected Q&A - Category: ${category}, Question: ${question}`);
    const newQAs = new Map(selectedQAsRef.current);
    const qaList = newQAs.get(category) || [];
    qaList.push({ question, answer: '' });
    newQAs.set(category, qaList);
    selectedQAsRef.current = newQAs;
    
    //질문이 눌렸으니 scrollTOBottom을 force시킨다
    forceScrollRef.current = true ;
    // UI 업데이트를 위한 state도 함께 관리 (필요한 경우)
  };

  // 답변 저장
  const handleAnswer = (category, answer) => {
    console.log('=== Handle Answer Debug ===');
    console.log(`1. Saving Answer - Category: ${category}, Answer:`, answer);
    
    const newQAs = new Map(selectedQAsRef.current);
    console.log('2. Current QAs Map:', Object.fromEntries(selectedQAsRef.current));
    
    if (newQAs.has(category)) {
      const qaList = newQAs.get(category);
      console.log(`3. Found QA list for ${category}:`, qaList);
      
      const lastQA = qaList[qaList.length - 1];
      console.log('4. Last QA:', lastQA);
      
      if (lastQA && lastQA.answer === '') {
        console.log('5. Updating answer for question:', lastQA.question);
        lastQA.answer = answer;
        newQAs.set(category, qaList);
        selectedQAsRef.current = newQAs;
        
        console.log('6. Updated QA list:', qaList);
        console.log('7. Final QAs Map:', Object.fromEntries(selectedQAsRef.current));
      } else {
        console.log('5. Answer not updated because:', 
          !lastQA ? 'no question found' : 'answer already exists');
      }
    } else {
      console.log(`3. No QA list found for category: ${category}`);
    }
    console.log('=== Handle Answer Debug End ===');
  };

  const extractKoreanName = (fullName) => {
    if (!fullName) return { lastName: '', firstName: '' };
  
    // 이름 분리를 하지 않을 특수 성씨 목록
    const specialLastNames = [
      '남궁', 
      '제갈',
      '선우',
      '독고',
      '황보',
      '사공',
      '서문',
      '동방'
    ];
  
    // 특수 성씨로 시작하는지 확인
    const hasSpecialLastName = specialLastNames.some(lastName => 
      fullName.startsWith(lastName)
    );
  
    if (hasSpecialLastName) {
      return {
        lastName: '',  // 성 구분이 불필요한 경우
        firstName: fullName  // 전체 이름을 firstName으로 사용
      };
    }
  
    // 일반적인 경우: 끝의 2글자를 이름으로 사용
    return {
      lastName: fullName.slice(0, fullName.length - 2),
      firstName: fullName.slice(-2)  // 마지막 2글자를 이름으로 사용
    };
  };

  const fetchAndUpdateSelfProfile = async () => {
    try {
      // relationship=self 쿼리 파라미터를 사용하여 self 프로필만 가져오기
      const response = await apiService.get('/fortune/persons/?relationship=self');

      // self 프로필이 있는 경우 (배열의 첫 번째 항목 사용)
      if (response.data.length > 0) {

        const selfData = response.data[0];
        const { firstName } = extractKoreanName(selfData.name);

        const profileData = {
          birthday: {
            year: selfData.birth_year,
            month: selfData.birth_month,
            day: selfData.birth_day,
            hour: selfData.birth_hour,
            minute: selfData.birth_minute
          },
          userInfo: {
            person_id: selfData.id, //백엔드에 연결되는 ID vs profileId랑 다름 (프론트에만 사용)
            first_name: firstName,
            gender: selfData.gender_display,
            is_birth_time_unknown: selfData.is_birth_time_unknown
          }
        };

        // self 프로필 업데이트
        updateProfile('self', profileData);
        setCurrentProfileId('self');
      } else {
        // self 프로필이 없는 경우 기본 프로필 사용
        console.log('Self profile not found, using default profile');
        updateProfile('self', DEFAULT_PROFILE);
      }

    } catch (error) {
      console.error('Error fetching self profile:', error);
      updateProfile('self', DEFAULT_PROFILE);
    }
  };

  // 향후 'self' 뿐만이 아니라 다른 프로필 타입에 대해서도 실행
  // TODO: API CALL을 통해 type, 그리고 이름에 따라 프로필을 가져오도록 실행

  const prepareDirectBaseSaju = async (type) => {
    try {
      // 0. TODO: fetchAndUpdateSelfProfile 대신 fetchAndUpdateOOTypeProfile

      // 1. additionalParams 업데이트
      await updateAdditionalParams(
        {
          action: 'direct_base_saju'
        },
        'prepareDirectBaseSaju'
      );
  
      // 2. assistant와 thread ID 변경
      await changeAssistantAndThreadId('direct_base_saju', '');
  
      console.log('Direct base saju preparation completed');
      
    } catch (error) {
      console.error('Error preparing direct base saju:', error);
    }
  };

  // https://www.notion.so/hkbusiness/1063a0ef8cc98025add0dac29ad0b98d

  const [questions, setQuestions] = useState({
    '2025_all_luck': {
      default: [
        "올해 특별히 구체적으로 어떤 활동을 하면 좋을까요?",
        "왜 저를 공격하는 사람이 있는 걸까요?",
        "동료들과의 관계가 어려워요",
        "어떻게 해야 주변 사람들과 원만히 지낼 수 있을까요?",
        "저는 어떤 사람과 잘 맞나요?",
        "주변 사람들과 잘 맞지 않는 이유가 뭘까요?",

        "2025년에 제가 가진 가장 큰 기회는 무엇인가요?",
        "올해 제가 집중해야 할 주요 목표는 무엇일까요?",
        "저의 숨겨진 재능을 어떻게 활용하면 좋을까요?",
        "올해 제가 도전해보면 좋을 새로운 분야가 있을까요?",
        "행운이 따르는 시기는 언제이고 어떻게 활용하면 좋을까요?",
        "저의 장점을 극대화할 수 있는 방법이 궁금해요",
        "올해 인연운이 좋은 시기는 언제인가요?",
        "제가 가진 에너지를 어떤 방향으로 집중하면 좋을까요?",
        "올해 제가 꼭 알아야 할 중요한 조언이 있다면 무엇인가요?"
      ]
    },
    '2025_money_luck': {
      default: [
        "돈을 벌 수 있는 분야는 어디인가요?",
        "금전적으로 조심해야할 분야가 있을까요?",
        "저의 재물운은 어떤가요?",
        "저에게 잘 맞는 투자는 뭘까요?",
        "추천하는 투잡 활동이 있을까요?"
      ]
    },
    '2025_love_luck': {
      default: [
        "제가 이성과 만날 때 조심해야 할 점이 있나요?",
        "제 사주에서 보이는 이상적인 짝의 특징은 무엇인가요?",
        "결혼이나 연애에서 제가 주의해야 할 점은 무엇인가요?"
      ],
      single: [
        "이성을 만나기 위해 추천하는 액티비티나 모임이 있을까요?",
        "언제쯤 좋은 인연이 나타날까요?",
        "제 사주는 이성을 만나기에 유리한가요?",
        "좋은 이성을 만나는 방법이 궁금해요",
        "미래 배우자는 어떤 사람일까요?",
        "저는 결혼을 하는게 좋을까요?"
      ],
      dating_happy: [
        "현재 연애 중인 사람과 결혼해도 될까요?",
        "언제 결혼할 수 있을까요?",
        "현재 관계를 발전시키려면 어떻게 해야 할까요?",
      ],
      married: [
        "부부 관계를 개선하려면 어떻게 해야 할까요?",
        "부부 사이의 신뢰를 높이려면 어떻게 해야 할까요?",
      ]
    },
    '2025_career_luck': {
      default: [
        "제 사주에서 보이는 직업운은 어떤가요?",
        "올해 경력 개발을 위해 주의해야 할 점은 무엇인가요?",
        "제가 가진 장점을 직장에서 어떻게 활용하면 좋을까요?",
      ],
      not_working: [
        "어떤 분야에서 일하면 좋을까요?",
        "취업 준비는 어떻게 하면 좋을까요?",
        "이직을 준비하는게 좋을까요?",
        "잘 맞는 직종은 뭔가요?",
        "적성에 맞는 직무는 뭘까요?",
        "저의 천직은 무엇일까요?"
      ],
      job_seeking: [
        "어떤 직종이 잘 맞을까요?",
        "언제쯤 취업이 될까요?",
        "면접 운이 좋은 시기는 언제인가요?",
        "어떤 회사에 지원하면 좋을까요?"
      ],
      employed: [
        "승진 가능성은 어떤가요?",
        "현재 직장을 계속 다녀야 할까요?",
        "이직이 필요할까요?",
        "직장에서 잘 나갈 수 있을까요?",
        "동료 또는 상사에게 인정받는 방법이 궁금해요",
        "직장에서 인정받는 방법은 뭘까요?",
        "직장 생활은 언제까지 할 수 있을까요?",
        "회사생활 중 제 단점을 커버하는 방법은 뭘까요?",
        "승진이 안 되는 이유는 뭘까요?"
      ],
      business_owner: [
        "사업 확장의 적기는 언제일까요?",
        "어떤 분야로 확장하면 좋을까요?",
        "동업자나 파트너는 어떻게 만나야 할까요?",
        "장사나 사업을 하면 잘 맞을까요?",
        "어떤 사업을 하면 잘 맞을까요?",
        "새로운 사업을 시작해도 될까요?",
        "사업에서 좋은 성과를 낼 시기는 언제일까요?"
      ]
    },
    '2025_study_luck': {
      default: [
        "저는 공부를 잘하는 스타일일까요?",
        "자격증 공부를 하는게 맞을까요?",
        "어떤 자격증을 따는 게 잘 맞을까요?",
        "시험운은 어떤가요?"
      ]
    },
    '2025_coming_luck': {
      default: [
        "올해 제게 찾아올 가장 큰 기회는 무엇인가요?",
        "어떤 분야에서 행운이 따를까요?",
        "행운을 극대화하기 위해 해야 할 일이 있나요?",
        "올해 피해야 할 불운한 상황이 있을까요?",
        "귀인을 만날 수 있는 장소나 시기가 있나요?",
        "새로운 도전을 시작하기에 좋은 시기는 언제인가요?",
        "올해 제게 찾아올 변화는 어떤 것들이 있나요?",
        "행운을 만드는 좋은 습관이나 행동이 있을까요?",
        "올해 제가 집중해야 할 주요 활동은 무엇인가요?",
        "좋은 기회가 올 때 놓치지 않으려면 어떻게 해야 할까요?"
      ]
    }
  });

  // 상태에 따른 질문 필터링 함수
  const getFilteredQuestions = (category) => {
    const questions = questionsRef.current[category];
    if (!questions) return [];
  
    // 현재 프로필의 상태 가져오기
    const currentProfile = getCurrentProfile();
    const datingStatus = currentProfile?.userInfo?.dating_status || 'single';
    const careerStatus = currentProfile?.userInfo?.career_status || 'not_working';
  
    // 카테고리별 상태 기반 필터링
    let filteredQuestions = new Set(); // Set을 사용하여 중복 제거
    if (category === '2025_love_luck') {
      // 상태별 질문과 기본 질문을 Set에 추가
      (questions[datingStatus] || []).forEach(q => filteredQuestions.add(q));
      (questions.default || []).forEach(q => filteredQuestions.add(q));
    } else if (category === '2025_career_luck') {
      (questions[careerStatus] || []).forEach(q => filteredQuestions.add(q));
      (questions.default || []).forEach(q => filteredQuestions.add(q));
    } else {
      (questions.default || []).forEach(q => filteredQuestions.add(q));
    }
  
    // Set을 배열로 변환하고 랜덤으로 섞기
    const shuffled = [...filteredQuestions].sort(() => 0.5 - Math.random());
    
    // 최대 3개까지만 반환하되, 가능한 질문이 4개 미만이면 그만큼만 반환
    return shuffled.slice(0, Math.min(3, shuffled.length));
  };

  
  // 티켓 체크 함수
  const checkAndSetTicket = async () => {
    try {
      console.log('타셋 운세 카테고리:',  CATEGORY_CATEG_ID);
      const response = await apiService.get('/fortune/fortune-tickets/check/', {
        params: {
          categ_id: CATEGORY_CATEG_ID
        }
      });
  
      console.log('티켓 체크 응답:', response.data);
      // DEV FIX: 향후 Modal로 교체 가능할수 있음
      if (response.data.has_valid_ticket) {
        ticketIdRef.current = response.data.ticket_id; 
        return true;
      } else {
        alert('2025년 신년운세를 보기 위해서는 사용권이 필요합니다.');
        navigate('/new-year-gate');
        return false;
      }
    } catch (error) {
      console.error('티켓 체크 실패:', error);
      navigate('/');
      return false;
    }
  };

  // FIRST UPLOAD 최초 업로드시 동작해야할 코드는 여기에
  useEffect(() => {
    const init = async () => {
      await fetchAndUpdateSelfProfile();
      // await checkAndSetTicket();  // 티켓 체크 추가
    };
    
    init();
  }, []);

  // questions의 최신 상태를 참조하기 위한 ref
  const questionsRef = useRef(questions);

  // questions 상태가 변경될 때마다 ref 업데이트
  useEffect(() => {
    questionsRef.current = questions;
    console.log("Questions state updated:", questions);
  }, [questions]);
  
  // 현재 상태에 따른 질문 목록 가져오기
  const getQuestionsForCategory = (category) => {
    const categoryQuestions = questionsRef.current[category];
    if (!categoryQuestions) return [];
  
    const currentProfile = getCurrentProfile();
    const status = 
      category === '2025_love_luck' ? currentProfile?.userInfo?.dating_status :
      category === '2025_career_luck' ? currentProfile?.userInfo?.career_status :
      'default';
  
    // default 질문과 상태별 질문을 합침
    const defaultQuestions = categoryQuestions.default || [];
    const statusQuestions = status && categoryQuestions[status] ? categoryQuestions[status] : [];
    
    return [...defaultQuestions, ...statusQuestions];
  };
  
  // 질문 제거 함수
  const removeQuestion = (category, questionToRemove) => {
    // state 업데이트
    setQuestions(prevQuestions => {
      const newQuestions = { ...prevQuestions };
      const categoryQuestions = newQuestions[category];
      
      // default와 상태별 질문 모두에서 해당 질문 제거
      Object.keys(categoryQuestions).forEach(status => {
        if (Array.isArray(categoryQuestions[status])) {
          categoryQuestions[status] = categoryQuestions[status].filter(q => q !== questionToRemove);
        }
      });
  
      // ref도 동시에 업데이트
      questionsRef.current = newQuestions;
      
      return newQuestions;
    });
  };


  const getCopiedQuestions = useCallback(() => {
    return questionsRef.current;
  }, []);


  //애정운 관련
  const relationshipStatusRef = useRef('single');  // 초기값은 빈 문자열
  // const relationshipMessages = {
  //   'single': [
  //     "지금은 비록 솔로 생활을 하고 계시지만",
  //     "말씀드린 애정운을 잘 참고해보세요.",
  //     "분명 좋은 인연을 만날 수 있을 거에요😉"
  //   ],
  //   'dating_happy': [
  //     "말씀드린 애정운을 잘 참고해보시고",
  //     "더 큰 사랑으로 키워나가시길 바랄게요!"
  //   ],
  //   'married': [
  //     "배우자분과 이상적인 연인상이 일치하시나요?🤭",
  //     "두 분의 결혼 생활, 쭈욱- 행복하시길 바랄게요!"
  //   ]
  // };

  const relationshipUserResponses = {
    'single': "좋은 인연 기다릴게요",
    'dating_happy': "더 행복하게 지낼게요",
    'married': "행복하게 살겠습니다"
  };
  
  // 상태 업데이트 함수 - ref와 userInfo state 모두 업데이트
  const updateRelationshipStatus = (status) => {
    relationshipStatusRef.current = status;  // ref는 그대로 유지

    // 현재 프로필 가져오기
    const currentProfile = profileManagerRef.current.getProfile(currentProfileIdRef.current);
    
    // 프로필 업데이트
    setProfileManager(prev => {
      const newManager = new PersonProfileManager();
      newManager.profiles = new Map(prev.profiles);
      newManager.multiTargetIds = new Map(prev.multiTargetIds);
      
      // 현재 프로필의 모든 정보를 유지하면서 dating_status만 업데이트
      newManager.addProfile(
        currentProfile.type,
        {
          birthday: currentProfile.birthday,
          userInfo: {
            ...currentProfile.userInfo,
            dating_status: status
          }
        },
        currentProfile.name
      );
      
      return newManager;
    });
  };
  


  //직장운 관련
  const careerStatusRef = useRef('');  // 초기값은 빈 문자열

  const careerUserResponses = {
    'not_working': "좋은 기회를 기다려볼게요 🌱",
    'job_seeking': "열심히 준비하겠습니다 💪",
    'employed': "더 열심히 해볼게요 ✨",
    'business_owner': "차근차근 해나가볼게요 🌿"
  };
  
  // 상태 업데이트 함수
  const updateCareerStatus = (status) => {
    careerStatusRef.current = status;  // ref는 그대로 유지

    // 현재 프로필 가져오기
    const currentProfile = profileManagerRef.current.getProfile(currentProfileIdRef.current);
    
    // 프로필 업데이트
    setProfileManager(prev => {
      const newManager = new PersonProfileManager();
      newManager.profiles = new Map(prev.profiles);
      newManager.multiTargetIds = new Map(prev.multiTargetIds);
      
      // 현재 프로필의 모든 정보를 유지하면서 career_status만 업데이트
      newManager.addProfile(
        currentProfile.type,
        {
          birthday: currentProfile.birthday,
          userInfo: {
            ...currentProfile.userInfo,
            career_status: status
          }
        },
        currentProfile.name
      );
      
      return newManager;
    });
  };
  

  // 최종 질의 관련 state 추가
  const finalQnaSelectedCategoriesRef = useRef([]);
  const finalQnaCurrentIndexRef = useRef(0); // 외부로 이동
  const [, forceUpdate] = useState({});  // 강제 리렌더링을 위한 state

  // 대분류-중분류 매핑
  const categoryMapping = {
    '2025_all_luck': ['인간관계'],
    '2025_money_luck': ['재물운'],
    '2025_love_luck': ['연애/결혼'],
    '2025_career_luck': ['직업/적성', '직장/취업', '사업'],
    '2025_study_luck': ['시험/합격운'],
  };

  // 중분류-대분류 역매핑 (조회 용이성을 위해)
  const reverseMapping = Object.entries(categoryMapping).reduce((acc, [major, minors]) => {
    minors.forEach(minor => {
      acc[minor] = major;
    });
    return acc;
  }, {});

  const getFinalQuestions = (minorCategory) => {
    const majorCategory = reverseMapping[minorCategory];
    if (!majorCategory) return [];
  
    const categoryQuestions = questionsRef.current[majorCategory];
    if (!categoryQuestions) return [];
  
    const currentProfile = getCurrentProfile();
    const status = 
      majorCategory === '2025_love_luck' ? currentProfile?.userInfo?.dating_status :
      majorCategory === '2025_career_luck' ? currentProfile?.userInfo?.career_status :
      'default';
  
    // default 질문과 상태별 질문을 합침
    const defaultQuestions = categoryQuestions.default || [];
    const statusQuestions = status && categoryQuestions[status] ? categoryQuestions[status] : [];
    
    return [...defaultQuestions, ...statusQuestions];
  };

  // 이미 선택된 질문들을 추적하기 위한 ref 추가
  const selectedQuestionsRef = useRef(new Set());

  // 중복을 피하여 랜덤 질문을 선택하는 함수
  const getRandomQuestionsWithoutDuplication = (questions, n) => {
    // 아직 선택되지 않은 질문들만 필터링
    const availableQuestions = questions.filter(q => !selectedQuestionsRef.current.has(q));
    
    // 사용 가능한 질문이 없으면 selectedQuestions를 초기화
    if (availableQuestions.length === 0) {
      selectedQuestionsRef.current.clear();
      return getRandomQuestionsWithoutDuplication(questions, n);
    }

    // 랜덤으로 질문 선택
    const shuffled = [...availableQuestions].sort(() => 0.5 - Math.random());
    const selected = shuffled.slice(0, Math.min(n, shuffled.length));
    
    // 선택된 질문들을 Set에 추가
    selected.forEach(q => selectedQuestionsRef.current.add(q));
    
    return selected;
  };

  // FOCUS: 추가 파라미터를 저장하는 리프
  const additionalParamsRef = useRef({
    action: 'initial-step',
    output_format: '',
    api_topic_id_key: '',
    current_display: '',
    remaining_questions: 10,
    // display_order: ['2025_all_luck', '2025_money_luck', '2025_love_luck', '2025_career_luck', '2025_study_luck', '2025_coming_luck', ],
    display_order: ['2025_all_luck', '2025_money_luck', '2025_love_luck', '2025_career_luck', '2025_study_luck', '2025_coming_luck', '2025_luck_summary'],
    display_order_kor_str: ['25년 총운', '25년 재물운', '25년 애정운', '25년 직장 및 명예운', '25년 학업 및 계약운', '25년 나에게 다가올 행운']
  });

  const updateAdditionalParams = async (newParams, calledFrom) => {
    return new Promise((resolve) => {
      additionalParamsRef.current = { ...additionalParamsRef.current, ...newParams };
      console.log("##Additional Param Updated:", additionalParamsRef.current);
      console.log("## CALLED BY:", calledFrom);
      
      // setTimeout을 사용하여 다음 이벤트 루프까지 기다립니다.
      // 이는 상태 업데이트가 반영될 시간을 줍니다.
      setTimeout(() => {
        resolve(additionalParamsRef.current);
      }, 0);
    });
  };


  // 헬퍼 함수 추가
  const getNextStepBasedOnRemaining = (category, messageList) => {
    const remainingQuestions = additionalParamsRef.current.remaining_questions;
    const currentIndex = additionalParamsRef.current.display_order.indexOf(category);
    const remainingSections = additionalParamsRef.current.display_order.length - (currentIndex + 1) - 1;
    
    if (remainingQuestions > 0) {
      let finalMessageList = [...messageList];
      
      if (remainingQuestions <= remainingSections && remainingSections > 0 && additionalParamsRef.current.display_order != '2025_coming_luck') {
        finalMessageList.push(messages.remaining_questions_warning.save_questions);
        finalMessageList.push(
          messages.remaining_questions_warning.count.replace('{count}', remainingQuestions)
        );
      }
  
      return {
        trigger: 'make-system-message',
        value: {
          message: finalMessageList,
          next_trigger: 'display-interactive-component',
          additional_params: {
            target_ui_component: `${category}`
          }
        }
      };
    } else {
      return {
        trigger: 'check-next-action',
      };
    }
  };

  // DEV: 운세 저장 상태
  const [isSaving, setIsSaving] = useState(false);
  const savedReadingShareIdRef = useRef(null);


  //
  const [assistantId, setAssistantId] = useState('direct_base_saju'); // draw_base_saju_new_year (GPT로 보냄)
  const [threadId, setThreadId] = useState('');
  const [threadMap, setThreadMap] = useState(new Map());

   // thread_id를 topic별로 관리하기 위한 ref 추가
   const topicThreadIdsRef = useRef({});

   
  // 새로운 함수 추가
  const handleTopicThreadIdChange = (threadId, topicKey) => {
    if (threadId && topicKey) {
      topicThreadIdsRef.current = {
        ...topicThreadIdsRef.current,
        [topicKey]: threadId
      };
      console.log('Topic Thread IDs Updated:', topicThreadIdsRef.current);
    }
  };

  const getTopicThreadId = (topicKey) => {
    return topicThreadIdsRef.current[topicKey];
  };

  //

  // 결과 보기 핸들러 추가
  const handleViewResult = () => {
    navigate(`/fortune-reading/${savedReadingShareIdRef.current}`);
  };

  const handleShare = async () => {
    try {
      const shareUrl = `${window.location.origin}/fortune-reading/${savedReadingShareIdRef.current}`;
      await navigator.clipboard.writeText(shareUrl);
      alert('공유 링크가 클립보드에 복사되었습니다!');
    } catch (err) {
      console.error('클립보드 복사 실패:', err);
      alert('링크 복사에 실패했습니다. 다시 시도해주세요.');
    }
  };
 

  // [프로필 관리 코드]
  const [profileManager, setProfileManager] = useState(new PersonProfileManager());
  const profileManagerRef = useRef(profileManager);
  
  // 현재 선택된 프로필 ID
  const [currentProfileId, setCurrentProfileId] = useState('self'); // 별도의 변경이 없다면 시작은 본인 프로필로
  const currentProfileIdRef = useRef(currentProfileId);

  useEffect(() => {
    profileManagerRef.current = profileManager;
  }, [profileManager]);

  useEffect(() => {
    currentProfileIdRef.current = currentProfileId;
  }, [currentProfileId]);

  // 프로필 업데이트 함수
  // 페이지 최초 업로드 시에 'self'에 대해서 아래 항목 진행
  const updateProfile = (type, profileData, name = '') => {
    setProfileManager(prev => {
      const newManager = new PersonProfileManager();
      newManager.profiles = new Map(prev.profiles);
      newManager.multiTargetIds = new Map(prev.multiTargetIds);
      const profileId = newManager.addProfile(type, profileData, name);
      return newManager;
    });
  };

  // [사용예시] 단일 대상 프로필 업데이트
  // updateProfile(PERSON_TYPES.SINGLE_TARGET.SELF, profileData);

  // // 다중 대상 프로필 추가
  // updateProfile(PERSON_TYPES.MULTI_TARGET.FRIEND, profileData, "철수");
  // updateProfile(PERSON_TYPES.MULTI_TARGET.FRIEND, profileData, "영희");

  // 특정 타입의 모든 프로필 조회
  // const allFriends = profileManager.getProfilesByType(PERSON_TYPES.MULTI_TARGET.FRIEND);

  // // 생년월일 정보만 업데이트
  // const updateProfileBirthday = (birthday) => {
  //   setProfileManager(prev => {
  //     const newManager = new PersonProfileManager();
  //     newManager.profiles = new Map(prev.profiles);
  //     newManager.multiTargetIds = new Map(prev.multiTargetIds);
      
  //     const currentProfile = newManager.getProfile(currentProfileIdRef.current);
  //     if (currentProfile) {
  //       newManager.addProfile(
  //         currentProfile.type,
  //         {
  //           birthday: birthday,
  //           userInfo: currentProfile.userInfo
  //         },
  //         currentProfile.name
  //       );
  //     }
      
  //     return newManager;
  //   });
  // };

  // // 사용자 정보만 업데이트
  // const updateProfileUserInfo = (userInfo) => {
  //   setProfileManager(prev => {
  //     const newManager = new PersonProfileManager();
  //     newManager.profiles = new Map(prev.profiles);
  //     newManager.multiTargetIds = new Map(prev.multiTargetIds);
      
  //     const currentProfile = newManager.getProfile(currentProfileIdRef.current);
  //     if (currentProfile) {
  //       newManager.addProfile(
  //         currentProfile.type,
  //         {
  //           birthday: currentProfile.birthday,
  //           userInfo: {
  //             ...currentProfile.userInfo,
  //             ...userInfo
  //           }
  //         },
  //         currentProfile.name
  //       );
  //     }
      
  //     return newManager;
  //   });
  // };

  // 현재 선택된 프로필 가져오기
  const getCurrentProfile = () => {
    return profileManagerRef.current.getProfile(currentProfileIdRef.current) || DEFAULT_PROFILE;
  };

  // 타입 변경 함수
  const updateUserType = (type, name = '') => {
    if (!isValidPersonType(type)) {
      console.error('Invalid person type:', type);
      return;
    }

    const isSingleTarget = Object.values(PERSON_TYPES.SINGLE_TARGET).includes(type);
    let profileId;

    if (isSingleTarget) {
      profileId = type;
    } else {
      // 다중 대상의 경우 새 프로필 생성
      profileId = profileManagerRef.current.generateProfileId(type, name);
    }

    setCurrentProfileId(profileId);
  };




  // Refs to store the latest threadId and assistantId
  const threadIdRef = useRef(threadId);
  const assistantIdRef = useRef(assistantId);

  useEffect(() => {
    threadIdRef.current = threadId;
  }, [threadId]);

  useEffect(() => {
    assistantIdRef.current = assistantId;
  }, [assistantId]);

  const handleAssistantIdChange = (newAssistantId) => {
    setThreadMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(assistantId, threadIdRef.current); // Use ref to ensure the latest threadId
      return newMap;
    });

    setAssistantId(newAssistantId);
    setThreadId(threadMap.get(newAssistantId) || '');
  };

  const handleThreadIdChange = (newThreadId) => {
    setThreadId(newThreadId);
    setThreadMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(assistantIdRef.current, newThreadId); // Use ref to ensure the latest assistantId
      return newMap;
    });
  };

  const changeAssistantAndThreadId = async (newAssistantId, newThreadId) => {
    // 상태 업데이트는 비동기이기 때문에 그 이후 작업을 처리하기 위해 Promise를 사용
    await new Promise((resolve) => {
      setThreadMap((prevMap) => {
        const newMap = new Map(prevMap);
        newMap.set(assistantId, threadIdRef.current);
        return newMap;
      });

      // 상태 업데이트 후 resolve 호출
      setAssistantId(newAssistantId);
      handleThreadIdChange(newThreadId);
      resolve();
    });

    // Promise가 resolve된 후에 다음 작업이 진행된다
    console.log("## ASSIST_ID after update (async):", assistantIdRef.current);
    console.log("## THREAD_ID after update (async):", threadIdRef.current);
  };

  useEffect(() => {
    // changeAssistantAndThreadId('draw_base_saju_new_year', ''); //GPT 사용 버전
    changeAssistantAndThreadId('direct_base_saju', ''); // 다일렉트 버전
    console.log('first update:', threadId, assistantId);
  }, []);

  useEffect(() => {
    console.log('threadId Updated:', threadId, assistantId);
  }, [threadId]);


  // API 통신용 파라미터 가져오기
  const getLatestParams = () => {
    const currentProfile = getCurrentProfile();
    return {
      action: additionalParamsRef.current.action,
      output_format: additionalParamsRef.current.output_format,
      api_topic_id_key: additionalParamsRef.current.api_topic_id_key,
      remaining_questions: additionalParamsRef.current.remaining_questions,
      assistantId: assistantIdRef.current,
      threadId: threadIdRef.current,
      birthday: currentProfile.birthday,
      user_info: currentProfile.userInfo
    };
  };


  const getLatestAssistAndThreadId = () => ({
    assistantId: assistantIdRef.current,
    threadId: threadIdRef.current
  });

  const getSeasonFromMonthLand = (monthLand) => {
    const seasonMap = {
      '인': { season: '봄', emoji: '🌸' },
      '묘': { season: '봄', emoji: '🌸' },
      '진': { season: '봄', emoji: '🌸' },
      '사': { season: '여름', emoji: '☀️' },
      '오': { season: '여름', emoji: '☀️' },
      '미': { season: '여름', emoji: '☀️' },
      '신': { season: '가을', emoji: '🍁' },
      '유': { season: '가을', emoji: '🍁' },
      '술': { season: '가을', emoji: '🍁' },
      '해': { season: '겨울', emoji: '❄️' },
      '자': { season: '겨울', emoji: '❄️' },
      '축': { season: '겨울', emoji: '❄️' }
    };
  
    return seasonMap[monthLand] || { season: '알 수 없음', emoji: '❓' };
  };

  //운세저장 함수
  const handleSaveFortuneResult = async () => {
    try {

      const currentProfile = getCurrentProfile();
      setIsSaving(true);

      // 현재 selectedQAs 상태 확인 (ref 사용)
      console.log('=== Save Fortune Debug ===');
      console.log('1. Current selectedQAs Map:', selectedQAsRef.current);
      console.log('1-1. selectedQAs entries:', Array.from(selectedQAsRef.current.entries()));

      const displayTypes = additionalParamsRef.current.display_order.map(item => ({
        type: item
      }));

      console.log('2. Display Types:', displayTypes);

      // 각 카테고리별 QA 내용 확인 (ref 사용)
      displayTypes.forEach(({ type }) => {
        // const categoryKey = `2025_${type}_luck`;
        const categoryKey = type;
        const qaList = selectedQAsRef.current.get(categoryKey);
        console.log(`3. QA List for ${categoryKey}:`, qaList);
      });

      // 선택된 Q&A 내용 수집 (ref 사용)
      const qaContents = displayTypes
        .flatMap(({ type }) => {
          const categoryKey = type;
          const qaList = selectedQAsRef.current.get(categoryKey) || [];
          console.log(`4. Processing ${categoryKey}:`, qaList);
          
          const filteredQAs = qaList
            .filter(qa => {
              const isValid = qa.question && qa.answer;
              console.log(`4-1. QA validity check:`, {
                question: qa.question,
                answer: qa.answer,
                isValid
              });
              return isValid;
            })
            .map(qa => ({
              type,
              question: qa.question,
              answer: qa.answer
            }));
          
          console.log(`4-2. Filtered QAs for ${categoryKey}:`, filteredQAs);
          return filteredQAs;
        });

      console.log('5. Final qaContents:', qaContents);

      const fortuneResult = {
        person: {
          id: currentProfile.userInfo.person_id,
        },
        category: {
          categ_id: CATEGORY_CATEG_ID
        },
        ticketId :ticketIdRef.current,
        displayContents: displayTypes, //DEBUG
        qaContents
      };

      console.log('6. Final fortuneResult:', fortuneResult);

      const reading_shared_id = await saveFortuneResult(fortuneResult);
      console.log("READING SHARE ID:", reading_shared_id)
      savedReadingShareIdRef.current = reading_shared_id;

    } catch (error) {
      console.error('운세 저장 실패:', error);
    } finally {
      setIsSaving(false);
    }
  };


  // 최초 접속시 정보 받아오기
  function IntroToDisplay({ previousStep, triggerNextStep }) {
    const messageGroup = messages.intro;
    const [response, setResponse] = useState(messageGroup.saju_question.default);
  
    useEffect(() => {
      const currentProfile = getCurrentProfile();
      const userName = currentProfile?.userInfo?.first_name || 'OO';
      
      setResponse(messageGroup.saju_question.personalized.replace('{name}', userName));
  
      scrollToBottom();
      const triggerData = {
        trigger: 'display-interactive-component',
        value: {
          message: '',
          target_ui_component: 'whom-to-choose'
        }
      }
  
      triggerNextStep(triggerData)
    }, [triggerNextStep]);
  
    return response;
  }

  // API 호출 함수 분리
  const callApi = async (body_params, retryCount = 0, maxRetries = 3) => {
    try {
      const response = await apiService.post('/saju/persona-api-chatbot/', body_params);
      return response.data;
    } catch (error) {
      console.error(`API 호출 실패 (시도 ${retryCount + 1}/${maxRetries}):`, error);
      
      if (retryCount < maxRetries - 1) {
        // 재시도 전 지수 백오프 적용 (1초, 2초, 4초...)
        const delay = Math.pow(2, retryCount) * 1000;
        await new Promise(resolve => setTimeout(resolve, delay));
        return callApi(body_params, retryCount + 1, maxRetries);
      }
      
      throw error; // 최대 재시도 횟수 초과 시 에러 전파
    }
  };

  // 자유질문용 follow-up 메시지
  const getRandomFollowUpMessage = () => {
    const followUpMessages = messages.follow_up_messages;
    return followUpMessages[Math.floor(Math.random() * followUpMessages.length)];
  };

  function UserInputProcessor({ previousStep, triggerNextStep }) {
    const [response, setResponse] = useState('입력중');
    const [loadingDots, setLoadingDots] = useState('.');
    const [isLoading, setIsLoading] = useState(true);
    const [delayMessage, setDelayMessage] = useState(null);
    const [currentOutputFormat, setCurrentOutputFormat] = useState(''); // New state for output_format

    const [displayStringList] = useState([
      '사주풀이를 보는 중 👀',
      '격을 계산하는 중 🧮',
      '운세를 분석하는 중 🔮',
      '데이터를 수집하는 중 📊',
      '결과를 정리하는 중 📋',
      '별자리를 확인하는 중 🌌',
      '운명의 실타래를 푸는 중 🧵',
      '미래를 예측하는 중 🔮',
      '행운을 찾는 중 🍀',
      '별의 움직임을 추적하는 중 🌠',
      '운세를 해석하는 중 📜',
      '시간의 흐름을 읽는 중 ⏳',
      '운명의 길을 찾는 중 🛤️',
      '별빛을 분석하는 중 ✨',
      '운세의 비밀을 밝히는 중 🔍',
      '행운의 기운을 모으는 중 🌈',
      '미래의 가능성을 탐색하는 중 🚀',
      '운명의 지도를 그리는 중 🗺️',
      '별의 메시지를 해독하는 중 📡',
      '운세의 흐름을 파악하는 중 🌊',
      '천체의 위치를 계산하는 중 🌍',
      '사주의 패턴을 분석하는 중 🔄',
      '운명의 실마리를 찾는 중 🕵️',
      '에너지 흐름을 읽는 중 ⚡',
      '운세의 퍼즐을 맞추는 중 🧩',
      '천기를 해독하는 중 🌠',
      '운명의 나침반을 조정하는 중 🧭',
      '인연의 실을 찾는 중 🕸️',
      '운세의 방정식을 풀어내는 중 ➗',
      '우주의 신호를 해석하는 중 📡',
      '운명의 책을 넘기는 중 📖',
      '별들의 춤을 해석하는 중 💃',
      '운세의 파도를 타는 중 🏄',
      '시간의 강을 거슬러 올라가는 중 🚣',
      '운명의 퍼즐 조각을 맞추는 중 🧩',
      '우주의 리듬을 감지하는 중 🎵',
      '운세의 지도를 펼치는 중 🗺️',
      '별들의 속삭임을 듣는 중 👂',
      '운명의 실타래를 풀어내는 중 🧶',
      '시간의 모래시계를 뒤집는 중 ⏳',
      '운세의 암호를 해독하는 중 🔐',
      '우주의 섭리를 이해하는 중 🌌',
      '운명의 나무를 그리는 중 🌳',
      '별들의 합창을 듣는 중 🎶',
      '운세의 방향을 조정하는 중 🧭',
      '시간의 물결을 타는 중 🌊',
      '운명의 별자리를 연결하는 중 ⭐',
      '우주의 지혜를 모으는 중 🧠',
      '운세의 색채를 분석하는 중 🎨',
      '시간의 톱니바퀴를 조정하는 중 ⚙️',
      '운명의 실을 짜는 중 🕸️',
      '별들의 이야기를 듣는 중 📚',
      '운세의 주파수를 맞추는 중 📻',
      '우주의 균형을 찾는 중 ⚖️',
      '운명의 꽃을 피우는 중 🌸',
      '시간의 문을 여는 중 🚪',
      '운세의 그림자를 해석하는 중 🕯️',
      '별들의 춤사위를 그리는 중 🖌️',
      '운명의 시계를 맞추는 중 ⏰',
      '우주의 신비를 풀어내는 중 🔮',
    ]);

    const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0);
    useEffect(() => {

      scrollToBottom();

      let loadingInterval;
      let delayCheck;
      let displayInterval;
  
      const fetchData = async () => {
        try {
          loadingInterval = setInterval(() => {
            if (isLoading) {
              setLoadingDots((prev) => (prev === '...' ? '.' : prev + '.'));
            }
          }, 700);
  
          delayCheck = setTimeout(() => {
            if (isLoading) {
              setDelayMessage('조금만 기다려주세요🙏');
            }
          }, 19000);
  
          const { action, output_format, api_topic_id_key, remaining_questions, assistantId, threadId, birthday, user_info } = getLatestParams();
          setCurrentOutputFormat(output_format);
  
          let body_params = JSON.stringify({
            message: previousStep.value,
            thread_id: threadId,
            assistant_id_key: assistantId,
          });
  
          if (action === 'saju_api_mixer' && output_format ==='display') {
            // Start cycling through displayStringList
            displayInterval = setInterval(() => {
              setCurrentDisplayIndex((prevIndex) => (prevIndex + 1) % displayStringList.length);
            }, 333);
          }
  
          // views.py에서 수행될 작업 -> action (default값은 initial-step)
          if (action === 'saju_api_mixer') {
  
            console.log('################')
            console.log('ACTION:', action)
            console.log('OUTPUT_FORMAT:', output_format)
            console.log('API_TOPIC_ID_KEY:', api_topic_id_key)
            console.log('ASSIST_ID:', assistantId)
            console.log('THREAD_ID:', threadId)
            console.log('USER_INFO:', user_info)
            console.log('################')
  
            body_params = JSON.stringify({
              message: previousStep.value,
              thread_id: threadId,
              assistant_id_key:assistantId,
              time_info: birthday,
              user_info: user_info,
              api_topic_id_key: api_topic_id_key,
              output_format: output_format,
            });
          }else if(action==='direct_base_saju'){
  
              // GPT를 거치지 않고 바로 사주를 볼수 있도록 해주는 플로우
              // 사전에 updateAdditionalParams을 통해 아래 값들이 업데이트 돼 있어야함
              console.log('################')
              console.log('ACTION:', action) //프론트용
              console.log('ASSIST_ID:', assistantId) //백엔드용
              console.log('TIME_INFO:', birthday)
              console.log('################')
  
              body_params = JSON.stringify({
                message: '',
                thread_id: threadId,
                assistant_id_key:assistantId,
                time_info: birthday,
              });
  
          }
  
          // API 호출 및 재시도 로직 적용
          const data = await callApi(body_params);
  
          if ('draw-base-saju' === data.trigger) {
  
            // 백엔드쪽 direct_base_saju 로직에는 time_info 및 user_info 데이터 전달 받지 않음
            if (data.saju_data.time_info) {
              const profileData = {
                birthday: data.saju_data.time_info || DEFAULT_PROFILE.birthday,
                userInfo: data.saju_data.user_info || DEFAULT_PROFILE.userInfo
              };
            
              // 현재 선택된 프로필의 타입에 대해 데이터 저장
              const currentProfile = getCurrentProfile();
              updateProfile(
                currentProfile.type || 'self',  // 기본값은 'self'
                profileData,
                data.saju_data.user_info.first_name || ''
              );
            }
          }
  
          if ('thread_id' in data) {
            //여기서 토픽에 따라 저장해주는게 가능할지도?
            handleThreadIdChange(data.thread_id);

            // 추가로 topic별 thread_id 관리
            if (additionalParamsRef.current.api_topic_id_key!=='') {
              handleTopicThreadIdChange(data.thread_id, additionalParamsRef.current.api_topic_id_key);
            }
          }
  
          clearInterval(loadingInterval);
          clearTimeout(delayCheck);
          clearInterval(displayInterval);
          setIsLoading(false);
  
          let triggerData = {
            trigger: data.trigger, // ex) 2025-coming-luck ---> 2025-coming-luck- 'display' (서버에서 붙여서 보냄)
            value: {
              baseSaju: data.saju_data || {},
            },
          };
          
          console.log('##########################')
          console.log('"DATA.TRIGGER:', data.trigger)
          console.log('##########################')
          //trigger에 따른 output_format 변경(display or answer)
          if (data.trigger.endsWith('-display')) {
  
            await updateAdditionalParams({
              current_display: data.trigger.replace('-display', ''),
              output_format: 'display',
            }, 'UIP-endswith-display');

            // DEV 삭제 가능성 높음
            // autoScrollRef.current = false;
            setResponse(data.message);
  
          }else if (data.trigger.endsWith('-answer')) {
  
            const currentCount = remaining_questions
            await updateAdditionalParams({
              output_format: 'answer',
              remaining_questions: remaining_questions - 1,
            }, 'UIP-endswith-answer');
  
            //저장용 코드
            const currentCategory = additionalParamsRef.current.current_display;
            handleAnswer(currentCategory, data.message);
  
            // const messageParts = data.message.split('.').filter(part => part.trim() !== '');
            const messageParts = data.message
            .replace(/<br>/g, '') // <br> 태그 제거
            .match(/[^.!?]+[.!?]+/g) // 구분자(.!?)를 포함하여 문장 단위로 분리
            .filter(part => part.trim() !== '');
          
            const firstMessage = messageParts[0];
            const remainingMessages = messageParts.slice(1);
  
            setResponse(firstMessage);
  
            let target_next_trigger = 'user-input';
  
            if(currentCategory=='2025_final_qna') {//최종질의 단계 //현재 disabled됨 질문 총량제로 사용중
              if ( currentCount ==1 ) { //최종질의를 다 했다 --> save_test_function으로 넘어가야함
  
                const currentProfile = getCurrentProfile();
                const userName = currentProfile?.userInfo?.first_name || 'OO';
                
                remainingMessages.push(...messages.final_encouragement.wisdom.map(msg => 
                  msg.replace('{name}', userName)
                ));
                
                triggerData = {
                  trigger: 'make-system-message',
                  value: {
                    message: remainingMessages,
                    next_trigger: 'display-interactive-component',
                    additional_params: {
                      target_ui_component: '2025-final-qna-ending-user-display',
                      timeInterval: 1500
                    }
                  }
                }
  
              } else if(currentCount ==2){
                finalQnaCurrentIndexRef.current += 1;
                remainingMessages.push(messages.before_last_question.hope);
                remainingMessages.push(messages.before_last_question.notice);
                
                triggerData = {
                  trigger: 'make-system-message',
                  value: {
                    message: remainingMessages,
                    next_trigger: 'display-interactive-component',
                    additional_params: {
                      target_ui_component: '2025-final-qna-questions-display',
                      timeInterval: 1500
                    }
                  }
                }
  
              } else {
                  finalQnaCurrentIndexRef.current += 1;
                  remainingMessages.push(messages.remaining_questions.two_left);

                  triggerData = {
                    trigger: 'make-system-message',
                    value: {
                      message: remainingMessages,
                      next_trigger: 'display-interactive-component',
                      additional_params: {
                        target_ui_component: '2025-final-qna-questions-display',
                        timeInterval: 1500
                      }
                    }
                  }
              } 
  
            } else{ //마지막 단계 이전 단계에 대해서는 아래 로직으로 진행        
              if ( remaining_questions == 1) {

                target_next_trigger = 'check-next-action';

                const currentProfile = getCurrentProfile();
                const userName = currentProfile?.userInfo?.first_name || 'OO';
                
                remainingMessages.push(...messages.final_message.conclusion.map(msg => 
                  msg.replace('{name}', userName)
                ));
                
                triggerData = {
                  trigger: 'make-system-message',
                  value: {
                    message: remainingMessages,
                    next_trigger: target_next_trigger,
                    timeInterval: 1500
                  },
                };

              }else if( remaining_questions == 2){


                remainingMessages.push(getRandomFollowUpMessage());
                remainingMessages.push(messages.last_question.notice);

                triggerData = {
                  trigger: 'make-system-message',
                  value: {
                    message: remainingMessages,
                    next_trigger: 'display-interactive-component',
                    additional_params: {
                      target_ui_component: data.trigger,
                    }
                  }
                }


              } else {
  
                  // 또 궁금한 점이 있다면 말씀해 주세요
                  remainingMessages.push(getRandomFollowUpMessage());
                  remainingMessages.push(`남은 질문 가능 횟수: ${remaining_questions - 1}`);
  
                  triggerData = {
                    trigger: 'make-system-message',
                    value: {
                      message: remainingMessages,
                      next_trigger: 'display-interactive-component',
                      additional_params: {
                        target_ui_component: data.trigger,
                      }
                    }
                  }
              } 
            }
  
  
            
          } else { //draw-base-saju or draw-base-saju-new-year
            const messageParts = data.message.split('.').filter(part => part.trim() !== '');
            const firstMessage = messageParts[0];
            const remainingMessages = messageParts.slice(1);
  
            // 그럼, 만세력을 구해볼게요(메시지 1건인 경우 아래 if문 SKIP)
            if (remainingMessages.length > 0) {
              triggerData = {
                trigger: 'make-system-message',
                value: {
                  message: remainingMessages,
                  next_trigger: data.trigger,
                  additional_params: {
                    baseSaju: data.saju_data || {},
                  }
                },
              };
            }
            setResponse(firstMessage);
          }
  
          triggerNextStep(triggerData);
  
        } catch (error) {
          clearInterval(loadingInterval);
          clearTimeout(delayCheck);
          clearInterval(displayInterval);
          setIsLoading(false);
          setResponse('Failed to get response. Try again later.');
  
        // 에러 종류에 따른 친화적인 메시지 설정
          console.log(error)
          let errorMessage = messages.error_messages.prefix;
          if (error.name === 'TypeError' || error.message.includes('fetch')) {
            errorMessage += messages.error_messages.types.network;
          } else if (error.name === 'SyntaxError') {
            errorMessage += messages.error_messages.types.data;
          } else {
            errorMessage += messages.error_messages.types.default;
          }
          setResponse(errorMessage);
          
          // 에러 발생 시 시스템 메시지를 통해 자연스러운 대화 흐름 유지
          triggerNextStep({
            trigger: 'make-system-message',
            value: {
              message: messages.error_messages.retry,
              next_trigger: 'terminate-service'
            }
          });
  
        }
      };
  
      fetchData();
  
      return () => {
        clearInterval(loadingInterval);
        clearTimeout(delayCheck);
        clearInterval(displayInterval);
      };
    }, [previousStep, triggerNextStep]);
  
    const displayResponse = isLoading
    ? delayMessage
      ? `${delayMessage}${loadingDots}`
      : (currentOutputFormat === 'display'
        ? displayStringList[currentDisplayIndex]
        : `입력중${loadingDots}`)
    : response;
  
  return displayResponse;
  }

  const formatText = (text) => {
    try {

        if (!text) return '';
        
        // 객체인 경우
        if (typeof text === 'object') {
            text = JSON.stringify(text);
            console.log('객체를 문자열로 변환:', text);
        }
        
        // 문자열로 변환 후 처리
        const result = text
            .replace(/<br>/g, '\n\n')
            .replace(/\\n/g, '\n')  // 이스케이프된 개행문자 처리
            .replace(/\n/g, '\n')   // 일반 개행문자 처리
            .replace(/^"|"$/g, ''); // 앞뒤 따옴표 제거
        return result;
    } catch (e) {
        console.error('텍스트 포맷 에러:', e);
        console.error('에러 발생 텍스트:', text);
        return text || '';
    }
};

  
  // all_luck-answer 일때
  function AllLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const messageGroup = messages['all_luck_display'];
    const { component } = previousStep.value.baseSaju;
    const currentProfile = getCurrentProfile();
    const userName = currentProfile?.userInfo?.first_name || 'OO';
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: messageGroup.summary.map(msg => 
          msg.replace('{name}', userName)
        ),
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-all-luck-display',
        }
      }
    };

    useEffect(() => {
      const updateParams = async () => {

        // 연간 운세 요약 데이터 저장
        yearSummaryRef.current = {
          favorable_periods: component.favorable_periods || [],
          cautious_periods: component.cautious_periods || [],
          luck_summary: component['2025_luck_summary'] || ''
        };

        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_all_luck',
          current_display: '2025_all_luck',
        }, 'all-luck-display-component');
  
        scrollDownByPixels(350);
  
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
  
      updateParams();
      
    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
        <div id={`fortune-content-${FORTUNE_TYPES.ALL}`} className="base-saju-info">
          <h2 className="title">2025년 운세</h2>
          
          <div className="card">
            <div className="card-content">

              <div className="info-item">
                <h3 className="card-title">유리한 시기</h3>
                <div className="timeline-container favorable">
                  {component.favorable_periods.map((period, index) => (
                    <div key={index} className="timeline-item">
                      <div className="period-badge favorable">
                        {period.period}
                      </div>
                      <div className="period-description">
                        {period.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="info-item">
                <h3 className="card-title">주의해야 할 시기</h3>
                <div className="timeline-container cautious">
                  {component.cautious_periods.map((period, index) => (
                    <div key={index} className="timeline-item">
                      <div className="period-badge cautious">
                        {period.period}
                      </div>
                      <div className="period-description">
                        {period.description}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="info-item">
                <h3 className="card-title">🌟 유리한 변화의 시기</h3>
                <p>{formatText(component.favorable_changes)}</p>
              </div>

              <div className="info-item">
                <h3 className="card-title">🚨 주의해야 할 시기</h3>
                <p>{formatText(component.cautious_changes)}</p>
              </div>

              <div className="info-item">
                <h3 className="card-title">🚀 성장의 기회</h3>
                <p>{formatText(component.growth_areas)}</p>
              </div>

              <div className="info-item">
                <h3 className="card-title">⚖️ 의사결정 포인트</h3>
                <p>{formatText(component.decision_points)}</p>
              </div>
            </div>
          </div>
      </div>
    );
  }


  function MoneyLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const messageGroup = messages['money_luck_display'];
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: messageGroup.conclusion,
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-money-luck-display',
        }
      }
    };
  
    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_money_luck',
          current_display: '2025_money_luck',
        }, '2025-money-luck-display');
    
        scrollDownByPixels(350);
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
    
      updateParams();
    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };

    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.MONEY}`} className="base-saju-info">
        <h2 className="title">2025년 재물운</h2>
        
        <div className="card">
          <div className="card-content">

            <div className="info-item">
              <h3 className="card-title">💰 타고난 재물의 그릇</h3>
              <p>{formatText(component.innate_wealth_capacity)}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">💳 소비 및 저축 습관</h3>
              <p>{formatText(component.spending_patterns)}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🏠 맞춤 재테크 전략</h3>
              <p>{formatText(component.investment_strategy)}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🔥 투자 유리 시기</h3>
              <p>{formatText(component.timing_opportunities)}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🔍 재물 관리 방안</h3>
              <p>{formatText(component.risk_management)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // TODO: 미혼, 기혼, 연애중에 따라 메시지를 다르게 출력해야 하는데....
  // TODO: before-display + 함수 발동되도록 구현 필요함
  function LoveLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const messageGroup = messages['love_luck_display'];
    const { component } = previousStep.value.baseSaju;
    const currentProfile = getCurrentProfile();
    const status = currentProfile?.userInfo?.dating_status || 'single';

    // 상태별 제목 매핑
    const titlesByStatus = {
      single: {
        ideal_partner: '💏 이상적인 연인상',
        relationship_cautions: '✋ 연애시 주의사항',
        timing_encounters: '💕 새로운 인연의 시기',
        long_term_prospects: '💍 연애와 결혼 전망'
      },
      dating_happy: {
        ideal_partner: '💑 현재 연인과의 궁합',
        relationship_cautions: '✋ 관계 발전을 위한 조언',
        timing_encounters: '💕 관계 전환점',
        long_term_prospects: '💍 결혼으로의 발전 가능성'
      },
      married: {
        ideal_partner: '💑 배우자와의 관계',
        relationship_cautions: '✋ 부부관계 주의사항',
        timing_encounters: '💕 관계 개선 시기',
        long_term_prospects: '💍 결혼생활 장기 전망'
      }
    };
  
    const getLoveMessagesByStatus = () => {
      return messageGroup.status_messages[status] || [messageGroup.completion];
    };
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          messageGroup.completion,
          ...getLoveMessagesByStatus()
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-love-luck-display',
        }
      }
    };

    useEffect(() => {

      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_love_luck',
          current_display: '2025_love_luck',
        }, '2025-love-luck-display');
    
        scrollDownByPixels(350);

        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };

      updateParams();

    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.LOVE}`} className="base-saju-info">
        <h2 className="title">2025년 애정운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">{titlesByStatus[status].ideal_partner}</h3>
              <p>{formatText(component.ideal_partner)}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">{titlesByStatus[status].relationship_cautions}</h3>
              <p>{formatText(component.relationship_cautions)}</p>
            </div>
  
            {/* 솔로일 경우에만 timing_encounters 섹션 표시 */}
            {status === 'single' && (
              <div className="info-item">
                <h3 className="card-title">{titlesByStatus[status].timing_encounters}</h3>
                <p>{formatText(component.timing_encounters)}</p>
              </div>
            )}
  
            <div className="info-item">
              <h3 className="card-title">{titlesByStatus[status].long_term_prospects}</h3>
              <p>{formatText(component.long_term_prospects)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function CareerLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const messageGroup = messages['career_luck_display'];
    const { component } = previousStep.value.baseSaju;
    const currentProfile = getCurrentProfile();
    const status = currentProfile?.userInfo?.career_status || 'not_working';
    const firstName = currentProfile?.userInfo?.first_name || '';
  
    // 상태별 제목 매핑
    const titlesByStatus = {
      not_working: {
        career_ability: '📈 잠재적 직무 역량',
        career_changes: '🏅 취업 가능성과 시기',
        workplace_relationships: '👥 미래 직장에서의 대인관계',
        recognition_opportunities: '🏆 성과 창출 가능성',
        development_advice: '🚀 취업 준비 방향'
      },
      job_seeking: {
        career_ability: '📈 취업 역량과 강점',
        career_changes: '🏅 취업 성공 시기',
        workplace_relationships: '👥 조직 적응력',
        recognition_opportunities: '🏆 입사 후 성과 전망',
        development_advice: '🚀 구직 전략'
      },
      employed: {
        career_ability: '📈 현재 직무 역량',
        career_changes: '🏅 경력 발전 기회',
        workplace_relationships: '👥 직장 내 인간관계',
        recognition_opportunities: '🏆 승진과 인정',
        development_advice: '🚀 경력 개발 방향'
      },
      business_owner: {
        career_ability: '📈 경영 역량',
        career_changes: '🏅 사업 확장 기회',
        workplace_relationships: '👥 사업상 인간관계',
        recognition_opportunities: '🏆 사업적 성과',
        development_advice: '🚀 사업 발전 방향'
      }
    };

    // getCareerMessagesByStatus 함수 사용
    const getCareerMessagesByStatus = () => {
      return messageGroup.status_messages[status]?.map(msg => 
        msg.replace('{name}', firstName)
      ) || ["자~ 직장운 풀이가 끝났어~"];
    };
  
    const careerStatusMessages = getCareerMessagesByStatus();
    
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          ...messageGroup.completion_start,
          ...careerStatusMessages,
          ...messageGroup.completion_end,
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-career-luck-display',
        }
      }
    };
  
  
  useEffect(() => {
    const updateParams = async () => {
      await updateAdditionalParams({
        action: 'saju_api_mixer',
        output_format: 'answer',
        api_topic_id_key: '2025_career_luck',
        current_display: '2025_career_luck',
      }, '2025-career-luck-display');

      scrollDownByPixels(350);
      const timer = setTimeout(() => {
        triggerNextStep(triggerData);
      }, 10);
      return () => clearTimeout(timer);
    };

    updateParams();
  }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.CAREER}`} className="base-saju-info">
        <h2 className="title">2025년 직장/명예운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">{titlesByStatus[status].career_ability}</h3>
              <p>{formatText(component.career_ability)}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">{titlesByStatus[status].career_changes}</h3>
              <p>{formatText(component.career_changes)}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">{titlesByStatus[status].workplace_relationships}</h3>
              <p>{formatText(component.workplace_relationships)}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">{titlesByStatus[status].recognition_opportunities}</h3>
              <p>{formatText(component.recognition_opportunities)}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">{titlesByStatus[status].development_advice}</h3>
              <p>{formatText(component.development_advice)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }


  function StudyLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const messageGroup = messages['study_luck_display'];
    const { component } = previousStep.value.baseSaju;
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: messageGroup.explanation,
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-study-luck-display',
        }
      }
    };
  
    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_study_luck',
          current_display: '2025_study_luck',
        }, '2025-study-luck-display');
    
        scrollDownByPixels(350);
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
    
      updateParams();

    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.STUDY}`} className="base-saju-info">
        <h2 className="title">2025년 학업/계약운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">🏆 합격 유리 시기</h3>
              <p>{formatText(component.exam_timing)}</p>
            </div>
      
            <div className="info-item">
              <h3 className="card-title">✍️ 계약 유리 시기</h3>
              <p>{formatText(component.contract_timing)}</p>
            </div>
      
            <div className="info-item">
              <h3 className="card-title">📚 학습 전략</h3>
              <p>{formatText(component.study_strategy)}</p>
            </div>
      
            <div className="info-item">
              <h3 className="card-title">🤝 협상 전략</h3>
              <p>{formatText(component.negotiation_advice)}</p>
            </div>
          </div>
        </div>
    </div>
    );
  }
  
  function ComingLuckDisplay({ steps, previousStep, triggerNextStep }) {
    const messageGroup = messages['coming_luck_display'];
    const { component } = previousStep.value.baseSaju;
    const currentProfile = getCurrentProfile();
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: [
          messageGroup.completion.replace('{name}', currentProfile.userInfo.first_name)
        ],
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-2025-coming-luck-display',
        }
      }
    };
  
    useEffect(() => {
      const updateParams = async () => {
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'answer',
          api_topic_id_key: '2025_coming_luck',
          current_display: '2025_coming_luck',
        }, '2025-coming-luck-display');
    
        scrollDownByPixels(350);
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
    
      updateParams();

    }, [triggerNextStep]);
  
    const renderKeywords = (keywords) => {
      return keywords.split(',').map((keyword, index) => (
        <span key={index} className="keyword-badge">
          #{keyword.trim()}
        </span>
      ));
    };
  
    const renderBulletPoints = (text) => {
      return text.split(',').map((line, index) => (
        line.trim() && <div key={index} className="bullet-point">{line.trim()}</div>
      ));
    };
  
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.COMING}`} className="base-saju-info">
        <h2 className="title">2025년 나에게 다가올 행운</h2>
        
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">💰 금전적 행운의 기회</h3>
              <p>{formatText(component.money_luck_opportunity)}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🎯 귀인을 만날 가능성</h3>
              <p>{formatText(component.meeting_benefactor)}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🤗 주변 사람들과의 협력을 통한 행운</h3>
              <p>{formatText(component.cooperation_luck)}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🌅 새로운 시작과 변화의 가능성</h3>
              <p>{formatText(component.new_beginnings)}</p>
            </div>

            <div className="info-item">
              <h3 className="card-title">🚀 나의 운을 극대화하는 방법</h3>
              <p>{formatText(component.luck_maximization)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  function LuckSummaryDisplay({ steps, previousStep, triggerNextStep }) {
    const messageGroup = messages['luck_summary_display'];
    const currentProfile = getCurrentProfile();
    const userName = currentProfile?.userInfo?.first_name || 'OO';
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: messageGroup.review_request.map(msg => 
          msg.replace('{name}', userName)
        ),
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'review-input-display',
        }
      }
    };

    useEffect(() => {
      const updateParams = async () => {
        try {
          if (!ticketIdRef.current) { //유료 컨텐츠 이므로 티켓이 있어야함
            console.log("유효한 티켓 없음");
            return;
          }else{
            await handleSaveFortuneResult();
            console.log("운세 저장 완료");
          }
        } catch (error) {
          console.error("운세 저장 실패:", error);
        }

        scrollDownByPixels(350);
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);

      };
    
      updateParams();

    }, [triggerNextStep]);
   
    return (
      <div id={`fortune-content-${FORTUNE_TYPES.SUMMARY}`} className="base-saju-info">
        <h2 className="title">2025년 운세 요약</h2>
              
        <div className="card">
          <div className="card-content">
            <div className="info-item">
              <h3 className="card-title">💫 2025년 운세 핵심</h3>
              <p>{formatText(yearSummaryRef.current.luck_summary)}</p>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">🌅 유리한 시기</h3>
              <div className="timeline-container favorable">
                {yearSummaryRef.current.favorable_periods.map((period, index) => (
                  <div key={index} className="timeline-item">
                    <div className="period-badge favorable">
                      {period.period}
                    </div>
                    <div className="period-description">
                      {period.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
  
            <div className="info-item">
              <h3 className="card-title">⚠️ 주의해야 할 시기</h3>
              <div className="timeline-container cautious">
                {yearSummaryRef.current.cautious_periods.map((period, index) => (
                  <div key={index} className="timeline-item">
                    <div className="period-badge cautious">
                      {period.period}
                    </div>
                    <div className="period-description">
                      {period.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* 버튼 추가 */}
            <div className="fortune-action-buttons">
              <button 
                className="fortune-action-button secondary"
                onClick={handleViewResult}
              >
                📋 저장기록 보러가기
              </button>
              <button 
                className="fortune-action-button primary"
                onClick={handleShare}
              >
                🔗 내 운세 공유하기
              </button>
            </div>

          </div>


        </div>
      </div>
    );
  }

  
  // 사주팔자 기본 8글자
  function DrawBaseSaju({ steps, previousStep, triggerNextStep }) {
    const messageGroup = messages['draw_base_saju'];
    const { saju_pillar, umyangohang } = previousStep.value.baseSaju;
    
    const monthHint = saju_pillar.monthLand;
    const { season, emoji } = getSeasonFromMonthLand(monthHint);
    const dayPillarWord = `${saju_pillar.daySky}${saju_pillar.dayLand}일주`;
  
    const currentProfile = getCurrentProfile();
    const dayPillarMessage = messageGroup.birth_pattern
      .replace('{name}', currentProfile.userInfo.first_name)
      .replace('{emoji}', emoji)
      .replace('{season}', season)
      .replace('{dayPillar}', dayPillarWord);
  
    const remainingMessages = [
      messageGroup.thinking,
      dayPillarMessage,
      ...messageGroup.year_2025_prediction
    ];
  
    const triggerData = {
      trigger: 'make-system-message',
      value: {
        message: remainingMessages,
        next_trigger: 'display-interactive-component',
        additional_params: {
          target_ui_component: 'after-draw-base-saju',
        }
      }
    };

    
    useEffect(() => {
      const updateParams = async () => {
        forceScrollRef.current = true;
        scrollToBottom();
        const timer = setTimeout(() => {
          triggerNextStep(triggerData);
        }, 10);
        return () => clearTimeout(timer);
      };
  
      updateParams();
      
    }, [triggerNextStep]);




    return (
      <div className="saju-container">
      <h2 className="saju-title">사주명식</h2>
      <div className="saju-pillar-grid">
        <OhangBoxHanja 
          sky={umyangohang.timeSky.ohang} 
          land={umyangohang.timeLand.ohang} 
          pillar={{ sky: saju_pillar.timeSky, land: saju_pillar.timeLand }} 
          headerSky="시간"
          headerLand="시지"
          headerSkyExtraText=" "
          headerLandExtraText=" "
        />
        <OhangBoxHanja 
          sky={umyangohang.daySky.ohang} 
          land={umyangohang.dayLand.ohang} 
          pillar={{ sky: saju_pillar.daySky, land: saju_pillar.dayLand }} 
          headerSky="일간"
          headerLand="일지"
          headerSkyExtraText="나"
          headerLandExtraText=" "
        />
        <OhangBoxHanja 
          sky={umyangohang.monthSky.ohang} 
          land={umyangohang.monthLand.ohang} 
          pillar={{ sky: saju_pillar.monthSky, land: saju_pillar.monthLand }} 
          headerSky="월간"
          headerLand="월지"
          headerSkyExtraText=" "
          headerLandExtraText="사회"
        />
        <OhangBoxHanja 
          sky={umyangohang.yearSky.ohang} 
          land={umyangohang.yearLand.ohang} 
          pillar={{ sky: saju_pillar.yearSky, land: saju_pillar.yearLand }} 
          headerSky="년간"
          headerLand="년지"
          headerSkyExtraText=" "
          headerLandExtraText=" "
        />
        </div>
    </div>
    );
  }

    const CategoryButton = ({ category, onClick, isSelected, disabled }) => {
      return (
        <button
          className={`category-button ${isSelected ? 'selected' : ''}`}
          onClick={() => onClick(category)}
          disabled={disabled}
        >
          {category}
        </button>
      );
    };
  
    const FinalQnACategories = ({ onSelectCategory, selectedCategories }) => {
      const categories = Object.values(categoryMapping).flat();
      
      // 3개가 선택되면 아무것도 렌더링하지 않음
      if (selectedCategories.length >= 3) {
        return null;
      }
      
      return (
        <div className="qna-categories-container">
          <div className="category-buttons-grid">
            {categories.map((category) => (
              <CategoryButton
                key={category}
                category={category}
                onClick={onSelectCategory}
                isSelected={selectedCategories.includes(category)}
                disabled={selectedCategories.length >= 3 || selectedCategories.includes(category)}
              />
            ))}
          </div>
        </div>
      );
    };

  //answer ---> 다음 display 로 넘어가는 작업
 function MoveToNextDisplay({ steps, previousStep, triggerNextStep }) {
  const [message, setMessage] = useState("");

  useEffect(() => {

    scrollToBottom();

    const moveToNext = async () => {
      console.log("moveToNextDisplay 함수 실행");
      console.log("현재 additionalParamsRef:", additionalParamsRef.current);
    
      const currentIndex = additionalParamsRef.current.display_order.indexOf(additionalParamsRef.current.current_display);
      console.log("현재 current_display :", additionalParamsRef.current.current_display);
      console.log("현재 display 인덱스:", currentIndex);
    
      const nextDisplay = additionalParamsRef.current.display_order[currentIndex + 1];
      console.log("다음 display:", nextDisplay);

      const nextDisplayString = additionalParamsRef.current.display_order_kor_str[currentIndex + 1];
    
      let nextTrigger;
      if (nextDisplay === '2025_final_qna') {
        const messageGroup = messages['2025_final_qna'];
        
        await changeAssistantAndThreadId('saju_api_mixer', '');
      
        await updateAdditionalParams({
          output_format: 'answer',
          api_topic_id_key: nextDisplay,
          current_display: nextDisplay,
        }, '2025-move-next-display');
      
        setMessage(messageGroup.intro);
      
        const remainingQuestionsCount = additionalParamsRef.current.remaining_questions;
      
        const triggerData = {
          trigger: 'make-system-message',
          value: {
            message: messageGroup.explanation.map(msg => 
              msg.replace('{count}', remainingQuestionsCount)
            ),
            next_trigger: 'display-interactive-component',
            additional_params: {
              target_ui_component: '2025-final-qna-1',
            }
          }
        };

        triggerNextStep(triggerData);

      } else if  (nextDisplay) {


        console.log("다음 display로 이동");

        await changeAssistantAndThreadId('saju_api_mixer', '');
        // 기존의 remaining_questions 확인
        const currentRemainingQuestions = additionalParamsRef.current.remaining_questions;
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'display',
          api_topic_id_key: nextDisplay,
          current_display: nextDisplay,
        }, '2025-move-next-display');

         // 파라미터 업데이트를 기다리는 함수
         const waitForParamUpdate = () => new Promise(resolve => {
          const checkParams = () => {
            if (additionalParamsRef.current.output_format === 'display' &&
                additionalParamsRef.current.current_display === nextDisplay) {
              resolve();
            } else {
              setTimeout(checkParams, 50);
            }
          };
          checkParams();
        });
        
        await waitForParamUpdate();
  
        console.log("업데이트된 additionalParamsRef:", additionalParamsRef.current);
        
        // remaining_questions에 따라 메시지 설정
        // if (currentRemainingQuestions <= 0) {
        //   setMessage(`이번엔 ${nextDisplayString}에 대해서 살펴보겠습니다`);
        // } else {
        //   setMessage(`그럼 이번엔 ${nextDisplayString}에 대해서 살펴볼게요`);
        // }
        let triggerData = {};

        switch (nextDisplay) {

          case '2025_money_luck': {
            const messageGroup = messages['2025_money_luck'];
            const currentProfile = getCurrentProfile();
            const userName = currentProfile?.userInfo?.first_name || 'OO';
          
            setMessage(messageGroup.intro);
          
            triggerData = {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.explanation.map(msg => msg.replace('{name}', userName)),
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'before-2025-money-luck-display',
                }
              }
            };
            break;
          }

      
          case '2025_love_luck': {
            const messageGroup = messages['2025_love_luck'];
          
            setMessage(messageGroup.intro);
          
            triggerData = {
              trigger: 'display-interactive-component',
              value: {
                target_ui_component: 'before-2025-love-luck-display-1', //DEV FIX: 새로 교체 필요
              }
            };
            break;
          }
        
          case '2025_career_luck': {
            const messageGroup = messages['2025_career_luck'];
          
            setMessage(messageGroup.intro);
          
            triggerData = {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.explanation,
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'before-2025-career-luck-display-1', //DEV FIX: 새로 교체 필요
                }
              }
            };
            break;
          }
          
          case '2025_study_luck': {
            const messageGroup = messages['2025_study_luck'];
          
            setMessage(messageGroup.intro);
          
            triggerData = {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.explanation,
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'before-2025-study-luck-display-1',
                }
              }
            };
            break;
          }

          case '2025_coming_luck': {
            const messageGroup = messages['2025_coming_luck'];
            const currentProfile = getCurrentProfile();
            const userName = currentProfile?.userInfo?.first_name || 'OO';
          
            setMessage(messageGroup.last_part);
          
            triggerData = {
              trigger: 'make-system-message',
              value: {
                message: [
                  messageGroup.luck_intro.replace('{name}', userName)
                ],
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'before-2025-coming-luck-display',
                }
              }
            };
            break;
          }


          case '2025_luck_summary': {
            const messageGroup = messages['2025_luck_summary'];
            const currentProfile = getCurrentProfile();
            const userName = currentProfile?.userInfo?.first_name || 'OO';
          
            setMessage(messageGroup.completion);
          
            triggerData = {
              trigger: 'make-system-message',
              value: {
                message: [
                  ...messageGroup.conclusion,
                  messageGroup.summary_intro.replace('{name}', userName)
                ],
                next_trigger: '2025-luck-summary-display',
              }
            };
            break;
          }
      }
        
        setTimeout(() => {
          triggerNextStep(triggerData);
        }, 1500); // 500ms 딜레이

      } else {
        console.log("모든 display를 완료했습니다. 서비스를 종료합니다.");

        setMessage("모든 운세 분석 완료!");

        nextTrigger = 'terminate-service';
        triggerNextStep({ 
          trigger: nextTrigger,
          value: {
            message: 'hello'
          }
        });
      }

      
    };

    moveToNext();
  }, []); // 빈 의존성 배열 사용

  return message;
}
  // required: value.message, value.next_trigger
  // 특정 구간에서는 타이머 시간 조절해서 ,실제 대화하듯 구현해야함
  function MakeSystemMessage({ steps, previousStep, triggerNextStep }) {
    const messages = Array.isArray(previousStep.value.message) 
      ? previousStep.value.message 
      : [previousStep.value.message];
    const next_trigger = previousStep.value.next_trigger;
    const additional_params = previousStep.value.additional_params || {};
    const timeInterval = previousStep.value.timeInterval || 1200; // 기본값 900ms
  
    useEffect(() => {
      scrollToBottom();
    
      const timer = setTimeout(() => {
        if (messages.length > 1) {
          // 여러 메시지가 있을 때
          triggerNextStep({
            trigger: 'make-system-message',
            value: {
              message: messages.slice(1),
              next_trigger: next_trigger,
              additional_params: additional_params,
              timeInterval: timeInterval
            }
          });
        } else {
          // 마지막 메시지일 때
          triggerNextStep({
            trigger: next_trigger,
            value: {
              ...additional_params,
              message: messages[0]
            }
          });
        }
      }, timeInterval);  // 항상 동일한 timeInterval 적용
    
      return () => clearTimeout(timer);
    }, []);
  
    return messages[0];
  }

  //value required : target_ui_component, message 
function DisplayInteractiveComponent({ steps, previousStep, triggerNextStep }) {
  const { target_ui_component, message } = previousStep.value.additional_params || previousStep.value;
  
  const questionKey = useMemo(() => Math.random(), []);

  useEffect(() => {
    
    scrollToBottom();
    const timer = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    const updateParams = async () => {
      // after와 display 문자열이 모두 포함되어 있는지 확인

      // scrollToBottom 컨트롤하는 로직
      // const shouldDisableScroll = target_ui_component && 
      //   target_ui_component.includes('after') && 
      //   target_ui_component.includes('display');

      //   console.log("[DEV] shouldDisableScroll:", shouldDisableScroll)

      // 기존의 after-fortune-list 관련 로직
      if (target_ui_component === 'after-fortune-list') {

        await changeAssistantAndThreadId('saju_api_mixer', '');
        await updateAdditionalParams({
          action: 'saju_api_mixer',
          output_format: 'display',
          // DEV | FIX
          // api_topic_id_key: '2025_final_qna',

          api_topic_id_key: '2025_all_luck',
        }, 'display-interactive-component');
      }
    };

    updateParams();
  }, [target_ui_component]);

  const renderComponentByType = () => {


    switch (target_ui_component) {

      case 'review-input-display': {
        const messageGroup = messages['review-input-display'];

        const [rating, setRating] = useState(0);
        const [review, setReview] = useState('');
        const [feedback, setFeedback] = useState('');  // 피드백을 위한 state 추가

        const [isSubmitting, setIsSubmitting] = useState(false);
        const [isSubmitted, setIsSubmitted] = useState(false);
        const maxLength = 200;
      
        const handleRatingHover = (value) => {
          const ratingElement = document.getElementById('current-rating');
          if (ratingElement) {
            ratingElement.textContent = value.toFixed(1);
          }
        };
      
        const handleRatingClick = (value) => {
          setRating(value);
        };
      
        const handleReviewChange = (e) => {
          const text = e.target.value;
          if (text.length <= maxLength) {
            setReview(text);
          }
        };

        const handleFeedbackChange = (e) => {
          const text = e.target.value;
          if (text.length <= maxLength) {
            setFeedback(text);
          }
        };
      
        const handleReviewSubmit = async () => {
          if (isSubmitting || isSubmitted) return;
          
          try {
            setIsSubmitting(true);
            
            const reviewData = {
              categ_id: CATEGORY_CATEG_ID,  // 상수로 정의된 카테고리 ID 사용
              rating: rating,
              content: review.trim(),
              improvement_feedback: feedback.trim()  // 피드백 추가
            };
      
            const response = await apiService.post('/fortune/reviews/', reviewData);
            
            // 성공적으로 리뷰가 등록된 경우
            if (response.status === 201) {
              setIsSubmitted(true);  // 제출 완료 상태로 변경
              triggerNextStep({
                trigger: 'make-system-message',
                value: {
                  message: messageGroup.success_messages,
                  next_trigger: 'terminate-service'
                }
              });
            }
      
          } catch (error) {
            let errorMessage = messageGroup.error_messages.default;
            
            // 서버에서 보낸 구체적인 에러 메시지가 있는 경우
            if (error.response?.data?.error) {
              errorMessage = error.response.data.error;
            }
            
            // 이미 리뷰를 작성한 경우 등 특정 에러 처리
            if (error.response?.status === 400) {
              triggerNextStep({
                trigger: 'make-system-message',
                value: {
                  message: [errorMessage],
                  next_trigger: 'terminate-service'
                }
              });
            } else {
              // 기타 에러의 경우
              triggerNextStep({
                trigger: 'make-system-message',
                value: {
                  message: [messageGroup.error_messages.temporary],
                  next_trigger: 'terminate-service'
                }
              });
            }
          } finally {
            setIsSubmitting(false);
          }
        };
      
        // 별점 렌더링 헬퍼 함수
        const renderStar = (index) => {
          const handleStarClick = (e) => {
            if (isSubmitted) return;  // 제출 완료시 클릭 불가

            const rect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const halfWidth = rect.width / 2;
            
            // 별의 왼쪽 절반을 클릭하면 .5, 오른쪽 절반을 클릭하면 1.0
            const value = x < halfWidth ? index - 0.5 : index;
            setRating(value);
          };
      
          let className = 'star-button';
          if (index <= Math.floor(rating)) {
            className += ' filled';
          } else if (index - 0.5 === rating) {
            className += ' half-filled';
          }

          if (isSubmitted) {
            className += ' disabled';  // 제출 완료시 스타일 추가
          }
      
          return (
            <button
              key={index}
              className={className}
              onClick={handleStarClick}
              disabled={isSubmitted}
            >
              ★
            </button>
          );
        };
      
        return (
          <div className="review-input-container">
            <div className="review-header">
              🎉 리뷰 제출하고 리워드 받기 🎉
            </div>
            <div className="rating-container">
              <div className="rating-wrapper">
                <span className="rating-text">
                  <span id="current-rating">{rating.toFixed(1)}</span>점
                </span>
                <div className="stars-wrapper">
                  {[1, 2, 3, 4, 5].map((index) => renderStar(index))}
                </div>
              </div>
            </div>
            <textarea
              className={`review-textarea ${isSubmitted ? 'disabled' : ''}`}
              value={review}
              onChange={handleReviewChange}
              placeholder="서비스 이용 후기를 부탁드려요 (최대 200자)"
              maxLength={maxLength}
              disabled={isSubmitted}
            />
            <div className={`char-count ${review.length === maxLength ? 'limit' : ''}`}>
              {review.length}/{maxLength}
            </div>
            <textarea
              className={`review-textarea ${isSubmitted ? 'disabled' : ''}`}
              value={feedback}
              onChange={handleFeedbackChange}
              placeholder="아쉬웠던점 or 제안사항 (선택사항, 최대 200자)"
              maxLength={maxLength}
              disabled={isSubmitted}
            />
            <div className={`char-count ${feedback.length === maxLength ? 'limit' : ''}`}>
              {feedback.length}/{maxLength}
            </div>
            <button
              className="review-submit-button"
              disabled={rating === 0 || review.trim() === '' || isSubmitting || isSubmitted}
              onClick={handleReviewSubmit}
            >
              {isSubmitting ? '등록 중...' : isSubmitted ? '제출 완료' : '제출하고 리워드 받기'}
            </button>
        </div>
        );
      }


      case 'whom-to-choose': {
        const currentProfile = getCurrentProfile();
        const userName = currentProfile?.userInfo?.first_name || 'OO';
        const messageGroup = messages['whom-to-choose'];
      
        const formatMessages = (messages, name) => {
          return messages.map(msg => msg.replace('{name}', name));
        };
      
        const relationshipCategories = [
          {
            question: messageGroup.choices.self.question,
            onSelect: () => prepareDirectBaseSaju('self'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: formatMessages(messageGroup.choices.self.messages, userName),
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'must-ask-question-dating-status'
                }
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={relationshipCategories}
          triggerNextStep={triggerNextStep}
        />;
      }


      case 'fortune-list': {
        const messageGroup = messages['fortune-list'];
        
        const triggerData = {
          trigger: 'make-system-message',
          value: {
            message: messageGroup.intro_messages,
            next_trigger: 'display-interactive-component',
            additional_params: {
              target_ui_component: 'after-fortune-list',
            },
          }
        };
      
        return <NewYearFortuneList 
          triggerNextStep={triggerNextStep} 
          triggerData={triggerData} 
          forceScrollRef={forceScrollRef} 
        />;
      }

      case 'custom-option-1': {
        const messageGroup = messages['custom-option-1'];
        
        const customOptionTriggerData = {
          yesOption: {
            trigger: 'make-system-message',
            value: {
              message: messageGroup.yes_option.messages,
              next_trigger: 'user-input-processor',
            }
          }
        };
      
        return <CustomOptionButtons 
          triggerNextStep={triggerNextStep} 
          triggerData={customOptionTriggerData} 
        />;
      }

      case 'after-fortune-list': {
        const messageGroup = messages['after-fortune-list'];
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.ready.question,
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.choices.ready.messages,
                next_trigger: 'user-input-processor',
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }
      
      case 'after-draw-base-saju': {
        const messageGroup = messages['after-draw-base-saju'];
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.eager.question,
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.choices.eager.messages,
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'fortune-list'
                }
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case 'after-2025-all-luck-display': {
        const messageGroup = messages['after-2025-all-luck-display'];
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.ready.question,
            nextData: getNextStepBasedOnRemaining(
              '2025-all-luck-answer', 
              messageGroup.choices.ready.messages
            )
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case '2025-all-luck-answer':
        // 질문이 눌려지는 UI에 대해서는 전부 scrollToBottom이 실행되어야 한다

        return (
          <DisplayQuestionsNewYear 
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={getFilteredQuestions('2025_all_luck')}
            removeQuestion={(question) => removeQuestion('2025_all_luck', question)}
            category="2025_all_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );

      case 'after-2025-money-luck-display': {
        const messageGroup = messages['after-2025-money-luck-display'];
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.understand.question,
            nextData: getNextStepBasedOnRemaining(
              '2025-money-luck-answer', 
              messageGroup.choices.understand.messages
            )
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case '2025-money-luck-answer':
        return (
          <DisplayQuestionsNewYear 
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={getFilteredQuestions('2025_money_luck')}
            removeQuestion={(question) => removeQuestion('2025_money_luck', question)}
            category="2025_money_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );

 
      // 애정운 전에 발동 필요
      case 'must-ask-question-dating-status': {
        const currentProfile = getCurrentProfile();
        const userName = currentProfile?.userInfo?.first_name || 'OO';
        const messageGroup = messages['must-ask-question-dating-status'];
      
        const formatMessages = (messages, name) => {
          return messages.map(msg => msg.replace('{name}', name));
        };
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.single.question,
            onSelect: () => updateRelationshipStatus('single'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: formatMessages(messageGroup.choices.single.messages, userName),
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'must-ask-question-career-status'
                }
              }
            }
          },
          {
            question: messageGroup.choices.dating_happy.question,
            onSelect: () => updateRelationshipStatus('dating_happy'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: formatMessages(messageGroup.choices.dating_happy.messages, userName),
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'must-ask-question-career-status'
                }
              }
            }
          },
          {
            question: messageGroup.choices.married.question,
            onSelect: () => updateRelationshipStatus('married'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: formatMessages(messageGroup.choices.married.messages, userName),
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: 'must-ask-question-career-status'
                }
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />;
      }

      case 'before-2025-money-luck-display': {
        const messageGroup = messages['before-2025-money-luck-display'];

        const nextDataByChoice = [
          {
            question: messageGroup.choices.ready.question,
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.choices.ready.messages,
                next_trigger: 'user-input-processor',
              }
            }
          }
        ];

        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case 'before-2025-love-luck-display-1': {
        const messageGroup = messages['before-2025-love-luck-display-1'];

        const nextDataByChoice = [
          {
            question: messageGroup.choices.ready.question,
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.choices.ready.messages,
                next_trigger: 'user-input-processor',
              }
            }
          }
        ];

        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case 'before-2025-career-luck-display-1': {
        const messageGroup = messages['before-2025-career-luck-display-1'];
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.ready.question,
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.choices.ready.messages,
                next_trigger: 'user-input-processor',
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case 'after-2025-love-luck-display': {
        const messageGroup = messages['after-2025-love-luck-display'];
      
        const nextDataByChoice = [
          {
            question: relationshipUserResponses[relationshipStatusRef.current] || "알겠습니다",
            nextData: getNextStepBasedOnRemaining(
              '2025-love-luck-answer', 
              [messageGroup.follow_up.question]
            )
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case '2025-love-luck-answer':
        return (
          <DisplayQuestionsNewYear 
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={getFilteredQuestions('2025_love_luck')}
            removeQuestion={(question) => removeQuestion('2025_love_luck', question)}
            category="2025_love_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );

      // 직장운 

      case 'must-ask-question-career-status': {
        const messageGroup = messages['must-ask-question-career-status'];
        
        const nextDataByChoice = [
          {
            question: messageGroup.choices.not_working.question,
            onSelect: () => updateCareerStatus('not_working'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.common_response.messages,
                next_trigger: 'user-input-processor',
              }
            }
          },
          {
            question: messageGroup.choices.job_seeking.question,
            onSelect: () => updateCareerStatus('job_seeking'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.common_response.messages,
                next_trigger: 'user-input-processor',
              }
            }
          },
          {
            question: messageGroup.choices.employed.question,
            onSelect: () => updateCareerStatus('employed'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.common_response.messages,
                next_trigger: 'user-input-processor',
              }
            }
          },
          {
            question: messageGroup.choices.business_owner.question,
            onSelect: () => updateCareerStatus('business_owner'),
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: messageGroup.common_response.messages,
                next_trigger: 'user-input-processor',
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />;
      }

      case 'after-2025-career-luck-display': {
          const messageGroup = messages['after-2025-career-luck-display'];
          
          const nextDataByChoice = [
            {
              question: messageGroup.career_responses[careerStatusRef.current],
              nextData: getNextStepBasedOnRemaining(
                '2025-career-luck-answer', 
                [messageGroup.follow_up.question]
              )
            }
          ];
        
          return <DisplayPinnedChoices 
            nextDataByChoice={nextDataByChoice}
            triggerNextStep={triggerNextStep}
            forceScrollRef={forceScrollRef}
          />
      }

      case '2025-career-luck-answer':
        return (
          <DisplayQuestionsNewYear
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={getFilteredQuestions('2025_career_luck')}
            removeQuestion={(question) => removeQuestion('2025_career_luck', question)}
            category="2025_career_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );
      
      //학업운

      case 'before-2025-study-luck-display-1': {
        //DEV TODO: 네 좋아요 라고 유저가 말한다음 그걸 대답해주는 멘트가 없다 추가 필요
        const nextDataByChoice = [
          {
            question: "네 좋아요!",
            nextData: {
              trigger: 'user-input-processor',
              value:{
                message:'dummy_data'
              }
            }
          }
        ];
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case 'after-2025-study-luck-display': {
        const messageGroup = messages['after-2025-study-luck-display'];
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.surprised.question,
            nextData: getNextStepBasedOnRemaining(
              '2025-study-luck-answer', 
              messageGroup.choices.surprised.messages
            )
          },
          {
            question: messageGroup.choices.understand.question,
            nextData: getNextStepBasedOnRemaining(
              '2025-study-luck-answer', 
              messageGroup.choices.understand.messages
            )
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case '2025-study-luck-answer':
        return (
          <DisplayQuestionsNewYear
            key={questionKey}
            triggerNextStep={triggerNextStep} 
            questions={getFilteredQuestions('2025_study_luck')}
            removeQuestion={(question) => removeQuestion('2025_study_luck', question)}
            category="2025_study_luck"
            onQuestionSelect={handleQuestionSelect}
          />
        );

        
      case 'before-2025-coming-luck-display': {
          const messageGroup = messages['before-2025-coming-luck-display'];
        
          const nextDataByChoice = [
            {
              question: messageGroup.choices.confirm.question,
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: messageGroup.choices.confirm.messages,
                  next_trigger: 'user-input-processor',
                }
              }
            }
          ];
        
          return <DisplayPinnedChoices 
            nextDataByChoice={nextDataByChoice}
            triggerNextStep={triggerNextStep}
            forceScrollRef={forceScrollRef}
          />
        }

      case 'after-2025-coming-luck-display': { //스크롤 활성화 위해 aft로 사용
        const messageGroup = messages['after-2025-coming-luck-display'];
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.confirm.question,
            nextData: getNextStepBasedOnRemaining(
              '2025-coming-luck-answer', 
              messageGroup.choices.confirm.messages
            )
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case '2025-coming-luck-answer':
          return (
            <DisplayQuestionsNewYear
              key={questionKey}
              triggerNextStep={triggerNextStep} 
              questions={getFilteredQuestions('2025_coming_luck')}
              removeQuestion={(question) => removeQuestion('2025_coming_luck', question)}
              category="2025_coming_luck"
              onQuestionSelect={handleQuestionSelect}
            />
          );

      case '2025-final-qna-1': {
          const currentProfile = getCurrentProfile();
          const userName = currentProfile?.userInfo?.first_name || 'OO';
          const messageGroup = messages['2025-final-qna-1'];
          
          const formatMessages = (messageList, name) => {
            return messageList.map(msg => 
              msg.replace('{name}', name)
                  .replace('{preview}', messageGroup.preview.replace('{name}', name))
            );
          };
        
          const nextDataByChoice = [
            {
              question: messageGroup.choices.yes.question,
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: formatMessages(messageGroup.choices.yes.messages, userName),
                  next_trigger: 'display-interactive-component',
                  additional_params: {
                    target_ui_component: '2025-final-qna-show-category-button'
                  }
                }
              }
            },
            {
              question: messageGroup.choices.no.question,
              nextData: {
                trigger: 'make-system-message',
                value: {
                  message: formatMessages(messageGroup.choices.no.messages, userName),
                  next_trigger: 'display-interactive-component',
                  additional_params: {
                    target_ui_component: '2025-final-qna-ending-wanted'
                  }
                }
              }
            }
          ];
        
          return <DisplayPinnedChoices 
            nextDataByChoice={nextDataByChoice}
            triggerNextStep={triggerNextStep}
            forceScrollRef={forceScrollRef}
          />
        }

      case '2025-final-qna-ending-wanted': {
        const currentProfile = getCurrentProfile();
        const userName = currentProfile?.userInfo?.first_name || 'OO';
        const messageGroup = messages['2025-final-qna-ending-wanted'];
        
        // 메시지 포맷팅 헬퍼 함수
        const formatMessages = (messageList, name) => {
          return messageList.map(msg => 
            msg.replace('{name}', name)
                .replace('{luck_wish}', messageGroup.luck_wish.replace('{name}', name))
                .replace('{preview}', messageGroup.preview.replace('{name}', name))
          );
        };
      
        const nextDataByChoice = [
          {
            question: messageGroup.choices.yes.question,
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: formatMessages(messageGroup.choices.yes.messages, userName),
                next_trigger: '2025-luck-summary-display',
              }
            }
          },
          {
            question: messageGroup.choices.no.question,
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: formatMessages(messageGroup.choices.no.messages, userName),
                next_trigger: 'display-interactive-component',
                additional_params: {
                  target_ui_component: '2025-final-qna-show-category-button'
                }
              }
            }
          }
        ];
      
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      case '2025-final-qna-show-category-button': {
        const handleCategorySelection = (category) => {
          console.log('Category selected:', category);
          console.log('Current categories:', finalQnaSelectedCategoriesRef.current);
    
          if (finalQnaSelectedCategoriesRef.current.length < 3) {
            const majorCategory = reverseMapping[category];
            const newCategories = [...finalQnaSelectedCategoriesRef.current, {
              major: majorCategory,
              minor: category
            }];
            
            finalQnaSelectedCategoriesRef.current = newCategories;
            forceUpdate({}); // 강제 리렌더링
    
            console.log('Updated categories:', finalQnaSelectedCategoriesRef.current);
    
            if (newCategories.length === 3) {
              const categoryNames = newCategories.map(cat => cat.minor).join(', ');
              
              setTimeout(() => {
                triggerNextStep({
                  trigger: 'make-system-message',
                  value: {
                    message: [
                      `${categoryNames} 분야를 선택하셨군요👌`,
                      "선택하신 카테고리와 관련된 질문들을 순서대로 보여드릴게요.",
                      "궁금한 질문을 선택해서",
                      "물어봐주세요",
                    ],
                    next_trigger: 'display-interactive-component',
                    additional_params: {
                      target_ui_component: '2025-final-qna-questions-display'
                    }
                  }
                });
              }, 500);
            }
          }
        };
    
        return (
          <div>
            <FinalQnACategories 
              onSelectCategory={handleCategorySelection}
              selectedCategories={finalQnaSelectedCategoriesRef.current.map(cat => cat.minor)}
            />
          </div>
        );
      }

      // 질문 표시를 위한 새로운 케이스 추가
      case '2025-final-qna-questions-display': {
        const QUESTIONS_TO_SHOW = 4

        //다른 질문 보기 구현
        // user-input-processor로 보내기 전에 필요한 정보를 세팅함

        // case 1) 카테고리가 정해진 상태로 보내는 경우 (질문을 골라서 보내는 경우)

        // case 2) 카테고리가 정해지지 않은 상태로 보내는 경우 (질문을 직접 써서 보내는 경우) <----판별 agent 필요

        const handleQuestionSelectFinal = async (category, question) => {


          handleQuestionSelect('2025_final_qna', question ) // api_topic_id_key는 category를 질문에 맞춰서 보내는게 맞고, 이거는 고정값 2025_final_qna 이걸로 보내는게 맞음
          //기존에 한번 거쳐왔던 thread_id를 사용하는게 필요함
          const targetThreadId = getTopicThreadId(category)
          console.log("handleQuestionSelectFinal CATEGORY:", category)

          await changeAssistantAndThreadId('saju_api_mixer', targetThreadId)
          await updateAdditionalParams({
            action: 'saju_api_mixer',
            output_format: 'answer',
            api_topic_id_key: category,
          });
        };


        const handleQuestionDirectAsk = async (category, question) => {

          console.log("handleQuestionDirectAsk CATEGORY:", category)
          handleQuestionSelect('2025_final_qna', question ) // api_topic_id_key는 category를 질문에 맞춰서 보내는게 맞고, 이거는 고정값 2025_final_qna 이걸로 보내는게 맞음
          await updateAdditionalParams({
            action: 'saju_api_mixer',
            output_format: 'answer',
            api_topic_id_key: category,
          });
        };
    
        return (
          <div>
            <DisplayQuestionsNewYearQnA
              triggerNextStep={triggerNextStep}
              questions={getRandomQuestionsWithoutDuplication(
                getFinalQuestions(finalQnaSelectedCategoriesRef.current[finalQnaCurrentIndexRef.current].minor),
                QUESTIONS_TO_SHOW
              )}
              questionKey={questionKey}
              category={finalQnaSelectedCategoriesRef.current[finalQnaCurrentIndexRef.current].major}
              onQuestionSelect={handleQuestionSelectFinal}
              onDirectAsk={handleQuestionDirectAsk}
              forceScrollRef={forceScrollRef}
            />
          </div>
        );
      }

      case '2025-final-qna-ending-user-display': {

        const currentProfile = getCurrentProfile();
        const userName3 = currentProfile?.userInfo?.first_name || 'OO';
        const tempMessage3 = `${userName3}님에게 밝은 미래가 있기를 응원하겠습니다`
        //여기서 사실 저장 기능 발동 //save_test_function을 버튼으로 안해도 될듯하다
        // 사용권 차감도 여기서 해주고, 사용권이 없기 때문에, 페이지 접속이 불가하다.
        const nextDataByChoice = [
          {
            question: "네 알겠어요🙂",
            nextData: {
              trigger: 'make-system-message',
              value: {
                message: [tempMessage3],
                next_trigger:'2025-luck-summary-display'
              }
            }
          }
        ];
        return <DisplayPinnedChoices 
          nextDataByChoice={nextDataByChoice}
          triggerNextStep={triggerNextStep}
          forceScrollRef={forceScrollRef}
        />
      }

      default:
        return <p>올바른 컴포넌트를 찾을 수 없습니다.</p>;
    }
  };

  return (
    <div className="interactive-container">
      {renderComponentByType()}
    </div>
  );

}


  //챗봇 플로우 노드 구성
  const [steps, setSteps] = useState([
    {
      id: 'start',
      message:  messages.intro.greeting,
      trigger: 'intro-1',
      delay: 1200
    },
    {
      id: 'intro-1',
      message:  messages.intro.self_intro,
      trigger: 'intro-2',
      delay: 1200
    },
    {
      id: 'intro-2',
      message: messages.intro.purpose,
      trigger: 'intro-to-display',
      delay: 1200
    },
    {
      id: 'intro-to-display',
      component: <IntroToDisplay/>,
      waitAction: true,
      trigger: 'user-input', //실제로는 다르게 트리거 될 예정
      asMessage: true
    },


    ////////////////////////////////////////////////////////////////////////////////
    // 유저가 입력하면 ----> 유저 인풋 프로세서로 들어가게 된다.
    // UserInputProcessor는 user-input에서 받은 내용을 서버에 보내서 가져온다.
    {
      id: 'user-input',
      user: true,
      trigger: 'user-input-processor'
    },
    // 중개기 노드라고 보면 될듯한데..?
    {
      id: 'user-input-processor',
      component: <UserInputProcessor/>,
      waitAction: true,
      trigger: 'user-input',
      asMessage: true
    },
    {
      id: 'display-yes-or-no',
      options: [
        { 
          label: '응', 
          trigger: 'user-input-processor',
          value: {
            action: 'saju_api_mixer', // 이전 스텝의 time_info를 다음 스텝으로 전달
          }
        },
        { 
          value: "아니", 
          label: '아니', 
          trigger:'terminate-service'
        },
      ],
    },


    ////////////////////////////////////////////////////////////////////////
    {
      id: 'draw-base-saju',
      component: <DrawBaseSaju />, //기본 8개의 사주팔자
      waitAction: true,
      trigger: 'user-input'
    },

    //////총운 관련
    {
      id: '2025-all-luck-display',
      component: <AllLuckDisplay/>, 
      waitAction: true,
      trigger: 'terminate-service',
    },
    {
      id: '2025-money-luck-display',
      component: <MoneyLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: '2025-love-luck-display',
      component: <LoveLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: '2025-career-luck-display',
      component: <CareerLuckDisplay/>,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: '2025-study-luck-display',
      component: <StudyLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: '2025-coming-luck-display',
      component: <ComingLuckDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },
    {
      id: '2025-luck-summary-display',
      component: <LuckSummaryDisplay />,
      waitAction: true,
      trigger: 'user-input',
    },

    ////////////////////////////////////////////////////////////////////////
    // 서버에 보내지 않고 컨텐츠 분기점을 만들어주는 노드
    // 경우에 따라서 assistant_id_key 값을 변경해줘야 할수도 있다. 
    {
      id: 'make-system-message',
      component: <MakeSystemMessage />,
      waitAction: true,
      trigger: ({ value }) => value.trigger || 'make-system-message',
      asMessage: true
    },
    {
      id: 'display-interactive-component',
      component: <DisplayInteractiveComponent />,
      waitAction: true,
      trigger: ({ value }) => value.trigger || 'error',
    },
    {
      id: 'check-next-action',
      component :<MoveToNextDisplay/>,
      waitAction: true,
      trigger: 'user-input',  // 기본 trigger 값 지정돼있으나, 다르게 이동될것
      asMessage: true
    },
    // {
    //   id: 'error',
    //   message: 'There was an error processing your request.',
    //   end: true,
    // }, 
    {
      id: 'error',
      message: messages.system.error,
      trigger: 'user-input'  // 에러 후 다시 user-input으로 이동
    },
    {
      id: 'terminate-service',
      message: messages.system.terminate,
      end: true,
    }
  ]);

  const botName = "서화도령"

  const theme = {

    fontFamily: 'NotoSansKR',
    headerBgColor: "#007BFF",
    headerFontColor: "#fff",
    headerFontSize: "15px",

    background: "#b5c9e8",
    userBubbleColor: "#FFEB33", // 사용자 말풍선 배경색을 노란색으로 변경
    userFontColor: "#000000", // 사용자 텍스트 색상을 검정색으로 변경
  
    botBubbleColor: "#ffffff", // 봇 말풍선 배경색을 흰색으로 변경
    botFontColor: "#000000", // 봇 텍스트 색상을 검정색으로 변경
  }

  const containerRef = useRef(null);

  useEffect(() => {

    if (!window.visualViewport) {
      window.visualViewport = {
        addEventListener: window.addEventListener.bind(window),
        removeEventListener: window.removeEventListener.bind(window),
        height: window.innerHeight,
      };
    }

    const adjustViewport = () => {
      if (containerRef.current) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        
        const navbarHeight = 60; // NavBar의 높이
        let keyboardHeight = window.innerHeight - window.visualViewport.height;
        
        // iOS Safari에서 키보드가 열릴 때 추가 처리
        if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
          if (window.innerHeight < window.visualViewport.height) {
            keyboardHeight = window.innerHeight - window.visualViewport.height;
          }
        }
        
        const chatbotHeight = `calc(var(--vh, 1vh) * 100 - ${navbarHeight}px - ${keyboardHeight}px)`;
        
        containerRef.current.style.height = chatbotHeight;
      }
    };

    window.visualViewport.addEventListener('resize', adjustViewport);
    adjustViewport();

    return () => window.visualViewport.removeEventListener('resize', adjustViewport);
  }, []);

  // before
  useEffect(() => {
  
    // Adjust scroll after a slight delay to ensure DOM is updated
    const initialScrollTimeout = setTimeout(scrollToBottom, 100);
  
    // Adjust scroll immediately when steps change
    scrollToBottom();
  
    return () => clearTimeout(initialScrollTimeout);
  }, [steps]);


  return (
    <div className='saju-page' ref={containerRef}>
      <div className='chatbot-container'>
          <ThemeProvider theme={theme}>
            <CustomChatBot
              ref={chatbotRef}
              // The style object to use to override the root element
              customStyle={{
                padding: '0',
                margin: '0',
                marginBottom: '10px',
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
              style={{
                width: '100%',
                height: '100%',
                boxShadow: 'none',
              }}
              contentStyle={{
                height: 'calc(100% - 60px)', // 입력 필드(푸터)의 높이를 고려하여 조정
                overflowY: 'auto',
              }}
              footerStyle={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                fontFamily:'NotoSansKR'
              }}
              inputStyle={{fontWeight:400, fontFamily:'NotoSansKR'}}
              botAvatar={botAvatar}
              hideUserAvatar={true}
              hideHeader ={true}
              placeholder = "여기에 메시지를 입력하세요"
              steps={steps}
              enableSmoothScroll={true} 
              bubbleStyle={ {textAlign: 'left', fontWeight:400} }
              botDelay={0}
              />
          </ThemeProvider>
      </div>

        {showNewMessagePopup && (
        <div className="new-message-popup" onClick={handlePopupClick}>
          <span>새로운 메시지가 있습니다</span>
        </div>
      )}
    </div>
  );

}


const OhangBoxHanja = ({ sky, land, pillar, headerSky, headerLand, headerSkyExtraText, headerLandExtraText }) => {
  return (
    <div className="pillar">
      <div className="pillar-section">
        <div className="pillar-header">{headerSky}</div>
        <div 
          className="pillar-sky hanja-box"
          style={{ backgroundColor: ohangDataV2[hanjaOhangMapping[pillar.sky]].color }}
        >
          {/* <span className="ohang-extra-text">{headerSkyExtraText}</span> */}
          <span className="hanja-text">{skyHanjaData[pillar.sky]}</span>
          <span className="hangul-text">{pillar.sky}</span>
        </div>
      </div>
      <div className="pillar-section">
        <div className="pillar-header">{headerLand}</div>
        <div 
          className="pillar-land hanja-box"
          style={{ backgroundColor: ohangDataV2[hanjaOhangMapping[pillar.land]].color }}
        >
          {/* <span className="ohang-extra-text">{headerLandExtraText}</span> */}
          <span className="hanja-text">{landHanjaData[pillar.land]}</span>
          <span className="hangul-text">{pillar.land}</span>
        </div>
      </div>
    </div>
  );
};


export default NewYearPersonaPage;
