import bigTree from '../images/elements/0_bigtree.png';
import smallTree from '../images/elements/1_smalltree.png';
import bigFire from '../images/elements/2_bigfire.png';
import smallFire from '../images/elements/3_smallfire.png';
import bigSoil from '../images/elements/4_bigsoil.png';
import smallSoil from '../images/elements/5_smallsoil.png';
import bigGold from '../images/elements/6_biggold.png';
import smallGold from '../images/elements/7_smallgold.png';
import bigWater from '../images/elements/8_bigwater.png';
import smallWater from '../images/elements/9_smallwater.png';


export const ohangDataOriginal = {
    "목": { color: "#00DE5D", symbol: bigTree },
    "화": { color: "#FF2C4C", symbol: smallFire },
    "토": { color: "#886F68", symbol: bigSoil },
    "금": { color: "#FFE448", symbol: bigGold },
    "수": { color: "#71A8FF", symbol: smallWater }
};

export const ohangData = {
    "목": { color: "#00DE5D", symbol: bigTree },
    "화": { color: "#FF2C4C", symbol: bigFire },
    "토": { color: "#886F68", symbol: bigSoil },
    "금": { color: "#FFE448", symbol: bigGold },
    "수": { color: "#71A8FF", symbol: bigWater }
};

// export const ohangDataV2 = {
//     "목": { color: "#4DD597", symbol: bigTree },
//     "화": { color: "#FF8080", symbol: smallFire },
//     "토": { color: "#B89F96", symbol: bigSoil },
//     "금": { color: "#FFD280", symbol: bigGold },
//     "수": { color: "#80B9FF", symbol: smallWater }
// };

export const ohangDataV2 = {
    "목": { color: "#4DD597", symbol: bigTree },
    "화": { color: "#FF2C4C", symbol: smallFire },
    "토": { color: "#B89F96", symbol: bigSoil },
    "금": { color: "#FFD280", symbol: bigGold },
    "수": { color: "#71A8FF", symbol: smallWater }
};

export const hanjaOhangMapping = {
    "갑": "목", "을": "목",
    "병": "화", "정": "화",
    "무": "토", "기": "토",
    "경": "금", "신": "금",
    "임": "수", "계": "수",
    
    "인": "목", "묘": "목",
    "사": "화", "오": "화",
    "진": "토", "술": "토", "축": "토", "미": "토",
    "신": "금", "유": "금",
    "자": "수", "해": "수"
};

export const skyHanjaData = {
    // 천간 (10개)
    "갑": "甲",
    "을": "乙",
    "병": "丙",
    "정": "丁",
    "무": "戊",
    "기": "己",
    "경": "庚",
    "신": "辛",
    "임": "壬",
    "계": "癸"
};

export const landHanjaData = {
    // 지지 (12개)
    "자": "子",
    "축": "丑",
    "인": "寅",
    "묘": "卯",
    "진": "辰",
    "사": "巳",
    "오": "午",
    "미": "未",
    "신": "申",
    "유": "酉",
    "술": "戌",
    "해": "亥"
};