import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { sajuClassData } from '../contents/class_contents/classContentsData';
import { handleKakaoLogin } from '../utils/SocialAuth';
import './ClassPage.css';

function ClassCard({ classType, gender, onClick }) {
  const [currentSrc, setCurrentSrc] = useState(classType.images[gender].thumbnail);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 고화질 이미지 프리로드
    const fullImage = new Image();
    fullImage.src = classType.images[gender].full;
    
    fullImage.onload = () => {
      setCurrentSrc(classType.images[gender].full);
      setIsLoading(false);
    };
  }, [classType.images, gender]);

  return (
    <div 
      className="class-card"
      onClick={onClick}
    >
      <div className="class-image-wrapper">
        <img 
          src={currentSrc}
          alt={classType.name} 
          className={`class-image ${isLoading ? 'loading' : 'loaded'}`}
        />
      </div>
      <h3 className="class-name">{classType.name}</h3>
      <p className="class-description">{classType.shortDescription}</p>
    </div>
  );
}

function ClassPage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [classes, setClasses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClassClick = (id, gender) => {
    navigate(`/class/${id}`, { state: { gender } });
  };

  const handleTestButtonClick = async () => {
    if (isAuthenticated) {
      navigate('/class-router');
    } else {
      try {
        setIsLoading(true);
        localStorage.setItem('intended_redirect_url', '/class-router');
        await handleKakaoLogin();
      } catch (error) {
        console.error('Kakao login failed:', error);
      }
    }
  };

  useEffect(() => {
    try {
      if (!sajuClassData || !sajuClassData.common) {
        throw new Error('사주 데이터를 불러올 수 없습니다.');
      }
      setClasses(sajuClassData.common);
      setError(null);
    } catch (err) {
      console.error('데이터 로딩 에러:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  //여기 isLoading 부분도 수정 필요함
  if (error) return <div>에러: {error}</div>;
  if (!classes) return <div>사주격 데이터가 없습니다.</div>;

  return (
    <div className="saju-class-container">
      <h1 className="saju-class-title">나의 사주격 알아보기</h1>
      <p className="saju-class-subtitle">
        당신의 타고난 기질과 성향을 알려주는 사주격을 확인해보세요
      </p>

      <section className="gender-section">
        <h2 className="gender-title">여성의 사주격</h2>
        <div className="class-grid">
          {classes.map((classType) => (
            <ClassCard 
              key={`female-${classType.id}`}
              classType={classType}
              gender="female"
              onClick={() => handleClassClick(classType.id, 'female')}
            />
          ))}
        </div>
      </section>

      <section className="gender-section">
        <h2 className="gender-title">남성의 사주격</h2>
        <div className="class-grid">
          {classes.map((classType) => (
            <ClassCard 
              key={`male-${classType.id}`}
              classType={classType}
              gender="male"
              onClick={() => handleClassClick(classType.id, 'male')}
            />
          ))}
        </div>
      </section>

      <button 
        className={`saju-class__floating-button ${isLoading ? 'loading' : ''}`}
        onClick={handleTestButtonClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="saju-class__loading-spinner"></span>
        ) : (
          isAuthenticated 
            ? '나의 사주격 알아보기' 
            : '무료 테스트 해보기'
        )}
      </button>
    </div>
  );
}

export default ClassPage;