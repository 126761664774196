// TODO:ENV
export const handleKakaoLogin = async () => {
  const domain = process.env.REACT_APP_PUBLIC_URL 
    ? new URL(process.env.REACT_APP_PUBLIC_URL).origin 
    : 'https://www.miri.so';

  // Promise를 반환하여 비동기 처리
  return new Promise((resolve) => {
    // 약간의 지연 후 카카오 인증 실행
    setTimeout(() => {
      window.Kakao.Auth.authorize({
        redirectUri: `${domain}/api/accounts/kakao/callback/`,
      });
      resolve();
    }, 10);
  });
};

export const handleAuthProtection = (isAuthenticated, navigate, path) => {
  if (!isAuthenticated) {
    // 보호된 라우트일 경우에만 알림 표시
    if (path && ['/new-year-prospects', '/how-special-you-are',  '/profile-registration'].includes(path)) {
      alert('로그인이 필요한 서비스입니다.');
    }
    navigate('/', { replace: true });
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return false;
  }
  return true;
};
