import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-links">
          <Link to="https://mirilabs.notion.site/1463a0ef8cc980edb49dc725d83a2c65">서비스 이용약관</Link>
          <span className="divider">|</span>
          <Link to="https://mirilabs.notion.site/1463a0ef8cc980d1bb8dc8d42e71f7cf">개인정보 처리방침</Link>
        </div>
        
        <div className="company-info">
          <p>
            <span>상호명: 미리랩스</span>
            <span className="divider">|</span>
            <span>이메일: labs.miri@gmail.com</span>
          </p>
          <p>
            <span>사업자등록번호: 747-14-02410 </span>
            <span className="divider">|</span>
            <span>대표자: 김민혁</span>
          </p>
          <p>
            <span>주소: 경기도 용인시 기흥구 동백죽전대로 444, C608-S82호 (중동,쥬네브)</span>
          </p>
          <p>
            <span>연락처: 070-8970-5263</span>
          </p>
        </div>
        
        <div className="copyright">
          <p>© 2024 MIRI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;