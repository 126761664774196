import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { apiService } from '../utils/api';

function PaymentRedirectPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePaymentResult = async () => {
      // URL 쿼리 파라미터 파싱
      const params = new URLSearchParams(location.search);
      const paymentId = params.get('paymentId');
      const code = params.get('code');
      
      // 세션 스토리지에서 결제 정보 가져오기
      const pendingPaymentInfo = JSON.parse(sessionStorage.getItem('pendingPaymentInfo') || '{}');
      const returnPath = pendingPaymentInfo.returnPath || '/new-year-gate';
      
      try {
        if (!code) { // 결제 성공
          // 백엔드 검증 요청
          console.log('Payment verification Request')
          const verificationResponse = await apiService.post('/payments/verify/', {
            paymentId: paymentId,
            categoryId: pendingPaymentInfo.categoryId
          });

          if (verificationResponse.status === 200) {
            navigate('/new-year-prospects');
          } else {
            throw new Error('Payment verification failed');
          }
        } else { // 결제 실패
          alert(`결제 실패: ${params.get('message')}`);
          navigate(returnPath);
        }
      } catch (error) {
        console.error('Payment verification failed:', error);
        alert('결제 확인 중 오류가 발생했습니다.');

        //프로덕션 시에 주석 해제
        navigate(returnPath);
      } finally {
        sessionStorage.removeItem('pendingPaymentInfo');
      }
    };

    handlePaymentResult();
  }, [location, navigate]);

  return (
    <div className="payment-redirect-page">
      <div className="loading-spinner">결제 처리 중...</div>
    </div>
  );
}

export default PaymentRedirectPage;