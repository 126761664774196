import { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { handleKakaoLogin } from '../utils/SocialAuth';
import { apiService } from '../utils/api';
import { useInView } from 'react-intersection-observer';
import './TodayResultPage.css';


const extractKoreanName = (fullName) => {
    if (!fullName) return { lastName: '', firstName: '' };
  
    // 이름 분리를 하지 않을 특수 성씨 목록
    const specialLastNames = [
      '남궁', 
      '제갈',
      '선우',
      '독고',
      '황보',
      '사공',
      '서문',
      '동방'
    ];
  
    // 특수 성씨로 시작하는지 확인
    const hasSpecialLastName = specialLastNames.some(lastName => 
      fullName.startsWith(lastName)
    );
  
    if (hasSpecialLastName) {
      return {
        lastName: '',  // 성 구분이 불필요한 경우
        firstName: fullName  // 전체 이름을 firstName으로 사용
      };
    }
  
    // 일반적인 경우: 끝의 2글자를 이름으로 사용
    return {
      lastName: fullName.slice(0, fullName.length - 2),
      firstName: fullName.slice(-2)  // 마지막 2글자를 이름으로 사용
    };
  };

// 이미지와 텍스트를 합치는 함수
const createShareImage = async (text) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  const dpr = window.devicePixelRatio || 1;
  const CANVAS_WIDTH = 375;
  const TEXT_PADDING = 40;
  const TOP_MARGIN = 40;
  
  // 텍스트 높이 계산을 위한 임시 캔버스
  const tempCanvas = document.createElement('canvas');
  const tempCtx = tempCanvas.getContext('2d');
  tempCtx.font = '16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
  
  // 텍스트 높이 계산
  const maxWidth = CANVAS_WIDTH - (TEXT_PADDING * 2);
  const lineHeight = 22;
  let totalTextHeight = 0;
  
  const paragraphs = text.split('\n');
  paragraphs.forEach(paragraph => {
    if (paragraph.trim() === '') {
      totalTextHeight += lineHeight;
      return;
    }
    
    const words = paragraph.split(' ');
    let line = '';
    words.forEach(word => {
      const testLine = line + word + ' ';
      const metrics = tempCtx.measureText(testLine);
      if (metrics.width > maxWidth) {
        totalTextHeight += lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    });
    totalTextHeight += lineHeight * 1.5;
  });
  
  // 요소들의 위치 계산 (간격 축소)
  const SITE_URL_Y = TOP_MARGIN;
  const TITLE_Y = SITE_URL_Y + 30;  // 간격 축소
  const HEADER_HEIGHT = TITLE_Y + 30;  // 간격 축소
  
  // 캔버스 크기 설정 (IMAGE_HEIGHT 제거)
  canvas.width = CANVAS_WIDTH * dpr;
  canvas.height = (HEADER_HEIGHT + totalTextHeight + TOP_MARGIN) * dpr;
  
  // DPR 스케일링 적용
  ctx.scale(dpr, dpr);
  
  // 배경 그리기
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  
  // 사이트 URL 그리기 (스타일 및 URL 수정)
  ctx.fillStyle = '#34776d';
  ctx.font = 'bold 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(process.env.REACT_APP_PUBLIC_URL, CANVAS_WIDTH / 2, SITE_URL_Y);
  
  // 제목 그리기
  ctx.font = 'bold 24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
  ctx.fillStyle = '#000000';
  ctx.textAlign = 'left';  // 다시 왼쪽 정렬로 변경
  ctx.textBaseline = 'alphabetic';  // 기본값으로 복원
  ctx.fillText('오늘의 운세', TEXT_PADDING, TITLE_Y);
  
  // 텍스트 그리기 (시작 위치 수정)
  ctx.font = '16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
  ctx.fillStyle = '#333333';
  
  let currentY = HEADER_HEIGHT;  // IMAGE_HEIGHT 제거
  
  paragraphs.forEach(paragraph => {
    if (paragraph.trim() === '') {
      currentY += lineHeight;
      return;
    }
    
    const words = paragraph.split(' ');
    let line = '';
    
    words.forEach(word => {
      const testLine = line + word + ' ';
      const metrics = ctx.measureText(testLine);
      
      if (metrics.width > maxWidth) {
        ctx.fillText(line, TEXT_PADDING, currentY);
        currentY += lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    });
    
    ctx.fillText(line, TEXT_PADDING, currentY);
    currentY += lineHeight * 1.5;
  });
  
  return canvas.toDataURL('image/png');
};

function TodayResultPage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [todayFortune, setTodayFortune] = useState(null);
  const [error, setError] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [referralInfo, setReferralInfo] = useState(null);
  const shareButtonsRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { profileId } = useParams();

  // referralCode 제거하고 초기 invitation 처리
  useEffect(() => {
    const invitation = searchParams.get('invitation');
    if (invitation) {
      localStorage.setItem('referral_code', invitation);
    }
  }, [searchParams]);

  const [ref, inView] = useInView({
    threshold: 0
  });

  // 프로필 데이터 가져오기
  const fetchProfileData = async () => {
    try {
      // localStorage에서 임시 프로필 ID 확인
      const tempProfileId = localStorage.getItem('temp_profile_id');
      
      if (tempProfileId) {
        // 임시 프로필 ID가 있는 경우 해당 프로필 정보 조회
        const response = await apiService.get(`/fortune/persons/${tempProfileId}/`);
        const profileData = response.data;
        const { firstName } = extractKoreanName(profileData.name);
        
        const newProfileData = {
          birthday: {
            year: profileData.birth_year,
            month: profileData.birth_month,
            day: profileData.birth_day,
            hour: profileData.birth_hour,
            minute: profileData.birth_minute
          },
          userInfo: {
            person_id: profileData.id,
            first_name: firstName,
            gender: profileData.gender_display,
            is_birth_time_unknown: profileData.is_birth_time_unknown
          }
        };
        
        setProfileData(newProfileData);
        
        // 사용 후 localStorage에서 삭제
        localStorage.removeItem('temp_profile_id');
        return;
      }

      // 임시 프로필 ID가 없는 경우 기존 self 프로필 조회 로직 실행
      console.log('프로필 데이터 가져오기 시작');
      const response = await apiService.get('/fortune/persons/?relationship=self');
      if (response.data.length > 0) {
        const selfData = response.data[0];
        const { firstName } = extractKoreanName(selfData.name);
        
        setProfileData({
          birthday: {
            year: selfData.birth_year,
            month: selfData.birth_month,
            day: selfData.birth_day,
            hour: selfData.birth_hour,
            minute: selfData.birth_minute
          },
          userInfo: {
            person_id: selfData.id,
            first_name: firstName,
            gender: selfData.gender_display,
            is_birth_time_unknown: selfData.is_birth_time_unknown
          }
        });
      } else {
        throw new Error('프로필을 찾을 수 없습니다.');
      }
    } catch (error) {
      console.error('프로필 조회 실패:', error);
      setError('프로필 정보를 불러오는데 실패했습니다.');
    }
  };

  // 레퍼럴 정보 가져오기
  const fetchReferralInfo = async () => {
    try {
      console.log('레퍼럴 정보 가져오기 시작');
      const response = await apiService.get('/referral-info/');
      console.log('레퍼럴 응답:', response.data);
      setReferralInfo(response.data.referral);
    } catch (error) {
      console.error('레퍼럴 정보 로딩 실패:', error);
    }
  };

  // 오늘의 운세 가져오기
  const fetchTodayFortune = async () => {
    if (!profileData) {
      console.log('프로필 데이터가 없어 운세 조회 스킵');
      return;
    }

    try {
      console.log('오늘의 운세 가져오기 시작', profileData);
      const response = await fetch('https://api.dk-saju.com/contents/unse/today', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_MANSE_TOKEN}`,
        },
        body: JSON.stringify({
          year: profileData.birthday.year,
          month: profileData.birthday.month,
          day: profileData.birthday.day,
          hour: profileData.birthday.hour || 0,
          minute: profileData.birthday.minute || 0,
        }),
      });

      if (!response.ok) {
        throw new Error('운세 조회에 실패했습니다.');
      }

      const data = await response.json();
      console.log('운세 응답:', data);
      setTodayFortune(data);
    } catch (error) {
      console.error('운세 API 호출 실패:', error);
      setError('오늘의 운세를 불러오는데 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  };

  // 초기 데이터 로딩
  useEffect(() => {
    console.log('초기 데이터 로딩 시작, 인증상태:', isAuthenticated);
    if (isAuthenticated) {
      const loadInitialData = async () => {
        try {
          await Promise.all([
            fetchProfileData(),
            fetchReferralInfo()
          ]);
        } catch (error) {
          console.error('초기 데이터 로딩 실패:', error);
        }
      };
      loadInitialData();
    }
  }, [isAuthenticated]);

  // 프로필 데이터가 있을 때 운세 조회
  useEffect(() => {
    console.log('프로필 데이터 변경 감지:', profileData);
    if (profileData) {
      fetchTodayFortune();
    }
  }, [profileData]);

  const handleShare = async () => {
    try {
      setIsLoading(true);
      
      const baseUrl = window.location.origin;
      const testUrl = referralInfo?.code 
        ? `${baseUrl}/today-gate?invitation=${referralInfo.code}`
        : `${baseUrl}/today-gate`;
      
      const shareText = `${profileData?.userInfo.first_name}님의 오늘의 운세\n🔮${todayFortune?.data?.contents || ''}`;
      
      let shareImage;
      
      try {

        // 먼저 클립보드에 URL 복사 시도
        try {
          await navigator.clipboard.writeText(shareUrl);
        } catch (clipboardErr) {
          console.error('클립보드 복사 실패:', clipboardErr);
        }

        shareImage = await createShareImage(shareText);
        const blob = await (await fetch(shareImage)).blob();
        const file = new File([blob], 'fortune.png', { type: 'image/png' });

        if (navigator.share && navigator.canShare({ files: [file] })) {

          await navigator.share({
            title: '오늘의 운세',
            text: '나의 오늘의 운세를 확인해보세요!',
            files: [file],
            url: testUrl
          });
        } else {
          await navigator.clipboard.writeText(testUrl);
          alert('링크가 클립보드에 복사되었습니다!');
        }
      } catch (imageError) {
        console.error('이미지 생성 실패:', imageError);
        await navigator.clipboard.writeText(`${shareText}\n\n${testUrl}`);
        alert('링크가 클립보드에 복사되었습니다!');
      }
    } catch (err) {
      console.error('공유 실패:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKakaoShare = async () => {
    try {
      setIsLoading(true);
      
      const baseUrl = window.location.origin;
      const testUrl = referralInfo?.code 
        ? `${baseUrl}/today-gate?invitation=${referralInfo.code}`
        : `${baseUrl}/today-gate`;
      
      const shareText = `${profileData?.userInfo.first_name}님의 오늘의 운세\n\n🔮 상세 운세\n${todayFortune?.data?.contents || ''}`;
      
      // Canvas로 이미지 생성
      const shareImage = await createShareImage(shareText);
      
      // Base64 데이터 URL을 Blob으로 변환
      const base64Response = await fetch(shareImage);
      const blob = await base64Response.blob();
      
      // Blob을 File 객체로 변환 (이름과 타입 지정)
      const imageFile = new File([blob], 'fortune.png', { type: 'image/png' });
      
      // File 객체로부터 임시 URL 생성
      const imageUrl = URL.createObjectURL(imageFile);

      // 카카오 공유 실행
      window.Kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: `${profileData?.userInfo.first_name}님의 오늘의 운세`,
          description: todayFortune?.data?.comment + '...' || '오늘의 운세를 확인해보세요!',
          imageUrl: imageUrl,
          link: {
            mobileWebUrl: testUrl,
            webUrl: testUrl,
          },
        },
        buttons: [
          {
            title: '나도 운세 보기',
            link: {
              mobileWebUrl: testUrl,
              webUrl: testUrl,
            },
          }
        ],
        callback: () => {
          // 공유 완료 후 임시 URL 해제
          URL.revokeObjectURL(imageUrl);
        }
      });

      // 5초 후 URL 해제 (공유 다이얼로그가 닫힐 때를 대비한 안전장치)
      setTimeout(() => {
        URL.revokeObjectURL(imageUrl);
      }, 5000);

    } catch (error) {
      console.error('카카오 공유 실패:', error);
      alert('공유하기를 실패했습니다. 다시 시도해주세요.');
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrentKoreanDate = () => {
    const date = new Date();
    const koreanTime = new Date(date.getTime() + (9 * 60 * 60 * 1000));
    return koreanTime.toISOString().split('T')[0];
  };

  const handleTestButtonClick = async () => {
    if (isAuthenticated) {
      // ref.current가 있는지 확인하고 스크롤
      const shareSection = document.querySelector('.tr-share-section');
      if (shareSection) {
        shareSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      try {
        setIsLoading(true);
        localStorage.setItem('intended_redirect_url', '/today-result');
        await handleKakaoLogin();
      } catch (error) {
        console.error('Kakao login failed:', error);
        setIsLoading(false);
      }
    }
  };

  if (error) {
    return <div className="tr-error">{error}</div>;
  }

  return (
    <div className="tr-container">
      {isLoading ? (
        <div className="tr-loading">
          <div className="tr-spinner"></div>
        </div>
      ) : (
        <>
          <main className="tr-content">
            <section className="tr-fortune-section">
              <h1 className="tr-title">
                {profileData?.userInfo.first_name}님의 오늘의 운세
              </h1>
              
              <div className="tr-card">
                <div className="tr-date-header">
                  <div className="tr-day-type">{todayFortune?.day}일(日)</div>
                  <div className="tr-date">{getCurrentKoreanDate()}</div>
                </div>
                <div className="tr-fortune-content">
                  <h3 className="tr-subtitle">⭐ 상세 운세</h3>
                  <p className="tr-details">{todayFortune?.data.contents}</p>
                </div>
              </div>
            </section>

            <section className="tr-share-section" ref={ref}>
              <div className="tr-share-buttons tr-share-buttons--vertical">
                <button 
                  className="tr-share-button"
                  onClick={handleShare}
                >
                  <span role="img" aria-label="share">📤</span>
                  결과 공유하기
                </button>
                <button 
                  className="tr-share-button"
                  onClick={handleKakaoShare}
                >
                  <span role="img" aria-label="kakao share">💬</span>
                  카카오톡으로 공유하기
                </button>
                <p className="tr-share-reward-info">
                  공유된 링크로 친구도 테스트를 진행할 경우<br />
                  리워드 점수가 올라가요! <br/>
                  점수를 모아 유료 운세 이용권을 받아보세요.<br/>
                  마이페이지 메뉴에서 확인 가능합니다.
                </p>
                <button 
                  className="tr-share-button tr-share-button--newyear"
                  onClick={() => navigate('/?section=services')}
                >
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Microsoft-Teams-Animated-Emojis/master/Emojis/Travel%20and%20places/Star.png" 
                    alt="" 
                    className="tr-button-icon--left"
                  />
                  다른 서비스 둘러보기
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Microsoft-Teams-Animated-Emojis/master/Emojis/Travel%20and%20places/Star.png" 
                    alt="" 
                    className="tr-button-icon--right"
                  />
                </button>
                <button 
                    className="tr-share-button tr-share-button--newyear"
                  onClick={() => navigate('/my-page')}
                >
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Light%20Bulb.webp" 
                    alt="" 
                    className="tr-button-icon--left"
                  />
                  나의 사주명식 보러가기
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Light%20Bulb.webp" 
                    alt="" 
                    className="tr-button-icon--right"
                  />
              </button>
                <button 
                  className="tr-share-button tr-share-button--newyear"
                  onClick={() => navigate('/new-year-gate')}
                >
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png" 
                    alt="" 
                    className="tr-button-icon--left"
                  />
                  25년 신년운세 보러가기
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png" 
                    alt="" 
                    className="tr-button-icon--right"
                  />
                </button>
              </div>
            </section>
          </main>

          {!inView && (
            <button 
              className={`tr-floating-button ${isLoading ? 'tr-loading' : ''}`}
              onClick={handleTestButtonClick}
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="tr-loading-spinner"></span>
              ) : (
                !isAuthenticated 
                  ? '무료로 운세 보기'
                  : '내 운세 공유하기'
              )}
            </button>
          )}
        </>
      )}
    </div>
  );
}

export default TodayResultPage;