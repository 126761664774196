
import {
  jaMouse,
  chukCow,
  inTiger,
  myoRabbit,
  jinDragon,
  saSnake,
  ohHorse,
  miSheep,
  shinMonkey,
  yuChicken,
  soolDog,
  haePig
} from './images'

export const dayPillarContentsData = {
  common: [
    {
      id: "dp1",
      name: "갑자(甲子)",
      heavenlyStem: {
        char: "갑",
        emoji: "🌳",
        element: "갑목(甲木)",
        symbol: "큰 나무, 떡갈나무",
        meaning: "정직하고 곧은 성품을 지니며, 목표를 향해 꾸준히 성장하려는 성향이 강합니다. 뿌리깊은 나무처럼 안정적이고 신뢰감이 있습니다."
      },
      earthlyBranch: {
        char: "자",
        animal: "쥐",
        element: "자수(子水)",
        symbol: "깊은 밤의 고요한 물, 심해수",
        symbolMeaning: "깊은 곳에서 솟아나는 물처럼 깊이 있는 지혜와 잠재력을 상징합니다.",
        meaning: "지혜롭고 영리하며, 적응력이 뛰어납니다. 끊임없이 새로운 것을 탐구하고 수집하는 성향이 있습니다."
      },
      image: jaMouse,
      personality: "갑자일주는 창의적이고 선구자적인 성격을 가졌어요. 새로운 아이디어를 떠올리는 능력이 뛰어나고, 이를 실행에 옮기는 데 주저하지 않는 열정을 가지고 있어요. 자신이 설정한 목표에 대해 명확하고 뚜렷한 계획을 세우는 타입이에요. 주변 사람들과의 관계에서 사교성이 돋보이며, 활발한 성격 덕분에 쉽게 신뢰를 얻어요. 하지만 독립심이 강해서 개인적인 공간과 시간을 중요하게 생각하기도 해요. 때로는 계획에 지나치게 몰입해 융통성이 부족하다는 평을 듣기도 해요. 갑자일주는 긍정적이고 진취적인 태도로 다른 사람들에게 좋은 영향을 주는 사람이에요. 그러나 마음속으로는 실패에 대한 두려움을 숨기고 있어서 이를 인정하는 데 시간이 걸릴 때도 있어요.",
      relationship: "갑자일주는 사람들과의 관계를 잘 맺고 조화롭게 지내요. 리더십이 강한 만큼 주변에서 자연스럽게 따르는 사람이 많아요. 하지만 때로는 자신만의 생각에 몰두해 주변의 의견을 간과할 수 있어요. 혼자만의 시간을 소중히 여기므로, 적당히 균형을 맞추는 것이 필요해요.",
      career: "갑자일주는 리더십과 기획력이 필요한 직업에서 두각을 나타내요. 분석적 사고와 창의력을 모두 활용할 수 있는 직업에서 특히 성공 가능성이 높으며, 사람들과 협력해 새로운 가치를 창출하는 직업이 잘 어울려요. 커뮤니케이션 능력도 뛰어나 마케팅, 홍보, 교육 등에서도 능력을 발휘할 수 있고, 자신의 전문성을 기반으로 직업을 선택하면 더욱 유리해요.",
      recommendedCareers: [
        "스타트업 CEO",
        "마케팅 기획자",
        "연구원",
        "디자이너",
        "콘텐츠 제작자"
      ],
      wealth: "갑자일주는 안정성과 성장을 동시에 고려한 재정 관리가 중요해요. 지나치게 안전지향적이거나 과감한 투자보다는 꾸준히 장기적인 목표를 세워 자산을 관리하는 것이 적합해요. 저축과 함께 새로운 투자 기회를 분석하며, 자신의 전문성을 기반으로 자산을 확장하는 것이 좋아요. 특히 기술 혁신이나 창의적인 비즈니스에 과감히 도전하면 예상치 못한 성과를 얻을 가능성이 높아요. 다만, 과도한 부담을 느낄 수 있으니 적절한 스트레스 관리가 필요해요.",
      wealthKeywords: [
        "계획적 투자",
        "꾸준한 저축",
        "미래 지향"
      ]
    },
    {
      id: "dp2",
      name: "을축(乙丑)",
      heavenlyStem: {
        char: "을",
        emoji: "🌱",
        element: "을목(乙木)",
        symbol: "작은 나무, 풀",
        meaning: "유연하고 섬세한 성품을 지니며, 상황에 잘 적응하는 융통성이 있습니다. 부드럽지만 강인한 생명력을 가졌습니다."
      },
      earthlyBranch: {
        char: "축",
        animal: "소",
        element: "축토(丑土)",
        symbol: "땅속의 씨앗, 저장된 토양",
        symbolMeaning: "땅속에서 영양을 저장하고 있는 씨앗처럼 잠재된 가능성과 축적된 에너지를 상징합니다.",
        meaning: "성실하고 참을성이 있으며, 꾸준히 노력하는 성향입니다. 책임감이 강하고 신뢰할 수 있습니다."
      },
      image: chukCow,
      personality: "을축일주는 꼼꼼하고 책임감이 강한 성격을 가졌어요. 현실적이고 실용적인 태도로 주어진 일을 충실히 수행하며, 세부적인 계획을 세우는 데 능숙해요. 안정적인 환경을 선호하며, 꾸준히 목표를 이루려는 노력이 돋보이는 사람이에요. 신뢰감 있는 태도로 주변 사람들에게 좋은 인상을 주지만, 가끔은 지나치게 보수적인 태도로 인해 변화에 적응하는 데 시간이 걸릴 수 있어요. 자신만의 원칙을 중요시하며, 이를 끝까지 고수하려는 경향이 강해요. 다만, 융통성을 기르면 더욱 조화로운 성격으로 발전할 수 있어요. 을축일주는 내면적으로 큰 결단력을 가지고 있으며, 꾸준함과 성실함을 기반으로 안정적인 성과를 만들어내는 사람으로 평가받아요.",
      relationship: "을축일주는 안정적인 인간관계를 선호하며, 타인과의 신뢰를 중요하게 생각해요. 말수가 적은 편이지만, 진정성 있는 대화로 깊은 관계를 형성하는 데 능숙해요. 다만, 자신의 의견을 지나치게 고집하는 경우 의견 충돌이 생길 수 있으니 주의가 필요해요. 조화로운 관계를 유지하기 위해 적절히 타인의 입장을 수용하며, 유연한 태도를 보이는 것이 관계를 더욱 깊게 만드는 열쇠가 될 수 있어요.",
      career: "을축일주는 세부적인 계획과 꾸준함이 요구되는 직업에서 성공 가능성이 높아요. 안정적인 환경에서의 직업을 선호하며, 꼼꼼한 관리 능력과 분석력을 필요로 하는 분야에서 두각을 나타낼 수 있어요. 재정 관리, 법률, 행정과 같은 분야에서 특히 적합하며, 직업 내에서 신뢰받는 인재로 자리매김할 가능성이 높아요. 끈기와 책임감이 돋보이는 을축일주는 자신이 맡은 일을 끝까지 완수하려는 자세가 돋보여요.",
      recommendedCareers: [
        "회계사",
        "데이터 분석가",
        "법률가",
        "연구원",
        "교육자"
      ],
      wealth: "을축일주는 안정적인 자산 관리를 중시하며, 꾸준히 저축하고 계획적으로 자산을 늘리는 타입이에요. 지나치게 보수적인 태도는 투자의 기회를 놓칠 수 있으니, 적절히 균형을 맞춘 전략이 필요해요. 재정적으로 신뢰를 받는 을축일주는 위험을 최소화하는 방식으로 안정적인 자산 성장을 이뤄낼 수 있어요. 특히 장기적인 관점에서 투자 전략을 세우는 것이 효과적이에요.",
      wealthKeywords: [
        "안정적 저축",
        "장기 투자",
        "균형 있는 관리"
      ]
    },
    {
      id: "dp3",
      name: "병인(丙寅)",
      heavenlyStem: {
        char: "병",
        emoji: "🔥",
        element: "병화(丙火)",
        symbol: "태양, 번쩍이는 불",
        meaning: "열정적이고 활동적인 성향으로, 리더십이 강하고 영향력이 큽니다. 밝고 긍정적인 에너지를 가졌습니다."
      },
      earthlyBranch: {
        char: "인",
        animal: "호랑이",
        element: "인목(寅木)",
        symbol: "떡갈나무, 큰 숲",
        symbolMeaning: "대지를 뚫고 나오는 강인한 나무의 기운처럼 강력한 생명력과 성장을 상징합니다.",
        meaning: "용감하고 정의로우며, 리더십이 있습니다. 도전정신이 강하고 진취적인 성향을 가졌습니다."
      },
      image: inTiger,
      personality: "병인일주는 열정적이고 도전적인 성격을 가졌어요. 어려운 상황에서도 새로운 해결책을 찾아내는 창의적인 사고를 가지고 있으며, 끊임없이 목표를 향해 나아가려는 추진력이 강한 사람이에요. 자신감이 넘치며, 주변 사람들에게 긍정적인 영향을 미치기도 하지만, 가끔은 지나치게 자기주장이 강하다는 평을 들을 수 있어요. 이를 보완하기 위해 타인의 의견을 적극적으로 수용하려는 노력이 필요해요. 병인일주는 항상 큰 그림을 그리고 자신의 이상을 실현하려는 의지를 보여주며, 한계를 뛰어넘으려는 태도로 사람들에게 동기와 영감을 줘요.",
      relationship: "병인일주는 사람들과의 관계에서 주도적인 역할을 맡는 경우가 많아요. 타인의 의견을 경청하려는 노력이 관계를 더욱 풍부하게 만들어줄 수 있어요. 열정적이고 사교적인 성격 덕분에 주변 사람들에게 좋은 인상을 남기지만, 깊이 있는 관계를 유지하기 위해 꾸준히 신뢰를 쌓는 것이 중요해요.",
      career: "병인일주는 창의적이고 리더십이 필요한 직업에서 성공 가능성이 높아요. 새로운 아이디어를 제시하고 이를 실행에 옮기는 능력이 강점으로 작용해요. 특히 혁신적인 분야에서 두각을 나타낼 가능성이 높으며, 사람들과 협력하여 새로운 가치를 창출하는 직업에서 큰 성과를 낼 수 있어요.",
      recommendedCareers: [
        "창업가",
        "기획자",
        "마케팅 전문가",
        "디자이너",
        "콘텐츠 제작자"
      ],
      wealth: "병인일주는 도전적인 투자와 적극적인 재정 관리를 통해 자산을 증대하는 타입이에요. 하지만 과도한 리스크를 피하고 안정성을 함께 고려하는 것이 필요해요. 적극적인 태도와 함께 장기적인 관점에서 투자 전략을 세우는 것이 재정적으로 큰 성과를 가져올 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "장기 계획",
        "창의적 접근"
      ]
    },
    {
      id: "dp4",
      name: "정묘(丁卯)",
      heavenlyStem: {
        char: "정",
        emoji: "🕯️",
        element: "정화(丁火)",
        symbol: "촛불, 은은한 불",
        meaning: "따뜻하고 섬세한 성품으로, 주변을 밝히는 지혜와 통찰력이 있습니다. 차분하면서도 지속적인 열정을 가졌습니다."
      },
      earthlyBranch: {
        char: "묘",
        animal: "토끼",
        element: "묘목(卯木)",
        symbol: "꽃과 새싹, 유연한 덩굴",
        symbolMeaning: "부드럽게 뻗어나가는 덩굴처럼 유연하고 우아한 생명력을 상징합니다.",
        meaning: "민첩하고 우아하며, 예술적 감각이 뛰어납니다. 조화를 추구하고 평화를 사랑합니다."
      },
      image: myoRabbit,
      personality: "정묘일주는 섬세하고 감성적인 성격을 가졌어요. 주변 사람들에게 따뜻함을 주며, 조화로운 관계를 형성하는 데 능숙한 사람이에요. 자신만의 예술적 감각과 독창성을 발휘하여 새로운 아이디어를 만들어내는 데 능숙하지만, 가끔은 지나치게 신중해 결정을 내리는 데 시간이 걸릴 수 있어요. 정묘일주는 주변 상황에 민감하게 반응하며, 타인의 감정을 잘 이해하고 공감하려는 성향이 강해요.",
      relationship: "정묘일주는 부드럽고 조화로운 대인관계를 형성하며, 타인에게 신뢰를 주는 사람이에요. 자신의 감정을 솔직히 표현하는 것이 관계를 더욱 깊게 만들 수 있어요. 타인과의 관계에서 감정적 균형을 유지하려는 태도가 돋보이며, 주변 사람들에게 안정감을 제공할 수 있어요.",
      career: "정묘일주는 창의적이고 감각적인 능력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 예술, 디자인, 상담과 같은 분야에서 두각을 나타낼 가능성이 커요. 특히, 인간적인 교감이 필요한 직업에서 큰 성과를 낼 수 있으며, 자신의 감정을 잘 활용할 수 있는 직업을 선택하면 더욱 유리할 거예요.",
      recommendedCareers: [
        "예술가",
        "상담사",
        "디자이너",
        "교육자",
        "콘텐츠 제작자"
      ],
      wealth: "정묘일주는 균형 잡힌 소비와 투자 방식을 선호하며, 안정성을 중시하는 자산 관리 전략이 적합해요. 자신이 즐길 수 있는 분야에서 투자 기회를 모색하며, 장기적인 관점에서 자산을 확장하는 것이 좋아요.",
      wealthKeywords: [
        "안정적 투자",
        "감성적 접근",
        "균형 관리"
      ]
    },
    {
      id: "dp5",
      name: "무진(戊辰)",
      heavenlyStem: {
        char: "무",
        emoji: "⛰️",
        element: "무토(戊土)",
        symbol: "큰 산, 대지",
        meaning: "안정적이고 신뢰감 있는 성품으로, 책임감이 강하고 포용력이 큽니다. 든든한 산과 같은 존재감이 있습니다."
      },
      earthlyBranch: {
        char: "진",
        animal: "용",
        element: "진토(辰土)",
        symbol: "우레를 동반한 비, 천둥",
        symbolMeaning: "천둥과 비를 동반하며 만물을 깨우는 강력한 변화의 기운을 상징합니다.",
        meaning: "창의적이고 카리스마 있으며, 큰 꿈을 가졌습니다. 변화를 주도하고 성공을 추구합니다."
      },
      image: jinDragon,
      personality: "무진일주는 강한 책임감을 가진 성격으로, 자신의 신념과 원칙을 소중히 여겨요. 주어진 일을 끝까지 완수하려는 끈기가 강하며, 주변 사람들에게 신뢰를 주는 타입이에요. 현실적이고 실용적인 사고를 기반으로 문제를 해결하며, 복잡한 상황에서도 침착함을 유지할 수 있어요. 다만, 융통성이 부족하다는 평가를 받을 수 있으니 타인의 의견을 수용하는 자세를 기르는 것이 중요해요. 무진일주는 리더십과 안정감을 동시에 제공하며, 조직 내에서 중요한 역할을 맡는 경우가 많아요.",
      relationship: "무진일주는 타인과의 관계에서 신뢰와 안정감을 제공하며, 솔직하고 직접적인 의사소통을 선호해요. 다만, 자신의 의견을 지나치게 강조하면 갈등이 생길 수 있어 유연한 태도를 유지하는 것이 중요해요. 책임감 있는 태도로 주변 사람들에게 안정감을 주며, 리더십을 발휘해 대인관계를 조율할 수 있는 능력을 가지고 있어요.",
      career: "무진일주는 체계적인 관리와 실행력을 요구하는 직업에서 성공 가능성이 높아요. 특히, 경영, 법률, 프로젝트 관리와 같은 분야에서 두각을 나타낼 가능성이 커요. 재정적 안정성을 중요시하는 무진일주는 자신의 원칙과 신념을 바탕으로 조직 내에서 중요한 역할을 수행하며, 높은 신뢰를 얻을 수 있어요.",
      recommendedCareers: [
        "프로젝트 매니저",
        "법률가",
        "경영 컨설턴트",
        "공무원",
        "컨설턴트"
      ],
      wealth: "무진일주는 철저한 계획과 안정적인 투자로 자산을 늘리는 타입이에요. 실질적인 접근 방식을 통해 재산을 축적하며, 무리하지 않는 전략이 중요해요. 장기적인 관점에서 재정을 관리하며, 안정성과 수익성을 동시에 고려한 투자 방식을 선호해요.",
      wealthKeywords: [
        "안정적 투자",
        "계획적 관리",
        "신중한 접근"
      ]
    },
    {
      id: "dp6",
      name: "기사(己巳)",
      heavenlyStem: {
        char: "기",
        emoji: "🌾",
        element: "기토(己土)",
        symbol: "비옥한 땅, 기름진 토양",
        meaning: "온화하고 차분한 성품으로, 실용적이고 현실적인 사고를 가졌습니다. 안정을 추구하며 신중한 성향입니다."
      },
      earthlyBranch: {
        char: "사",
        animal: "뱀",
        element: "사화(巳火)",
        symbol: "은은한 불꽃, 숨은 화기",
        symbolMeaning: "조용히 타오르는 불꽃처럼 내면에 숨겨진 지혜와 통찰력을 상징합니다.",
        meaning: "지혜롭고 신중하며, 통찰력이 뛰어납니다. 비밀을 잘 지키고 신비로운 매력이 있습니다."
      },
      image: saSnake,
      personality: "기사일주는 논리적이고 분석적인 성격을 가졌어요. 세부적인 계획을 세우는 데 능숙하며, 체계적으로 목표를 달성하려는 사람이에요. 감정적으로 차분하고 신중한 태도로 문제를 해결하며, 주변 사람들에게 믿음을 줄 수 있는 안정감을 제공해요. 하지만 가끔은 지나치게 분석적인 태도가 기회를 놓치는 원인이 될 수 있어요. 기사일주는 실질적인 결과를 중요하게 여기며, 모든 일을 끝까지 완수하려는 책임감이 돋보이는 사람이에요.",
      relationship: "기사일주는 타인의 의견을 존중하며, 이성적이고 설득력 있는 대화로 신뢰를 얻는 사람이에요. 다만, 감정 표현이 부족하다는 평을 들을 수 있으니 주변과의 감정적 소통을 보완하려는 노력이 필요해요. 섬세하고 논리적인 태도로 사람들에게 도움을 줄 수 있는 조언자로 인식되는 경우가 많아요.",
      career: "기사일주는 체계적인 사고와 분석력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히 데이터 분석, 컨설팅, 금융과 같은 분야에서 두각을 나타낼 가능성이 커요. 정확성과 실용성을 요구하는 분야에서 큰 성과를 낼 수 있으며, 자신의 분석적 능력을 활용할 수 있는 직업이 적합해요.",
      recommendedCareers: [
        "데이터 분석가",
        "금융 전문가",
        "컨설턴트",
        "연구원",
        "경영 기획자"
      ],
      wealth: "기사일주는 안정적인 재정 관리를 통해 자산을 꾸준히 늘리는 타입이에요. 데이터 기반의 투자 전략을 선호하며, 체계적으로 계획을 세워 실행하는 것이 효과적이에요. 위험을 최소화하는 동시에 꾸준한 수익을 추구하며, 장기적인 재정 계획을 통해 자산을 관리하는 데 능숙해요.",
      wealthKeywords: [
        "데이터 기반",
        "안정적 투자",
        "체계적 성장"
      ]
    },
    {
      id: "dp7",
      name: "경오(庚午)",
      heavenlyStem: {
        char: "경",
        emoji: "🪨",
        element: "경금(庚金)",
        symbol: "바위, 돌",
        meaning: "강직하고 결단력 있는 성품으로, 원칙을 중시하고 정의감이 강합니다. 단단한 의지를 가졌습니다."
      },
      earthlyBranch: {
        char: "오",
        animal: "말",
        element: "오화(午火)",
        symbol: "태양의 불꽃, 들불",
        symbolMeaning: "활활 타오르는 불꽃처럼 강렬한 열정과 에너지를 상징합니다.",
        meaning: "활동적이고 열정적이며, 자유를 사랑합니다. 솔직하고 정직한 성향을 가졌습니다."
      },
      image: ohHorse,
      personality: "경오일주는 강렬하고 대담한 성격을 가졌어요. 목표를 향해 적극적으로 나아가는 추진력이 뛰어나며, 자신만의 길을 개척하려는 의지가 강한 사람이에요. 자신감 넘치는 태도로 주변 사람들에게 영감을 주지만, 가끔은 자신의 의견을 지나치게 고집해 갈등을 일으킬 수 있어요. 이를 보완하기 위해 타인의 의견을 수용하려는 노력이 필요해요. 경오일주는 대담한 성격과 강한 의지로 도전을 즐기며, 어려운 상황에서도 문제를 해결하는 데 탁월한 능력을 발휘해요.",
      relationship: "경오일주는 활발하고 외향적인 성격 덕분에 사람들과 쉽게 친해질 수 있어요. 자신의 의견을 명확히 전달하며, 주변 사람들에게 강한 인상을 남기는 경우가 많아요. 하지만 때로는 타인의 감정을 간과할 수 있으니, 더 많은 공감과 배려를 기르는 것이 필요해요. 리더십이 강한 경오일주는 조직에서 자연스럽게 중심 인물이 되며, 협력 관계에서도 주도적인 역할을 맡는 경우가 많아요.",
      career: "경오일주는 창의성과 추진력을 발휘할 수 있는 직업에서 두각을 나타낼 가능성이 높아요. 특히 사람들과의 상호작용이 많거나, 도전적인 목표를 다루는 직업이 잘 어울려요. 새로운 아이디어를 제시하거나 조직을 이끄는 역할에서 성공 가능성이 높으며, 혁신적인 환경에서 더 큰 성과를 낼 수 있어요.",
      recommendedCareers: [
        "창업가",
        "마케팅 전문가",
        "세일즈 리더",
        "스포츠 지도자",
        "이벤트 기획자"
      ],
      wealth: "경오일주는 대담한 투자와 적극적인 재정 관리를 통해 자산을 증대하는 타입이에요. 하지만 위험을 적절히 통제하며 안정성을 유지하는 것이 중요해요. 크고 작은 기회를 모두 활용하며, 장기적인 관점에서 재정 목표를 설정하고 실현할 수 있는 능력이 돋보여요.",
      wealthKeywords: [
        "대담한 투자",
        "전략적 관리",
        "안정성 유지"
      ]
    },
    {
      id: "dp8",
      name: "신미(辛未)",
      heavenlyStem: {
        char: "신",
        emoji: "💍",
        element: "신금(辛金)",
        symbol: "보석, 장신구",
        meaning: "예리하고 섬세한 성품으로, 심미안이 뛰어나고 완벽을 추구합니다. 아름다움을 사랑하는 성향입니다."
      },
      earthlyBranch: {
        char: "미",
        animal: "양",
        element: "미토(未土)",
        symbol: "비옥한 대지, 목초지",
        symbolMeaning: "풍성한 목초지처럼 풍요롭고 부드러운 대지의 기운을 상징합니다.",
        meaning: "온순하고 예술적이며, 창의성이 뛰어납니다. 평화를 사랑하고 조화를 추구합니다."
      },
      image: miSheep,
      personality: "신미일주는 차분하고 조화로운 성격을 가졌어요. 주변 사람들과의 관계에서 평화를 중시하며, 갈등을 피하려는 성향이 강해요. 섬세하고 꼼꼼한 성격 덕분에 작은 부분까지 놓치지 않고 살피는 능력이 있어요. 하지만 지나친 완벽주의는 스트레스를 유발할 수 있으니 적절한 조화를 찾는 것이 중요해요. 신미일주는 자신의 안정감을 바탕으로 주변 사람들에게 긍정적인 영향을 미치며, 어려운 상황에서도 침착함을 유지하는 데 능숙해요.",
      relationship: "신미일주는 따뜻한 마음과 배려심으로 타인에게 신뢰를 얻어요. 특히 깊이 있는 대화를 즐기며, 타인의 감정을 잘 이해해요. 다만, 자신의 감정을 숨기지 않고 적절히 표현하려는 노력이 필요할 수 있어요. 조용하지만 신뢰를 주는 존재로 인식되며, 관계를 더욱 깊게 만들기 위해 꾸준한 소통이 중요해요.",
      career: "신미일주는 섬세함과 조직력이 필요한 직업에서 성공 가능성이 높아요. 특히 인사 관리, 심리 상담, 교육과 같은 분야에서 두각을 나타낼 가능성이 커요. 또한, 사람을 돕고 치유하는 역할에서도 잘 어울리며, 자신의 감성을 발휘할 수 있는 직업에서 만족감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "상담사",
        "인사 관리자",
        "교육 전문가",
        "심리 치료사",
        "콘텐츠 기획자"
      ],
      wealth: "신미일주는 안정적인 재정 관리를 선호하며, 장기적인 관점에서 자산을 축적하는 타입이에요. 위험보다는 안정성을 우선으로 하며, 작은 투자로도 꾸준한 성과를 내는 능력이 있어요. 균형 잡힌 소비와 저축을 통해 안정적으로 자산을 늘릴 수 있는 능력을 발휘할 수 있어요.",
      wealthKeywords: [
        "안정적 저축",
        "균형 잡힌 소비",
        "장기 투자"
      ]
    },
    {
      id: "dp9",
      name: "임신(壬申)",
      heavenlyStem: {
        char: "임",
        emoji: "🌊",
        element: "임수(壬水)",
        symbol: "큰 물, 바다",
        meaning: "포용력이 크고 진취적인 성품으로, 도전정신과 창의성이 뛰어납니다. 깊이 있는 지혜를 가졌습니다."
      },
      earthlyBranch: {
        char: "신",
        animal: "원숭이",
        element: "신금(申金)",
        symbol: "백색 금속, 단단한 쇠",
        symbolMeaning: "차갑고 단단한 금속처럼 예리하고 강인한 의지를 상징합니다.",
        meaning: "영리하고 재치있으며, 적응력이 뛰어납니다. 다재다능하고 문제해결 능력이 탁월합니다."
      },
      image: shinMonkey,
      personality: "임신일주는 창의적이고 독립적인 성격을 가졌어요. 변화에 민감하게 반응하며, 새로운 아이디어를 빠르게 실행으로 옮기는 능력이 있어요. 스스로 목표를 정하고 이를 달성하기 위해 노력하며, 기존의 틀에 얽매이지 않고 독창적으로 문제를 해결하려는 성향이 강해요. 하지만 가끔은 산만해 보일 수 있어, 명확한 계획을 세우는 것이 중요해요. 임신일주는 자유로운 사고방식과 도전정신으로 자신만의 길을 만들어가는 사람이에요.",
      relationship: "임신일주는 다양한 사람들과 쉽게 어울리며, 풍부한 아이디어로 타인에게 영감을 주는 타입이에요. 하지만 깊이 있는 관계를 유지하려면 꾸준한 소통과 신뢰가 필요해요. 다양한 활동과 관계를 통해 넓은 네트워크를 형성할 수 있으며, 타인에게 긍정적인 인상을 남기는 경우가 많아요.",
      career: "임신일주는 창의력과 실행력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히 혁신적이고 변화를 요구하는 환경에서 두각을 나타낼 수 있어요. 자유롭게 사고할 수 있는 직업에서 자신의 능력을 발휘하며, 대중과 소통하는 직업에서도 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "스타트업 창업자",
        "디자이너",
        "콘텐츠 크리에이터",
        "마케팅 전문가",
        "이벤트 기획자"
      ],
      wealth: "임신일주는 창의적이고 유연한 투자 방식을 선호하며, 위험을 감수하면서도 새로운 기회를 적극적으로 활용하는 타입이에요. 다만, 장기적인 관점을 유지하며 안정적인 자산 증식을 함께 고려하는 것이 필요해요.",
      wealthKeywords: [
        "창의적 접근",
        "유연한 투자",
        "기회 활용"
      ]
    },
    {
      id: "dp10",
      name: "계유(癸酉)",
      heavenlyStem: {
        char: "계",
        emoji: "💧",
        element: "계수(癸水)",
        symbol: "작은 물, 비",
        meaning: "섬세하고 감성적인 성품으로, 직관력이 뛰어나고 생각이 깊습니다. 조용히 스며드는 영향력을 가졌습니다."
      },
      earthlyBranch: {
        char: "유",
        animal: "닭",
        element: "유금(酉金)",
        symbol: "보석, 정제된 금속",
        symbolMeaning: "정교하게 다듬어진 보석처럼 순수하고 완벽을 추구하는 성질을 상징합니다.",
        meaning: "정확하고 완벽을 추구하며, 성실합니다. 책임감이 강하고 시간 관념이 뛰어납니다."
      },
      image: yuChicken,
      personality: "계유일주는 논리적이고 신중한 성격을 가졌어요. 문제를 명확히 파악하고 체계적으로 해결하는 능력이 뛰어난 사람이에요. 이성적인 사고방식으로 주변 상황을 분석하며, 정확하고 책임감 있는 태도로 신뢰를 얻어요. 하지만 지나치게 신중한 태도로 인해 결정을 늦추는 경우가 있으니 유연성을 기르는 것이 중요해요. 계유일주는 자신의 목표를 이루기 위해 꾸준히 노력하며, 어려운 상황에서도 합리적인 결정을 내릴 수 있는 사람이에요.",
      relationship: "계유일주는 설득력 있고 논리적인 대화로 사람들과 소통하며, 타인에게 안정감을 주는 경우가 많아요. 다만, 자신의 감정을 표현하는 데 더욱 적극적일 필요가 있어요. 타인의 의견을 존중하며 공정한 태도를 유지하려는 모습이 돋보이며, 깊은 신뢰를 얻는 대인관계를 형성할 수 있어요.",
      career: "계유일주는 분석력과 논리력을 요구하는 직업에서 성공 가능성이 높아요. 특히 세부적인 계획과 관리가 중요한 분야에서 큰 성과를 낼 가능성이 커요. 데이터 분석, 전략 기획, 법률과 같은 분야에서 두각을 나타내며, 자신이 가진 능력을 체계적으로 활용할 수 있는 직업에서 만족감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "금융 분석가",
        "연구원",
        "법률가",
        "전략 기획자",
        "데이터 과학자"
      ],
      wealth: "계유일주는 안정성과 수익성을 동시에 추구하며, 위험을 잘 통제하는 투자 방식을 선호해요. 계획적인 저축과 점진적인 자산 관리를 통해 재정적인 안정을 이룰 수 있어요. 장기적인 관점에서 투자 계획을 세우고 실행하는 것이 성공의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "계획적 저축",
        "위험 통제",
        "수익성 중시"
      ]
    },
    {
      id: "dp11",
      name: "갑술(甲戌)",
      heavenlyStem: {
        char: "갑",
        emoji: "🌳",
        element: "갑목(甲木)",
        symbol: "큰 나무, 떡갈나무",
        meaning: "정직하고 곧은 성품을 지니며, 목표를 향해 꾸준히 성장하려는 성향이 강합니다. 뿌리깊은 나무처럼 안정적이고 신뢰감이 있습니다."
      },
      earthlyBranch: {
        char: "술",
        animal: "개",
        element: "술토(戌土)",
        symbol: "성벽, 단단한 흙",
        symbolMeaning: "견고한 성벽처럼 단단하고 방어적인 대지의 성질을 상징합니다.",
        meaning: "충직하고 정의로우며, 의리가 강합니다. 신념이 확고하고 보호본능이 강합니다."
      },
      image: soolDog,
      personality: "갑술일주는 정의롭고 원칙을 중시하는 성격을 가졌어요. 불공정한 상황을 참지 못하며, 자신의 신념을 끝까지 지키려는 태도를 보여요. 책임감이 강하며, 주어진 역할을 충실히 수행하려는 노력을 기울이는 사람이에요. 하지만 지나치게 자신의 원칙을 고집하면 타인과의 관계에서 갈등이 생길 수 있어요. 유연함을 기르는 것이 중요해요. 갑술일주는 자신의 믿음과 도덕성을 기반으로 어려운 상황에서도 흔들리지 않고 중심을 잡을 수 있는 안정감 있는 사람이에요.",
      relationship: "갑술일주는 타인과의 관계에서 신뢰를 중요하게 생각하며, 솔직하고 정직한 태도로 주변 사람들에게 좋은 인상을 줘요. 하지만 자신의 의견을 지나치게 강조하면 관계가 경직될 수 있으니 타인의 입장을 수용하는 노력이 필요해요. 리더십이 돋보이며, 조직 내에서 중심 인물로 자리잡는 경우가 많아요. 주변 사람들에게 조언자 역할을 하며 깊이 있는 관계를 형성할 수 있어요.",
      career: "갑술일주는 도덕성과 책임감이 필요한 직업에서 두각을 나타낼 가능성이 높아요. 특히 법률, 사회 활동, 조직 관리와 같은 분야에서 적합해요. 정의로운 성향 덕분에 공익과 관련된 일에서도 성과를 낼 가능성이 크며, 자신의 원칙과 신념을 바탕으로 조직의 성장에 기여할 수 있어요.",
      recommendedCareers: [
        "변호사",
        "공무원",
        "경영 관리자",
        "인권 활동가",
        "프로젝트 매니저"
      ],
      wealth: "갑술일주는 신중하고 계획적인 재정 관리를 선호하며, 꾸준한 저축과 안정적인 투자를 통해 자산을 증대시키는 타입이에요. 무리한 위험을 감수하지 않고, 안정적인 방식으로 자산을 늘리는 것이 적합해요.",
      wealthKeywords: [
        "안정적 투자",
        "계획적 저축",
        "균형 있는 관리"
      ]
    },
    {
      id: "dp12",
      name: "을해(乙亥)",
      heavenlyStem: {
        char: "을",
        emoji: "🌱",
        element: "을목(乙木)",
        symbol: "작은 나무, 풀",
        meaning: "유연하고 섬세한 성품을 지니며, 상황에 잘 적응하는 융통성이 있습니다. 부드럽지만 강인한 생명력을 가졌습니다."
      },
      earthlyBranch: {
        char: "해",
        animal: "돼지",
        element: "해수(亥水)",
        symbol: "깊은 바다, 큰 강",
        symbolMeaning: "깊은 바다처럼 포용력 있고 풍부한 수덕(水德)을 상징합니다.",
        meaning: "너그럽고 온화하며, 복덕이 있습니다. 정직하고 순수한 성품을 가졌습니다."
      },
      image: haePig,
      personality: "을해일주는 온화하고 너그러운 성격을 가졌어요. 사람들과 조화를 이루며 갈등을 피하려는 경향이 강하고, 주변에 긍정적인 에너지를 전달할 수 있어요. 섬세하고 예민한 감각을 가지고 있어 타인의 감정을 잘 이해하지만, 자신을 지나치게 보호하려는 태도가 소극적으로 보일 수 있어요. 자신감을 기르는 것이 중요해요. 을해일주는 평화로운 환경을 선호하며, 어려운 상황에서도 차분함을 유지하는 안정감 있는 태도를 보여요.",
      relationship: "을해일주는 배려심 많고 따뜻한 성격으로 주변 사람들과 조화로운 관계를 유지해요. 깊이 있는 대화를 즐기며, 타인의 감정을 이해하고 공감하려는 성향이 강해요. 다만, 자신의 의견을 표현하는 데 적극적으로 나서는 태도가 필요하며, 이러한 점이 관계를 더욱 깊게 만들어줄 수 있어요.",
      career: "을해일주는 조화로운 성격과 섬세한 감각을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히 예술, 상담, 교육, 치유와 같은 분야에서 적합해요. 감정과 연결된 직업에서 만족감을 느낄 가능성이 높으며, 창의적인 분야에서도 좋은 성과를 낼 수 있어요.",
      recommendedCareers: [
        "예술가",
        "상담사",
        "심리치료사",
        "교육자",
        "콘텐츠 제작자"
      ],
      wealth: "을해일주는 안전한 투자와 꾸준한 저축을 통해 자산을 축적하는 타입이에요. 위험보다는 안정성을 우선으로 하며, 지속적으로 수익을 증대시킬 수 있어요. 장기적인 관점에서 재정을 관리하며, 감정적 결정을 피하는 것이 중요해요.",
      wealthKeywords: [
        "안정적 저축",
        "꾸준한 투자",
        "장기 관리"
      ]
    },
    {
      id: "dp13",
      name: "병자(丙子)",
      heavenlyStem: {
        char: "병",
        emoji: "🔥",
        element: "병화(丙火)",
        symbol: "태양, 번쩍이는 불",
        meaning: "열정적이고 활동적인 성향으로, 리더십이 강하고 영향력이 큽니다. 밝고 긍정적인 에너지를 가졌습니다."
      },
      earthlyBranch: {
        char: "자",
        animal: "쥐",
        element: "자수(子水)",
        symbol: "깊은 밤의 고요한 물, 심해수",
        symbolMeaning: "깊은 곳에서 솟아나는 물처럼 깊이 있는 지혜와 잠재력을 상징합니다.",
        meaning: "지혜롭고 영리하며, 적응력이 뛰어납니다. 끊임없이 새로운 것을 탐구하고 수집하는 성향이 있습니다."
      },
      image: jaMouse,
      personality: "병자일주는 활발하고 긍정적인 성격을 가졌어요. 새로운 환경에서도 빠르게 적응하며, 다양한 사람들과의 교류를 즐기는 사교적인 성향이 강해요. 풍부한 아이디어와 창의력을 바탕으로 문제를 해결하려는 능력을 가지고 있지만, 가끔은 산만하다는 평을 들을 수 있어요. 집중력을 기르는 것이 중요해요. 병자일주는 주변 사람들에게 긍정적인 영향을 미치며, 열린 마음으로 새로운 기회를 받아들이는 태도를 보여요.",
      relationship: "병자일주는 타인과의 관계에서 친근하고 밝은 태도로 주변 사람들에게 좋은 인상을 남겨요. 다만, 너무 많은 관계를 유지하려다 깊이가 부족해질 수 있으니 주의가 필요해요. 주변 사람들에게 활력을 불어넣는 존재로 인식되며, 관계를 더욱 강화하려면 꾸준한 소통과 신뢰를 쌓는 노력이 필요해요.",
      career: "병자일주는 창의력과 활동력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히 사람들과의 교류가 중요한 분야에서 두각을 나타낼 가능성이 커요. 새로운 아이디어를 실행하는 데 능숙하며, 대중과 소통하는 직업에서 만족감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "마케팅 전문가",
        "콘텐츠 제작자",
        "행사 기획자",
        "창업가",
        "강연가"
      ],
      wealth: "병자일주는 다양한 기회를 통해 재산을 증대시키는 타입이에요. 하지만 무리한 투자는 피하고 안정성을 겸비한 전략이 중요해요. 계획적인 자산 관리와 적극적인 기회를 탐색하는 태도가 재정적으로 성공을 가져올 수 있어요.",
      wealthKeywords: [
        "다양한 기회",
        "안정적 투자",
        "성장 지향"
      ]
    },
    {
      id: "dp14",
      name: "정축(丁丑)",
      heavenlyStem: {
        char: "정",
        emoji: "🕯️",
        element: "정화(丁火)",
        symbol: "촛불, 은은한 불",
        meaning: "따뜻하고 섬세한 성품으로, 주변을 밝히는 지혜와 통찰력이 있습니다. 차분하면서도 지속적인 열정을 가졌습니다."
      },
      earthlyBranch: {
        char: "축",
        animal: "소",
        element: "축토(丑土)",
        symbol: "땅속의 씨앗, 저장된 토양",
        symbolMeaning: "땅속에서 영양을 저장하고 있는 씨앗처럼 잠재된 가능성과 축적된 에너지를 상징합니다.",
        meaning: "성실하고 참을성이 있으며, 꾸준히 노력하는 성향입니다. 책임감이 강하고 신뢰할 수 있습니다."
      },
      image: chukCow,
      personality: "정축일주는 성실하고 신뢰감 있는 성격을 가졌어요. 주어진 일을 끝까지 해내며, 한 걸음씩 꾸준히 목표를 이루는 노력이 돋보이는 타입이에요. 현실적이고 실용적인 태도로 일과 관계를 처리하며, 안정성과 지속 가능성을 중시해요. 하지만 가끔은 지나치게 고지식해 보일 수 있어 유연함을 기르는 것이 중요해요. 정축일주는 믿음직스럽고 안정적인 성격으로 어려운 상황에서도 흔들리지 않고 중심을 잡을 수 있는 사람이에요.",
      relationship: "정축일주는 사람들에게 신뢰를 주는 태도로 주변에서 좋은 평가를 받아요. 하지만 자신의 의견을 고수하는 경향이 있어 타인의 의견을 수용하는 노력이 필요할 수 있어요. 조화롭고 성실한 태도로 팀워크를 중시하며, 조직 내에서 중요한 역할을 맡는 경우가 많아요. 주변 사람들에게 조언자로 인식되는 경우도 많아요.",
      career: "정축일주는 꾸준함과 책임감을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 안정적이고 체계적인 환경에서 자신의 장점을 발휘할 수 있으며, 관리와 분석이 필요한 분야에서 두각을 나타낼 가능성이 커요. 정확성과 실용성을 기반으로 한 직업에서 성공할 가능성이 높으며, 조직 내에서 신뢰를 얻을 수 있어요.",
      recommendedCareers: [
        "회계사",
        "금융 전문가",
        "연구원",
        "행정 관리자",
        "교육자"
      ],
      wealth: "정축일주는 철저한 계획과 안정적인 재정 관리를 통해 자산을 증대시키는 타입이에요. 무리한 위험은 피하고, 안정성을 기반으로 한 자산 증식을 선호해요. 장기적인 관점에서 투자 전략을 세우며, 실질적이고 꾸준한 자산 관리가 중요해요.",
      wealthKeywords: [
        "철저한 계획",
        "안정적 성장",
        "꾸준한 저축"
      ]
    },
    {
      id: "dp15",
      name: "무인(戊寅)",
      heavenlyStem: {
        char: "무",
        emoji: "⛰️",
        element: "무토(戊土)",
        symbol: "큰 산, 대지",
        meaning: "안정적이고 신뢰감 있는 성품으로, 책임감이 강하고 포용력이 큽니다. 든든한 산과 같은 존재감이 있습니다."
      },
      earthlyBranch: {
        char: "인",
        animal: "호랑이",
        element: "인목(寅木)",
        symbol: "떡갈나무, 큰 숲",
        symbolMeaning: "대지를 뚫고 나오는 강인한 나무의 기운처럼 강력한 생명력과 성장을 상징합니다.",
        meaning: "용감하고 정의로우며, 리더십이 있습니다. 도전정신이 강하고 진취적인 성향을 가졌습니다."
      },
      image: inTiger,
      personality: "무인일주는 강한 의지와 추진력을 가진 성격을 가졌어요. 목표를 설정하면 흔들림 없이 끝까지 나아가며, 도전적인 태도로 주변 사람들에게 영감을 주는 경우가 많아요. 현실적이고 실용적인 사고를 바탕으로 문제를 해결하며, 어려운 상황에서도 굴하지 않고 돌파구를 찾는 능력이 뛰어나요. 다만, 가끔은 지나치게 자기주장이 강하다는 평을 들을 수 있어 타인의 의견을 수용하려는 노력이 필요해요. 무인일주는 리더십과 열정을 바탕으로 팀과 조직을 이끄는 데 강한 역할을 할 수 있는 사람이에요.",
      relationship: "무인일주는 적극적이고 활발한 태도로 타인과의 관계를 형성하며, 신뢰를 얻는 데 능숙해요. 하지만 자신의 의견을 강하게 주장할 경우 갈등이 생길 수 있으니 적절한 조율이 필요해요. 주변 사람들에게 동기와 영감을 주는 존재로 인식되며, 관계를 더욱 강화하기 위해 열린 태도가 중요해요.",
      career: "무인일주는 창의성과 리더십을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 새로운 아이디어를 실현하거나 조직을 이끄는 분야에서 두각을 나타낼 가능성이 커요. 혁신적인 환경에서 더 큰 성과를 낼 수 있으며, 도전과 변화를 즐기는 직업을 선택하면 만족감이 높아질 가능성이 커요.",
      recommendedCareers: [
        "창업가",
        "프로젝트 매니저",
        "마케팅 리더",
        "콘텐츠 제작자",
        "강연가"
      ],
      wealth: "무인일주는 적극적인 투자와 재정 관리를 통해 자산을 증대시키는 타입이에요. 다만, 위험을 적절히 통제하며 안정성을 함께 고려하는 것이 중요해요. 장기적인 관점에서 재정 계획을 세우고, 도전과 신중함의 균형을 유지하는 것이 재정적으로 성공하는 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "장기 계획",
        "전략적 접근"
      ]
    },
    {
      id: "dp16",
      name: "기묘(己卯)",
      heavenlyStem: {
        char: "기",
        emoji: "🌾",
        element: "기토(己土)",
        symbol: "비옥한 땅, 기름진 토양",
        meaning: "온화하고 차분한 성품으로, 실용적이고 현실적인 사고를 가졌습니다. 안정을 추구하며 신중한 성향입니다."
      },
      earthlyBranch: {
        char: "묘",
        animal: "토끼",
        element: "묘목(卯木)",
        symbol: "꽃과 새싹, 유연한 덩굴",
        symbolMeaning: "부드럽게 뻗어나가는 덩굴처럼 유연하고 우아한 생명력을 상징합니다.",
        meaning: "민첩하고 우아하며, 예술적 감각이 뛰어납니다. 조화를 추구하고 평화를 사랑합니다."
      },
      image: myoRabbit,
      personality: "기묘일주는 섬세하고 창의적인 성격을 가졌어요. 주변의 변화를 빠르게 감지하며, 감성적이면서도 현실적인 태도를 유지하는 사람이에요. 감정적으로 안정된 편이며, 사람들에게 따뜻한 감정을 전달하는 능력이 있어 주변에서 신뢰를 얻어요. 하지만 지나치게 신중해 결정을 내리기까지 시간이 오래 걸릴 수 있어요. 기묘일주는 예술적 감각과 독창성을 발휘해 새로운 아이디어를 만들어내는 데 능숙하며, 자신의 창의성을 활용할 수 있는 환경에서 큰 성과를 낼 수 있어요.",
      relationship: "기묘일주는 부드럽고 조화로운 대인관계를 형성하며, 타인의 감정을 잘 이해하는 사람으로 평가받아요. 하지만 자신의 감정을 솔직히 표현하는 노력이 필요할 수 있어요. 타인과의 관계에서 감정적 균형을 유지하려는 태도가 돋보이며, 깊이 있는 인간관계를 형성하는 데 능숙해요.",
      career: "기묘일주는 감각적이고 창의력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 예술, 디자인, 상담과 같은 분야에서 두각을 나타낼 가능성이 커요. 인간적인 교감이 중요한 직업에서 성과를 낼 가능성이 높으며, 자신의 감정을 잘 활용할 수 있는 직업을 선택하면 더욱 유리해요.",
      recommendedCareers: [
        "예술가",
        "상담사",
        "디자이너",
        "교육자",
        "콘텐츠 제작자"
      ],
      wealth: "기묘일주는 균형 잡힌 소비와 투자 방식을 선호하며, 안정성을 중시하는 자산 관리 전략이 적합해요. 자신이 즐길 수 있는 분야에 투자하며, 장기적인 관점에서 자산을 확장하는 것이 좋아요. 재정을 관리할 때 자신의 창의성을 활용하면 더 큰 성과를 낼 수 있어요.",
      wealthKeywords: [
        "균형 있는 소비",
        "창의적 투자",
        "안정성 중시"
      ]
    },
    {
      id: "dp17",
      name: "경진(庚辰)",
      heavenlyStem: {
        char: "경",
        emoji: "🪨",
        element: "경금(庚金)",
        symbol: "바위, 돌",
        meaning: "강직하고 결단력 있는 성품으로, 원칙을 중시하고 정의감이 강합니다. 단단한 의지를 가졌습니다."
      },
      earthlyBranch: {
        char: "진",
        animal: "용",
        element: "진토(辰土)",
        symbol: "우레를 동반한 비, 천둥",
        symbolMeaning: "천둥과 비를 동반하며 만물을 깨우는 강력한 변화의 기운을 상징합니다.",
        meaning: "창의적이고 카리스마 있으며, 큰 꿈을 가졌습니다. 변화를 주도하고 성공을 추구합니다."
      },
      image: jinDragon,
      personality: "경진일주는 현실적이고 실용적인 성격을 가졌어요. 문제를 냉철하게 분석하며, 체계적인 방식으로 목표를 이루려는 사람이에요. 책임감이 강하고 목표 달성에 대한 의지가 뛰어나며, 계획적인 태도로 안정적인 결과를 만들어내요. 다만, 감정 표현이 부족하다는 평을 들을 수 있어 유연한 소통 능력을 기르는 것이 중요해요. 경진일주는 실질적인 결과를 중요하게 여기며, 꾸준한 노력을 통해 자신의 목표를 이루려는 안정된 성격의 소유자예요.",
      relationship: "경진일주는 타인과의 관계에서 신뢰를 중시하며, 솔직한 태도로 사람들과 소통해요. 자신의 의견을 명확히 표현하지만, 가끔은 지나치게 이성적이라는 인상을 줄 수 있어요. 주변 사람들에게 안정감을 주며, 대인관계를 유지하려면 더 많은 공감과 감정적 표현이 필요할 수 있어요.",
      career: "경진일주는 체계적이고 관리적인 능력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 법률, 경영, 프로젝트 관리와 같은 분야에서 두각을 나타낼 가능성이 커요. 실질적인 사고방식을 바탕으로 조직 내에서 중요한 역할을 맡으며, 신뢰를 얻는 경우가 많아요.",
      recommendedCareers: [
        "프로젝트 매니저",
        "법률가",
        "금융 전문가",
        "행정 관리자",
        "전략 기획자"
      ],
      wealth: "경진일주는 철저한 계획과 안정적인 투자로 자산을 늘리는 타입이에요. 지나치게 보수적이기보다 적절한 위험을 감수하는 전략을 통해 더 큰 성과를 낼 수 있어요. 장기적인 관점에서 투자하며, 현실적이고 꾸준한 자산 증식을 이루는 데 능숙해요.",
      wealthKeywords: [
        "안정적 투자",
        "철저한 계획",
        "실용적 관리"
      ]
    },
    {
      id: "dp18",
      name: "신사(辛巳)",
      heavenlyStem: {
        char: "신",
        emoji: "💍",
        element: "신금(辛金)",
        symbol: "보석, 장신구",
        meaning: "예리하고 섬세한 성품으로, 심미안이 뛰어나고 완벽을 추구합니다. 아름다움을 사랑하는 성향입니다."
      },
      earthlyBranch: {
        char: "사",
        animal: "뱀",
        element: "사화(巳火)",
        symbol: "은은한 불꽃, 숨은 화기",
        symbolMeaning: "조용히 타오르는 불꽃처럼 내면에 숨겨진 지혜와 통찰력을 상징합니다.",
        meaning: "지혜롭고 신중하며, 통찰력이 뛰어납니다. 비밀을 잘 지키고 신비로운 매력이 있습니다."
      },
      image: saSnake,
      personality: "신사일주는 명확하고 논리적인 성격을 가졌어요. 실수를 최소화하려는 태도로 계획을 꼼꼼히 세우며, 체계적으로 목표를 이루려는 사람이에요. 차분하고 분석적인 태도로 주변 사람들에게 신뢰를 주지만, 가끔은 완벽주의가 스트레스를 초래할 수 있어요. 적당한 균형을 유지하며 유연성을 기르는 것이 중요해요. 신사일주는 효율적이고 현실적인 접근 방식으로 주어진 일을 끝까지 해내는 책임감 있는 사람이에요.",
      relationship: "신사일주는 주변 사람들에게 안정감을 주며, 논리적이고 설득력 있는 대화로 신뢰를 얻어요. 하지만 감정을 표현하는 데 소극적일 수 있으니 더욱 적극적인 소통을 시도하는 것이 필요해요. 세심하고 이성적인 태도로 타인의 문제를 해결하려 노력하며, 진정성 있는 태도로 사람들과의 관계를 발전시킬 수 있어요.",
      career: "신사일주는 분석적 사고와 논리를 요구하는 직업에서 두각을 나타낼 가능성이 커요. 특히, 데이터 분석, 금융, 컨설팅과 같은 분야에서 성공 가능성이 높아요. 세부적인 계획이 필요한 직업에서 만족감을 느끼며, 조직 내에서 안정적인 위치를 확보할 수 있어요.",
      recommendedCareers: [
        "데이터 분석가",
        "컨설턴트",
        "금융 전문가",
        "연구원",
        "전략 기획자"
      ],
      wealth: "신사일주는 체계적인 계획과 데이터 기반의 투자를 선호하며, 위험을 최소화하면서 꾸준히 자산을 늘리는 타입이에요. 장기적인 관점에서 안정성을 중시하는 투자 방식을 활용하면 더 큰 성과를 기대할 수 있어요.",
      wealthKeywords: [
        "데이터 기반",
        "안정적 성장",
        "계획적 투자"
      ]
    },
    {
      id: "dp19",
      name: "임오(壬午)",
      heavenlyStem: {
        char: "임",
        emoji: "🌊",
        element: "임수(壬水)",
        symbol: "큰 물, 바다",
        meaning: "포용력이 크고 진취적인 성품으로, 도전정신과 창의성이 뛰어납니다. 깊이 있는 지혜를 가졌습니다."
      },
      earthlyBranch: {
        char: "오",
        animal: "말",
        element: "오화(午火)",
        symbol: "태양의 불꽃, 들불",
        symbolMeaning: "활활 타오르는 불꽃처럼 강렬한 열정과 에너지를 상징합니다.",
        meaning: "활동적이고 열정적이며, 자유를 사랑합니다. 솔직하고 정직한 성향을 가졌습니다."
      },
      image: ohHorse,
      personality: "임오일주는 열정적이고 활동적인 성격을 가졌어요. 다양한 환경에 빠르게 적응하며, 긍정적인 에너지로 주변 사람들에게 활력을 불어넣는 타입이에요. 새로운 도전을 즐기며, 어려운 상황에서도 돌파구를 찾아내는 능력이 있어요. 다만, 지나치게 성급한 결정이 갈등을 초래할 수 있으니 신중함을 기르는 것이 중요해요. 임오일주는 추진력과 자신감을 바탕으로 목표를 이루기 위해 끊임없이 노력하는 사람이에요.",
      relationship: "임오일주는 사람들과 쉽게 친해지는 사교적인 성격을 가지고 있어요. 하지만 많은 관계를 유지하려다 보니 깊이 있는 관계가 부족해질 수 있으니 주의가 필요해요. 열린 태도로 주변 사람들과 소통하며, 타인의 감정을 이해하려는 노력이 관계를 더욱 풍부하게 만들 수 있어요.",
      career: "임오일주는 창의력과 추진력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 변화가 많은 환경이나 대중과의 소통이 중요한 분야에서 두각을 나타낼 가능성이 커요. 도전적인 목표를 수행할 수 있는 직업에서 성과를 내며, 대중적 영향력을 발휘할 수 있는 역할에 적합해요.",
      recommendedCareers: [
        "마케팅 전문가",
        "콘텐츠 크리에이터",
        "이벤트 기획자",
        "창업가",
        "강연가"
      ],
      wealth: "임오일주는 도전적인 투자와 적극적인 재정 관리를 통해 자산을 증대시키는 타입이에요. 안정성을 병행하면서 새로운 기회를 찾아내는 것이 중요해요. 계획적인 투자 전략과 장기적인 관점이 재정적인 성공을 이끄는 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "계획적 접근",
        "성장 지향"
      ]
    },
    {
      id: "dp20",
      name: "계미(癸未)",
      heavenlyStem: {
        char: "계",
        emoji: "💧",
        element: "계수(癸水)",
        symbol: "작은 물, 비",
        meaning: "섬세하고 감성적인 성품으로, 직관력이 뛰어나고 생각이 깊습니다. 조용히 스며드는 영향력을 가졌습니다."
      },
      earthlyBranch: {
        char: "미",
        animal: "양",
        element: "미토(未土)",
        symbol: "비옥한 대지, 목초지",
        symbolMeaning: "풍성한 목초지처럼 풍요롭고 부드러운 대지의 기운을 상징합니다.",
        meaning: "온순하고 예술적이며, 창의성이 뛰어납니다. 평화를 사랑하고 조화를 추구합니다."
      },
      image: miSheep,
      personality: "계미일주는 차분하고 신뢰감 있는 성격을 가졌어요. 조화를 중시하며, 타인과 갈등을 피하려는 성향이 강한 사람이에요. 섬세하고 꼼꼼한 성격 덕분에 디테일을 놓치지 않으며, 맡은 일을 완벽하게 해내려고 노력해요. 하지만 지나친 완벽주의는 스트레스를 초래할 수 있으니 적당히 조화를 이루는 것이 중요해요. 계미일주는 안정적이고 성실한 태도로 주변 사람들에게 신뢰를 주며, 팀 내에서 중요한 역할을 맡는 경우가 많아요.",
      relationship: "계미일주는 배려심과 따뜻한 태도로 사람들과의 관계를 형성하며, 진정성 있는 태도로 신뢰를 얻는 경우가 많아요. 다만, 자신의 감정을 너무 억누르지 않고 표현하는 것이 필요해요. 조화롭고 안정적인 관계를 유지하며, 갈등을 해결하는 데도 뛰어난 능력을 발휘할 수 있어요.",
      career: "계미일주는 안정성과 신뢰를 필요로 하는 직업에서 성공 가능성이 높아요. 특히, 상담, 교육, 심리치료와 같은 사람을 돕는 분야에서 두각을 나타낼 가능성이 커요. 사람들과의 소통이 중요한 역할에서도 큰 성과를 낼 가능성이 높으며, 자신의 감각을 활용할 수 있는 분야가 적합해요.",
      recommendedCareers: [
        "상담사",
        "교육자",
        "심리치료사",
        "콘텐츠 기획자",
        "HR 전문가"
      ],
      wealth: "계미일주는 안정적이고 장기적인 재정 관리를 통해 자산을 늘리는 타입이에요. 위험을 최소화하며, 꾸준한 저축과 투자 전략을 통해 성장할 수 있어요. 감정적 결정보다는 데이터 기반의 실용적인 접근 방식을 선호하며, 균형 잡힌 자산 관리를 추구해",
      wealthKeywords: []
    },
    {
      id: "dp21",
      name: "갑신(甲申)",
      heavenlyStem: {
        char: "갑",
        emoji: "🌳",
        element: "갑목(甲木)",
        symbol: "큰 나무, 떡갈나무",
        meaning: "정직하고 곧은 성품을 지니며, 목표를 향해 꾸준히 성장하려는 성향이 강합니다. 뿌리깊은 나무처럼 안정적이고 신뢰감이 있습니다."
      },
      earthlyBranch: {
        char: "신",
        animal: "원숭이",
        element: "신금(申金)",
        symbol: "백색 금속, 단단한 쇠",
        symbolMeaning: "차갑고 단단한 금속처럼 예리하고 강인한 의지를 상징합니다.",
        meaning: "영리하고 재치있으며, 적응력이 뛰어납니다. 다재다능하고 문제해결 능력이 탁월합니다."
      },
      image: shinMonkey,
      personality: "갑신일주는 창의적이고 분석적인 성격을 가졌어요. 새로운 아이디어를 떠올리는 데 탁월하며, 이를 실현하려는 강한 추진력을 가지고 있어요. 명확한 목표와 계획을 세우는 데 능숙하며, 도전을 두려워하지 않는 적극적인 태도를 보이는 사람이에요. 하지만 자신의 의견을 지나치게 고집할 때가 있어, 주변의 의견을 수용하는 균형 잡힌 태도가 필요해요. 갑신일주는 독립심이 강하고 진취적인 태도로 새로운 환경에서도 빠르게 적응할 수 있는 능력이 돋보여요.",
      relationship: "갑신일주는 사교적이고 자신감 있는 태도로 주변 사람들에게 신뢰를 얻어요. 하지만 자신의 관점을 지나치게 강조하지 않도록 조율하는 노력이 필요해요. 팀워크를 통해 가치를 창출하려는 태도가 돋보이며, 긍정적인 에너지를 전달하는 능력이 있어요.",
      career: "갑신일주는 창의성과 추진력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 변화와 혁신을 필요로 하는 분야에서 두각을 나타낼 가능성이 커요. 새로운 프로젝트를 이끌거나 창의적인 아이디어를 실현할 수 있는 직업에서 높은 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "창업가",
        "마케팅 전문가",
        "프로젝트 매니저",
        "디자이너",
        "콘텐츠 제작자"
      ],
      wealth: "갑신일주는 도전적인 투자와 창의적인 접근 방식을 선호하지만, 안정성을 병행하는 전략이 중요해요. 장기적인 관점에서 자산을 관리하며, 균형 잡힌 투자를 통해 꾸준히 성과를 이룰 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "창의적 접근",
        "안정성 유지"
      ]
    },
    {
      id: "dp22",
      name: "을유(乙酉)",
      heavenlyStem: {
        char: "을",
        emoji: "🌱",
        element: "을목(乙木)",
        symbol: "작은 나무, 풀",
        meaning: "유연하고 섬세한 성품을 지니며, 상황에 잘 적응하는 융통성이 있습니다. 부드럽지만 강인한 생명력을 가졌습니다."
      },
      earthlyBranch: {
        char: "유",
        animal: "닭",
        element: "유금(酉金)",
        symbol: "보석, 정제된 금속",
        symbolMeaning: "정교하게 다듬어진 보석처럼 순수하고 완벽을 추구하는 성질을 상징합니다.",
        meaning: "정확하고 완벽을 추구하며, 성실합니다. 책임감이 강하고 시간 관념이 뛰어납니다."
      },
      image: yuChicken,
      personality: "을유일주는 섬세하고 차분한 성격을 가졌어요. 주변 상황을 명확히 분석하며, 실질적인 해결책을 찾으려는 경향이 강한 사람이에요. 이성적이고 현실적인 태도로 문제를 해결하며, 주변 사람들에게 안정감을 주는 신뢰감 있는 모습이 돋보여요. 하지만 지나치게 신중한 태도는 결정을 늦추는 원인이 될 수 있으니 적절한 결단력을 기르는 것이 중요해요. 을유일주는 실용적이고 안정적인 태도로 환경을 조율하며, 실질적인 성과를 중요하게 여기는 사람이에요.",
      relationship: "을유일주는 논리적이고 설득력 있는 대화로 타인과 소통하며, 깊이 있는 관계를 형성하려는 노력이 돋보여요. 하지만 자신의 감정을 적절히 표현하려는 노력이 필요할 수 있어요. 조화롭고 안정적인 관계를 유지하려는 태도로 사람들에게 좋은 인상을 남길 가능성이 높아요.",
      career: "을유일주는 체계적이고 실용적인 접근 방식을 요구하는 직업에서 성공 가능성이 높아요. 특히, 데이터 분석, 금융, 연구와 같은 분야에서 두각을 나타낼 가능성이 커요. 분석력과 실용성을 발휘할 수 있는 환경에서 성취감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "데이터 분석가",
        "금융 전문가",
        "연구원",
        "컨설턴트",
        "회계사"
      ],
      wealth: "을유일주는 안정성과 신중함을 기반으로 자산을 관리하며, 위험을 최소화하면서 꾸준히 자산을 늘리는 타입이에요. 장기적인 관점에서 재정 계획을 세우고, 균형 잡힌 투자를 통해 안정감을 유지할 수 있어요.",
      wealthKeywords: [
        "안정적 관리",
        "신중한 투자",
        "균형 유지"
      ]
    },
    {
      id: "dp23",
      name: "병술(丙戌)",
      heavenlyStem: {
        char: "병",
        emoji: "🔥",
        element: "병화(丙火)",
        symbol: "태양, 번쩍이는 불",
        meaning: "열정적이고 활동적인 성향으로, 리더십이 강하고 영향력이 큽니다. 밝고 긍정적인 에너지를 가졌습니다."
      },
      earthlyBranch: {
        char: "술",
        animal: "개",
        element: "술토(戌土)",
        symbol: "성벽, 단단한 흙",
        symbolMeaning: "견고한 성벽처럼 단단하고 방어적인 대지의 성질을 상징합니다.",
        meaning: "충직하고 정의로우며, 의리가 강합니다. 신념이 확고하고 보호본능이 강합니다."
      },
      image: soolDog,
      personality: "병술일주는 강한 책임감과 의지를 가진 성격이에요. 목표를 정하면 흔들리지 않고 끝까지 이루려는 끈기 있는 사람이에요. 현실적이고 실용적인 사고를 기반으로 문제를 해결하며, 주변 사람들에게 신뢰를 주는 안정적인 태도를 보여요. 다만, 지나치게 고집스러운 면이 갈등을 초래할 수 있으니 유연한 태도를 기르는 것이 중요해요. 병술일주는 성실하고 체계적인 접근 방식을 통해 장기적인 성과를 이루는 데 능숙해요.",
      relationship: "병술일주는 사람들과의 관계에서 솔직하고 직접적인 태도를 보이며, 신뢰를 쌓는 데 능숙해요. 다만, 자신의 의견을 강하게 주장할 경우 갈등이 생길 수 있으니 조율이 필요해요. 주변 사람들에게 안정감을 제공하며, 깊이 있는 관계를 유지하려는 노력이 돋보여요.",
      career: "병술일주는 책임감과 실용성을 필요로 하는 직업에서 성공 가능성이 높아요. 특히, 법률, 경영, 프로젝트 관리와 같은 분야에서 두각을 나타낼 가능성이 커요. 조직 내에서 중요한 역할을 맡으며, 체계적인 사고와 실행력을 발휘할 수 있는 직업에서 성취감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "변호사",
        "프로젝트 매니저",
        "경영 컨설턴트",
        "공무원",
        "연구원"
      ],
      wealth: "병술일주는 철저한 계획과 신중한 재정 관리를 통해 자산을 축적하며, 안정적인 투자를 선호하는 타입이에요. 장기적인 관점에서 꾸준히 자산을 관리하며, 무리한 위험을 피하는 것이 중요해요.",
      wealthKeywords: [
        "신중한 관리",
        "장기 계획",
        "안정적 투자"
      ]
    },
    {
      id: "dp24",
      name: "정해(丁亥)",
      heavenlyStem: {
        char: "정",
        emoji: "🕯️",
        element: "정화(丁火)",
        symbol: "촛불, 은은한 불",
        meaning: "따뜻하고 섬세한 성품으로, 주변을 밝히는 지혜와 통찰력이 있습니다. 차분하면서도 지속적인 열정을 가졌습니다."
      },
      earthlyBranch: {
        char: "해",
        animal: "돼지",
        element: "해수(亥水)",
        symbol: "깊은 바다, 큰 강",
        symbolMeaning: "깊은 바다처럼 포용력 있고 풍부한 수덕(水德)을 상징합니다.",
        meaning: "너그럽고 온화하며, 복덕이 있습니다. 정직하고 순수한 성품을 가졌습니다."
      },
      image: haePig,
      personality: "정해일주는 차분하고 신뢰감 있는 성격을 가졌어요. 타인의 감정을 잘 이해하며, 조화로운 관계를 형성하려는 성향이 강해요. 섬세하고 신중한 태도로 상황을 판단하며, 현실적이고 실용적인 사고로 문제를 해결하려는 경향이 있어요. 하지만 지나치게 신중한 태도가 결정을 지연시키는 원인이 될 수 있으니 적절한 균형이 필요해요. 정해일주는 조용하지만 안정감 있는 태도로 주변 사람들에게 신뢰를 주며, 팀 내에서 중요한 역할을 맡는 경우가 많아요.",
      relationship: "정해일주는 진정성과 배려심 있는 태도로 사람들과 소통하며, 깊이 있는 관계를 형성하려는 노력이 돋보여요. 자신의 감정을 적절히 표현하면 관계를 더욱 풍부하게 만들 수 있어요. 타인의 문제를 해결하려는 노력이 돋보이며, 조화로운 관계를 유지하려는 모습이 인상적이에요.",
      career: "정해일주는 섬세함과 책임감을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 상담, 교육, 예술과 같은 분야에서 두각을 나타낼 가능성이 커요. 조화로운 환경에서 자신의 감각을 활용할 수 있는 직업에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "상담사",
        "예술가",
        "교육자",
        "연구원",
        "콘텐츠 제작자"
      ],
      wealth: "정해일주는 안정적이고 신중한 재정 관리를 통해 자산을 늘리는 타입이에요. 장기적인 관점에서 균형 잡힌 투자를 통해 재정적 성과를 이룰 수 있어요. 꾸준한 저축과 실용적인 접근이 성공의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "안정적 투자",
        "신중한 관리",
        "꾸준한 성장"
      ]
    },
    {
      id: "dp25",
      name: "무자(戊子)",
      heavenlyStem: {
        char: "무",
        emoji: "⛰️",
        element: "무토(戊土)",
        symbol: "큰 산, 대지",
        meaning: "안정적이고 신뢰감 있는 성품으로, 책임감이 강하고 포용력이 큽니다. 든든한 산과 같은 존재감이 있습니다."
      },
      earthlyBranch: {
        char: "자",
        animal: "쥐",
        element: "자수(子水)",
        symbol: "깊은 밤의 고요한 물, 심해수",
        symbolMeaning: "깊은 곳에서 솟아나는 물처럼 깊이 있는 지혜와 잠재력을 상징합니다.",
        meaning: "지혜롭고 영리하며, 적응력이 뛰어납니다. 끊임없이 새로운 것을 탐구하고 수집하는 성향이 있습니다."
      },
      image: jaMouse,
      personality: "무자일주는 강한 추진력과 결단력을 가진 성격이에요. 목표를 설정하고 이를 이루기 위해 끝까지 노력하는 끈기가 돋보여요. 현실적이고 체계적인 태도로 문제를 해결하며, 조직 내에서 신뢰를 쌓는 데 능숙해요. 다만, 지나치게 고집스러운 면이 있을 수 있으니 조화를 이루려는 노력이 필요해요. 무자일주는 안정적인 태도로 어려운 상황에서도 흔들리지 않고 목표를 이루려는 의지가 강한 사람이에요.",
      relationship: "무자일주는 사람들과의 관계에서 솔직하고 정직한 태도를 보여요. 신뢰를 중시하며, 자신의 관점을 고집하기보다는 조율하려는 노력이 중요해요. 주변 사람들에게 안정감을 제공하며, 팀워크에서 중요한 역할을 수행할 가능성이 커요.",
      career: "무자일주는 책임감과 체계적인 사고를 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 경영, 법률, 프로젝트 관리와 같은 분야에서 두각을 나타낼 가능성이 커요. 끈기와 실용성을 발휘할 수 있는 환경에서 성취감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "경영 컨설턴트",
        "프로젝트 매니저",
        "변호사",
        "공무원",
        "금융 전문가"
      ],
      wealth: "무자일주는 철저한 계획과 안정적인 자산 관리를 통해 꾸준히 자산을 증대시키는 타입이에요. 위험을 최소화하며 장기적인 재정 목표를 달성할 수 있어요. 균형 잡힌 소비와 실용적인 접근이 재정적 안정의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "계획적 관리",
        "안정적 투자",
        "장기 목표"
      ]
    },
    {
      id: "dp26",
      name: "기축(己丑)",
      heavenlyStem: {
        char: "기",
        emoji: "🌾",
        element: "기토(己土)",
        symbol: "비옥한 땅, 기름진 토양",
        meaning: "온화하고 차분한 성품으로, 실용적이고 현실적인 사고를 가졌습니다. 안정을 추구하며 신중한 성향입니다."
      },
      earthlyBranch: {
        char: "축",
        animal: "소",
        element: "축토(丑土)",
        symbol: "땅속의 씨앗, 저장된 토양",
        symbolMeaning: "땅속에서 영양을 저장하고 있는 씨앗처럼 잠재된 가능성과 축적된 에너지를 상징합니다.",
        meaning: "성실하고 참을성이 있으며, 꾸준히 노력하는 성향입니다. 책임감이 강하고 신뢰할 수 있습니다."
      },
      image: chukCow,
      personality: "기축일주는 성실하고 현실적인 성격을 가졌어요. 작은 일도 소홀히 하지 않으며, 맡은 일을 끝까지 완수하려는 책임감이 강해요. 현실적이고 실용적인 태도로 상황을 판단하며, 안정성을 중요하게 여기는 경향이 있어요. 하지만 가끔은 지나치게 보수적인 태도로 변화를 수용하는 데 시간이 걸릴 수 있어요. 기축일주는 신뢰감 있는 태도로 조직 내에서 중요한 역할을 맡으며, 꾸준히 목표를 향해 나아가는 사람이에요.",
      relationship: "기축일주는 조용하고 안정감 있는 태도로 타인에게 신뢰를 주며, 깊이 있는 관계를 형성하려는 노력이 돋보여요. 자신의 의견을 지나치게 고집하지 않도록 균형 잡힌 태도를 유지하는 것이 중요해요. 배려심 있는 태도로 사람들에게 좋은 인상을 남기며, 타인에게 안정감을 제공하는 능력이 뛰어나요.",
      career: "기축일주는 안정적이고 체계적인 환경에서 두각을 나타낼 가능성이 커요. 특히, 금융, 회계, 재정 관리와 같은 분야에서 성공할 가능성이 높아요. 꾸준함과 정확성을 요구하는 직업에서 높은 성취를 이룰 가능성이 있으며, 팀 내에서 신뢰받는 인재로 자리 잡을 가능성이 커요.",
      recommendedCareers: [
        "회계사",
        "금융 전문가",
        "재정 관리자",
        "연구원",
        "행정 관리자"
      ],
      wealth: "기축일주는 철저한 계획과 안정적인 재정 관리를 통해 자산을 축적하는 타입이에요. 무리한 투자는 피하고, 장기적인 관점에서 꾸준히 자산을 관리하는 것이 중요해요. 안정성과 수익성을 균형 있게 고려하는 접근이 성공의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "안정적 투자",
        "계획적 관리",
        "장기 목표"
      ]
    },
    {
      id: "dp27",
      name: "경인(庚寅)",
      heavenlyStem: {
        char: "경",
        emoji: "🪨",
        element: "경금(庚金)",
        symbol: "바위, 돌",
        meaning: "강직하고 결단력 있는 성품으로, 원칙을 중시하고 정의감이 강합니다. 단단한 의지를 가졌습니다."
      },
      earthlyBranch: {
        char: "인",
        animal: "호랑이",
        element: "인목(寅木)",
        symbol: "떡갈나무, 큰 숲",
        symbolMeaning: "대지를 뚫고 나오는 강인한 나무의 기운처럼 강력한 생명력과 성장을 상징합니다.",
        meaning: "용감하고 정의로우며, 리더십이 있습니다. 도전정신이 강하고 진취적인 성향을 가졌습니다."
      },
      image: inTiger,
      personality: "경인일주는 지혜롭고 독립적인 성격을 가졌어요. 새로운 환경에서도 빠르게 적응하며, 주어진 일을 능숙하게 처리하는 능력이 뛰어나요. 결단력과 실행력이 돋보이며, 어려운 상황에서도 해결책을 찾아내는 추진력이 있어요. 하지만 가끔은 독립적인 태도가 강해 주변의 의견을 충분히 경청하지 않을 수 있으니 주의가 필요해요. 경인일주는 진취적인 태도로 주변 사람들에게 긍정적인 영향을 주며, 도전을 즐기는 리더 타입이에요.",
      relationship: "경인일주는 자신감 있는 태도로 사람들에게 신뢰를 얻으며, 다양한 사람들과 쉽게 친밀해질 수 있어요. 하지만 자신의 의견을 지나치게 고집하지 않고 조율하는 태도가 필요해요. 타인과의 협력을 중시하며, 적극적인 태도로 팀 내에서 중요한 역할을 맡을 가능성이 커요.",
      career: "경인일주는 창의성과 추진력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 변화와 혁신이 중요한 분야에서 두각을 나타낼 가능성이 커요. 새로운 프로젝트를 주도하거나, 자신의 아이디어를 실행할 수 있는 직업에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "창업가",
        "프로젝트 매니저",
        "마케팅 전문가",
        "콘텐츠 제작자",
        "강연가"
      ],
      wealth: "경인일주는 도전적이고 창의적인 투자 방식을 선호하지만, 안정성을 고려하는 균형 잡힌 접근이 중요해요. 장기적인 관점에서 꾸준히 자산을 관리하며, 위험을 줄이는 전략이 성공의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "창의적 접근",
        "안정성 유지"
      ]
    },
    {
      id: "dp28",
      name: "신묘(辛卯)",
      heavenlyStem: {
        char: "신",
        emoji: "💍",
        element: "신금(辛金)",
        symbol: "보석, 장신구",
        meaning: "예리하고 섬세한 성품으로, 심미안이 뛰어나고 완벽을 추구합니다. 아름다움을 사랑하는 성향입니다."
      },
      earthlyBranch: {
        char: "묘",
        animal: "토끼",
        element: "묘목(卯木)",
        symbol: "꽃과 새싹, 유연한 덩굴",
        symbolMeaning: "부드럽게 뻗어나가는 덩굴처럼 유연하고 우아한 생명력을 상징합니다.",
        meaning: "민첩하고 우아하며, 예술적 감각이 뛰어납니다. 조화를 추구하고 평화를 사랑합니다."
      },
      image: myoRabbit,
      personality: "신묘일주는 섬세하고 분석적인 성격을 가졌어요. 주변 상황을 민감하게 파악하며, 문제를 해결하려는 능력이 뛰어나요. 현실적이고 논리적인 사고를 바탕으로 안정적인 결과를 중요하게 여기며, 꾸준한 노력으로 성과를 만들어내요. 하지만 지나치게 신중한 태도는 결정을 지연시키는 원인이 될 수 있으니 주의가 필요해요. 신묘일주는 차분하고 신뢰감 있는 태도로 주변 사람들에게 안정감을 주며, 조직 내에서 중요한 역할을 맡는 경우가 많아요.",
      relationship: "신묘일주는 논리적이고 설득력 있는 태도로 타인과 소통하며, 깊이 있는 관계를 형성하려는 노력이 돋보여요. 자신의 감정을 적절히 표현하려는 노력이 필요할 수 있어요. 사람들에게 안정감을 제공하며, 신뢰를 바탕으로 관계를 유지하려는 태도가 돋보여요.",
      career: "신묘일주는 체계적이고 분석적인 사고를 필요로 하는 직업에서 성공 가능성이 높아요. 특히, 연구, 금융, 데이터 분석과 같은 분야에서 두각을 나타낼 가능성이 커요. 문제를 해결하며 신뢰받는 역할을 수행할 가능성이 높으며, 실질적인 성과를 중시하는 환경에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "연구원",
        "데이터 분석가",
        "금융 전문가",
        "컨설턴트",
        "회계사"
      ],
      wealth: "신묘일주는 안정성과 신중함을 기반으로 재정을 관리하며, 위험을 최소화하면서 꾸준히 자산을 축적하는 타입이에요. 장기적인 관점에서 재정 계획을 세우고, 균형 잡힌 투자를 통해 안정감을 유지할 수 있어요.",
      wealthKeywords: [
        "신중한 관리",
        "안정적 투자",
        "꾸준한 성장"
      ]
    },
    {
      id: "dp29",
      name: "임진(壬辰)",
      heavenlyStem: {
        char: "임",
        emoji: "🌊",
        element: "임수(壬水)",
        symbol: "큰 물, 바다",
        meaning: "포용력이 크고 진취적인 성품으로, 도전정신과 창의성이 뛰어납니다. 깊이 있는 지혜를 가졌습니다."
      },
      earthlyBranch: {
        char: "진",
        animal: "용",
        element: "진토(辰土)",
        symbol: "우레를 동반한 비, 천둥",
        symbolMeaning: "천둥과 비를 동반하며 만물을 깨우는 강력한 변화의 기운을 상징합니다.",
        meaning: "창의적이고 카리스마 있으며, 큰 꿈을 가졌습니다. 변화를 주도하고 성공을 추구합니다."
      },
      image: jinDragon,
      personality: "임진일주는 지혜롭고 독립적인 성격을 가졌어요. 어려운 상황에서도 침착하게 대처하며, 해결책을 빠르게 찾아내는 능력이 있어요. 자유로운 사고방식을 바탕으로 기존의 틀을 벗어난 새로운 시도를 즐기며, 진취적인 태도로 주변 사람들에게 긍정적인 영향을 미쳐요. 하지만 가끔은 고집이 강하다는 평가를 받을 수 있어, 타인의 의견을 수용하려는 노력이 필요해요. 임진일주는 목표를 설정하고 이를 이루기 위해 끈기 있게 노력하며, 리더십을 발휘하는 데 능숙한 사람이에요.",
      relationship: "임진일주는 활발하고 사교적인 성격으로 다양한 사람들과 쉽게 친해질 수 있어요. 하지만 깊이 있는 관계를 유지하기 위해서는 꾸준한 소통과 신뢰를 쌓는 노력이 필요해요. 타인에게 동기를 부여하고 긍정적인 영향을 미치는 리더로 평가받으며, 협력적인 태도로 관계를 더욱 풍부하게 만들 수 있어요.",
      career: "임진일주는 창의력과 추진력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 도전과 혁신이 중요한 환경에서 두각을 나타낼 가능성이 커요. 새로운 프로젝트를 주도하거나, 사람들과 협력하여 가치를 창출할 수 있는 직업에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "창업가",
        "프로젝트 매니저",
        "마케팅 전문가",
        "콘텐츠 제작자",
        "강연가"
      ],
      wealth: "임진일주는 창의적인 투자와 전략적 접근을 통해 자산을 증대시키는 타입이에요. 안정성과 장기적인 관점에서 재정을 관리하는 것이 중요해요. 계획적인 접근과 꾸준한 실행이 재정적 성공의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "전략적 관리",
        "창의적 투자",
        "꾸준한 실행"
      ]
    },
    {
      id: "dp30",
      name: "계사(癸巳)",
      heavenlyStem: {
        char: "계",
        emoji: "💧",
        element: "계수(癸水)",
        symbol: "작은 물, 비",
        meaning: "섬세하고 감성적인 성품으로, 직관력이 뛰어나고 생각이 깊습니다. 조용히 스며드는 영향력을 가졌습니다."
      },
      earthlyBranch: {
        char: "사",
        animal: "뱀",
        element: "사화(巳火)",
        symbol: "은은한 불꽃, 숨은 화기",
        symbolMeaning: "조용히 타오르는 불꽃처럼 내면에 숨겨진 지혜와 통찰력을 상징합니다.",
        meaning: "지혜롭고 신중하며, 통찰력이 뛰어납니다. 비밀을 잘 지키고 신비로운 매력이 있습니다."
      },
      image: saSnake,
      personality: "계사일주는 신중하고 분석적인 성격을 가졌어요. 상황을 명확히 파악하며, 체계적인 계획으로 목표를 달성하려는 성향이 강해요. 논리적이고 차분한 태도로 주변 사람들에게 안정감을 주며, 감정적으로 휘둘리지 않고 합리적으로 행동해요. 하지만 지나치게 신중한 태도는 기회를 놓치는 원인이 될 수 있어요. 계사일주는 책임감 있는 태도로 문제를 해결하며, 조직 내에서 중요한 역할을 맡는 경우가 많아요.",
      relationship: "계사일주는 논리적이고 설득력 있는 대화로 주변 사람들에게 신뢰를 얻어요. 자신의 감정을 적절히 표현하려는 노력이 관계를 더욱 깊게 만드는 열쇠가 될 수 있어요. 세심하고 안정적인 태도로 사람들에게 도움을 주며, 깊이 있는 관계를 형성하는 데 능숙해요.",
      career: "계사일주는 분석력과 체계적인 사고를 요구하는 직업에서 두각을 나타낼 가능성이 높아요. 특히, 금융, 데이터 분석, 전략 기획과 같은 분야에서 성공 가능성이 커요. 철저한 계획과 실행력을 바탕으로 조직 내에서 신뢰를 얻으며, 책임 있는 역할을 맡는 경우가 많아요.",
      recommendedCareers: [
        "금융 분석가",
        "연구원",
        "데이터 과학자",
        "컨설턴트",
        "전략 기획자"
      ],
      wealth: "계사일주는 안정적이고 체계적인 자산 관리를 선호하며, 장기적인 관점에서 꾸준히 재산을 증대시키는 타입이에요. 위험을 최소화하며 계획적인 저축과 투자로 안정적인 기반을 다질 수 있어요.",
      wealthKeywords: [
        "계획적 투자",
        "장기 관리",
        "안정성 중시"
      ]
    },
    {
      id: "dp31",
      name: "갑오(甲午)",
      heavenlyStem: {
        char: "갑",
        emoji: "🌳",
        element: "갑목(甲木)",
        symbol: "큰 나무, 떡갈나무",
        meaning: "정직하고 곧은 성품을 지니며, 목표를 향해 꾸준히 성장하려는 성향이 강합니다. 뿌리깊은 나무처럼 안정적이고 신뢰감이 있습니다."
      },
      earthlyBranch: {
        char: "오",
        animal: "말",
        element: "오화(午火)",
        symbol: "태양의 불꽃, 들불",
        symbolMeaning: "활활 타오르는 불꽃처럼 강렬한 열정과 에너지를 상징합니다.",
        meaning: "활동적이고 열정적이며, 자유를 사랑합니다. 솔직하고 정직한 성향을 가졌습니다."
      },
      image: ohHorse,
      personality: "갑오일주는 창의적이고 활발한 성격을 가졌어요. 새로운 아이디어를 떠올리고 실행하는 데 주저하지 않는 적극적인 태도를 가지고 있어요. 주변 사람들과 잘 어울리며, 사교적인 성격 덕분에 신뢰와 호감을 쉽게 얻을 수 있어요. 다만, 지나치게 독립적인 성향으로 주변의 도움을 무시할 수 있으니 주의가 필요해요. 갑오일주는 긍정적이고 진취적인 태도로 주변 사람들에게 좋은 영향을 주며, 새로운 환경에서도 빠르게 적응할 수 있는 능력이 돋보여요.",
      relationship: "갑오일주는 사람들과의 관계에서 활발하고 자신감 넘치는 태도로 주변에 좋은 인상을 남겨요. 하지만 자신의 의견을 지나치게 강조할 경우 관계가 경직될 수 있으니 균형을 유지하는 것이 중요해요. 리더십이 돋보이며, 팀 내에서 중심적인 역할을 맡는 경우가 많아요. 자신의 감정을 적절히 표현하면 더욱 깊은 관계를 형성할 수 있어요.",
      career: "갑오일주는 창의성과 리더십을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히 혁신적인 아이디어를 요구하거나 사람들과의 상호작용이 많은 직업에서 두각을 나타낼 수 있어요. 새로운 프로젝트를 주도하거나 조직 내에서 리더십을 발휘하는 분야에서 큰 성과를 낼 가능성이 높아요.",
      recommendedCareers: [
        "스타트업 CEO",
        "마케팅 전문가",
        "이벤트 기획자",
        "콘텐츠 제작자",
        "디자이너"
      ],
      wealth: "갑오일주는 창의적이고 대담한 투자 방식을 선호하지만, 안정성을 함께 고려하는 것이 중요해요. 기회를 빠르게 포착하며, 꾸준한 재정 계획을 통해 장기적인 자산 성장을 이루는 데 강점이 있어요.",
      wealthKeywords: [
        "대담한 투자",
        "기회 포착",
        "안정성 유지"
      ]
    },
    {
      id: "dp32",
      name: "을미(乙未)",
      heavenlyStem: {
        char: "을",
        emoji: "🌱",
        element: "을목(乙木)",
        symbol: "작은 나무, 풀",
        meaning: "유연하고 섬세한 성품을 지니며, 상황에 잘 적응하는 융통성이 있습니다. 부드럽지만 강인한 생명력을 가졌습니다."
      },
      earthlyBranch: {
        char: "미",
        animal: "양",
        element: "미토(未土)",
        symbol: "비옥한 대지, 목초지",
        symbolMeaning: "풍성한 목초지처럼 풍요롭고 부드러운 대지의 기운을 상징합니다.",
        meaning: "온순하고 예술적이며, 창의성이 뛰어납니다. 평화를 사랑하고 조화를 추구합니다."
      },
      image: miSheep,
      personality: "을미일주는 온화하고 사려 깊은 성격을 가졌어요. 주변 사람들과 조화를 이루며, 갈등을 피하고 평화를 유지하려는 성향이 강해요. 섬세하고 예민한 성격으로 타인의 감정을 잘 이해하며, 이를 바탕으로 관계를 유지하는 데 능숙해요. 하지만 때로는 지나치게 소극적으로 보일 수 있으니 자기주장을 표현하는 연습이 필요해요. 을미일주는 차분한 태도와 성실함으로 신뢰를 얻으며, 안정적인 환경을 선호하는 사람이에요.",
      relationship: "을미일주는 타인의 감정을 잘 이해하며, 조화로운 대인관계를 유지하는 데 능숙해요. 진정성 있는 대화로 깊은 관계를 형성하지만, 자신의 의견을 감추지 않는 것이 필요해요. 배려심 있는 태도로 주변 사람들에게 긍정적인 인상을 남기며, 갈등을 조정하는 능력도 돋보여요.",
      career: "을미일주는 섬세함과 창의성을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히 예술, 디자인, 상담과 같은 감성적인 분야에서 두각을 나타낼 가능성이 커요. 사람들과의 교감이 중요한 직업에서 성과를 낼 가능성이 높으며, 자신의 감각을 활용할 수 있는 분야가 적합해요.",
      recommendedCareers: [
        "예술가",
        "디자이너",
        "상담사",
        "콘텐츠 제작자",
        "심리치료사"
      ],
      wealth: "을미일주는 안정적인 재정 관리와 꾸준한 저축을 선호하며, 장기적인 투자 계획을 세우는 것이 중요해요. 감정적 결정보다는 분석적인 접근을 통해 더 큰 재정적 성과를 기대할 수 있어요.",
      wealthKeywords: [
        "안정적 저축",
        "계획적 소비",
        "장기 투자"
      ]
    },
    {
      id: "dp33",
      name: "병신(丙申)",
      heavenlyStem: {
        char: "병",
        emoji: "🔥",
        element: "병화(丙火)",
        symbol: "태양, 번쩍이는 불",
        meaning: "열정적이고 활동적인 성향으로, 리더십이 강하고 영향력이 큽니다. 밝고 긍정적인 에너지를 가졌습니다."
      },
      earthlyBranch: {
        char: "신",
        animal: "원숭이",
        element: "신금(申金)",
        symbol: "백색 금속, 단단한 쇠",
        symbolMeaning: "차갑고 단단한 금속처럼 예리하고 강인한 의지를 상징합니다.",
        meaning: "영리하고 재치있으며, 적응력이 뛰어납니다. 다재다능하고 문제해결 능력이 탁월합니다."
      },
      image: shinMonkey,
      personality: "병신일주는 열정적이고 독창적인 성격을 가졌어요. 새로운 아이디어를 빠르게 떠올리며, 이를 실행에 옮기는 능력이 뛰어난 사람이에요. 자신만의 방식으로 문제를 해결하려는 성향이 강하며, 도전적인 태도로 새로운 시도를 즐겨요. 하지만 가끔은 성급하게 판단해 실수를 범할 수 있으니 신중함을 기르는 것이 중요해요. 병신일주는 주변 사람들에게 긍정적인 에너지를 전달하며, 진취적이고 활발한 성격으로 환경을 변화시키는 힘을 가지고 있어요.",
      relationship: "병신일주는 타인과의 관계에서 열정적이고 사교적인 태도로 주목받는 경우가 많아요. 하지만 자신의 의견을 지나치게 강조하면 관계가 경직될 수 있어요. 타인에게 동기를 부여하고 긍정적인 영향을 주며, 다양한 사람들과의 관계를 유지하는 데 능숙해요.",
      career: "병신일주는 창의성과 추진력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 도전과 혁신이 중요한 분야에서 두각을 나타낼 가능성이 커요. 새로운 아이디어를 실행하거나, 대중과 소통하는 직업에서 성과를 낼 가능성이 높아요.",
      recommendedCareers: [
        "콘텐츠 제작자",
        "마케팅 리더",
        "디자이너",
        "창업가",
        "강연가"
      ],
      wealth: "병신일주는 도전적인 투자와 창의적인 접근 방식을 선호하며, 새로운 기회를 적극적으로 활용하는 타입이에요. 안정성과 수익성을 병행하며 장기적인 재정 목표를 세우는 것이 필요해요.",
      wealthKeywords: [
        "도전적 투자",
        "창의적 접근",
        "안정성 중시"
      ]
    },
    {
      id: "dp34",
      name: "정유(丁酉)",
      heavenlyStem: {
        char: "정",
        emoji: "🕯️",
        element: "정화(丁火)",
        symbol: "촛불, 은은한 불",
        meaning: "따뜻하고 섬세한 성품으로, 주변을 밝히는 지혜와 통찰력이 있습니다. 차분하면서도 지속적인 열정을 가졌습니다."
      },
      earthlyBranch: {
        char: "유",
        animal: "닭",
        element: "유금(酉金)",
        symbol: "보석, 정제된 금속",
        symbolMeaning: "정교하게 다듬어진 보석처럼 순수하고 완벽을 추구하는 성질을 상징합니다.",
        meaning: "정확하고 완벽을 추구하며, 성실합니다. 책임감이 강하고 시간 관념이 뛰어납니다."
      },
      image: yuChicken,
      personality: "정유일주는 꼼꼼하고 세심한 성격을 가졌어요. 체계적인 사고와 명확한 목표 설정으로 주변 사람들에게 신뢰를 얻는 타입이에요. 현실적이고 분석적인 태도로 일을 처리하며, 세부적인 부분까지 놓치지 않으려는 책임감이 강해요. 하지만 때로는 지나친 완벽주의로 스트레스를 받을 수 있으니 유연함을 기르는 것이 중요해요. 정유일주는 실용적이고 조직적인 접근으로 어려운 상황에서도 문제를 해결하는 능력을 발휘하며, 목표 달성을 위해 꾸준히 노력하는 사람이에요.",
      relationship: "정유일주는 논리적이고 설득력 있는 대화를 통해 타인과 소통하며, 깊이 있는 관계를 형성하려는 경향이 있어요. 자신의 의견을 지나치게 강조하지 않도록 조율하는 능력이 필요할 수 있어요. 조용하지만 진정성 있는 태도로 주변 사람들에게 신뢰를 얻으며, 팀 내에서 중요한 역할을 맡는 경우가 많아요.",
      career: "정유일주는 체계적이고 세부적인 관리가 필요한 직업에서 성공 가능성이 높아요. 특히, 금융, 데이터 분석, 프로젝트 관리와 같은 분야에서 두각을 나타낼 가능성이 커요. 분석력과 실용성을 요구하는 직업에서 만족감을 느끼며, 조직 내에서 신뢰받는 인재로 성장할 수 있어요.",
      recommendedCareers: [
        "데이터 분석가",
        "연구원",
        "금융 전문가",
        "프로젝트 매니저",
        "전략 기획자"
      ],
      wealth: "정유일주는 안정적인 투자와 계획적인 재정 관리를 통해 자산을 늘리는 타입이에요. 큰 리스크를 피하고 꾸준히 자산을 축적하는 전략이 효과적이에요. 장기적인 관점에서 안정성과 성장을 병행하는 투자가 적합해요.",
      wealthKeywords: [
        "안정적 투자",
        "계획적 저축",
        "꾸준한 성장"
      ]
    },
    {
      id: "dp35",
      name: "무술(戊戌)",
      heavenlyStem: {
        char: "무",
        emoji: "⛰️",
        element: "무토(戊土)",
        symbol: "큰 산, 대지",
        meaning: "안정적이고 신뢰감 있는 성품으로, 책임감이 강하고 포용력이 큽니다. 든든한 산과 같은 존재감이 있습니다."
      },
      earthlyBranch: {
        char: "술",
        animal: "개",
        element: "술토(戌土)",
        symbol: "성벽, 단단한 흙",
        symbolMeaning: "견고한 성벽처럼 단단하고 방어적인 대지의 성질을 상징합니다.",
        meaning: "충직하고 정의로우며, 의리가 강합니다. 신념이 확고하고 보호본능이 강합니다."
      },
      image: soolDog,
      personality: "무술일주는 강한 책임감과 결단력을 가진 성격이에요. 자신의 원칙을 중요하게 생각하며, 주어진 일을 끝까지 해내려는 끈기를 가지고 있어요. 현실적이고 실용적인 사고를 기반으로 복잡한 문제를 해결하며, 조직 내에서 리더 역할을 맡는 경우가 많아요. 다만, 지나치게 원칙을 고수하면 융통성이 부족하다는 평을 받을 수 있어요. 무술일주는 안정적이고 신뢰감 있는 태도로 어려운 상황에서도 흔들림 없이 목표를 이루려는 사람이에요.",
      relationship: "무술일주는 타인과의 관계에서 솔직하고 직접적인 태도를 선호하며, 신뢰를 중요하게 여겨요. 다만, 자신의 의견을 너무 강하게 주장하면 갈등이 생길 수 있으니 적절한 조율이 필요해요. 리더십을 발휘하며 주변 사람들에게 의지가 되는 존재로 인식되며, 관계를 더욱 강화하려면 유연한 태도를 보이는 것이 중요해요.",
      career: "무술일주는 책임감과 체계적인 사고를 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히 경영, 법률, 프로젝트 관리와 같은 분야에서 두각을 나타낼 가능성이 커요. 조직 내에서 중요한 역할을 맡으며, 자신만의 원칙을 기반으로 리더십을 발휘할 수 있어요.",
      recommendedCareers: [
        "프로젝트 매니저",
        "변호사",
        "경영자",
        "공무원",
        "컨설턴트"
      ],
      wealth: "무술일주는 철저한 계획과 안정적인 재정 관리를 통해 자산을 늘리는 타입이에요. 현실적이고 실용적인 접근 방식을 활용하면 재정적 성과를 더 크게 만들 수 있어요. 장기적인 관점에서 자산을 증대하며, 무리한 투자는 피하는 것이 중요해요.",
      wealthKeywords: [
        "계획적 관리",
        "안정적 성장",
        "신중한 투자"
      ]
    },
    {
      id: "dp36",
      name: "기해(己亥)",
      heavenlyStem: {
        char: "기",
        emoji: "🌾",
        element: "기토(己土)",
        symbol: "비옥한 땅, 기름진 토양",
        meaning: "온화하고 차분한 성품으로, 실용적이고 현실적인 사고를 가졌습니다. 안정을 추구하며 신중한 성향입니다."
      },
      earthlyBranch: {
        char: "해",
        animal: "돼지",
        element: "해수(亥水)",
        symbol: "깊은 바다, 큰 강",
        symbolMeaning: "깊은 바다처럼 포용력 있고 풍부한 수덕(水德)을 상징합니다.",
        meaning: "너그럽고 온화하며, 복덕이 있습니다. 정직하고 순수한 성품을 가졌습니다."
      },
      image: haePig,
      personality: "기해일주는 온화하고 조화로운 성격을 가졌어요. 타인의 감정을 잘 이해하며, 평화로운 환경에서 조화를 이루려는 태도가 강해요. 섬세하고 예민한 성격으로 주변 상황에 민감하게 반응하며, 안정적이고 신중한 태도로 문제를 해결하는 데 능숙해요. 하지만 가끔은 지나치게 소극적으로 보일 수 있으니 적극적인 태도를 기르는 것이 중요해요. 기해일주는 따뜻한 마음과 책임감을 바탕으로 어려운 상황에서도 신뢰를 주는 사람이에요.",
      relationship: "기해일주는 부드럽고 차분한 태도로 주변 사람들에게 긍정적인 영향을 미쳐요. 조용하지만 타인에게 깊은 인상을 남기며, 관계에서 배려심을 중시하는 경향이 강해요. 감정을 적절히 표현하는 것이 관계를 더욱 깊게 만들며, 타인과의 소통에서 진정성을 보이는 것이 중요해요.",
      career: "기해일주는 섬세함과 신뢰를 바탕으로 하는 직업에서 성공 가능성이 높아요. 특히, 예술, 상담, 교육, 치유와 같은 분야에서 두각을 나타낼 가능성이 커요. 조용한 환경에서도 창의력을 발휘하며, 자신의 감각과 교감을 활용할 수 있는 직업이 적합해요.",
      recommendedCareers: [
        "상담사",
        "예술가",
        "교육자",
        "콘텐츠 제작자",
        "심리치료사"
      ],
      wealth: "기해일주는 안정적이고 장기적인 재정 관리를 선호하며, 위험을 최소화하는 투자 전략이 적합해요. 꾸준한 저축과 계획적인 접근 방식을 통해 재정적 안정감을 확보할 수 있어요.",
      wealthKeywords: [
        "장기 투자",
        "안정적 관리",
        "균형 잡힌 소비"
      ]
    },
    {
      id: "dp37",
      name: "경자(庚子)",
      heavenlyStem: {
        char: "경",
        emoji: "🪨",
        element: "경금(庚金)",
        symbol: "바위, 돌",
        meaning: "강직하고 결단력 있는 성품으로, 원칙을 중시하고 정의감이 강합니다. 단단한 의지를 가졌습니다."
      },
      earthlyBranch: {
        char: "자",
        animal: "쥐",
        element: "자수(子水)",
        symbol: "깊은 밤의 고요한 물, 심해수",
        symbolMeaning: "깊은 곳에서 솟아나는 물처럼 깊이 있는 지혜와 잠재력을 상징합니다.",
        meaning: "지혜롭고 영리하며, 적응력이 뛰어납니다. 끊임없이 새로운 것을 탐구하고 수집하는 성향이 있습니다."
      },
      image: jaMouse,
      personality: "경자일주는 냉철하고 이성적인 성격을 가졌어요. 상황을 명확히 분석하며, 합리적인 판단을 내리는 데 강점이 있는 사람이에요. 책임감이 강하고 체계적인 사고를 바탕으로 어려운 상황에서도 흔들림 없이 목표를 달성하려는 의지를 보여요. 하지만 가끔은 감정적인 면에서 부족하다는 평을 받을 수 있으니 균형 잡힌 태도를 기르는 것이 중요해요. 경자일주는 실용적이고 현실적인 태도로 주어진 과제를 완벽하게 해결하며, 조직 내에서 신뢰받는 사람으로 자리 잡을 가능성이 커요.",
      relationship: "경자일주는 타인의 의견을 존중하며, 공정한 태도로 사람들과 소통하는 능력이 뛰어나요. 하지만 자신의 감정을 표현하는 데 소극적인 면이 있어 관계를 더 깊게 발전시키기 위해 노력할 필요가 있어요. 논리적이고 설득력 있는 대화로 주변 사람들에게 신뢰를 얻으며, 주변 환경을 안정적으로 유지하려는 태도가 돋보여요.",
      career: "경자일주는 체계적이고 분석적인 사고를 요구하는 직업에서 두각을 나타낼 가능성이 높아요. 특히, 법률, 금융, 데이터 분석과 같은 분야에서 적합해요. 실용적인 사고와 세부적인 관리 능력을 바탕으로 조직 내에서 중요한 역할을 맡으며, 장기적인 관점에서 성장할 수 있는 직업이 좋아요.",
      recommendedCareers: [
        "데이터 분석가",
        "연구원",
        "법률 전문가",
        "전략 기획자",
        "회계사"
      ],
      wealth: "경자일주는 안정적이고 철저한 계획을 바탕으로 자산을 관리하는 타입이에요. 무리한 모험보다 안정성을 우선시하며, 꾸준한 자산 증식을 목표로 해요. 장기적인 관점에서 균형 잡힌 투자를 통해 재정적인 안정과 성장을 동시에 추구해요.",
      wealthKeywords: [
        "안정적 투자",
        "계획적 저축",
        "균형 관리"
      ]
    },
    {
      id: "dp38",
      name: "신축(辛丑)",
      heavenlyStem: {
        char: "신",
        emoji: "💍",
        element: "신금(辛金)",
        symbol: "보석, 장신구",
        meaning: "예리하고 섬세한 성품으로, 심미안이 뛰어나고 완벽을 추구합니다. 아름다움을 사랑하는 성향입니다."
      },
      earthlyBranch: {
        char: "축",
        animal: "소",
        element: "축토(丑土)",
        symbol: "땅속의 씨앗, 저장된 토양",
        symbolMeaning: "땅속에서 영양을 저장하고 있는 씨앗처럼 잠재된 가능성과 축적된 에너지를 상징합니다.",
        meaning: "성실하고 참을성이 있으며, 꾸준히 노력하는 성향입니다. 책임감이 강하고 신뢰할 수 있습니다."
      },
      image: chukCow,
      personality: "신축일주는 성실하고 꾸준한 성격을 가졌어요. 맡은 일을 끝까지 해내며, 현실적인 태도로 주변 사람들에게 신뢰를 얻는 타입이에요. 세부적인 계획을 세우는 데 능숙하며, 안정성과 지속 가능성을 중시해요. 하지만 가끔은 지나치게 보수적인 태도로 인해 변화에 적응하는 데 시간이 걸릴 수 있어요. 신축일주는 조직 내에서 안정적인 기반을 다지며, 실질적이고 꾸준한 노력을 통해 장기적인 성과를 만들어내는 사람이에요.",
      relationship: "신축일주는 배려심 많고 믿음직스러운 태도로 주변 사람들에게 좋은 인상을 남겨요. 자신의 의견을 고집할 때는 타인의 입장을 수용하려는 노력이 필요해요. 조화롭고 성실한 태도로 사람들과 관계를 유지하며, 깊이 있는 대화를 통해 신뢰를 쌓는 데 능숙해요.",
      career: "신축일주는 안정적인 환경과 체계적인 관리를 요구하는 직업에서 성공 가능성이 높아요. 특히 회계, 재정 관리, 교육 분야에서 두각을 나타낼 가능성이 커요. 자신의 분석력과 세심함을 발휘할 수 있는 분야에서 높은 성취를 이룰 가능성이 커요.",
      recommendedCareers: [
        "회계사",
        "행정 관리자",
        "연구원",
        "교육자",
        "금융 전문가"
      ],
      wealth: "신축일주는 철저한 계획과 신중한 투자로 자산을 늘리는 타입이에요. 안전성을 중시하며, 장기적인 자산 관리를 통해 재정적 안정감을 확보할 수 있어요. 꾸준한 저축과 계획적인 접근이 재정적인 성공의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "신중한 투자",
        "안정적 관리",
        "계획적 저축"
      ]
    },
    {
      id: "dp39",
      name: "임인(壬寅)",
      heavenlyStem: {
        char: "임",
        emoji: "🌊",
        element: "임수(壬水)",
        symbol: "큰 물, 바다",
        meaning: "포용력이 크고 진취적인 성품으로, 도전정신과 창의성이 뛰어납니다. 깊이 있는 지혜를 가졌습니다."
      },
      earthlyBranch: {
        char: "인",
        animal: "호랑이",
        element: "인목(寅木)",
        symbol: "떡갈나무, 큰 숲",
        symbolMeaning: "대지를 뚫고 나오는 강인한 나무의 기운처럼 강력한 생명력과 성장을 상징합니다.",
        meaning: "용감하고 정의로우며, 리더십이 있습니다. 도전정신이 강하고 진취적인 성향을 가졌습니다."
      },
      image: inTiger,
      personality: "임인일주는 열정적이고 진취적인 성격을 가졌어요. 새로운 도전을 즐기며, 끊임없이 성장하려는 의지가 강한 사람이에요. 자신감 넘치는 태도로 주변 사람들에게 긍정적인 영향을 미치지만, 가끔은 고집이 강하다는 평가를 받을 수 있어요. 이를 보완하기 위해 타인의 의견을 적극적으로 수용하는 노력이 필요해요. 임인일주는 창의적인 아이디어와 추진력을 바탕으로 다양한 환경에서 두각을 나타내는 리더 타입이에요.",
      relationship: "임인일주는 사람들과 활발히 교류하며, 타인에게 영감을 주는 사교적인 성격을 가지고 있어요. 하지만 깊이 있는 관계를 유지하려면 꾸준한 소통과 신뢰가 필요해요. 열정적인 태도로 주변 사람들에게 동기를 부여하며, 팀워크에서도 큰 성과를 낼 수 있어요.",
      career: "임인일주는 창의성과 리더십을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 변화를 선도하거나 도전을 요구하는 분야에서 두각을 나타낼 가능성이 커요. 새로운 아이디어를 실행하거나 조직을 이끄는 직업에서 성취감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "창업가",
        "프로젝트 매니저",
        "콘텐츠 제작자",
        "마케팅 전문가",
        "강연가"
      ],
      wealth: "임인일주는 도전적인 투자와 전략적 접근을 통해 자산을 증대시키는 타입이에요. 하지만 지나친 리스크를 피하고 안정성을 병행하는 것이 중요해요. 장기적인 관점에서 체계적으로 재정을 관리하며 성장을 도모하는 것이 효과적이에요.",
      wealthKeywords: [
        "도전적 투자",
        "전략적 관리",
        "안정성 병행"
      ]
    },
    {
      id: "dp40",
      name: "계묘(癸卯)",
      heavenlyStem: {
        char: "계",
        emoji: "💧",
        element: "계수(癸水)",
        symbol: "작은 물, 비",
        meaning: "섬세하고 감성적인 성품으로, 직관력이 뛰어나고 생각이 깊습니다. 조용히 스며드는 영향력을 가졌습니다."
      },
      earthlyBranch: {
        char: "묘",
        animal: "토끼",
        element: "묘목(卯木)",
        symbol: "꽃과 새싹, 유연한 덩굴",
        symbolMeaning: "부드럽게 뻗어나가는 덩굴처럼 유연하고 우아한 생명력을 상징합니다.",
        meaning: "민첩하고 우아하며, 예술적 감각이 뛰어납니다. 조화를 추구하고 평화를 사랑합니다."
      },
      image: myoRabbit,
      personality: "계묘일주는 섬세하고 창의적인 성격을 가졌어요. 주변 상황을 민감하게 파악하며, 감정적으로도 안정된 태도를 유지하려는 사람이에요. 타인의 감정을 잘 이해하고, 이를 기반으로 조화로운 관계를 형성하려는 성향이 강해요. 하지만 지나치게 신중한 태도는 결정을 늦추는 원인이 될 수 있으니 주의가 필요해요. 계묘일주는 창의력과 현실적인 사고를 조화롭게 발휘하며, 예술적 감각과 실용성을 겸비한 사람이에요.",
      relationship: "계묘일주는 따뜻한 태도와 공감 능력으로 주변 사람들에게 긍정적인 인상을 남겨요. 타인의 감정을 이해하며, 조화로운 관계를 유지하려는 노력이 돋보여요. 적극적으로 자신의 감정을 표현하는 것이 관계를 더욱 깊게 만드는 열쇠가 될 수 있어요.",
      career: "계묘일주는 창의력과 감각을 활용할 수 있는 직업에서 성공 가능성이 높아요. 특히, 예술, 디자인, 교육 분야에서 두각을 나타낼 가능성이 커요. 자신의 감정을 잘 활용할 수 있는 직업에서 높은 만족감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "예술가",
        "디자이너",
        "콘텐츠 제작자",
        "상담사",
        "교육자"
      ],
      wealth: "계묘일주는 안정성과 창의성을 결합한 투자 방식을 선호하며, 꾸준한 저축과 안전한 자산 증식을 중시해요. 장기적인 관점에서 균형 잡힌 접근 방식을 통해 재정을 관리하는 것이 중요해요.",
      wealthKeywords: [
        "균형 잡힌 소비",
        "창의적 투자",
        "장기적 성장"
      ]
    },
    {
      id: "dp41",
      name: "갑진(甲辰)",
      heavenlyStem: {
        char: "갑",
        emoji: "🌳",
        element: "갑목(甲木)",
        symbol: "큰 나무, 떡갈나무",
        meaning: "정직하고 곧은 성품을 지니며, 목표를 향해 꾸준히 성장하려는 성향이 강합니다. 뿌리깊은 나무처럼 안정적이고 신뢰감이 있습니다."
      },
      earthlyBranch: {
        char: "진",
        animal: "용",
        element: "진토(辰土)",
        symbol: "우레를 동반한 비, 천둥",
        symbolMeaning: "천둥과 비를 동반하며 만물을 깨우는 강력한 변화의 기운을 상징합니다.",
        meaning: "창의적이고 카리스마 있으며, 큰 꿈을 가졌습니다. 변화를 주도하고 성공을 추구합니다."
      },
      image: jinDragon,
      personality: "갑진일주는 실용적이고 현실적인 성격을 가졌어요. 목표를 이루기 위해 체계적이고 논리적인 접근 방식을 선호하며, 실질적인 결과를 중요하게 여겨요. 책임감이 강하고 꼼꼼한 태도로 일을 처리하지만, 가끔은 지나치게 고지식해 보일 수 있으니 융통성을 기르는 것이 중요해요. 갑진일주는 조직 내에서 중요한 역할을 맡으며, 꾸준한 노력으로 안정적이고 지속 가능한 성과를 만들어내는 사람이에요.",
      relationship: "갑진일주는 타인의 의견을 존중하며 신뢰를 기반으로 관계를 형성해요. 하지만 자신의 관점을 지나치게 고수하면 갈등이 생길 수 있으니 타인의 입장을 수용하는 유연한 태도가 필요해요. 성실하고 책임감 있는 태도로 사람들에게 신뢰를 주며, 안정적인 관계를 유지하는 능력을 가지고 있어요.",
      career: "갑진일주는 체계적이고 분석적인 사고를 요구하는 직업에서 두각을 나타낼 가능성이 높아요. 특히 법률, 경영, 재정 관리와 같은 분야에서 적합해요. 끈기와 책임감을 발휘할 수 있는 환경에서 성취감을 느끼며, 자신의 장점을 극대화할 수 있는 분야에서 높은 성과를 낼 가능성이 커요.",
      recommendedCareers: [
        "변호사",
        "경영 컨설턴트",
        "회계사",
        "프로젝트 매니저",
        "금융 전문가"
      ],
      wealth: "갑진일주는 안정적인 투자와 철저한 재정 관리로 자산을 증대시키는 타입이에요. 무리한 모험보다 장기적인 계획을 세워 꾸준히 자산을 관리하는 것이 중요해요. 균형 잡힌 접근을 통해 재정적 성장을 지속적으로 추구할 수 있어요.",
      wealthKeywords: [
        "안정적 투자",
        "계획적 관리",
        "지속 성장"
      ]
    },
    {
      id: "dp42",
      name: "을사(乙巳)",
      heavenlyStem: {
        char: "을",
        emoji: "🌱",
        element: "을목(乙木)",
        symbol: "작은 나무, 풀",
        meaning: "유연하고 섬세한 성품을 지니며, 상황에 잘 적응하는 융통성이 있습니다. 부드럽지만 강인한 생명력을 가졌습니다."
      },
      earthlyBranch: {
        char: "사",
        animal: "뱀",
        element: "사화(巳火)",
        symbol: "은은한 불꽃, 숨은 화기",
        symbolMeaning: "조용히 타오르는 불꽃처럼 내면에 숨겨진 지혜와 통찰력을 상징합니다.",
        meaning: "지혜롭고 신중하며, 통찰력이 뛰어납니다. 비밀을 잘 지키고 신비로운 매력이 있습니다."
      },
      image: saSnake,
      personality: "을사일주는 신중하고 분석적인 성격을 가졌어요. 문제를 깊이 고민하며, 체계적인 접근 방식을 통해 해결책을 찾는 데 능숙해요. 논리적이고 이성적인 태도를 바탕으로 중요한 결정을 내리며, 한 번 결정을 내리면 흔들리지 않는 끈기를 가지고 있어요. 하지만 과도한 신중함이 기회를 놓치는 원인이 될 수 있으니 적절한 균형이 필요해요. 을사일주는 차분하고 조용하지만, 자신만의 강한 주관을 가지고 있는 사람이에요.",
      relationship: "을사일주는 타인의 의견을 존중하며, 논리적이고 설득력 있는 대화로 주변과 소통해요. 다만, 자신의 감정을 표현하는 데 노력을 기울이면 더 깊은 관계를 유지할 수 있어요. 조화로운 관계를 유지하려는 노력이 돋보이며, 타인에게 안정감을 제공할 수 있는 능력을 가지고 있어요.",
      career: "을사일주는 논리와 체계적인 사고가 필요한 직업에서 성공 가능성이 높아요. 데이터 분석, 금융, 전략 기획 등의 분야에서 두각을 나타낼 가능성이 커요. 문제를 해결하고 계획을 실행하는 능력을 발휘할 수 있는 환경에서 큰 성취를 이룰 가능성이 높아요.",
      recommendedCareers: [
        "데이터 분석가",
        "금융 전문가",
        "컨설턴트",
        "연구원",
        "전략 기획자"
      ],
      wealth: "을사일주는 철저한 계획과 안정적인 재정 관리를 선호하며, 신중한 투자와 꾸준한 저축으로 자산을 축적해요. 장기적인 관점에서 균형 잡힌 투자를 통해 재정적 안정을 이루는 데 능숙해요.",
      wealthKeywords: [
        "계획적 투자",
        "신중한 관리",
        "균형 성장"
      ]
    },
    {
      id: "dp43",
      name: "병오(丙午)",
      heavenlyStem: {
        char: "병",
        emoji: "🔥",
        element: "병화(丙火)",
        symbol: "태양, 번쩍이는 불",
        meaning: "열정적이고 활동적인 성향으로, 리더십이 강하고 영향력이 큽니다. 밝고 긍정적인 에너지를 가졌습니다."
      },
      earthlyBranch: {
        char: "오",
        animal: "말",
        element: "오화(午火)",
        symbol: "태양의 불꽃, 들불",
        symbolMeaning: "활활 타오르는 불꽃처럼 강렬한 열정과 에너지를 상징합니다.",
        meaning: "활동적이고 열정적이며, 자유를 사랑합니다. 솔직하고 정직한 성향을 가졌습니다."
      },
      image: ohHorse,
      personality: "병오일주는 활발하고 에너지 넘치는 성격을 가졌어요. 어려운 상황에서도 희망을 잃지 않고 문제를 해결하려는 긍정적인 태도를 보여요. 창의적이고 진취적인 사고를 바탕으로 새로운 아이디어를 떠올리며, 이를 실현하려는 강한 의지가 있어요. 하지만 가끔은 성급한 결정을 내릴 수 있으니 신중함을 기르는 것이 중요해요. 병오일주는 사람들에게 활력을 불어넣으며, 환경을 변화시키는 능력을 가진 리더 타입이에요.",
      relationship: "병오일주는 사람들과의 관계에서 활발하고 적극적인 태도로 주변 사람들에게 좋은 인상을 남겨요. 하지만 자신의 의견을 지나치게 강조하면 갈등이 생길 수 있으니 주의가 필요해요. 리더십이 돋보이며, 팀워크에서도 중심적인 역할을 수행할 수 있는 능력을 가지고 있어요.",
      career: "병오일주는 창의력과 추진력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 도전과 혁신이 중요한 분야에서 두각을 나타낼 가능성이 커요. 새로운 프로젝트를 주도하거나 조직 내에서 리더십을 발휘할 수 있는 환경에서 만족감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "마케팅 리더",
        "콘텐츠 제작자",
        "이벤트 기획자",
        "창업가",
        "강연가"
      ],
      wealth: "병오일주는 도전적인 투자와 창의적인 접근 방식을 선호하며, 새로운 기회를 적극적으로 활용하는 타입이에요. 안정성과 수익성을 병행하며 장기적인 재정 목표를 세우는 것이 필요해요. 적극적인 전략과 균형 잡힌 재정 관리를 통해 안정적인 성장을 도모할 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "창의적 접근",
        "장기적 성장"
      ]
    },
    {
      id: "dp44",
      name: "정미(丁未)",
      heavenlyStem: {
        char: "정",
        emoji: "🕯️",
        element: "정화(丁火)",
        symbol: "촛불, 은은한 불",
        meaning: "따뜻하고 섬세한 성품으로, 주변을 밝히는 지혜와 통찰력이 있습니다. 차분하면서도 지속적인 열정을 가졌습니다."
      },
      earthlyBranch: {
        char: "미",
        animal: "양",
        element: "미토(未土)",
        symbol: "비옥한 대지, 목초지",
        symbolMeaning: "풍성한 목초지처럼 풍요롭고 부드러운 대지의 기운을 상징합니다.",
        meaning: "온순하고 예술적이며, 창의성이 뛰어납니다. 평화를 사랑하고 조화를 추구합니다."
      },
      image: miSheep,
      personality: "정미일주는 온화하고 차분한 성격을 가졌어요. 주변 사람들과 조화를 이루며, 안정적인 환경에서 자신의 능력을 발휘하려는 경향이 강해요. 섬세하고 꼼꼼한 성격 덕분에 작은 부분까지 놓치지 않고 살피며, 문제를 해결하려는 노력을 보여요. 하지만 지나친 완벽주의로 인해 스트레스를 받을 수 있으니 적절히 조율하는 것이 중요해요. 정미일주는 책임감 있고 성실한 태도로 주변 사람들에게 신뢰를 주며, 조화로운 관계를 유지하려는 사람이에요.",
      relationship: "정미일주는 배려심 있고 따뜻한 태도로 주변 사람들과 조화로운 관계를 형성해요. 하지만 자신의 감정을 숨기지 않고 적절히 표현하는 것이 관계를 더욱 깊게 만들 수 있어요. 조용하지만 신뢰를 주는 태도로 관계에서 안정감을 제공하며, 갈등을 해결하는 데도 능숙해요.",
      career: "정미일주는 섬세함과 조직력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 상담, 교육, 심리치료와 같은 사람을 돕는 분야에서 두각을 나타낼 가능성이 커요. 창의적인 감각과 함께 책임감을 발휘할 수 있는 직업에서 높은 성취를 이룰 가능성이 높아요.",
      recommendedCareers: [
        "상담사",
        "심리치료사",
        "교육자",
        "콘텐츠 기획자",
        "예술가"
      ],
      wealth: "정미일주는 안정적이고 꾸준한 재정 관리를 선호하며, 계획적인 접근 방식을 통해 자산을 늘리는 타입이에요. 감정적 소비보다는 실용적이고 분석적인 투자 방식을 선호하며, 장기적인 관점에서 자산을 관리해요.",
      wealthKeywords: [
        "안정적 투자",
        "꾸준한 관리",
        "실용적 접근"
      ]
    },
    {
      id: "dp45",
      name: "무신(戊申)",
      heavenlyStem: {
        char: "무",
        emoji: "⛰️",
        element: "무토(戊土)",
        symbol: "큰 산, 대지",
        meaning: "안정적이고 신뢰감 있는 성품으로, 책임감이 강하고 포용력이 큽니다. 든든한 산과 같은 존재감이 있습니다."
      },
      earthlyBranch: {
        char: "신",
        animal: "원숭이",
        element: "신금(申金)",
        symbol: "백색 금속, 단단한 쇠",
        symbolMeaning: "차갑고 단단한 금속처럼 예리하고 강인한 의지를 상징합니다.",
        meaning: "영리하고 재치있으며, 적응력이 뛰어납니다. 다재다능하고 문제해결 능력이 탁월합니다."
      },
      image: shinMonkey,
      personality: "무신일주는 강한 의지와 추진력을 가진 성격으로, 자신의 목표를 설정하고 이를 이루기 위해 끝까지 노력하는 사람이에요. 책임감이 강하며, 복잡한 상황에서도 결단력을 발휘해 문제를 해결하려는 능력이 뛰어나요. 현실적이고 실용적인 사고를 바탕으로 어려운 상황에서도 침착하게 대처하며, 주어진 일에 최선을 다하는 성실함이 돋보여요. 하지만 때로는 지나치게 고집이 강하다는 평을 들을 수 있어 유연성을 기르는 것이 중요해요. 무신일주는 주변 사람들에게 안정감을 주며, 조직 내에서 중요한 역할을 맡는 경우가 많아요.",
      relationship: "무신일주는 타인에게 신뢰를 주는 태도로 관계를 형성하며, 사람들과의 소통에서 정직하고 직접적인 접근을 선호해요. 하지만 자신의 의견을 너무 강하게 주장하면 갈등이 생길 수 있으니 적절히 조율하는 노력이 필요해요. 리더십을 발휘하며 팀워크를 중시하는 태도로 주변 사람들에게 의지가 되는 존재로 평가받아요.",
      career: "무신일주는 체계적이고 책임감 있는 직업에서 성공 가능성이 높아요. 특히 경영, 프로젝트 관리, 법률과 같은 분야에서 두각을 나타낼 가능성이 커요. 분석력과 실행력을 발휘할 수 있는 직업에서 성취감을 느끼며, 조직 내에서 중요한 위치를 차지할 가능성이 높아요.",
      recommendedCareers: [
        "프로젝트 매니저",
        "변호사",
        "공무원",
        "금융 전문가",
        "컨설턴트"
      ],
      wealth: "무신일주는 현실적이고 신중한 재정 관리를 통해 자산을 늘리는 타입이에요. 안정성과 수익성을 겸비한 투자 방식을 선호하며, 장기적인 관점에서 꾸준히 자산을 관리해요. 철저한 계획과 실행력이 재정적 성공의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "신중한 투자",
        "계획적 관리",
        "안정적 성장"
      ]
    },
    {
      id: "dp46",
      name: "기유(己酉)",
      heavenlyStem: {
        char: "기",
        emoji: "🌾",
        element: "기토(己土)",
        symbol: "비옥한 땅, 기름진 토양",
        meaning: "온화하고 차분한 성품으로, 실용적이고 현실적인 사고를 가졌습니다. 안정을 추구하며 신중한 성향입니다."
      },
      earthlyBranch: {
        char: "유",
        animal: "닭",
        element: "유금(酉金)",
        symbol: "보석, 정제된 금속",
        symbolMeaning: "정교하게 다듬어진 보석처럼 순수하고 완벽을 추구하는 성질을 상징합니다.",
        meaning: "정확하고 완벽을 추구하며, 성실합니다. 책임감이 강하고 시간 관념이 뛰어납니다."
      },
      image: yuChicken,
      personality: "기유일주는 체계적이고 논리적인 성격을 가졌어요. 주어진 일을 명확히 이해하고, 효율적으로 처리하려는 태도를 보이는 사람이에요. 현실적이고 실용적인 사고를 기반으로 복잡한 상황에서도 명확한 해법을 제시하며, 주변 사람들에게 신뢰를 얻어요. 하지만 가끔은 지나치게 이성적이라는 평을 들을 수 있어, 감정적인 접근도 필요할 수 있어요. 기유일주는 꾸준히 목표를 달성하며, 안정적인 성과를 이루는 데 능숙한 사람이에요.",
      relationship: "기유일주는 타인의 의견을 존중하며, 논리적이고 설득력 있는 대화로 주변 사람들과 소통해요. 다만, 자신의 감정을 적극적으로 표현하려는 노력이 필요할 수 있어요. 주변 사람들에게 안정감을 주며, 깊이 있는 관계를 형성하는 데 능숙한 타입이에요.",
      career: "기유일주는 체계적이고 분석적인 사고를 요구하는 직업에서 두각을 나타낼 가능성이 높아요. 특히 데이터 분석, 연구, 금융 분야에서 큰 성과를 낼 가능성이 커요. 철저한 계획과 정확성을 요구하는 직업에서 만족감을 느끼며, 조직 내에서 신뢰받는 인재로 자리 잡을 가능성이 커요.",
      recommendedCareers: [
        "데이터 분석가",
        "연구원",
        "금융 전문가",
        "전략 기획자",
        "설계 엔지니어"
      ],
      wealth: "기유일주는 안정적이고 체계적인 재정 관리를 선호하며, 위험을 최소화하며 꾸준히 자산을 증대시키는 타입이에요. 장기적인 관점에서 지속 가능한 투자를 통해 재정적인 안정과 성장을 동시에 추구해요.",
      wealthKeywords: [
        "체계적 관리",
        "안정적 투자",
        "장기 성장"
      ]
    },
    {
      id: "dp47",
      name: "경술(庚戌)",
      heavenlyStem: {
        char: "경",
        emoji: "🪨",
        element: "경금(庚金)",
        symbol: "바위, 돌",
        meaning: "강직하고 결단력 있는 성품으로, 원칙을 중시하고 정의감이 강합니다. 단단한 의지를 가졌습니다."
      },
      earthlyBranch: {
        char: "술",
        animal: "개",
        element: "술토(戌土)",
        symbol: "성벽, 단단한 흙",
        symbolMeaning: "견고한 성벽처럼 단단하고 방어적인 대지의 성질을 상징합니다.",
        meaning: "충직하고 정의로우며, 의리가 강합니다. 신념이 확고하고 보호본능이 강합니다."
      },
      image: soolDog,
      personality: "경술일주는 강한 책임감과 원칙을 중시하는 성격을 가졌어요. 주어진 일을 끝까지 해내는 끈기가 강하며, 신뢰를 기반으로 한 관계를 중요하게 여겨요. 현실적이고 실용적인 태도로 어려운 상황에서도 침착함을 유지하며, 문제를 해결하려는 노력을 보여요. 하지만 가끔은 지나치게 고지식하다는 평가를 받을 수 있으니 유연성을 기르는 것이 중요해요. 경술일주는 정의롭고 공정한 태도로 주변 사람들에게 신뢰를 주며, 조직 내에서 중요한 역할을 맡는 경우가 많아요.",
      relationship: "경술일주는 솔직하고 직설적인 대화를 선호하며, 타인과의 관계에서 정직함과 신뢰를 중시해요. 다만, 자신의 의견을 지나치게 강하게 주장하면 갈등이 생길 수 있으니 적절히 조율하는 것이 필요해요. 배려심 있는 태도로 사람들과의 관계를 안정적으로 유지하며, 팀 내에서 조언자 역할을 맡는 경우가 많아요.",
      career: "경술일주는 책임감과 체계적인 사고를 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 법률, 경영, 프로젝트 관리와 같은 분야에서 두각을 나타낼 가능성이 커요. 리더십과 공정한 판단력이 돋보이는 환경에서 높은 성취를 이룰 가능성이 높아요.",
      recommendedCareers: [
        "변호사",
        "프로젝트 매니저",
        "경영 컨설턴트",
        "공무원",
        "연구원"
      ],
      wealth: "경술일주는 신중한 투자와 계획적인 재정 관리를 통해 자산을 증대시키는 타입이에요. 위험을 최소화하면서 안정적인 수익을 추구하는 방식을 선호해요. 장기적인 관점에서 꾸준히 자산을 축적하며, 안정성과 성장을 동시에 고려하는 투자 전략이 효과적이에요.",
      wealthKeywords: [
        "안정적 투자",
        "계획적 관리",
        "신중한 접근"
      ]
    },
    {
      id: "dp48",
      name: "신해(辛亥)",
      heavenlyStem: {
        char: "신",
        emoji: "💍",
        element: "신금(辛金)",
        symbol: "보석, 장신구",
        meaning: "예리하고 섬세한 성품으로, 심미안이 뛰어나고 완벽을 추구합니다. 아름다움을 사랑하는 성향입니다."
      },
      earthlyBranch: {
        char: "해",
        animal: "돼지",
        element: "해수(亥水)",
        symbol: "깊은 바다, 큰 강",
        symbolMeaning: "깊은 바다처럼 포용력 있고 풍부한 수덕(水德)을 상징합니다.",
        meaning: "너그럽고 온화하며, 복덕이 있습니다. 정직하고 순수한 성품을 가졌습니다."
      },
      image: haePig,
      personality: "신해일주는 차분하고 이성적인 성격을 가졌어요. 타인의 감정을 이해하며 조화를 이루려는 성향이 강해요. 섬세하고 분석적인 사고를 기반으로 문제를 해결하며, 주변 사람들에게 안정감을 주는 태도가 돋보여요. 하지만 가끔은 지나치게 신중해 보일 수 있으니 적절히 결단력을 발휘하는 것이 필요해요. 신해일주는 타인의 감정을 잘 이해하면서도 실질적인 결과를 추구하며, 조화와 실용성을 겸비한 성격의 소유자예요.",
      relationship: "신해일주는 조용하지만 진정성 있는 태도로 사람들에게 신뢰를 얻어요. 타인의 문제를 해결하려는 노력이 돋보이며, 관계를 더욱 풍부하게 만들 수 있어요. 적극적으로 자신의 의견을 표현하려는 노력이 관계를 더욱 깊게 만드는 열쇠가 될 수 있어요.",
      career: "신해일주는 감각적이고 체계적인 사고를 요구하는 직업에서 성공 가능성이 높아요. 특히, 예술, 교육, 상담과 같은 분야에서 두각을 나타낼 가능성이 커요. 조화와 창의성을 발휘할 수 있는 직업에서 만족감을 느낄 가능성이 높으며, 자신의 감각을 활용할 수 있는 환경이 적합해요.",
      recommendedCareers: [
        "상담사",
        "예술가",
        "교육자",
        "연구원",
        "콘텐츠 제작자"
      ],
      wealth: "신해일주는 안정적이고 장기적인 재정 관리를 선호하며, 위험을 최소화하는 투자 전략이 적합해요. 계획적인 저축과 균형 잡힌 소비로 재정적인 안정성을 확보할 수 있어요. 꾸준한 접근이 재정적 성공을 가져오는 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "안정적 관리",
        "장기 투자",
        "균형 잡힌 소비"
      ]
    },
    {
      id: "dp49",
      name: "임자(壬子)",
      heavenlyStem: {
        char: "임",
        emoji: "🌊",
        element: "임수(壬水)",
        symbol: "큰 물, 바다",
        meaning: "포용력이 크고 진취적인 성품으로, 도전정신과 창의성이 뛰어납니다. 깊이 있는 지혜를 가졌습니다."
      },
      earthlyBranch: {
        char: "자",
        animal: "쥐",
        element: "자수(子水)",
        symbol: "깊은 밤의 고요한 물, 심해수",
        symbolMeaning: "깊은 곳에서 솟아나는 물처럼 깊이 있는 지혜와 잠재력을 상징합니다.",
        meaning: "지혜롭고 영리하며, 적응력이 뛰어납니다. 끊임없이 새로운 것을 탐구하고 수집하는 성향이 있습니다."
      },
      image: jaMouse,
      personality: "임자일주는 지혜롭고 독립적인 성격을 가졌어요. 어려운 상황에서도 해결책을 찾아내는 창의적인 사고와 실행력을 갖춘 사람이에요. 자유로운 사고방식을 바탕으로 기존의 틀에 얽매이지 않고, 새로운 시도를 즐기는 성향이 강해요. 하지만 지나치게 독립적인 태도로 타인의 의견을 간과할 수 있으니 조화로운 태도를 유지하는 것이 필요해요. 임자일주는 자신의 능력을 최대한 활용하며, 창의성과 추진력을 바탕으로 목표를 이루려는 사람이에요.",
      relationship: "임자일주는 사교적이고 활발한 성격으로 다양한 사람들과 쉽게 친해질 수 있어요. 하지만 깊이 있는 관계를 유지하기 위해서는 꾸준한 소통과 신뢰를 쌓는 노력이 필요해요. 타인에게 영감을 주는 존재로 평가받으며, 주변 사람들에게 긍정적인 영향을 미칠 수 있어요.",
      career: "임자일주는 창의성과 실행력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 혁신과 도전이 중요한 환경에서 두각을 나타낼 가능성이 커요. 다양한 사람들과 교류하며 새로운 가치를 창출할 수 있는 직업이 적합하며, 자신의 아이디어를 실행할 수 있는 분야에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "콘텐츠 제작자",
        "마케팅 리더",
        "창업가",
        "기획자",
        "강연가"
      ],
      wealth: "임자일주는 창의적인 투자와 유연한 자산 관리를 선호하며, 위험을 감수하더라도 새로운 기회를 적극적으로 활용해요. 하지만 안정성을 병행하는 전략이 중요해요. 장기적인 관점에서 꾸준히 성장을 도모하며, 계획적인 재정 관리를 통해 성공을 이룰 가능성이 커요.",
      wealthKeywords: [
        "창의적 투자",
        "장기 계획",
        "안정성 병행"
      ]
    },
    {
      id: "dp50",
      name: "계축(癸丑)",
      heavenlyStem: {
        char: "계",
        emoji: "💧",
        element: "계수(癸水)",
        symbol: "작은 물, 비",
        meaning: "섬세하고 감성적인 성품으로, 직관력이 뛰어나고 생각이 깊습니다. 조용히 스며드는 영향력을 가졌습니다."
      },
      earthlyBranch: {
        char: "축",
        animal: "소",
        element: "축토(丑土)",
        symbol: "땅속의 씨앗, 저장된 토양",
        symbolMeaning: "땅속에서 영양을 저장하고 있는 씨앗처럼 잠재된 가능성과 축적된 에너지를 상징합니다.",
        meaning: "성실하고 참을성이 있으며, 꾸준히 노력하는 성향입니다. 책임감이 강하고 신뢰할 수 있습니다."
      },
      image: chukCow,
      personality: "계축일주는 성실하고 책임감이 강한 성격을 가졌어요. 현실적이고 실용적인 태도로 자신의 역할을 충실히 수행하며, 안정적인 환경을 선호해요. 작은 일도 소홀히 하지 않고 세심하게 처리하며, 꾸준히 목표를 달성하려는 의지가 돋보여요. 하지만 가끔은 융통성이 부족하다는 평가를 받을 수 있으니 유연한 태도를 기르는 것이 중요해요. 계축일주는 신뢰를 바탕으로 관계를 유지하며, 주변 사람들에게 안정감을 주는 역할을 맡는 경우가 많아요.",
      relationship: "계축일주는 진정성 있는 태도로 주변 사람들과의 관계를 형성하며, 신뢰를 쌓는 데 능숙해요. 하지만 자신의 의견을 지나치게 고집하지 않도록 조율하는 노력이 필요해요. 성실하고 차분한 태도로 사람들에게 안정감을 주며, 깊이 있는 관계를 유지하는 데 능숙해요.",
      career: "계축일주는 체계적이고 세부적인 관리가 필요한 직업에서 성공 가능성이 높아요. 특히, 회계, 재정 관리, 연구와 같은 분야에서 두각을 나타낼 가능성이 커요. 조직 내에서 중요한 역할을 맡으며, 자신의 능력을 최대한 발휘할 수 있는 환경에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "회계사",
        "연구원",
        "행정 관리자",
        "금융 전문가",
        "교육자"
      ],
      wealth: "계축일주는 철저한 계획과 안정적인 자산 관리를 통해 꾸준히 자산을 증대시키는 타입이에요. 위험을 최소화하며 장기적인 재정 목표를 설정해요. 현실적이고 실용적인 접근이 성공의 열쇠가 될 수 있어요.",
      wealthKeywords: [
        "계획적 관리",
        "안정적 성장",
        "장기 목표"
      ]
    },
    {
      id: "dp51",
      name: "갑인(甲寅)",
      heavenlyStem: {
        char: "갑",
        emoji: "🌳",
        element: "갑목(甲木)",
        symbol: "큰 나무, 떡갈나무",
        meaning: "정직하고 곧은 성품을 지니며, 목표를 향해 꾸준히 성장하려는 성향이 강합니다. 뿌리깊은 나무처럼 안정적이고 신뢰감이 있습니다."
      },
      earthlyBranch: {
        char: "인",
        animal: "호랑이",
        element: "인목(寅木)",
        symbol: "떡갈나무, 큰 숲",
        symbolMeaning: "대지를 뚫고 나오는 강인한 나무의 기운처럼 강력한 생명력과 성장을 상징합니다.",
        meaning: "용감하고 정의로우며, 리더십이 있습니다. 도전정신이 강하고 진취적인 성향을 가졌습니다."
      },
      image: inTiger,
      personality: "갑인일주는 창의적이고 독립적인 성격을 가졌어요. 새로운 아이디어를 떠올리고 이를 실현하기 위해 노력하는 혁신적인 태도를 보여요. 적극적이고 진취적인 성향으로 도전을 두려워하지 않으며, 자신감 넘치는 모습으로 주변 사람들에게 긍정적인 영향을 미쳐요. 하지만 지나치게 독립적인 성격으로 타인의 의견을 경청하지 않을 때도 있어 조화로운 태도를 기르는 것이 중요해요. 갑인일주는 목표를 설정하고 이를 이루기 위해 끊임없이 노력하며, 변화를 주도하는 리더 타입이에요.",
      relationship: "갑인일주는 사람들과의 관계에서 자신감 있는 태도로 신뢰를 얻으며, 주도적으로 관계를 이끌어가는 경향이 있어요. 하지만 자신의 의견만을 고집하지 않고 타인의 입장을 이해하려는 노력이 필요해요. 주변 사람들에게 영감을 주고, 협력적인 태도로 관계를 더욱 풍부하게 만들 수 있어요.",
      career: "갑인일주는 창의력과 리더십을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 변화와 혁신을 주도하는 역할에서 두각을 나타낼 가능성이 커요. 새로운 프로젝트를 이끌거나 창업과 같은 도전적인 환경에서 성취감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "창업가",
        "마케팅 전문가",
        "프로젝트 매니저",
        "디자이너",
        "콘텐츠 제작자"
      ],
      wealth: "갑인일주는 도전적이고 창의적인 투자 방식을 선호하며, 새로운 기회를 포착하는 능력이 뛰어나요. 하지만 안정성을 고려한 균형 잡힌 접근이 필요해요. 장기적인 관점에서 꾸준히 자산을 관리하며, 실용적인 투자를 통해 성과를 극대화할 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "창의적 접근",
        "장기 성장"
      ]
    },
    {
      id: "dp52",
      name: "을묘(乙卯)",
      heavenlyStem: {
        char: "을",
        emoji: "🌱",
        element: "을목(乙木)",
        symbol: "작은 나무, 풀",
        meaning: "유연하고 섬세한 성품을 지니며, 상황에 잘 적응하는 융통성이 있습니다. 부드럽지만 강인한 생명력을 가졌습니다."
      },
      earthlyBranch: {
        char: "묘",
        animal: "토끼",
        element: "묘목(卯木)",
        symbol: "꽃과 새싹, 유연한 덩굴",
        symbolMeaning: "부드럽게 뻗어나가는 덩굴처럼 유연하고 우아한 생명력을 상징합니다.",
        meaning: "민첩하고 우아하며, 예술적 감각이 뛰어납니다. 조화를 추구하고 평화를 사랑합니다."
      },
      image: myoRabbit,
      personality: "을묘일주는 섬세하고 감각적인 성격을 가졌어요. 주변 상황을 민감하게 파악하며, 타인의 감정을 잘 이해하고 공감하는 능력이 뛰어나요. 조용하고 차분한 태도로 안정감을 주지만, 가끔은 지나치게 신중한 태도로 기회를 놓칠 수 있으니 결단력을 기르는 것이 중요해요. 을묘일주는 창의적이고 감성적인 접근으로 새로운 아이디어를 만들어내며, 이를 바탕으로 조화로운 관계를 유지하려는 성향이 강해요.",
      relationship: "을묘일주는 부드럽고 친절한 태도로 타인에게 신뢰를 주며, 깊이 있는 인간관계를 유지하려는 노력이 돋보여요. 하지만 자신의 의견을 적절히 표현하려는 노력이 필요해요. 감정적으로 안정된 관계를 유지하며, 타인의 문제를 해결하려는 태도가 강한 사람으로 평가받아요.",
      career: "을묘일주는 창의성과 감각을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 예술, 디자인, 상담과 같은 분야에서 두각을 나타낼 가능성이 커요. 사람들과의 교감이 중요한 직업에서 만족감을 느끼며, 자신의 감정을 잘 활용할 수 있는 환경에서 성취감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "예술가",
        "상담사",
        "디자이너",
        "콘텐츠 제작자",
        "교육자"
      ],
      wealth: "을묘일주는 안정적인 투자와 감성적인 접근 방식을 선호하며, 꾸준한 저축과 균형 잡힌 소비를 중시해요. 장기적인 관점에서 자산을 관리하며, 안정성과 수익성을 함께 고려하는 투자를 추구해요.",
      wealthKeywords: [
        "안정적 투자",
        "균형 잡힌 소비",
        "장기 계획"
      ]
    },
    {
      id: "dp53",
      name: "병진(丙辰)",
      heavenlyStem: {
        char: "병",
        emoji: "🔥",
        element: "병화(丙火)",
        symbol: "태양, 번쩍이는 불",
        meaning: "열정적이고 활동적인 성향으로, 리더십이 강하고 영향력이 큽니다. 밝고 긍정적인 에너지를 가졌습니다."
      },
      earthlyBranch: {
        char: "진",
        animal: "용",
        element: "진토(辰土)",
        symbol: "우레를 동반한 비, 천둥",
        symbolMeaning: "천둥과 비를 동반하며 만물을 깨우는 강력한 변화의 기운을 상징합니다.",
        meaning: "창의적이고 카리스마 있으며, 큰 꿈을 가졌습니다. 변화를 주도하고 성공을 추구합니다."
      },
      image: jinDragon,
      personality: "병진일주는 열정적이고 목표 지향적인 성격을 가졌어요. 자신의 목표를 이루기 위해 꾸준히 노력하며, 어려운 상황에서도 포기하지 않는 끈기가 돋보여요. 자신감 넘치는 태도로 주어진 일을 수행하며, 주변 사람들에게 동기를 부여하는 능력이 있어요. 하지만 가끔은 고집이 강하다는 평가를 받을 수 있어 유연한 태도를 기르는 것이 중요해요. 병진일주는 주도적인 태도로 팀과 조직을 이끄는 데 능숙하며, 강한 추진력으로 성과를 만들어내는 사람이에요.",
      relationship: "병진일주는 사람들과의 관계에서 긍정적이고 적극적인 태도로 신뢰를 얻으며, 리더로서의 자질을 보여요. 하지만 자신의 의견을 지나치게 강조하지 않도록 주의가 필요해요. 주변 사람들에게 활력을 불어넣으며, 협력적인 태도로 관계를 더욱 깊게 만들 수 있어요.",
      career: "병진일주는 창의력과 추진력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 혁신적이고 도전적인 역할을 요구하는 분야에서 두각을 나타낼 가능성이 커요. 새로운 프로젝트를 주도하거나 사람들과 협력하여 가치를 창출하는 환경에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "창업가",
        "프로젝트 매니저",
        "마케팅 전문가",
        "이벤트 기획자",
        "강연가"
      ],
      wealth: "병진일주는 도전적인 투자와 전략적 접근을 통해 자산을 증대시키는 타입이에요. 다만, 안정성을 고려한 장기적인 관점에서 재정을 관리하는 것이 중요해요. 장기적인 목표와 계획적인 투자를 통해 안정성과 성장을 함께 도모할 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "전략적 관리",
        "장기 목표"
      ]
    },
    {
      id: "dp54",
      name: "정사(丁巳)",
      heavenlyStem: {
        char: "정",
        emoji: "🕯️",
        element: "정화(丁火)",
        symbol: "촛불, 은은한 불",
        meaning: "따뜻하고 섬세한 성품으로, 주변을 밝히는 지혜와 통찰력이 있습니다. 차분하면서도 지속적인 열정을 가졌습니다."
      },
      earthlyBranch: {
        char: "사",
        animal: "뱀",
        element: "사화(巳火)",
        symbol: "은은한 불꽃, 숨은 화기",
        symbolMeaning: "조용히 타오르는 불꽃처럼 내면에 숨겨진 지혜와 통찰력을 상징합니다.",
        meaning: "지혜롭고 신중하며, 통찰력이 뛰어납니다. 비밀을 잘 지키고 신비로운 매력이 있습니다."
      },
      image: saSnake,
      personality: "정사일주는 분석적이고 냉철한 성격을 가졌어요. 문제를 명확히 파악하고, 합리적인 결정을 내리는 데 강점을 보이는 사람이에요. 목표를 달성하기 위해 치밀한 계획을 세우며, 주어진 일을 끝까지 완수하려는 책임감이 강해요. 하지만 때로는 지나치게 이성적이라는 평가를 받을 수 있으니, 감정적인 유연성을 기르는 것이 필요해요. 정사일주는 실용적이고 현실적인 태도로 주변 사람들에게 신뢰를 주며, 복잡한 상황에서도 냉철하게 문제를 해결하는 능력을 가지고 있어요.",
      relationship: "정사일주는 논리적이고 설득력 있는 대화를 통해 타인과 소통하며, 신뢰를 쌓는 데 능숙해요. 다만, 자신의 감정을 표현하는 데 주저하지 않도록 노력하면 관계를 더욱 깊게 만들 수 있어요. 타인의 의견을 존중하며, 관계를 조화롭게 유지하려는 태도가 돋보여요.",
      career: "정사일주는 체계적이고 분석적인 사고를 요구하는 직업에서 성공 가능성이 높아요. 특히, 금융, 데이터 분석, 컨설팅과 같은 분야에서 두각을 나타낼 가능성이 커요. 정확성과 실용성을 발휘할 수 있는 환경에서 성취감을 느끼며, 장기적인 관점에서 성장할 수 있는 직업이 적합해요.",
      recommendedCareers: [
        "데이터 분석가",
        "연구원",
        "금융 전문가",
        "컨설턴트",
        "전략 기획자"
      ],
      wealth: "정사일주는 안정적이고 계획적인 재정 관리를 통해 자산을 축적하는 타입이에요. 무리한 투자보다는 안정성과 수익성을 고려한 균형 잡힌 접근이 필요해요. 장기적인 관점에서 꾸준히 자산을 관리하며 재정적 안정감을 유지할 수 있어요.",
      wealthKeywords: [
        "안정적 투자",
        "계획적 관리",
        "균형 유지"
      ]
    },
    {
      id: "dp55",
      name: "무오(戊午)",
      heavenlyStem: {
        char: "무",
        emoji: "⛰️",
        element: "무토(戊土)",
        symbol: "큰 산, 대지",
        meaning: "안정적이고 신뢰감 있는 성품으로, 책임감이 강하고 포용력이 큽니다. 든든한 산과 같은 존재감이 있습니다."
      },
      earthlyBranch: {
        char: "오",
        animal: "말",
        element: "오화(午火)",
        symbol: "태양의 불꽃, 들불",
        symbolMeaning: "활활 타오르는 불꽃처럼 강렬한 열정과 에너지를 상징합니다.",
        meaning: "활동적이고 열정적이며, 자유를 사랑합니다. 솔직하고 정직한 성향을 가졌습니다."
      },
      image: ohHorse,
      personality: "무오일주는 열정적이고 자신감 넘치는 성격을 가졌어요. 어떤 상황에서도 긍정적인 에너지를 발휘하며, 주어진 목표를 이루기 위해 끊임없이 노력해요. 새로운 아이디어를 실현하려는 추진력이 강하며, 사람들과 협력해 가치를 창출하는 데 능숙해요. 하지만 지나치게 성급한 태도는 실수를 유발할 수 있으니 신중함을 기르는 것이 중요해요. 무오일주는 활발한",
      relationship: "와 강한 추진력으로 환경을 변화시키는 리더 타입이에요. 대인관계 무오일주는 사교적이고 활발한 태도로 사람들과 쉽게 어울릴 수 있어요. 하지만 자신의 의견을 지나치게 강조하면 관계가 경직될 수 있으니 조율이 필요해요. 주변 사람들에게 긍정적인 영향을 주며, 협력과 조화를 통해 관계를 더욱 풍부하게 만들 수 있어요.",
      career: "무오일주는 창의력과 추진력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 변화와 도전이 많은 환경에서 두각을 나타낼 가능성이 커요. 리더십을 발휘하거나, 사람들과 소통하며 가치를 창출할 수 있는 직업에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "창업가",
        "마케팅 전문가",
        "콘텐츠 제작자",
        "이벤트 기획자",
        "강연가"
      ],
      wealth: "무오일주는 도전적이고 창의적인 투자 방식을 선호하지만, 안정성을 고려한 균형 잡힌 재정 관리가 필요해요. 기회를 빠르게 포착하며, 장기적인 관점에서 성장을 도모하는 것이 중요해요.",
      wealthKeywords: [
        "창의적 투자",
        "균형 유지",
        "장기 성장"
      ]
    },
    {
      id: "dp56",
      name: "기미(己未)",
      heavenlyStem: {
        char: "기",
        emoji: "🌾",
        element: "기토(己土)",
        symbol: "비옥한 땅, 기름진 토양",
        meaning: "온화하고 차분한 성품으로, 실용적이고 현실적인 사고를 가졌습니다. 안정을 추구하며 신중한 성향입니다."
      },
      earthlyBranch: {
        char: "미",
        animal: "양",
        element: "미토(未土)",
        symbol: "비옥한 대지, 목초지",
        symbolMeaning: "풍성한 목초지처럼 풍요롭고 부드러운 대지의 기운을 상징합니다.",
        meaning: "온순하고 예술적이며, 창의성이 뛰어납니다. 평화를 사랑하고 조화를 추구합니다."
      },
      image: miSheep,
      personality: "기미일주는 온화하고 차분한 성격을 가졌어요. 갈등을 피하고 조화를 중시하며, 안정적인 환경에서 능력을 발휘하려는 경향이 강해요. 섬세하고 꼼꼼한 성격 덕분에 작은 부분까지 놓치지 않고 살피며, 주어진 일을 완벽히 해내려는 책임감이 있어요. 다만, 지나친 완벽주의는 스트레스를 유발할 수 있으니 조율하는 태도가 필요해요. 기미일주는 성실하고 조화로운 태도로 주변 사람들에게 신뢰를 주며, 안정감을 제공하는 사람이에요.",
      relationship: "기미일주는 부드럽고 친절한 태도로 주변 사람들과 조화로운 관계를 유지해요. 하지만 자신의 감정을 지나치게 억누르지 않고 적절히 표현하는 것이 필요해요. 배려심 있는 태도로 사람들에게 긍정적인 인상을 남기며, 깊이 있는 관계를 형성하는 데 능숙해요.",
      career: "기미일주는 섬세함과 책임감을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 상담, 교육, 심리치료와 같은 사람을 돕는 분야에서 두각을 나타낼 가능성이 커요. 창의적이고 조직적인 능력을 발휘할 수 있는 환경에서 만족감을 느낄 가능성이 높아요.",
      recommendedCareers: [
        "상담사",
        "심리치료사",
        "교육자",
        "콘텐츠 제작자",
        "예술가"
      ],
      wealth: "기미일주는 안정적인 투자와 계획적인 재정 관리를 통해 자산을 늘리는 타입이에요. 작은 위험보다는 안정성을 기반으로 한 꾸준한 투자를 선호해요. 장기적인 관점에서 자산을 관리하며, 균형 잡힌 접근을 통해 안정감을 유지할 수 있어요.",
      wealthKeywords: [
        "안정적 투자",
        "꾸준한 관리",
        "장기 계획"
      ]
    },
    {
      id: "dp57",
      name: "경신(庚申)",
      heavenlyStem: {
        char: "경",
        emoji: "🪨",
        element: "경금(庚金)",
        symbol: "바위, 돌",
        meaning: "강직하고 결단력 있는 성품으로, 원칙을 중시하고 정의감이 강합니다. 단단한 의지를 가졌습니다."
      },
      earthlyBranch: {
        char: "신",
        animal: "원숭이",
        element: "신금(申金)",
        symbol: "백색 금속, 단단한 쇠",
        symbolMeaning: "차갑고 단단한 금속처럼 예리하고 강인한 의지를 상징합니다.",
        meaning: "영리하고 재치있으며, 적응력이 뛰어납니다. 다재다능하고 문제해결 능력이 탁월합니다."
      },
      image: shinMonkey,
      personality: "경신일주는 지혜롭고 독립적인 성격을 가졌어요. 새로운 아이디어를 떠올리고 이를 현실화하는 데 강한 추진력을 보이는 사람이에요. 결단력과 실행력이 뛰어나며, 어려운 상황에서도 빠르게 해결책을 찾아내는 능력이 있어요. 하지만 지나치게 독립적인 태도는 타인과의 협력에서 갈등을 초래할 수 있으니 균형을 유지하는 것이 중요해요. 경신일주는 창의적이고 목표 지향적인 태도로 주변 사람들에게 긍정적인 영향을 미치며, 도전과 변화를 즐기는 사람이에요.",
      relationship: "경신일주는 타인과의 관계에서 자신감 넘치는 태도로 신뢰를 얻으며, 활발한 소통을 통해 다양한 사람들과 친밀해질 수 있어요. 다만, 자신의 의견을 지나치게 고집하지 않는 유연한 태도가 필요해요. 협력과 조화를 중시하며, 긍정적인 태도로 관계를 더욱 풍부하게 만들 수 있어요.",
      career: "경신일주는 창의성과 실행력을 발휘할 수 있는 직업에서 성공 가능성이 높아요. 특히, 변화와 혁신이 중요한 분야에서 두각을 나타낼 가능성이 커요. 도전과 변화를 즐길 수 있는 환경에서 높은 성취를 이룰 가능성이 있으며, 자신의 아이디어를 현실화하는 직업에서 만족감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "창업가",
        "마케팅 전문가",
        "프로젝트 매니저",
        "콘텐츠 제작자",
        "강연가"
      ],
      wealth: "경신일주는 도전적인 투자와 창의적인 접근 방식을 선호하지만, 안정성을 함께 고려하는 것이 중요해요. 장기적인 관점에서 꾸준히 자산을 관리하며, 기회를 효과적으로 활용할 수 있어요.",
      wealthKeywords: [
        "도전적 투자",
        "창의적 접근",
        "안정성 유지"
      ]
    },
    {
      id: "dp58",
      name: "신유(辛酉)",
      heavenlyStem: {
        char: "신",
        emoji: "💍",
        element: "신금(辛金)",
        symbol: "보석, 장신구",
        meaning: "예리하고 섬세한 성품으로, 심미안이 뛰어나고 완벽을 추구합니다. 아름다움을 사랑하는 성향입니다."
      },
      earthlyBranch: {
        char: "유",
        animal: "닭",
        element: "유금(酉金)",
        symbol: "보석, 정제된 금속",
        symbolMeaning: "정교하게 다듬어진 보석처럼 순수하고 완벽을 추구하는 성질을 상징합니다.",
        meaning: "정확하고 완벽을 추구하며, 성실합니다. 책임감이 강하고 시간 관념이 뛰어납니다."
      },
      image: yuChicken,
      personality: "신유일주는 논리적이고 이성적인 성격을 가졌어요. 문제를 명확히 분석하며, 합리적인 해결책을 제시하는 능력이 뛰어난 사람이에요. 섬세하고 체계적인 사고를 바탕으로 주어진 일을 끝까지 해내며, 실질적인 결과를 중요하게 여겨요. 하지만 가끔은 감정 표현이 부족하다는 평가를 받을 수 있으니, 타인과의 공감 능력을 기르는 것이 중요해요. 신유일주는 신뢰를 바탕으로 관계를 형성하며, 안정적이고 실용적인 태도로 환경을 조율하는 데 능숙해요.",
      relationship: "신유일주는 차분하고 논리적인 태도로 타인과 소통하며, 신뢰를 쌓는 데 능숙해요. 자신의 감정을 더 적극적으로 표현하면 관계를 더욱 깊게 만들 수 있어요. 타인의 의견을 존중하며, 조화롭고 안정적인 관계를 유지하려는 태도가 돋보여요.",
      career: "신유일주는 체계적이고 분석적인 사고를 필요로 하는 직업에서 성공 가능성이 높아요. 특히, 데이터 분석, 연구, 금융 분야에서 두각을 나타낼 가능성이 커요. 문제를 해결하며 조직 내에서 신뢰받는 역할을 수행할 가능성이 높으며, 실질적인 성과를 중시하는 환경에서 만족감을 느낄 수 있어요.",
      recommendedCareers: [
        "데이터 분석가",
        "연구원",
        "금융 전문가",
        "컨설턴트",
        "회계사"
      ],
      wealth: "신유일주는 안정성과 신중함을 중시하는 재정 관리를 선호하며, 위험을 최소화하면서 꾸준히 자산을 늘리는 타입이에요. 장기적인 관점에서 균형 잡힌 투자와 저축으로 재정적 안정감을 유지할 수 있어요.",
      wealthKeywords: [
        "안정적 투자",
        "신중한 관리",
        "장기 계획"
      ]
    },
    {
      id: "dp59",
      name: "임술(壬戌)",
      heavenlyStem: {
        char: "임",
        emoji: "🌊",
        element: "임수(壬水)",
        symbol: "큰 물, 바다",
        meaning: "포용력이 크고 진취적인 성품으로, 도전정신과 창의성이 뛰어납니다. 깊이 있는 지혜를 가졌습니다."
      },
      earthlyBranch: {
        char: "술",
        animal: "개",
        element: "술토(戌土)",
        symbol: "성벽, 단단한 흙",
        symbolMeaning: "견고한 성벽처럼 단단하고 방어적인 대지의 성질을 상징합니다.",
        meaning: "충직하고 정의로우며, 의리가 강합니다. 신념이 확고하고 보호본능이 강합니다."
      },
      image: soolDog,
      personality: "임술일주는 지혜롭고 신중한 성격을 가졌어요. 타인의 의견을 존중하며, 조화를 이루기 위해 노력하는 성향이 강해요. 현실적이고 실용적인 사고를 바탕으로 주어진 상황에서 최선을 다하며, 실질적인 결과를 추구해요. 하지만 가끔은 지나치게 신중해 보일 수 있으니 결단력을 기르는 것이 필요해요. 임술일주는 책임감 있는 태도로 주변 사람들에게 안정감을 주며, 조직 내에서 중요한 역할을 맡는 경우가 많아요.",
      relationship: "임술일주는 진정성 있는 태도로 사람들과의 관계를 형성하며, 신뢰를 쌓는 데 능숙해요. 다만, 자신의 감정을 숨기지 않고 적절히 표현하는 것이 필요해요. 조용하지만 깊이 있는 대화를 통해 관계를 풍부하게 만들 수 있는 능력이 돋보여요.",
      career: "임술일주는 체계적이고 신뢰를 기반으로 한 직업에서 성공 가능성이 높아요. 특히, 법률, 교육, 행정과 같은 분야에서 두각을 나타낼 가능성이 커요. 자신의 분석력과 책임감을 발휘할 수 있는 직업에서 높은 성취를 이룰 가능성이 커요.",
      recommendedCareers: [
        "변호사",
        "공무원",
        "연구원",
        "교육자",
        "컨설턴트"
      ],
      wealth: "임술일주는 안정적인 투자와 계획적인 자산 관리를 선호하며, 위험을 최소화하면서 꾸준히 자산을 늘리는 타입이에요. 장기적인 관점에서 꾸준히 자산을 관리하며, 균형 잡힌 소비를 통해 안정감을 유지할 수 있어요.",
      wealthKeywords: [
        "계획적 관리",
        "안정적 투자",
        "균형 유지"
      ]
    },
    {
      id: "dp60",
      name: "계해(癸亥)",
      heavenlyStem: {
        char: "계",
        emoji: "💧",
        element: "계수(癸水)",
        symbol: "작은 물, 비",
        meaning: "섬세하고 감성적인 성품으로, 직관력이 뛰어나고 생각이 깊습니다. 조용히 스며드는 영향력을 가졌습니다."
      },
      earthlyBranch: {
        char: "해",
        animal: "돼지",
        element: "해수(亥水)",
        symbol: "깊은 바다, 큰 강",
        symbolMeaning: "깊은 바다처럼 포용력 있고 풍부한 수덕(水德)을 상징합니다.",
        meaning: "너그럽고 온화하며, 복덕이 있습니다. 정직하고 순수한 성품을 가졌습니다."
      },
      image: haePig,
      personality: "계해일주는 온화하고 차분한 성격을 가졌어요. 타인의 감정을 잘 이해하며, 평화로운 관계를 유지하려는 태도가 강해요. 섬세하고 신중한 성격 덕분에 세부적인 부분까지 놓치지 않으며, 문제를 해결하는 능력이 있어요. 하지만 지나치게 소극적인 태도는 기회를 놓치는 원인이 될 수 있으니, 자신감을 기르는 것이 중요해요. 계해일주는 조용하지만 책임감 있는 태도로 주변 사람들에게 신뢰를 주며, 안정감을 제공하는 사람이에요.",
      relationship: "계해일주는 진정성과 배려심으로 관계를 형성하며, 깊이 있는 소통을 통해 신뢰를 쌓는 데 능숙해요. 자신의 감정을 표현하려는 노력이 관계를 더욱 풍부하게 만들 수 있어요. 타인에게 긍정적인 영향을 주며, 조화로운 관계를 유지하려는 모습이 돋보여요.",
      career: "계해일주는 감각적이고 조화를 중시하는 직업에서 성공 가능성이 높아요. 특히, 상담, 교육, 예술과 같은 분야에서 두각을 나타낼 가능성이 커요. 창의성과 책임감을 발휘할 수 있는 환경에서 만족감을 느끼며, 안정적인 환경에서 성취감을 느낄 가능성이 커요.",
      recommendedCareers: [
        "상담사",
        "예술가",
        "교육자",
        "콘텐츠 제작자",
        "심리치료사"
      ],
      wealth: "계해일주는 안정적이고 계획적인 재정 관리를 선호하며, 꾸준히 자산을 증대시키는 타입이에요. 무리한 투자보다 장기적인 계획과 균형 잡힌 접근이 중요해요. 재정적 안정과 성장을 동시에 추구하며, 꾸준히 목표를 달성할 수 있어요.",
      wealthKeywords: [
        "계획적 관리",
        "안정적 투자",
        "꾸준한 성과"
      ]
    }
  ]
}