import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../utils/api';
import './MyPage.css';
import Select from 'react-select';
import { ohangData, ohangDataV2, skyHanjaData, landHanjaData, hanjaOhangMapping } from '../components/ohangData';
import { useAuth } from '../contexts/AuthContext';

// 추가
// 추천인 혜택 설명
// 탈퇴기능

const MyPage = () => {
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [fortuneResults, setFortuneResults] = useState([]);
  const [todayFortuneResults, setTodayFortuneResults] = useState({});

  const [referralInfo, setReferralInfo] = useState(null);
  const [ticketInfo, setTicketInfo] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  
  const {setUser, setIsAuthenticated, setUserMeta } = useAuth();

  const [eventCode, setEventCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventMessage, setEventMessage] = useState(null);

  useEffect(() => {
    fetchMyPageData();
    fetchReferralInfo();
  }, []);


  const fetchMyPageData = async () => {
    try {
      // 프로필 데이터 가져오기
      const profilesResponse = await apiService.get('/fortune/persons/');
      const profilesData = profilesResponse.data;
      
      setProfiles(profilesData);
      
      // 각 프로필에 대한 오늘의 운세 조회
      const fortuneResults = {};
      for (const profile of profilesData) {
        const todayFortune = await fetchTodayFortune(profile);
        if (todayFortune) {
          fortuneResults[profile.id] = todayFortune.data;
        }
      }
      setTodayFortuneResults(fortuneResults);
      
      // 'self' 관계를 가진 프로필을 찾아서 우선 설정
      const selfProfile = profilesData.find(profile => profile.relationship === 'self');
      if (selfProfile) {
        setSelectedProfileId(selfProfile.id);
      } else if (profilesData.length > 0) {
        // self 프로필이 없는 경우 첫 번째 프로필로 설정
        setSelectedProfileId(profilesData[0].id);
      }

      // 운세 결과 데이터 가져오기
      const resultsResponse = await apiService.get('/fortune/fortune-readings-list/');
      setFortuneResults(resultsResponse.data);
    } catch (error) {
      console.error('마이페이지 데이터 로딩 실패:', error);
    }
  };

  const fetchReferralInfo = async () => {
    try {
      const response = await apiService.get('/referral-info/');
      setReferralInfo(response.data.referral);
      setTicketInfo(response.data.tickets);
    } catch (error) {
      console.error('레퍼럴 정보 로딩 실패:', error);
    }
  };

  const handleCopyLink = (type) => {
    if (!referralInfo?.code) return;
    
    const baseUrl = window.location.origin;
    const referralCode = referralInfo.code;
    let link;
    
    if (type === 'class') {
      link = `${baseUrl}/class-gate?ref=${referralCode}`;
    } else if (type === '60days') {
      link = `${baseUrl}/day-pillar-gate?ref=${referralCode}`;
    }
    
    navigator.clipboard.writeText(link)
      .then(() => {
        alert('링크가 복사되었습니다!');
      })
      .catch(err => {
        console.error('링크 복사 실패:', err);
      });
  };

  const handleCopyCode = () => {
    if (!referralInfo?.code) return;
    
    navigator.clipboard.writeText(referralInfo.code)
      .then(() => {
        alert('추천 코드가 복사되었습니다!');
      })
      .catch(err => {
        console.error('코드 복사 실패:', err);
      });
  };

  // 결과 보기 핸들러 추가
  const handleViewResult = (shareId) => {
    navigate(`/fortune-reading/${shareId}`);
  };

  // formatDate 함수 추가
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  // 사주명식 렌더링 컴포넌트
  const SajuDisplay = ({ pillar, umyang_ohang }) => {
    if (!pillar || !umyang_ohang) return null;

    return (
      <div style={{padding:'0px'}} className="saju-container">
        <div className="saju-pillar-grid">
          <OhangBoxCombinedV2 
            sky={umyang_ohang.timeSky.ohang} 
            land={umyang_ohang.timeLand.ohang} 
            pillar={{ sky: pillar.timeSky, land: pillar.timeLand }} 
            headerSky="시간"
            headerLand="시지"
          />
          <OhangBoxCombinedV2 
            sky={umyang_ohang.daySky.ohang} 
            land={umyang_ohang.dayLand.ohang} 
            pillar={{ sky: pillar.daySky, land: pillar.dayLand }} 
            headerSky="일간"
            headerLand="일지"
          />
          <OhangBoxCombinedV2 
            sky={umyang_ohang.monthSky.ohang} 
            land={umyang_ohang.monthLand.ohang} 
            pillar={{ sky: pillar.monthSky, land: pillar.monthLand }} 
            headerSky="월간"
            headerLand="월지"
          />
          <OhangBoxCombinedV2 
            sky={umyang_ohang.yearSky.ohang} 
            land={umyang_ohang.yearLand.ohang} 
            pillar={{ sky: pillar.yearSky, land: pillar.yearLand }} 
            headerSky="년간"
            headerLand="년지"
          />
        </div>
      </div>
    );
  };


  
  // 사주명식 렌더링 컴포넌트
  const SajuDisplayHanja = ({ pillar, umyang_ohang }) => {
    if (!pillar || !umyang_ohang) return null;

    return (
      <div style={{padding:'0px'}} className="saju-container">
        <div className="saju-pillar-grid">
          <OhangBoxHanja 
            sky={umyang_ohang.timeSky.ohang} 
            land={umyang_ohang.timeLand.ohang} 
            pillar={{ sky: pillar.timeSky, land: pillar.timeLand }} 
            headerSky="시간"
            headerLand="시지"
            headerSkyExtraText=" "
            headerLandExtraText=" "
          />
          <OhangBoxHanja 
            sky={umyang_ohang.daySky.ohang} 
            land={umyang_ohang.dayLand.ohang} 
            pillar={{ sky: pillar.daySky, land: pillar.dayLand }} 
            headerSky="일간"
            headerLand="일지"
            headerSkyExtraText="나"
            headerLandExtraText=" "
          />
          <OhangBoxHanja 
            sky={umyang_ohang.monthSky.ohang} 
            land={umyang_ohang.monthLand.ohang} 
            pillar={{ sky: pillar.monthSky, land: pillar.monthLand }} 
            headerSky="월간"
            headerLand="월지"
            headerSkyExtraText=" "
            headerLandExtraText="사회"
          />
          <OhangBoxHanja 
            sky={umyang_ohang.yearSky.ohang} 
            land={umyang_ohang.yearLand.ohang} 
            pillar={{ sky: pillar.yearSky, land: pillar.yearLand }} 
            headerSky="년간"
            headerLand="년지"
            headerSkyExtraText=" "
            headerLandExtraText=" "
          />
        </div>
      </div>
    );
  };


  const formatDateTime = (year, month, day, hour, minute, is_time_unknown) => {
    const date = new Date(year, month - 1, day);
    const yearStr = date.getFullYear().toString();
    const monthStr = String(month).padStart(2, '0');
    const dayStr = String(day).padStart(2, '0');
    
    if (is_time_unknown) {
      return `${yearStr}.${monthStr}.${dayStr}`;
    }

    const ampm = hour >= 12 ? '오후' : '오전';
    const displayHour = hour % 12 || 12;
    const minuteStr = String(minute).padStart(2, '0');
    
    return `${yearStr}.${monthStr}.${dayStr} ${ampm} ${displayHour}시 ${minuteStr}분`;
  };

  const selectedProfile = profiles.find(profile => profile.id === selectedProfileId);
  const filteredResults = fortuneResults.filter(result => result.profile_id === selectedProfileId);

  // Select 옵션 형식으로 변환하는 함수
  const getOptions = (profiles) => {
    return profiles.map(profile => ({
      value: profile.id,
      label: `${profile.name} (${dict[profile.relationship]}) - ${formatDateTime(
        profile.birth_year,
        profile.birth_month,
        profile.birth_day,
        profile.birth_hour,
        profile.birth_minute,
        profile.is_birth_time_unknown
      )}`
    }));
  };

  // Select 컴포넌트의 커스텀 스타일
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '2px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'rgba(52, 119, 109, 0.8)'
      }
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '0.85rem',
      backgroundColor: state.isSelected ? 'rgba(52, 119, 109, 0.1)' : 'white',
      color: '#333',
      '&:hover': {
        backgroundColor: 'rgba(52, 119, 109, 0.05)'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '0.9rem',
      color: '#333'
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
    })
  };

  const handleDeleteAccount = async () => {
    try {
      await apiService.delete('/user-delete/');
      setShowDeleteModal(false);
      
      // AuthContext 상태 직접 초기화
      setUser(null);
      setIsAuthenticated(false);
      setUserMeta(null);
      
      // 쿠키 삭제
      document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      
      alert('계정이 삭제되었습니다.');
      navigate('/');
    } catch (error) {
      console.error('계정 삭제 중 오류 발생:', error);
      alert('계정 삭제 중 오류가 발생했습니다.');
      setShowDeleteModal(false);
    }
  };

  // 티켓 카테고리별 라우트 매핑 추가
  const TICKET_ROUTES = {
    '2025년_신년운세': '/new-year-gate'
    // 나중에 다른 카테고리가 추가되면 여기에 추가
  };

  // handleTicketClick 함수 추가
  const handleTicketClick = (categId) => {
    const route = TICKET_ROUTES[categId];
    if (route) {
      navigate(route);
    }
  };

  // fetchTodayFortune 함수 추가
  const fetchTodayFortune = async (profileData) => {
    try {
      const response = await fetch('https://api.dk-saju.com/contents/unse/today', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_MANSE_TOKEN}`,
        },
        body: JSON.stringify({
          year: profileData.birth_year,
          month: profileData.birth_month,
          day: profileData.birth_day,
          hour: profileData.birth_hour || 0,
          minute: profileData.birth_minute || 0,
        }),
      });
      
      if (!response.ok) {
        throw new Error('API 호출 실패');
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('오늘의 운세 조회 실패:', error);
      return null;
    }
  };

  // 프로필 선택 변경 핸들러 수정
  const handleProfileChange = async (option) => {
    const profileId = Number(option.value);
    setSelectedProfileId(profileId);
    
    // 해당 프로필의 오늘의 운세가 없는 경우에만 새로 조회
    if (!todayFortuneResults[profileId]) {
      const profile = profiles.find(p => p.id === profileId);
      const todayFortune = await fetchTodayFortune(profile);
      if (todayFortune) {
        setTodayFortuneResults(prev => ({
          ...prev,
          [profileId]: todayFortune.data
        }));
      }
    }
  };

  // 이벤트 코드 적용 함수 추가
  const handleEventCodeSubmit = async () => {
    if (!eventCode.trim()) {
      setEventMessage({ type: 'error', text: '이벤트 코드를 입력해주세요.' });
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await apiService.post('/apply-event-code/', {
        event_code: eventCode.trim()
      });
      
      setEventMessage({ type: 'success', text: response.data.message });
      setEventCode(''); // 입력 필드 초기화
      fetchReferralInfo(); // 이용권 목록 새로고침
    } catch (error) {
      setEventMessage({ 
        type: 'error', 
        text: error.response?.data?.message || '이벤트 코드 적용에 실패했습니다.' 
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="my-page-container">
      <section className="my-profile-section">
        <div className="my-profile-header">
          <h2>운세 프로필</h2>
        </div>
        
        {profiles.length > 0 ? (
          <>
            <div className="my-profile-selector">
              <Select
                value={getOptions(profiles).find(option => option.value === selectedProfileId)}
                onChange={handleProfileChange}
                options={getOptions(profiles)}
                styles={customStyles}
                isSearchable={false}
                placeholder="프로필을 선택하세요"
              />
            </div>

            {/* 오늘의 운세 섹션 수정 */}
            {selectedProfile && todayFortuneResults[selectedProfile.id] && (
              <div className="today-fortune-section">
                <h3 className="my-section-title">오늘의 운세</h3>
                <div className="my-results-list">
                  <div 
                    className="my-result-card" 
                    onClick={() => {
                      // localStorage에 임시로 프로필 ID 저장
                      localStorage.setItem('temp_profile_id', selectedProfile.id);
                      navigate('/today-result');
                    }}
                  >
                    <div className="my-result-info">
                      <div className="today-fortune-details">
                        {todayFortuneResults[selectedProfile.id].comment}
                      </div>
                    </div>
                    <div className="my-result-actions">
                      <span className="my-content-badge my-free">상세보기</span>
                      <div className="my-card-arrow">→</div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedProfile && (
              <div className="my-profile-info">
                <div className="my-saju-info">
                  <h3 className="my-section-title">사주명식</h3>
                  <SajuDisplayHanja 
                    pillar={selectedProfile.pillar} 
                    umyang_ohang={selectedProfile.umyang_ohang}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="my-empty-profile">
            <div className="my-profile-spinner"></div>
          </div>
        )}

        <div className="my-profile-actions">
          <button 
            className="my-profile-button" 
            onClick={() => navigate('/profile-add')}
          >
            새 프로필 추가
          </button>
          {selectedProfile && (
            <button 
              className="my-profile-button" 
              onClick={() => navigate(`/profile-edit/${selectedProfile.id}`)}
            >
              프로필 수정
            </button>
          )}
        </div>
      </section>

      <section className="my-results-section">
        <h3 className="my-section-title">AI 운세 컨텐츠</h3>
        <div className="my-results-list">
          {filteredResults.length > 0 ? (
            filteredResults.map((result) => (
              <div key={result.id} className="my-result-card" onClick={() => handleViewResult(result.share_id)}>
                <div className="my-result-info">
                  <h4>
                    {result.content_name}
                    <span className={`my-content-badge ${result.is_paid ? 'my-paid' : 'my-free'}`}>
                      {result.is_paid ? '유료' : '무료'}
                    </span>
                  </h4>
                  <p className="my-date">진행날짜: {formatDate(result.created_at)}</p>
                </div>
                <div className="my-card-arrow">→</div>
              </div>
            ))
          ) : (
            <button 
              className="referral-button"
              onClick={() => navigate('/new-year-gate')}
            >
              ✨ AI 신년운세 보러가기
            </button>
          )}
        </div>
      </section>

      <section className="my-results-section">
        <h3 className="my-section-title">무료 컨텐츠</h3>
        <p className="share-description">
          아래 무료 테스트를 진행하고 결과를 공유해보세요<br />
          공유된 링크로 친구도 테스트를 진행할 경우 리워드 점수가 올라가요
        </p>
        <div className="referral-buttons">
          <button 
            className="referral-button saju"
            onClick={() => navigate(`/class-gate?invitation=${referralInfo?.code}`)}
          >
            ✨ 사주 격 테스트 하러가기
          </button>
          <button 
            className="referral-button days60"
            onClick={() => navigate(`/day-pillar-gate?invitation=${referralInfo?.code}`)}
          >
            🌙 60일주 테스트 하러가기
          </button>
        </div>
      </section>

      <section className="my-referral-section">
        <h3 className="my-section-title">리워드 현황</h3>
        {referralInfo && (
          <div className="referral-content">
            <div className="referral-code-box" onClick={handleCopyCode}>
              <span className="referral-label">나의 추천코드</span>
              <span className="referral-code">{referralInfo.code}</span>
            </div>
            
            <div className="referral-progress">
              <div className="progress-label">
                <span>리워드 점수</span>
                <span>{referralInfo.reward_balance}/10점</span>
              </div>
              <div className="progress-bar-container">
                <div 
                  className="progress-bar" 
                  style={{ width: `${Math.min((referralInfo.reward_balance / 10) * 100, 100)}%` }}
                />
              </div>
              <p className="progress-description">
                무료 컨텐츠를 친구에게 공유하고 리워드 점수를 모아보세요!
                <br />
                10점 달성시 유료 운세 이용권으로 교환됩니다.
                {referralInfo.total_reward_count > 0 && (
                  <>
                    <br />
                    <span className="reward-history">
                      (지금까지 {referralInfo.total_reward_count}개의 이용권을 교환했어요)
                    </span>
                  </>
                )}
              </p>
            </div>
          </div>
        )}
      </section>

      <section className="my-tickets-section">
        <h3 className="my-section-title">이용권 보유 현황</h3>
        
        {/* 이벤트 코드 입력 UI 추가 */}
        <div className="event-code-section">
          <div className="event-code-input-wrapper">
            <input
              type="text"
              value={eventCode}
              onChange={(e) => setEventCode(e.target.value.toUpperCase())}
              placeholder="이벤트 코드를 입력하세요"
              className="event-code-input"
              disabled={isSubmitting}
            />
            <button
              onClick={handleEventCodeSubmit}
              disabled={isSubmitting}
              className="event-code-button"
            >
              {isSubmitting ? '처리중...' : '적용'}
            </button>
          </div>
          {eventMessage && (
            <p className={`event-message ${eventMessage.type}`}>
              {eventMessage.text}
            </p>
          )}
        </div>

        <div className="my-results-list">
          {ticketInfo.length > 0 ? (
            ticketInfo.map((ticket) => (
              <div 
                key={ticket.id} 
                className="my-result-card"
                onClick={() => handleTicketClick(ticket.content_categ_id)}
                style={{ cursor: TICKET_ROUTES[ticket.content_categ_id] ? 'pointer' : 'default' }}
              >
                <div className="my-result-info">
                  <h4>
                    {ticket.content_name}
                    <span className="my-content-badge my-paid">
                      {ticket.ticket_type}
                    </span>
                  </h4>
                  <p className="my-date">
                    유효기간: {formatDate(ticket.expire_at)}
                  </p>
                </div>
                {TICKET_ROUTES[ticket.content_categ_id] && (
                  <div className="my-card-arrow">→</div>
                )}
              </div>
            ))
          ) : (
            <div className="my-empty-state">
              보유 중인 이용권이 없습니다
            </div>
          )}
        </div>
      </section>

      <div className="account-delete-wrapper">
        <button 
          className="account-delete-button"
          onClick={() => setShowDeleteModal(true)}
        >
          계정 삭제
        </button>
      </div>

      {showDeleteModal && (
        <section className="delete-modal-overlay" role="dialog" aria-modal="true">
          <div className="delete-modal">
            <header className="delete-modal-header">
              <h2>계정 삭제</h2>
            </header>
            <main className="delete-modal-content">
              <p>계정을 삭제하시면, 유료 결제한 내용도 전부 지워집니다.<br/>그래도 삭제하시겠습니까?</p>
            </main>
            <footer className="delete-modal-buttons">
              <button 
                className="delete-modal-button danger"
                onClick={handleDeleteAccount}
              >
                삭제
              </button>
              <button 
                className="delete-modal-button cancel"
                onClick={() => setShowDeleteModal(false)}
              >
                취소
              </button>
            </footer>
          </div>
        </section>
      )}


    </div>
  );
};

// 관계 표시를 위한 딕셔너리
const dict = {
  'self': '본인',
  'spouse': '배우자',
  'girlfriend': '여자친구',
  'boyfriend': '남자친구',
  'father': '아버지',
  'mother': '어머니',
  'elder_brother': '형',
  'younger_brother': '남동생',
  'elder_sister': '언니/누나',
  'younger_sister': '여동생',
  'friend': '친구',
  'acquaintance': '지인'
};

const OhangBox = ({ sky, land, pillar, headerSky, headerLand, headerSkyExtraText, headerLandExtraText }) => {
    return (
      <div className="pillar">
        <div className="pillar-section">
          <div className="pillar-header">{headerSky}</div>
          <div
            className="pillar-sky"
            style={{ backgroundColor: ohangDataV2[sky].color }}
          >
            {/* <span className="ohang-extra-text">{headerSkyExtraText}</span> */}
            <span className="ohang-symbol">
              <img src={ohangDataV2[sky].symbol} alt={sky} className="ohang-icon" />
            </span>
            <span className="ohang-text">{pillar.sky}</span>
          </div>
        </div>
        <div className="pillar-section">
          <div className="pillar-header">{headerLand}</div>
          <div
            className="pillar-land"
            style={{ backgroundColor: ohangDataV2[land].color }}
          >
            {/* <span className="ohang-extra-text">{headerLandExtraText}</span> */}
            <span className="ohang-symbol">
              <img src={ohangDataV2[land].symbol} alt={land} className="ohang-icon" />
            </span>
            <span className="ohang-text">{pillar.land}</span>
          </div>
        </div>
      </div>
    );
  };
  

const OhangBoxHanja = ({ sky, land, pillar, headerSky, headerLand, headerSkyExtraText, headerLandExtraText }) => {
    return (
      <div className="pillar">
        <div className="pillar-section">
          <div className="pillar-header">{headerSky}</div>
          <div 
            className="pillar-sky hanja-box"
            style={{ backgroundColor: ohangDataV2[hanjaOhangMapping[pillar.sky]].color }}
          >
            {/* <span className="ohang-extra-text">{headerSkyExtraText}</span> */}
            <span className="hanja-text">{skyHanjaData[pillar.sky]}</span>
            <span className="hangul-text">{pillar.sky}</span>
          </div>
        </div>
        <div className="pillar-section">
          <div className="pillar-header">{headerLand}</div>
          <div 
            className="pillar-land hanja-box"
            style={{ backgroundColor: ohangDataV2[hanjaOhangMapping[pillar.land]].color }}
          >
            {/* <span className="ohang-extra-text">{headerLandExtraText}</span> */}
            <span className="hanja-text">{landHanjaData[pillar.land]}</span>
            <span className="hangul-text">{pillar.land}</span>
          </div>
        </div>
      </div>
    );
};

const OhangBoxCombined = ({ sky, land, pillar, headerSky, headerLand, headerSkyExtraText, headerLandExtraText }) => {
    return (
      <div className="pillar">
        <div className="pillar-section">
          <div className="pillar-header">{headerSky}</div>
          <div 
            className="pillar-sky hanja-box combined"
            style={{ backgroundColor: ohangDataV2[hanjaOhangMapping[pillar.sky]].color }}
          >
            <span className="hanja-text">{skyHanjaData[pillar.sky]}</span>
            <span className="hangul-text">{pillar.sky}</span>
            <img 
              src={ohangDataV2[hanjaOhangMapping[pillar.sky]].symbol} 
              alt={hanjaOhangMapping[pillar.sky]} 
              className="ohang-icon-small" 
            />
          </div>
        </div>
        <div className="pillar-section">
          <div className="pillar-header">{headerLand}</div>
          <div 
            className="pillar-land hanja-box combined"
            style={{ backgroundColor: ohangDataV2[hanjaOhangMapping[pillar.land]].color }}
          >
            <span className="hanja-text">{landHanjaData[pillar.land]}</span>
            <span className="hangul-text">{pillar.land}</span>
            <img 
              src={ohangDataV2[hanjaOhangMapping[pillar.land]].symbol} 
              alt={hanjaOhangMapping[pillar.land]} 
              className="ohang-icon-small" 
            />
          </div>
        </div>
      </div>
    );
};

const OhangBoxCombinedV2 = ({ sky, land, pillar, headerSky, headerLand, headerSkyExtraText, headerLandExtraText }) => {
  return (
    <div className="pillar">
      <div className="pillar-section">
        <div className="pillar-header">{headerSky}</div>
        <div 
          className="pillar-sky hanja-box combined-v2"
          style={{ backgroundColor: ohangDataV2[hanjaOhangMapping[pillar.sky]].color }}
        >
          <img 
            src={ohangDataV2[hanjaOhangMapping[pillar.sky]].symbol} 
            alt={hanjaOhangMapping[pillar.sky]} 
            className="ohang-icon-small" 
          />
          <span className="hanja-text">{skyHanjaData[pillar.sky]}</span>
          <span className="hangul-text">{pillar.sky}</span>
        </div>
      </div>
      <div className="pillar-section">
        <div className="pillar-header">{headerLand}</div>
        <div 
          className="pillar-land hanja-box combined-v2"
          style={{ backgroundColor: ohangDataV2[hanjaOhangMapping[pillar.land]].color }}
        >
          <img 
            src={ohangDataV2[hanjaOhangMapping[pillar.land]].symbol} 
            alt={hanjaOhangMapping[pillar.land]} 
            className="ohang-icon-small" 
          />
          <span className="hanja-text">{landHanjaData[pillar.land]}</span>
          <span className="hangul-text">{pillar.land}</span>
        </div>
      </div>
    </div>
  );
};

export default MyPage;
