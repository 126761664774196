import axios from 'axios';

console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://www.tagmeet.io/api',
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true, // 쿠키 자동 포함
});

// CSRF 토큰 가져오기 (Django와 함께 사용시)
const getCSRFToken = () => {
    return document.cookie
        .split('; ')
        .find(row => row.startsWith('csrfToken='))
        ?.split('=')[1];
};

// 요청 인터셉터
api.interceptors.request.use(
    (config) => {
        // CSRF 토큰이 필요한 요청에만 추가
        if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(config.method?.toUpperCase())) {
            const csrfToken = getCSRFToken();
            if (csrfToken) {
                config.headers['X-CSRFToken'] = csrfToken;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 응답 인터셉터
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 401) {
            // 인증 실패시 로그인 페이지로 리다이렉트
            window.location.href = '/';  // '/home' 대신 '/login'으로 변경
        }
        return Promise.reject(error);
    }
);

// API 요청 헬퍼 함수들
export const apiService = {
    get: async (url, config = {}) => {
        try {
            const response = await api.get(url, config);
            return response;
        } catch (error) {
            throw error;
        }
    },

    post: async (url, data = {}, config = {}) => {
        try {
            const response = await api.post(url, data, config);
            return response;
        } catch (error) {
            throw error;
        }
    },

    put: async (url, data = {}, config = {}) => {
        try {
            const response = await api.put(url, data, config);
            return response
        } catch (error) {
            throw error;
        }
    },

    delete: async (url, config = {}) => {
        try {
            const response = await api.delete(url, config);
            return response;
        } catch (error) {
            throw error;
        }
    }
};

export default api;