import { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { useAuth } from '../contexts/AuthContext';
import { sajuClassData } from '../contents/class_contents/classContentsData';
import './ClassDetailPage.css';
import { handleKakaoLogin } from '../utils/SocialAuth';
import './ClassPage.css'; 

// 각 섹션을 위한 컴포넌트
const AnimatedSection = ({ children }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`saju-class-detail__card animate-on-scroll ${inView ? 'visible' : ''}`}
    >
      {children}
    </div>
  );
};

// 이미지와 텍스트를 합치는 함수
const createShareImage = async (originalImage, text) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  const dpr = window.devicePixelRatio || 1;
  const CANVAS_WIDTH = 375;
  const VIEWPORT_HEIGHT = window.innerHeight-80;
  const TEXT_PADDING = 40;
  const BORDER_RADIUS = 16 * dpr;
  const TOP_MARGIN = 40;
  
  // 임시 캔버스로 텍스트 높이 계산
  const tempCanvas = document.createElement('canvas');
  const tempCtx = tempCanvas.getContext('2d');
  tempCtx.font = 'bold 20px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
  
  // 텍스트 높이 계산
  const maxWidth = CANVAS_WIDTH - (TEXT_PADDING * 2);
  const lineHeight = 26;
  let totalTextHeight = 0;
  
  const paragraphs = text.split('\n');
  paragraphs.forEach(paragraph => {
    if (paragraph.trim() === '') {
      totalTextHeight += lineHeight;
      return;
    }
    
    const words = paragraph.split(' ');
    let line = '';
    words.forEach(word => {
      const testLine = line + word + ' ';
      const metrics = tempCtx.measureText(testLine);
      if (metrics.width > maxWidth) {
        totalTextHeight += lineHeight;
        line = word + ' ';
      } else {
        line = testLine;
      }
    });
    totalTextHeight += lineHeight * 1.5;
  });
  
  // 요소들의 위치 계산
  const SITE_URL_Y = TOP_MARGIN + 30; // 웹 주소 위치 (상단 여백 + 기본 간격)
  const TITLE_Y = SITE_URL_Y + 40; // 제목 위치 (웹 주소 아래)
  const HEADER_HEIGHT = TITLE_Y + 40; // 헤더 영역 전체 높이
  
  // 이미지 높이 계산
  const IMAGE_HEIGHT = VIEWPORT_HEIGHT * 0.33;
  
  // 전체 캔버스 높이 계산 (상단 여백 포함)
  const TOTAL_HEIGHT = TOP_MARGIN + HEADER_HEIGHT + IMAGE_HEIGHT + totalTextHeight + (TEXT_PADDING * 2);
  
  canvas.width = CANVAS_WIDTH * dpr;
  canvas.height = TOTAL_HEIGHT * dpr;
  ctx.scale(dpr, dpr);
  
  canvas.style.width = CANVAS_WIDTH + 'px';
  canvas.style.height = TOTAL_HEIGHT + 'px';
  
  const img = new Image();
  img.crossOrigin = 'anonymous';
  
  return new Promise((resolve, reject) => {
    img.onload = () => {
      // 배경색 설정
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, CANVAS_WIDTH, TOTAL_HEIGHT);
      
      // 상단 사이트 주소 추가 (상단 여백 적용)
      ctx.fillStyle = '#34776d';
      ctx.font = 'bold 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(process.env.REACT_APP_PUBLIC_URL, CANVAS_WIDTH / 2, SITE_URL_Y);
      
      // "사주격 테스트" 텍스트 추가
      ctx.fillStyle = '#2c3e50';
      ctx.font = 'bold 24px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
      ctx.fillText('사주 유형(격) 테스트', CANVAS_WIDTH / 2, TITLE_Y);
      
      // 이미지 그리기
      ctx.save();
      
      const scale = IMAGE_HEIGHT / img.height;
      const scaledWidth = img.width * scale;
      const x = (CANVAS_WIDTH - scaledWidth) / 2;
      const y = HEADER_HEIGHT; // 헤더 높이 위치에 이미지 시작
      
      // 라운드 처리를 위한 패스 생성
      ctx.beginPath();
      ctx.moveTo(x + BORDER_RADIUS/dpr, y);
      ctx.lineTo(x + scaledWidth - BORDER_RADIUS/dpr, y);
      ctx.quadraticCurveTo(x + scaledWidth, y, x + scaledWidth, y + BORDER_RADIUS/dpr);
      ctx.lineTo(x + scaledWidth, y + IMAGE_HEIGHT - BORDER_RADIUS/dpr);
      ctx.quadraticCurveTo(x + scaledWidth, y + IMAGE_HEIGHT, x + scaledWidth - BORDER_RADIUS/dpr, y + IMAGE_HEIGHT);
      ctx.lineTo(x + BORDER_RADIUS/dpr, y + IMAGE_HEIGHT);
      ctx.quadraticCurveTo(x, y + IMAGE_HEIGHT, x, y + IMAGE_HEIGHT - BORDER_RADIUS/dpr);
      ctx.lineTo(x, y + BORDER_RADIUS/dpr);
      ctx.quadraticCurveTo(x, y, x + BORDER_RADIUS/dpr, y);
      ctx.closePath();
      
      ctx.clip();
      ctx.drawImage(img, x, y, scaledWidth, IMAGE_HEIGHT);
      ctx.restore();
      
      // 텍스트 렌더링 시작 위치 수정
      ctx.fillStyle = 'black';
      ctx.font = 'bold 16px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif';
      ctx.textAlign = 'center';
      
      let currentY = HEADER_HEIGHT + IMAGE_HEIGHT + TEXT_PADDING;
      
      paragraphs.forEach(paragraph => {
        if (paragraph.trim() === '') {
          currentY += lineHeight;
          return;
        }
        
        const words = paragraph.split(' ');
        let line = '';
        
        words.forEach(word => {
          const testLine = line + word + ' ';
          const metrics = ctx.measureText(testLine);
          
          if (metrics.width > maxWidth) {
            ctx.fillText(line, CANVAS_WIDTH / 2, currentY);
            line = word + ' ';
            currentY += lineHeight;
          } else {
            line = testLine;
          }
        });
        
        if (line) {
          ctx.fillText(line.trim(), CANVAS_WIDTH / 2, currentY);
          currentY += lineHeight * 1.5;
        }
      });
      
      canvas.toBlob(blob => {
        resolve(new File([blob], 'share-image.png', { type: 'image/png' }));
      }, 'image/png', 1.0);
    };
    
    img.onerror = reject;
    img.src = originalImage;
  });
};

function ClassDetailPage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  
  // URL 파라미터에서 상태 읽기
  const gender = searchParams.get('gender') || 'female';
  const testDone = searchParams.get('testDone') === 'true';
  const referralCode = searchParams.get('invitation');

  const [classData, setClassData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const shareButtonsRef = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0.1, // 10%만 보여도 감지
  });

  useEffect(() => {
    const invitation = searchParams.get('invitation');
    if (invitation) {
      localStorage.setItem('referral_code', invitation);
    }
  }, [searchParams]);

  useEffect(() => {
    try {
      const foundClass = sajuClassData.common.find(c => c.id === id);
      if (!foundClass) {
        throw new Error('해당하는 사주격을 찾을 수 없습니다.');
      }
      setClassData(foundClass);
    } catch (err) {
      setError(err.message);
    }
  }, [id]);

  const handleTestButtonClick = async () => {
    if (!isAuthenticated) {
      try {
        setIsLoading(true);
        localStorage.setItem('intended_redirect_url', '/class-router');
        await handleKakaoLogin();
      } catch (error) {
        console.error('Kakao login failed:', error);
        setIsLoading(false);
      }
      return;
    }

    if (testDone) {
      shareButtonsRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    navigate('/class-router');
  };

  const handleShare = async () => {
    const baseUrl = window.location.origin;
    const currentPath = window.location.pathname;
    const params = new URLSearchParams(searchParams);
    
    const shareUrl = `${baseUrl}${currentPath}?${params.toString()}`;
    
    // 문장 단위로 자르기
    const sentences = classData.fullDescription.split(/[.!?]+/).filter(Boolean);
    const truncatedDesc = sentences.slice(0, 3).join('. ') + '.';
    const shareText = `${classData.name} - ${classData.shortDescription}\n\n${truncatedDesc}`;
    
    try {
      const imageUrl = classData.images[gender].full;
      const combinedImageFile = await createShareImage(imageUrl, shareText);

      // 먼저 클립보드에 URL 복사 시도
      try {
        await navigator.clipboard.writeText(shareUrl);
      } catch (clipboardErr) {
        console.error('클립보드 복사 실패:', clipboardErr);
      }

      // 이미지 공유 시도
      if (navigator.share && navigator.canShare({ files: [combinedImageFile] })) {
        await navigator.share({
          files: [combinedImageFile],
          title: `${classData.name} - 나의 사주격 결과`,
          url: shareUrl
        });
      } else {
        // 이미지 공유가 불가능한 경우 텍스트만 공유
        await navigator.share({
          title: `${classData.name} - 나의 사주격 결과`,
          text: shareText,
          url: shareUrl
        });
      }
    } catch (err) {
      console.error('공유 실패:', err);
      // 공유가 실패했을 때만 클립보드 복사 성공 메시지를 표시
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert('링크가 클립보드에 복사되었습니다!');
      } catch (clipboardErr) {
        alert('링크 공유에 실패했습니다.');
      }
    }
  };

  const handleKakaoShare = () => {
    const baseUrl = window.location.origin;
    const currentPath = window.location.pathname;
    const params = new URLSearchParams(searchParams);
    
    if (referralCode) {
      params.set('invitation', referralCode);
    }
    
    const resultUrl = `${baseUrl}${currentPath}?${params.toString()}`;
    const testUrl = `${baseUrl}/class-gate${referralCode ? `?invitation=${referralCode}` : ''}`;

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: `${classData.name} - 나의 사주격 결과`,
        description: classData.shortDescription,
        imageUrl: classData.images[gender].full,
        link: {
          mobileWebUrl: resultUrl,
          webUrl: resultUrl,
        },
      },
      buttons: [
        {
          title: '결과보기',
          link: {
            mobileWebUrl: resultUrl,
            webUrl: resultUrl,
          },
        },
        {
          title: '테스트하기',
          link: {
            mobileWebUrl: testUrl,
            webUrl: testUrl,
          },
        },
      ],
    });
  };

  if (error) return <div className="error-message">에러: {error}</div>;
  if (!classData) return <div className="loading-message">로딩중...</div>;

  return (
    <div className="saju-class-detail">
      <main className="saju-class-detail__content">
        <section className="saju-class-detail__section">
          <AnimatedSection>
            <header className="saju-class-detail__header">
              <h1 className="saju-class-detail__title">{classData.name}</h1>
              <p className="saju-class-detail__subtitle">{classData.shortDescription}</p>
            </header>

            <div className="saju-class-detail__image-wrapper">
              <img
                src={classData.images[gender].full}  // 여기만 .full 추가
                alt={classData.name}
                className="saju-class-detail__main-image"
              />
            </div>

            <div className="saju-class-detail__description-wrapper">
              <h2 className="saju-class-detail__section-title">📝 상세 설명</h2>
              <p className="saju-class-detail__description">{classData.fullDescription}</p>
            </div>
          </AnimatedSection>
        </section>

        <section className="saju-class-detail__section">
          <AnimatedSection>
            <h2 className="saju-class-detail__section-title">💼 추천 직업</h2>
            <div className="saju-class-detail__career-tags">
              {classData.careers.map((career, index) => (
                <span key={index} className="saju-class-detail__tag">{career}</span>
              ))}
            </div>
          </AnimatedSection>
        </section>

        <section className="saju-class-detail__section">
          <AnimatedSection>
            <h2 className="saju-class-detail__section-title">💪 강점</h2>
            <ul className="saju-class-detail__list">
              {classData.strengths.map((strength, index) => (
                <li key={index} className="saju-class-detail__list-item">
                    {strength}
                </li>
              ))}
            </ul>
          </AnimatedSection>
        </section>

        <section className="saju-class-detail__section">
          <AnimatedSection>
            <h2 className="saju-class-detail__section-title">🔍 약점</h2>
            <ul className="saju-class-detail__list">
              {classData.weaknesses.map((weakness, index) => (
                <li key={index} className="saju-class-detail__list-item">
                    {weakness}
                </li>
              ))}
            </ul>
          </AnimatedSection>
        </section>

        <section className="saju-class-detail__section">
          <AnimatedSection>
            <h2 className="saju-class-detail__section-title">💢 화나게 하는 것들</h2>
            <ul className="saju-class-detail__list">
              {classData.angerTriggers.map((trigger, index) => (
                <li key={index} className="saju-class-detail__list-item saju-class-detail__list-item--trigger">
                  {trigger}
                </li>
              ))}
            </ul>
          </AnimatedSection>
        </section>

        <section className="saju-class-detail__section">
          <AnimatedSection>
            <h2 className="saju-class-detail__section-title">🌟 좋아하는 칭찬</h2>
            <ul className="saju-class-detail__list">
              {classData.compliments.map((compliment, index) => (
                <li key={index} className="saju-class-detail__list-item saju-class-detail__list-item--compliment">
                  {compliment}
                </li>
              ))}
            </ul>
          </AnimatedSection>
        </section>

        <section className="saju-class-detail__section">
          <AnimatedSection>
            <h2 className="saju-class-detail__section-title">✨ 동기부여되는 말</h2>
            <ul className="saju-class-detail__list">
              {classData.motivations.map((motivation, index) => (
                <li key={index} className="saju-class-detail__list-item saju-class-detail__list-item--motivation">
                  {motivation}
                </li>
              ))}
            </ul>
          </AnimatedSection>
        </section>

        <section className="saju-class-detail__section">
          <AnimatedSection>
            <h2 className="saju-class-detail__section-title">💰 소비 스타일</h2>
            <p className="saju-class-detail__description">{classData.consumptionStyle}</p>
          </AnimatedSection>
        </section>

        <section className="saju-class-detail__section">
          <AnimatedSection>
            <h2 className="saju-class-detail__section-title">💝 연애 스타일</h2>
            <ul className="saju-class-detail__list">
              {classData.loveStyle.map((style, index) => (
                <li key={index} className="saju-class-detail__list-item saju-class-detail__list-item--love">
                  {style}
                </li>
              ))}
            </ul>
          </AnimatedSection>
        </section>

        {/* 공유 버튼 섹션 */}
        {isAuthenticated && testDone && (
          <section 
            ref={(element) => {
              shareButtonsRef.current = element;
              ref(element);
            }}
            className="saju-class-detail__share-section"
          >
            <div className="saju-class-detail__share-buttons saju-class-detail__share-buttons--vertical">
              <button 
                className="saju-class-detail__share-button"
                onClick={handleShare}
              >
                <span role="img" aria-label="share">📤</span>
                링크 공유하기
              </button>
              <button 
                className="saju-class-detail__share-button"
                onClick={handleKakaoShare}
              >
                <span role="img" aria-label="kakao share">💬</span>
                카카오톡으로 공유하기
              </button>
              <p className="saju-class-detail__share-reward-info">
                공유된 링크로 친구도 테스트를 진행할 경우<br />
                리워드 점수가 올라가요! <br/>
                점수를 모아 유료 운세 이용권을 받아보세요.<br/>
                마이페이지 메뉴에서 확인 가능합니다.
              </p>
              <button 
                  className="saju-class-detail__share-button saju-class-detail__share-button--newyear"
                  onClick={() => navigate('/?section=services')}
                >
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Microsoft-Teams-Animated-Emojis/master/Emojis/Travel%20and%20places/Star.png" 
                    alt="" 
                     className="saju-class-detail__button-icon--left"
                  />
                  다른 서비스 둘러보기
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Microsoft-Teams-Animated-Emojis/master/Emojis/Travel%20and%20places/Star.png" 
                    alt="" 
                    className="saju-class-detail__button-icon--right"
                  />
              </button>
              <button 
                  className="saju-class-detail__share-button saju-class-detail__share-button--newyear"
                  onClick={() => navigate('/my-page')}
                >
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Light%20Bulb.webp" 
                    alt="" 
                     className="saju-class-detail__button-icon--left"
                  />
                  나의 사주명식 보러가기
                  <img 
                    src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Light%20Bulb.webp" 
                    alt="" 
                    className="saju-class-detail__button-icon--right"
                  />
              </button>
              <button 
                className="saju-class-detail__share-button saju-class-detail__share-button--newyear"
                onClick={() => navigate('/new-year-gate')}
              >
                <img 
                  src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png" 
                  alt="" 
                  className="saju-class-detail__button-icon--left"
                />
                25년 신년운세 보러가기
                <img 
                  src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png" 
                  alt="" 
                  className="saju-class-detail__button-icon--right"
                />
              </button>
            </div>
          </section>
        )}
      </main>

      {/* 플로팅 버튼은 공유 버튼이 화면에 보이지 않을 때만 표시 */}
      {(!inView || !testDone) && (
        <button 
          className={`saju-class__floating-button ${isLoading ? 'loading' : ''}`}
          onClick={handleTestButtonClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="saju-class__loading-spinner"></span>
          ) : (
            !isAuthenticated 
              ? '무료 테스트 해보기'
              : testDone
                ? '내 결과 공유하기'
                : '나의 사주격 알아보기'
          )}
        </button>
      )}
    </div>
  );
}

export default ClassDetailPage;
