import React, { useEffect } from 'react';
import './FortuneList.css';

const NewYearFortuneList = ({ triggerNextStep, triggerData, forceScrollRef }) => {
  const fortuneItems = [
    { id: 1, title: '2025 운세 총평', imageUrl: 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Glowing%20Star.png' },
    { id: 2, title: '재물운', imageUrl: 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Money%20Bag.webp' },
    { id: 3, title: '애정운', imageUrl: 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Symbols/Sparkling%20Heart.webp' },
    { id: 4, title: '직장 명예운', imageUrl: 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Briefcase.webp' },
    { id: 5, title: '학업 계약운', imageUrl: 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Books.webp' },
    { id: 6, title: '2025년 나에게 다가올 행운', imageUrl: 'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Crystal%20Ball.png' },
  ];

  useEffect(() => {
    if(forceScrollRef){
      forceScrollRef.current = true;
    }
    const timer = setTimeout(() => {
      triggerNextStep(triggerData);
    }, 100); // 1초 후에 다음 단계로 넘어감

    return () => clearTimeout(timer);
  }, []); // Add dependencies


  return (
    <div className="fortune-list-container">
      <h2 className="fortune-list-title">25년 운세 풀이 순서</h2>
      <ul className="fortune-list">
        {fortuneItems.map((item) => (
          <li key={item.id} className="fortune-list-item">
            <img 
              src={item.imageUrl} 
              alt={item.title}
              className="fortune-item-image"
            />
            <span className="fortune-item-title">{item.title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewYearFortuneList;