import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../utils/api';
import './ClassRouterPage.css';

function ClassRouterPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserClassData = async () => {
      try {
        const response = await apiService.get('/fortune/class-result/');
        const { class_id, gender, referral_code } = response.data;
        
        const params = new URLSearchParams({
          gender,
          testDone: 'true'
        });
        if (referral_code) {
          params.append('invitation', referral_code);
        }
        
        navigate(`/class/${class_id}?${params.toString()}`, { replace: true });
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserClassData();
  }, [navigate]);

  if (error) return <div className="class-router__error">에러: {error}</div>;
  
  return (
    <div className="class-router">
      <div className="class-router__spinner"></div>
    </div>
  );
}

export default ClassRouterPage;
