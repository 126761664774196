import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './RegistrationPage.css';
import { apiService } from '../utils/api'; 

function ProfileEditPage() {
  const navigate = useNavigate();
  const { profileId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    gender: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    birthHour: '',
    birthMinute: '',
    relationship: 'acquaintance',
    is_birth_time_unknown: false,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isSelfProfile, setIsSelfProfile] = useState(false);

  const isEditMode = Boolean(profileId);

  // 시간 값을 임시 저장할 ref 추가
  const savedTimeRef = React.useRef({
    hour: '',
    minute: ''
  });

  const RELATIONSHIP_OPTIONS = [
    { value: 'self', label: '본인' },
    { value: 'spouse', label: '배우자' },
    { value: 'girlfriend', label: '여자친구' },
    { value: 'boyfriend', label: '남자친구' },
    { value: 'father', label: '아버지' },
    { value: 'mother', label: '어머니' },
    { value: 'elder_brother', label: '형' },
    { value: 'younger_brother', label: '남동생' },
    { value: 'elder_sister', label: '언니/누나' },
    { value: 'younger_sister', label: '여동생' },
    { value: 'friend', label: '친구' },
    { value: 'acquaintance', label: '지인' }
  ];

  // 프로필 데이터 불러오기
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await apiService.get(`/fortune/persons/${profileId}/`);
        const profile = response.data;
        
        // 본인 프로필 여부 확인
        const isSelf = profile.relationship === 'self';
        setIsSelfProfile(isSelf);

        setFormData({
          name: profile.name,
          gender: profile.gender,
          birthYear: profile.birth_year,
          birthMonth: profile.birth_month,
          birthDay: profile.birth_day,
          birthHour: profile.birth_hour || '',
          birthMinute: profile.birth_minute || '',
          relationship: profile.relationship,
          is_birth_time_unknown: profile.is_birth_time_unknown,
        });

        // 본인 프로필 여부 설정
        setIsSelfProfile(profile.relationship === 'self');
      } catch (error) {
        console.error('프로필 데이터 로딩 실패:', error);
        alert('프로필 정보를 불러오는데 실패했습니다.');
        navigate('/my-page');
      }
    };

    if (isEditMode) {
      fetchProfileData();
    }
  }, [profileId, navigate, isEditMode]);

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = '이름을 입력해주세요';
    }
    if (!formData.gender) {
      errors.gender = '성별을 선택해주세요';
    }
    if (!formData.birthYear || formData.birthYear.length !== 4) {
      errors.birthYear = '출생년도 4자리를 입력해주세요';
    }
    
    // 월 검증
    const month = parseInt(formData.birthMonth);
    if (!formData.birthMonth) {
      errors.birthMonth = '출생월을 입력해주세요';
    } else if (isNaN(month) || month < 1 || month > 12) {
      errors.birthMonth = '1-12 사이의 월을 입력해주세요';
    }

    // 일 검증
    const day = parseInt(formData.birthDay);
    if (!formData.birthDay) {
      errors.birthDay = '출생일을 입력해주세요';
    } else {
      const year = parseInt(formData.birthYear);
      const lastDay = new Date(year, month, 0).getDate();
      
      if (isNaN(day) || day < 1 || day > lastDay) {
        errors.birthDay = `1-${lastDay} 사이의 일을 입력해주세요`;
      }
    }
    
    // 시간 검증 (시간 모름이 체크되지 않은 경우에만)
    if (!formData.is_birth_time_unknown) {
      if (formData.birthHour === '') {
        errors.birthHour = '시간을 입력해주세요';
      } else {
        const hour = parseInt(formData.birthHour);
        if (isNaN(hour) || hour < 0 || hour > 23) {
          errors.birthHour = '시간은 0-23 사이여야 합니다';
        }
      }
      
      if (formData.birthMinute === '') {
        errors.birthMinute = '분을 입력해주세요';
      } else {
        const minute = parseInt(formData.birthMinute);
        if (isNaN(minute) || minute < 0 || minute > 59) {
          errors.birthMinute = '분은 0-59 사이여야 합니다';
        }
      }
    }
    
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setIsSubmitting(true);

      const profileData = {
        name: formData.name,
        gender: formData.gender,
        birth_year: parseInt(formData.birthYear),
        birth_month: parseInt(formData.birthMonth),
        birth_day: parseInt(formData.birthDay),
        birth_hour: formData.is_birth_time_unknown ? null : parseInt(formData.birthHour),
        birth_minute: formData.is_birth_time_unknown ? null : parseInt(formData.birthMinute),
        relationship: formData.relationship,
        is_birth_time_unknown: formData.is_birth_time_unknown
      };

      if (isEditMode) {
        // 수정 모드
        await apiService.put(`/fortune/persons/${profileId}/`, profileData);
        alert('프로필이 성공적으로 수정되었습니다.');
      } else {
        // 추가 모드
        await apiService.post('/fortune/persons/', profileData);
        alert('프로필이 성공적으로 추가되었습니다.');
      }
      
      navigate('/my-page');
    } catch (error) {
      console.error(isEditMode ? '프로필 수정 실패:' : '프로필 추가 실패:', error);
      alert(isEditMode ? '프로필 수정에 실패했습니다.' : '프로필 추가에 실패했습니다.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'birthYear') {
      if (value === '' || (value.length <= 4 && /^\d*$/.test(value))) {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    } else if (name === 'birthMonth') {
      // 월 입력 처리 (1-12)
      let monthValue = value.replace(/^0+/, '');
      const month = parseInt(monthValue);
      
      if (monthValue === '' || (month >= 0 && month <= 12 && monthValue.length <= 2)) {
        setFormData(prev => ({
          ...prev,
          [name]: monthValue,
          birthDay: month === 0 ? prev.birthDay : ''
        }));
      }
    } else if (name === 'birthDay') {
      let dayValue = value.replace(/^0+/, '');
      const day = parseInt(dayValue);
      const month = parseInt(formData.birthMonth);
      const year = parseInt(formData.birthYear);
      const lastDay = new Date(year, month, 0).getDate();
      
      if (dayValue === '' || (day >= 0 && day <= lastDay && dayValue.length <= 2)) {
        setFormData(prev => ({
          ...prev,
          [name]: dayValue
        }));
      }
    } else if (name === 'birthHour') {
      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 23)) {
        setFormData(prev => ({ ...prev, [name]: value }));
      }
    } else if (name === 'birthMinute') {
      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
        setFormData(prev => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleTimeUnknownChange = (e) => {
    const isChecked = e.target.checked;
    
    if (isChecked) {
      // 체크 시 현재 시간 값을 저장하고 0시 0분으로 설정
      savedTimeRef.current = {
        hour: formData.birthHour,
        minute: formData.birthMinute
      };
      
      setFormData(prev => ({
        ...prev,
        is_birth_time_unknown: true,
        birthHour: '0',
        birthMinute: '0'
      }));
    } else {
      // 체크 해제 시 저장된 시간 값을 복원
      setFormData(prev => ({
        ...prev,
        is_birth_time_unknown: false,
        birthHour: savedTimeRef.current.hour,
        birthMinute: savedTimeRef.current.minute
      }));
    }
  };

  const handleDelete = async () => {
    if (window.confirm('정말로 이 프로필을 삭제하시겠습니까?')) {
      try {
        await apiService.delete(`/fortune/persons/${profileId}/`);
        alert('프로필이 삭제되었습니다.');
        navigate('/my-page');
      } catch (error) {
        if (error.response?.status === 400) {
          alert('운세 결과가 있는 프로필은 삭제할 수 없습니다. 이 경우, 관계를 지인 등으로 바꾸어서 업데이트 해주세요.');
        } else {
          alert('프로필 삭제에 실패했습니다.');
        }
      }
    }
  };

  // JSX는 기존 코드와 동일하게 유지하되, 불필요한 부분 제거
  return (
    <div className="registration-container">
      <div className="registration-form">
        <h1>{isEditMode ? '프로필 수정' : '프로필 추가'}</h1>
        <form onSubmit={handleSubmit}>
        <div className="form-group name-gender-group">
          <div className="input-wrapper">
            <label>이름</label>
            <div className="name-gender-inputs">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="홍길동"
                className={validationErrors.name ? 'error' : ''}
              />
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className={validationErrors.gender ? 'error' : ''}
              >
                <option value="">성별</option>
                <option value="M">남성</option>
                <option value="F">여성</option>
              </select>
            </div>
            {(validationErrors.name || validationErrors.gender) && (
              <span className="error-message">
                {validationErrors.name || validationErrors.gender}
              </span>
            )}
          </div>
        </div>

          <div className="form-group birth-date">
            <label>생년월일</label>
            <div className="date-inputs">
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthYear"
                  value={formData.birthYear}
                  onChange={handleChange}
                  placeholder="1995"
                  maxLength="4"
                  className={validationErrors.birthYear ? 'error' : ''}
                />
                <span>년</span>
              </div>
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthMonth"
                  value={formData.birthMonth}
                  onChange={handleChange}
                  placeholder="1"
                  maxLength="2"
                  className={validationErrors.birthMonth ? 'error' : ''}
                />
                <span>월</span>
              </div>
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthDay"
                  value={formData.birthDay}
                  onChange={handleChange}
                  placeholder="1"
                  maxLength="2"
                  className={validationErrors.birthDay ? 'error' : ''}
                />
                <span>일</span>
              </div>
            </div>
            {(validationErrors.birthYear || validationErrors.birthMonth || validationErrors.birthDay) && (
              <span className="error-message">생년월일을 모두 입력해주세요</span>
            )}
          </div>
          <div className="form-group birth-time">
            <label>태어난 시간</label>
            <div className="time-container">
              <div className="time-inputs">
                <div className="time-input-group">
                  <input
                    type="number"
                    name="birthHour"
                    value={formData.birthHour}
                    onChange={handleChange}
                    placeholder="10"
                    maxLength="2"
                    className={validationErrors.birthHour ? 'error' : ''}
                    disabled={formData.is_birth_time_unknown}
                  />
                  <span>시</span>
                </div>
                <div className="time-input-group">
                  <input
                    type="number"
                    name="birthMinute"
                    value={formData.birthMinute}
                    onChange={handleChange}
                    placeholder="30"
                    maxLength="2"
                    className={validationErrors.birthMinute ? 'error' : ''}
                    disabled={formData.is_birth_time_unknown}
                  />
                  <span>분</span>
                </div>
                <label className="time-unknown-label">
                  <input
                    type="checkbox"
                    checked={formData.is_birth_time_unknown}
                    onChange={handleTimeUnknownChange}
                  />
                  <span>시간 모름</span>
                </label>
              </div>
            </div>
            {formData.is_birth_time_unknown && (
              <span className="error-message" style={{ color: '#2e7d32' }}>
                시간 모름이 체크된 경우, 시간이 0시 0분으로 임의로 저장됩니다.
              </span>
            )}
            {validationErrors.birthHour && !formData.is_birth_time_unknown && (
              <span className="error-message">{validationErrors.birthHour}</span>
            )}
            {validationErrors.birthMinute && !formData.is_birth_time_unknown && (
              <span className="error-message">{validationErrors.birthMinute}</span>
            )}
          </div>

          {/* 관계 선택 필드 */}
          <div className="form-group">
            <label>관계</label>
            <select 
              name="relationship"
              value={formData.relationship}
              onChange={handleChange}
              disabled={isSelfProfile}
              className={isSelfProfile ? 'disabled-select' : ''}
            >
              {isEditMode && <option value="self">본인</option>}
              <option value="spouse">배우자</option>
              <option value="girlfriend">여자친구</option>
              <option value="boyfriend">남자친구</option>
              <option value="father">아버지</option>
              <option value="mother">어머니</option>
              <option value="elder_brother">형</option>
              <option value="younger_brother">남동생</option>
              <option value="elder_sister">언니/누나</option>
              <option value="younger_sister">여동생</option>
              <option value="friend">친구</option>
              <option value="acquaintance">지인</option>
            </select>
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting 
              ? (isEditMode ? '수정 중...' : '추가 중...') 
              : (isEditMode ? '수정하기' : '추가하기')}
          </button>

          {/* 삭제 버튼 (수정 모드 & 본인 프로필이 아닐 때만) */}
          {isEditMode && !isSelfProfile && (
            <button 
              type="button"
              className="delete-button"
              onClick={handleDelete}
            >
              프로필 삭제하기
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default ProfileEditPage;