import { classImages } from './images';


export const sajuClassData = {
    common: [
      {
        id: 'f1',
        name: '상관격',
        shortDescription: '창의적인 아이디어 뱅크',
        fullDescription: '상관격은 양손에 서로 다른 물건을 들고 독창적인 결과물을 만들어내는 창의적 혁신가 같아요. 다재다능하며, 기존의 틀을 넘어 새로운 아이디어와 가능성을 끊임없이 발견해내는 능력을 가지고 있어요. 변화에 누구보다 민감해, 마치 안테나를 세운 동물처럼 세상의 흐름을 빠르게 읽고 대응하죠. 이러한 능력은 주변 환경을 재구성하고, 변화 속에서 새로운 기회를 만들어내는 데 빛을 발합니다. 자신의 재능을 긍정적으로 활용하고 내면의 균형을 유지한다면, 상관격은 세상에 큰 영향을 미칠 잠재력을 지니고 있어요.',
        images: classImages.sangkwan,
        careers: [
          '영화 감독',
          '장사꾼',
          '트렌드세터',
          '무역업자',
          '광고/브랜딩 디렉터',
          'UX/UI 디자이너',
          '스타트업 창업자',
          '패션 디자이너',
          '콘텐츠 크리에이터/유튜버'
        ],
        strengths: [
          '혁신을 주도하는 걸 좋아하고, 틀을 깨는 새로운 아이디어를 만들어내는 데 능해요.',
          '얽매임 없이 자유롭게 사고하고 행동하는 게 강점이에요.',
          '규칙을 상황에 맞게 잘 응용하는 응용력이 뛰어나요.'
        ],
        weaknesses: [
          '기분에 따라 행동하고, 감정적으로 불안정할 때가 많아요.',
          '과도한 허세나 거짓말로 자신을 포장하는 경향이 있어요.',
          '스스로를 통제하지 못해 충동적으로 행동하기도 해요.'
        ],
        angerTriggers: [
          '주변에서 자신의 능력을 인정하지 않거나 관심을 주지 않을 때 화가 나요.',
          '"SNS형 인간"처럼 외적인 평가에 민감하고 허언증 같다는 말도 상처가 돼요.',
          '분위기를 못 읽는 사람들을 보면 답답해질 때가 있어요.'
        ],
        compliments: [
          '넌 항상 상황에 맞게 잘 적응하는 것 같아.',
          '네가 내놓는 아이디어는 진짜 독창적이야.',
          '너는 이런 상황에서도 능력을 발휘하니까 대단해.'
        ],
        motivations: [
          '지금 조금 힘들어도 이 노력은 분명 보상받을 거야.',
          '지금의 네 모습이 미래를 바꿔줄 거라고 믿어.',
          '이 정도 고생이면 나중에 크게 웃을 수 있을 거야.'
        ],
        consumptionStyle: '상관격은 유행과 트렌드에 민감하고, 타인의 시선을 끌 수 있는 디자인 중심의 소비를 좋아해요. 실용성보다는 자신을 돋보이게 해주는 외적인 요소를 더 중시해요. 투자할 때는 시세 차익을 기대하며 변화 가능성이 큰 분야에 도전하는 경향이 있어요.',
        loveStyle: [
          '내 모든 걸 알려주긴 싫지만, 상대의 모든 것을 알고 싶어요.',
          '상대방이 자기 일을 잘하는 전문가였으면 좋겠지만, 다양한 경험을 했으면 좋겠어요.',
          '상대가 말 잘하고 웃겼으면 좋겠지만, 진지한 사람이었으면 좋겠어요.'
        ]
      },
      {
        id: 'f2',
        name: '편관격',
        shortDescription: '단호박 카리스마',
        fullDescription: '편관격은 좋고 싫음이 분명하고, 명확한 기준으로 세상을 바라보는 사람이에요. 마치 자신이 속한 조직의 질서를 바로잡아야 한다는 책임감을 가지고 행동하는 모습이 돋보여요. 이런 성향 덕분에 주변에서 신뢰받는 경우가 많고, 상황을 객관적으로 판단하는 데 능숙해요. 스스로에 대한 확고한 기준과 타인에게도 동일한 기대를 가지는 모습을 보이지만, 이를 통해 관계를 명확히 하고 조화롭게 만들어갈 수 있어요. 특히 애매한 관계를 싫어해 처음 만난 사람과도 선명한 소통을 지향하는 점이 특징이에요.',
        images: classImages.pyungkwan,
        careers: [
          '의사',
          '변호사/판사',
          '군 고위직/경찰 간부',
          '기업 임원/CEO',
          '외교관',
          '투자 분석가/펀드 매니저'
        ],
        strengths: [
          '책임감이 강하고 조직의 이익을 개인보다 우선해요.',
          '솔선수범하며 카리스마로 사람들을 이끌 줄 알아요.',
          '위험 요소를 잘 찾아내고 미리 대처하는 능력이 있어요.'
        ],
        weaknesses: [
          '네 편, 내 편을 명확히 구분해서 인간관계가 좁아질 때가 많아요.',
          '과도한 책임감으로 스스로를 지나치게 몰아붙이기도 해요.',
          '욱하는 성격으로 순간적으로 감정적인 반응을 보이기도 해요.'
        ],
        angerTriggers: [
          '갑질을 당하거나 부당한 대우를 받을 때 화가 나요.',
          '"너나 잘하세요" 같은 비아냥을 들으면 쉽게 욱해요.',
          '사람들 사이에서 편 가르기를 당하거나 자신이 배제될 때 화를 참기 어려워요.'
        ],
        compliments: [
          '네가 있어서 조직이 잘 돌아가는 것 같아.',
          '너의 책임감과 리더십은 정말 최고야.',
          '이 역할은 너 아니면 못했을 거야.'
        ],
        motivations: [
          '네가 알아보는 눈은 정말 뛰어난 것 같아.',
          '이런 상황에서도 딱 중요한 걸 집어내는 능력이 대단해.',
          '너라면 특별한 걸 꼭 찾아낼 거라고 믿어.'
        ],
        consumptionStyle: '편관격은 한정판이나 희소성이 있는 물건에 끌려요. 많은 사람이 가질 수 없는 물건이나 고급스러운 회원제 상품을 선호하고, 자신만의 차별화를 위해 투자하는 걸 좋아해요.',
        loveStyle: [
          '내가 상대를 좋아하지만, 상대보다 더 많이 좋아하기는 싫어요.',
          '상대가 나에게 많은 관심을 줬으면 좋겠지만, 나는 자유로웠으면 좋겠어요.',
          '상대가 독립적으로 알아서 했으면 좋겠지만, 내 말을 잘 들었으면 좋겠어요.'
        ]
      },
      {
        id: 'f3',
        name: '양인격',
        shortDescription: '부조리와 싸우는 정의로운 투사',
        fullDescription: '양인격은 일상 속 부조리나 억울한 상황을 외면하지 않는 정의로운 성격이에요. 사회적으로 불합리한 문제를 보면 강한 책임감을 느끼고, 국민청원이나 민원을 통해 적극적으로 해결하려는 행동력을 보여줘요. 스스로의 신념이 확고해 독립적인 결단을 내리는 경우가 많지만, 때로는 주변 사람들의 조언과 도움을 받아들이는 것도 큰 힘이 될 수 있어요. 자신을 지키는 방법을 배우고 내면의 균형을 유지한다면, 세상에 더 큰 변화를 만들어내는 원동력이 될 수 있는 사람입니다.',
        images: classImages.yangin,
        careers: [
          '장군',
          '저널리스트',
          '환경운동가/NGO 리더',
          '탐사 보도 기자',
          '정치인',
          '공익 변호사',
          '사회 혁신가'
        ],
        strengths: [
          '자신과 조직을 위험으로부터 지키려는 의지가 강해요.',
          '사회적 가치를 개인의 이익보다 우선하며 행동해요.',
          '부조리한 문제를 싫어하고 약자를 배려하는 마음을 가지고 있어요.'
        ],
        weaknesses: [
          '도움을 받지 않고 혼자 모든 걸 해결하려고 하다가 더 어려운 상황에 빠질 때가 있어요.',
          '화를 내며 문제가 해결될 거라고 믿는 착각에 빠지기도 해요.',
          '강자에게 약하고 약자에게 강한 모습이 보일 때도 있어요.'
        ],
        angerTriggers: [
          '부조리한 상황에서 무력감을 느낄 때 참을 수 없어요.',
          '강약약강처럼 보이는 태도나 행동을 비난받을 때 화가 나요.',
          '자신의 분노를 잘못 이해하거나 무시하는 사람들에게 답답함을 느껴요.'
        ],
        compliments: [
          '너처럼 헌신적으로 행동하는 사람은 정말 귀해.',
          '너의 노력 덕분에 많은 사람들이 좋아졌을 거야.',
          '이런 일을 하면서도 사람들을 먼저 생각하는 너는 정말 멋져.'
        ],
        motivations: [
          '너라면 모두가 만족할 만한 해결책을 만들어낼 수 있을 거야.',
          '네가 하는 행동 하나가 조직에 큰 변화를 줄 거야.',
          '지금까지 해온 노력들이 정말 큰 결과를 가져올 거야.'
        ],
        consumptionStyle: '양인격은 남들과 비교당하지 않으려고 고급 브랜드 제품을 선호해요. 하지만 브랜드 가치가 떨어지면 가차 없이 처분하는 냉정함도 가지고 있어요.',
        loveStyle: [
          '상대가 어디서 맞고 다니지 않아야 하지만, 나한테는 당해도 돼요.',
          '상대에게 친구가 많았으면 좋겠지만, 친구보다 나와 시간을 많이 보냈으면 좋겠어요.',
          '상대가 인기가 많았으면 좋겠지만, 사람들이 안 꼬였으면 좋겠어요.'
        ]
      },
      {
        id: 'f4',
        name: '건록격',
        shortDescription: '세상을 변화시키는 연설가',
        fullDescription: '건록격은 정의감이 강해 부도덕한 일을 지나치지 못하는 성격이에요. 말과 글을 통해 문제를 해결하려는 능력이 뛰어나며, 자신의 의견을 명확하고 당당하게 표현하는 모습을 보여요. 주변 사람들을 설득하거나 새로운 시각을 제시하는 데 탁월한 재능을 지녔어요. 이러한 능력을 더욱 발전시켜 세상을 긍정적으로 변화시키는 데 활용한다면 큰 영향을 미칠 수 있을 거예요. 또한, 자신의 말이 다른 사람들에게 미치는 영향을 깊이 생각하며 공감력을 더한다면 더욱 빛나는 존재가 될 수 있어요.',
        images: classImages.gunrok,
        careers: [
          '사모펀드 대표',
          '강연가/동기부여 연사',
          '정치 캠페인 매니저',
          '작가/칼럼니스트',
          '방송인/토크쇼 호스트',
          'PR 전문가'
        ],
        strengths: [
          '자신의 이익보다 사회적 가치를 우선하는 헌신적인 성향을 가지고 있어요.',
          '품격 있는 언행을 좋아하며, 부도덕한 일을 싫어해요.',
          '말을 통해 사람들과 소통하며 배우는 걸 좋아해요.'
        ],
        weaknesses: [
          '신분적 위치를 강조하며 태생적 차별을 하는 경우가 있어요.',
          '스스로 문제를 만들면서 다른 사람의 빈축을 사기도 해요.',
          '입으로는 강하지만 행동이 따르지 않아 믿음을 잃을 때가 있어요.'
        ],
        angerTriggers: [
          '자신이 아싸처럼 취급받거나 무시당할 때 참을 수 없어요.',
          '온라인에서 악플을 받거나 자신의 의견이 공격당할 때 화가 나요.',
          '자신의 말이 다른 사람들에게 상처를 줬다는 지적을 받을 때 마음이 불편해요.'
        ],
        compliments: [
          '너처럼 사회를 위해 고민하는 사람은 많지 않아.',
          '너의 말이 많은 사람들에게 긍정적인 영향을 줬을 거야.',
          '네가 주변 사람들을 생각하며 행동하는 모습은 정말 대단해.'
        ],
        motivations: [
          '지금 준비한 것들을 세상에 보여줄 때가 된 것 같아.',
          '너라면 이 문제를 해결할 수 있을 거라고 믿어.',
          '이제 너만의 방식으로 세상을 변화시켜봐.'
        ],
        consumptionStyle: '건록격은 주변 사람들의 행동을 따라가는 경향이 강해요. 특히 브랜드 가치가 높은 제품이나 명확한 용도가 있는 물건에 돈을 쓰지만, 가끔씩 낭비를 하기도 해요.',
        loveStyle: [
          '상대의 품격이 우월했으면 좋겠지만, 내 앞에서는 순한 양이 됐으면 좋겠어요.',
          '모두에게 부러움을 받는 커플이고 싶지만, 관심은 받고 싶지 않아요.',
          '상대가 우아하게 말을 했으면 좋겠지만, 나는 상대에게 막말을 할 수 있어야 해요.'
        ]
      },
      {
        id: 'f5',
        name: '식신격',
        shortDescription: '목표 지향적인 실천가',
        fullDescription: '식신격은 한 가지를 깊이 탐구하고 끝까지 몰두하는 성실한 성격이에요. 자신이 맡은 일에 대한 책임감이 매우 강해, 마치 마라톤 선수처럼 꾸준히 목표를 향해 나아가는 모습이 돋보여요. 이러한 책임감은 주변에 신뢰를 주는 힘이 되기도 해요. 자신의 능력에 대한 자부심이 높아 스스로를 믿고 나아가지만, 이를 바탕으로 주변 사람들과의 관계를 더 조화롭게 만들어간다면 더욱 빛나는 성과를 이룰 수 있어요.',
        images: classImages.sikshin,
        careers: [
          '장인',
          '운동선수',
          '데이터 과학자',
          '연구원/과학자',
          '건축가',
          '회계사/세무사',
          'IT 엔지니어'
        ],
        strengths: [
          '맡은 일에 책임감이 강하고 끝까지 완수하려고 노력해요.',
          '지식이나 사물을 깊이 연구하며 배우는 걸 즐겨요.',
          '게으른 행동을 싫어하며 부지런하게 움직여요.'
        ],
        weaknesses: [
          '쓸데없는 일에 지나치게 집중하며 낭비가 생길 때가 있어요.',
          '자신이 하고 싶은 일만 몰두하면서 주변과 단절되기도 해요.',
          '부지런한 척하며 정작 본질적인 성과가 없을 때도 있어요.'
        ],
        angerTriggers: [
          '자신의 능력을 무시당하거나 평가절하당할 때 화가 나요.',
          '게으른 사람들과 함께 일할 때 참을 수 없어요.',
          '자신이 중요하게 여기는 가치를 인정받지 못하면 속상해요.'
        ],
        compliments: [
          '네가 이런 걸 잘 해내는 걸 보면 정말 멋져.',
          '너 없었으면 이 일이 어떻게 됐을지 상상이 안 돼.',
          '네가 하는 일은 정말 중요한 역할을 해.'
        ],
        motivations: [
          '더 큰 시장에서 네 능력을 보여줄 기회를 잡아봐.',
          '이 일을 통해 한 단계 더 성장할 수 있을 거야.',
          '너라면 지금보다 더 큰 일을 할 수 있을 것 같아.'
        ],
        consumptionStyle: '식신격은 꼭 필요한 곳에만 돈을 쓰는 실용적인 소비를 선호해요. 하지만 미래에 대한 불안감을 줄이기 위해 건강, 노후 대비와 같은 영역에는 과감하게 투자하는 편이에요.',
        loveStyle: [
          '상대는 나의 모든 잘못을 용서해야 하지만, 나는 상대의 잘못을 지적할 수 있어야 해요.',
          '상대가 자기계발을 위해 시간을 투자했으면 좋겠지만, 나랑 놀 시간도 많아야 해요.',
          '내가 놀 때는 방해하면 안 되지만, 상대가 놀 때는 보고를 잘해야 해요.'
        ]
      },
      {
        id: 'f6',
        name: '정인격',
        shortDescription: '걸어다니는 백과사전',
        fullDescription: '정인격은 자신이 배운 지식을 다른 사람들과 나누는 것을 즐기는 성격이에요. 묻지 않아도 자신만의 생각과 이야기를 활발히 공유하며, 창의적인 관점으로 세상을 바라보는 능력이 돋보여요. 마치 살아 있는 백과사전처럼 풍부한 지식으로 주변에 도움을 주는 경우가 많아요. 자신의 주장을 강하게 펼치며 확신을 보여주지만, 다양한 의견을 함께 수용한다면 더욱 조화롭고 큰 성과를 이룰 수 있는 잠재력을 가지고 있어요.',
        images: classImages.jungin,
        careers: [
          '교수',
          '건물주',
          '교육 컨설턴트',
          '출판 편집자',
          '기술 분석가',
          '통계학자/경제학자',
          '심리학자'
        ],
        strengths: [
          '다양한 분야에서 객관적인 지식을 습득하며 배우는 걸 좋아해요.',
          '맡은 일을 정확히 완수하며 성실한 태도를 보여줘요.',
          '주변 환경에 적응을 잘하며 필요한 역할을 수행할 줄 알아요.'
        ],
        weaknesses: [
          '생각은 많지만 행동으로 이어지지 않는 경우가 많아요.',
          '자신의 의견이 항상 옳다고 믿으며, 타인의 의견을 무시할 때가 있어요.',
          '자신의 행동에 관대하면서도 타인에게는 엄격한 모습을 보일 때가 있어요.'
        ],
        angerTriggers: [
          '"근거없는 이야기 아니야?"라는 비난을 받을 때 화가 나요.',
          '고지식하거나 내로남불이라는 말을 들을 때 상처받아요.',
          '자신의 지식이나 능력을 인정받지 못하는 상황을 참기 어려워요.'
        ],
        compliments: [
          '넌 정말 모르는 게 없는 것 같아.',
          '어떤 상황에서도 정확한 정보를 제시하니 믿음이 가.',
          '너처럼 똑똑한 사람은 정말 보기 드물어.'
        ],
        motivations: [
          '네가 가진 능력이라면 이 일을 잘 해결할 수 있을 거야.',
          '넌 정말 일을 체계적으로 처리하는 것 같아.',
          '어떤 문제도 너라면 해결할 수 있을 거라고 믿어.'
        ],
        consumptionStyle: '정인격은 외적인 치장에는 관심이 없지만, 단정하고 깔끔한 이미지를 유지하는 데는 신경을 써요. 필요 이상의 소비를 하지 않으려 하면서도 실용적인 물건에는 기꺼이 투자하는 편이에요.',
        loveStyle: [
          '내가 좋아해서 잘해주고 싶지만, 먼저 말 걸어주기 전까지는 잘해주기 싫어요.',
          '내 말은 잘 들었으면 좋겠지만, 알아서 했으면 좋겠어요.',
          '상대가 원하는 것을 해주고 싶지만, 그게 내가 좋아하는 거였으면 좋겠어요.'
        ]
      },
      {
        id: 'f7',
        name: '편인격',
        shortDescription: '지적 세계를 탐구하는 여행가',
        fullDescription: '편인격은 상상력과 감수성이 풍부해 세상의 다양한 일에 깊이 공감하는 성격이에요. 끊임없이 이어지는 생각은 때로는 예상치 못한 결론으로 이어지지만, 그 안에는 독창적이고 창의적인 아이디어가 가득해요. 지적 활동에 열정을 쏟으며, 자신만의 신념과 사상을 깊이 탐구하는 모습이 돋보여요. 또한, 개인적인 취미와 관심사에 많은 시간을 투자하며 이를 통해 자신을 성장시키고 풍부한 내면세계를 만들어갑니다.',
        images: classImages.pyungin,
        careers: [
          '전략 컨설턴트',
          '예술가',
          '작가',
          '아트 디렉터',
          '게임 디자이너',
          '음악 작곡가'
        ],
        strengths: [
          '다양한 아이디어를 구체화하며 창의적 결과물을 만들어내요.',
          '뛰어난 공감 능력을 바탕으로 따뜻한 마음으로 사람들을 돕는 성향이 강해요.',
          '지식의 폭을 넓히는 데 제한이 없고, 열린 사고를 지니고 있어요.'
        ],
        weaknesses: [
          '자신의 생각이 옳다고 주장하며 고집을 부리는 경우가 많아요.',
          '지나치게 자신의 감정을 호소해 타인을 피곤하게 할 때가 있어요.',
          '없는 사실을 만들어내거나 과장해 이야기할 때 오해를 사기도 해요.'
        ],
        angerTriggers: [
          '"오타쿠 같다"는 평가를 받거나 엉뚱한 상상력을 무시당할 때 속상해요.',
          '"그게 뭐야? 말도 안 돼" 같은 말을 들으면 화가 나요.',
          '자신의 4차원적인 성향을 조롱하거나 무시하는 태도를 참기 어려워요.'
        ],
        compliments: [
          '네가 이런 아이디어를 내다니 정말 대단해.',
          '항상 다른 사람들을 생각하며 행동하는 모습이 멋져.',
          '너처럼 창의적인 생각을 가진 사람은 진짜 귀해.'
        ],
        motivations: [
          '네가 원하는 대로 해도 충분히 좋은 결과가 나올 거야.',
          '너만의 창의력을 더 살려볼 수 있는 기회인 것 같아.',
          '네가 하고 싶은 대로 하면 멋진 결과가 나올 거라고 믿어.'
        ],
        consumptionStyle: '편인격은 특이하고 개성적인 물건에 관심이 많아요. 개인 취향을 저격하는 물건이나 유행을 타지 않는 복고풍 스타일에도 아낌없이 투자하는 성향을 보여요.',
        loveStyle: [
          '사랑하기에 모든 것이 용서되지만, 내 마음에 들어야 해요.',
          '조건 없이 사랑을 주고 싶지만, 사랑을 돌려받고 싶어요.',
          '관심 받는 게 너무 좋지만, 내 사생활에는 관여 안 했으면 좋겠어요.'
        ]
      },
      {
        id: 'f8',
        name: '정재격',
        shortDescription: '안정된 삶을 추구하는 실용주의자',
        fullDescription: '정재격은 안정된 삶과 실용적인 가치를 가장 중요하게 생각하는 현실적인 성격이에요. 불필요한 낭비를 줄이고 효율적으로 자원을 활용하며, 꼭 필요한 부분에 집중하는 모습을 보여줘요. 필요 없는 부분은 과감히 정리할 줄 알지만, 진심으로 소중히 여기는 사람들에게는 아낌없이 베푸는 따뜻한 면도 지니고 있어요. 안정적인 현재를 유지하려는 신중함은 위험을 최소화하고 삶을 안정적으로 이끌어가는 원동력이 될 수 있어요.',
        images: classImages.jungjae,
        careers: [
          '최고 재무 책임자(CFO)',
          '부동산 개발자',
          '금융 컨설턴트',
          'HR 관리자',
          '공기업 고위직',
          '안전 엔지니어'
        ],
        strengths: [
          '안정적인 삶을 위해 신중하게 행동하며, 실용성을 중시해요.',
          '경제적인 선택과 효율적인 관리 능력이 뛰어나요.',
          '자신의 삶의 균형을 잘 유지하려고 노력해요.'
        ],
        weaknesses: [
          '지나친 절약으로 인해 구두쇠처럼 보일 때가 있어요.',
          '책임을 회피하거나 어려운 상황에서 물러서는 경향이 있어요.',
          '자신만의 이익을 우선시해 이기적이라는 평가를 받을 때가 있어요.'
        ],
        angerTriggers: [
          '"너는 이기적이야."라는 말을 들으면 쉽게 속상해해요.',
          '위험 부담이 큰 행동이나 낭비를 강요받는 상황은 참을 수 없어요.',
          '안정된 삶을 흔드는 변화를 겪게 되면 큰 스트레스를 받아요.'
        ],
        compliments: [
          '지금처럼 꾸준히 해나가면 정말 좋은 결과가 나올 거야.',
          '너는 항상 안정적으로 상황을 잘 관리해.',
          '이 정도면 누구나 너처럼 하고 싶어 할 거야.'
        ],
        motivations: [
          '지금 하는 일이 너를 더 성장시킬 거야.',
          '이 변화가 너에게 좋은 결과를 가져다줄 거라고 믿어.',
          '너라면 안정 속에서 혁신을 만들어낼 수 있을 거야.'
        ],
        consumptionStyle: '정재격은 실용성과 경제성을 가장 우선하며, 가정에 도움이 되거나 가족 모두가 공유할 수 있는 물건에 돈을 써요. 투자도 현실적인 이익이 보장되는 분야에 집중하는 편이에요.',
        loveStyle: [
          '상대가 가정적이고 다정했으면 좋겠지만, 나는 개인 시간을 보내고 싶어요.',
          '내가 알아서 잘하니 잔소리는 안 했으면 좋겠지만, 사소한 것을 나누며 대화하고 싶어요.',
          '상대가 헛돈 안 쓰는 검소한 삶을 살았으면 좋겠지만, 내 소비에 관해 지적은 안 했으면 좋겠어요.'
        ]
      },
      {
        id: 'f9',
        name: '편재격',
        shortDescription: '매력 넘치는 사교 전문가',
        fullDescription: '편재격은 다양한 사람들과 관계를 맺으며 서로에게 이익을 가져다주는 능력을 지닌 성격이에요. 활동적이고 사교적인 성향 덕분에 주변을 밝히고 타인의 주목을 자연스럽게 받는 매력이 있어요. 많은 사람들에게 베풀 줄 아는 넓은 마음을 가지고 있으며, 관계를 중요하게 생각해 세심한 배려를 보여줘요. 화려한 언변과 매력적인 모습으로 분위기를 이끌며, 신뢰를 쌓아간다면 더욱 큰 성과를 만들어낼 수 있어요.',
        images: classImages.pyungjae,
        careers: [
          '유튜버',
          '인플루언서',
          '연예인/모델',
          '비즈니스 디벨로퍼',
          '이벤트 플래너',
          'VIP 컨시어지',
          '국제 무역 전문가'
        ],
        strengths: [
          '새로운 분야를 개척하며 진취적인 태도를 보여줘요.',
          '사람들에게 베풀기를 좋아하고, 인간관계에서 활발하게 활동해요.',
          '파트너와 함께 성장하며 협력하는 걸 중요하게 생각해요.'
        ],
        weaknesses: [
          '외적인 모습에 지나치게 집착하며 과시적인 태도를 보일 때가 있어요.',
          '허세성 소비로 인해 재정적 부담을 겪을 수 있어요.',
          '지나친 오지랖으로 신뢰를 잃거나 갈등을 일으킬 수 있어요.'
        ],
        angerTriggers: [
          '"너는 믿음이 안 가."라는 말을 들으면 속상해져요.',
          '자신의 오지랖을 지적받거나 허세를 조롱당할 때 화가 나요.',
          '다른 사람이 자신을 과소평가하거나 무시할 때 참기 어려워요.'
        ],
        compliments: [
          '네 덕분에 사람들과 더 잘 어울리게 된 것 같아.',
          '너처럼 진취적으로 나아가는 사람은 보기 힘들어.',
          '너의 사교성과 리더십은 정말 대단해.'
        ],
        motivations: [
          '네가 지금 하고 있는 건 정말 모범적인 모습이야.',
          '도전하면서도 균형을 잘 맞추는 게 멋져.',
          '너라면 이 도전을 성공적으로 해낼 수 있을 거야.'
        ],
        consumptionStyle: '편재격은 현재의 소비를 미래를 위한 투자로 생각하며, 인간관계 유지와 직업적 위신을 위해 허세성 지출도 기꺼이 해요. 하지만 배신에 대한 두려움 때문에 소비를 조심할 때도 있어요.',
        loveStyle: [
          '상대가 어디서 꿀리지 않았으면 좋겠지만, 나대는 것은 싫어요.',
          '상대가 나에게 매달렸으면 좋겠지만, 너무 질척대는 것은 싫어요.',
          '상대가 자기관리를 잘해서 나를 빛내 줬으면 좋겠지만, 나보다 빛나면 안 돼요.'
        ]
      },
      {
        id: 'f10',
        name: '정관격',
        shortDescription: '신뢰받는 원칙주의자',
        fullDescription: '정관격은 원리원칙을 중시하며, 도덕적이고 모범적인 태도로 행동하는 성격이에요. 규칙과 질서를 소중히 여기며, 자신뿐만 아니라 주변 사람들과의 협동을 통해 조화로운 환경을 만들어가요. 책임감과 신뢰를 바탕으로 조직의 안정성을 높이는 데 큰 역할을 하며, 그들의 기준과 원칙은 목표를 꾸준히 이루는 데 중요한 힘이 됩니다. 융통성을 더하면 더욱 다채롭고 유연한 리더십을 발휘할 수 있어요.',
        images: classImages.junggwan,
        careers: [
          '기업 총괄 운영 관리자',
          '외교관',
          '법률 고문',
          '인사 팀장',
          '품질 관리 전문가'
        ],
        strengths: [
          '개인보다 조직의 이익을 우선하며, 협력과 협동을 중요시해요.',
          '규칙과 도덕성을 강조하며 안정감을 제공해요.',
          '맡은 일에 헌신적으로 임하며 책임감이 강해요.'
        ],
        weaknesses: [
          '모든 걸 자신의 기준에 맞추려다 보니 융통성이 부족해요.',
          '소수를 배려하지 않거나, 자신의 주장을 지나치게 내세우는 경향이 있어요.',
          '작은 일은 하지 않으려 하며, 과도하게 높은 위치만 선호해요.'
        ],
        angerTriggers: [
          '"꼰대 같다"는 말을 들을 때 화가 나요.',
          '자신의 원칙이 무시되거나 주변에서 규칙을 어길 때 답답함을 느껴요.',
          '조직의 질서를 깨뜨리는 사람들을 보면 화가 치밀어 올라요.'
        ],
        compliments: [
          '너처럼 성실하게 일하는 사람이 조직의 큰 힘이 돼.',
          '항상 맡은 일을 끝까지 잘 해내는 모습이 멋져.',
          '네 덕분에 조직이 더 안정적으로 돌아가는 것 같아.'
        ],
        motivations: [
          '네가 알아보는 눈은 정말 뛰어난 것 같아.',
          '이런 상황에서도 딱 중요한 걸 집어내는 능력이 대단해.',
          '너라면 특별한 걸 꼭 찾아낼 거라고 믿어.'
        ],
        consumptionStyle: '정관격은 품위 있고 안정감을 줄 수 있는 물건에 돈을 써요. 주로 튀지 않고 실용적인 선택을 하며, 안전성이 보장된 분야에만 투자하려고 해요.',
        loveStyle: [
          '상대가 존경스러운 사람이었으면 좋겠지만, 나보다 잘나면 안 돼요.',
          '나를 지켜줄 정도로 상대의 능력이 빵빵했으면 좋겠지만, 잘난 척하는 건 싫어요.',
          '밖에서는 일 잘하는 능력자였으면 좋겠지만, 내 앞에서는 순한 양이었으면 좋겠어요.'
        ]
      }
    ]
  };