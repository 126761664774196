import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { handleKakaoLogin } from '../utils/SocialAuth';
import './ClassGatePage.css';
import { sajuClassData } from '../contents/class_contents/classContentsData';

function ClassCard({ classType, gender }) {
  return (
    <div className="cg-card">
      <div className="cg-image-wrapper">
        <img 
          src={classType.images[gender].thumbnail}
          alt={classType.name} 
          className="cg-card-image"
        />
      </div>
      <h3 className="cg-card-name">{classType.name}</h3>
      <p className="cg-card-description">{classType.shortDescription}</p>
    </div>
  );
}

function ClassGatePage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const invitation = searchParams.get('invitation');
    if (invitation) {
      localStorage.setItem('referral_code', invitation);
    }
  }, [searchParams]);

  const getRandomClasses = () => {
    const allClasses = [...sajuClassData.common];
    const selected = [];
    const usedNames = new Set();
    
    // 여성 이미지 2개 선택
    while (selected.length < 2 && allClasses.length > 0) {
      const randomIndex = Math.floor(Math.random() * allClasses.length);
      const classType = allClasses[randomIndex];
      
      if (!usedNames.has(classType.name)) {
        selected.push({ ...classType, gender: 'female' });
        usedNames.add(classType.name);
        allClasses.splice(randomIndex, 1);
      }
    }
    
    // 남성 이미지 2개 선택
    while (selected.length < 4 && allClasses.length > 0) {
      const randomIndex = Math.floor(Math.random() * allClasses.length);
      const classType = allClasses[randomIndex];
      
      if (!usedNames.has(classType.name)) {
        selected.push({ ...classType, gender: 'male' });
        usedNames.add(classType.name);
        allClasses.splice(randomIndex, 1);
      }
    }

    // 배열 순서 랜덤화
    return selected.sort(() => Math.random() - 0.5);
  };

  const randomClasses = getRandomClasses();

  const handleTestButtonClick = async () => {
    if (isAuthenticated) {
      navigate('/class-router');
    } else {
      try {
        setIsLoading(true);
        localStorage.setItem('intended_redirect_url', '/class-router');
        await handleKakaoLogin();
      } catch (error) {
        console.error('Kakao login failed:', error);
      }
    }
  };

  return (
    <div className="cg-container">
      <section className="cg-intro">
        <h1 className="cg-main-title">나의 사주격 알아보기</h1>
        <p className="cg-intro-subtitle">
          당신의 타고난 기질과 성향을 알려주는 사주격을 확인해보세요
        </p>
        
        <div className="cg-examples">
          <div className="cg-cards-grid">
            {randomClasses.map((classType) => (
              <ClassCard 
                key={classType.id} 
                classType={classType}
                gender={classType.gender}
              />
            ))}
          </div>
        </div>

        <div className="cg-content-section">
          <h2 className="cg-section-title">🌟 격국이란 무엇인가요?</h2>
          <p className="cg-section-text">
            격국은 한 사람이 조직을 책임지고 사회 구성원으로서 살아가야 되는 것에 대한 이론을 의미해요. 
            단순한 관계성이 아닌, 사회적 역할과 포지션을 정의하며 그 사람의 역할을 결정하는 것이죠. 
            격국은 우리의 운명과 성공 가능성을 이해하는 데 매우 중요한 개념이에요. 마치 우리가 사회에서 
            맡은 역할처럼, 우리의 삶의 방향과 성공 전략을 제시해주는 나침반 같은 역할을 한답니다.
          </p>
        </div>

        <div className="cg-content-section">
          <h2 className="cg-section-title">🧭 격국은 어떻게 결정되나요?</h2>
          <p className="cg-section-text">
            격국은 월지와 일간의 관계를 면밀히 살펴보며 결정돼요. 월지는 그 사람의 무의식적인 성향과 
            사회적 역할을 나타내는데, 이는 마치 하늘에 떠있는 달이 주변의 별들과 조화를 이루며 빛나듯이 
            타인과의 관계 속에서 발현되는 영향력을 의미해요. 또한 월지는 그 사람이 가진 잠재적 재능과 
            사회적 위치를 보여주는 중요한 지표이며, 이는 마치 하늘에 뜬 달처럼 자연스럽게 타인의 주목을 
            받는 힘과도 깊은 연관이 있답니다.
          </p>
        </div>

        <div className="cg-content-section">
          <h2 className="cg-section-title">🌱 격국으로 알 수 있는 것</h2>
          <h3 className="cg-section-subtitle">사회적 성공 방식과 패턴</h3>
          <p className="cg-section-text">
            격국을 통해 그 사람이 사회에서 어떻게 성공할 수 있는지, 어떤 방식으로 살아가는 것이 좋은지 
            알 수 있어요. 예를 들어, 식신격이라면 전문성을 바탕으로 한 성공이, 상관격이라면 창의력을 통한 
            성공이 유리해요. 또한 대인관계의 패턴과 특성을 심층적으로 분석할 수 있으며, 재물운과 금전적 성향, 
            그리고 부의 축적 방식까지 파악할 수 있답니다. 더불어 개인의 성공을 위한 최적의 전략과 시기, 
            그리고 잠재된 기회를 활용하는 방법까지 상세하게 이해할 수 있어요.
          </p>
        </div>

        <div className="cg-content-section">
          <h2 className="cg-section-title">🔮 격국을 통한 성공 전략</h2>
          <h3 className="cg-section-subtitle">나만의 성공 방정식</h3>
          <p className="cg-section-text">
            격국은 개인이 조직과 사회에서 어떻게 책임을 지고 살아가야 하는지를 보여주는 이론이에요. 
            이는 단순한 관계가 아닌 사회적 역할과 포지션을 정의하며, 그 사람의 운명과 성공 가능성을 
            이해하는 데 중요한 지표가 되죠. 격국은 월지와 일간의 관계를 통해 결정되며, 이는 그 사람의 
            무의식적 성향과 사회적 역할, 잠재적 재능을 나타내요.
          </p>
        </div>

        <div className="cg-content-section">
          <h2 className="cg-section-title">💡 일주와는 어떻게 다른가요?</h2>
          <h3 className="cg-section-subtitle">내면과 외면의 조화</h3>
          <p className="cg-section-text">
            일주가 타고난 성향과 내면의 모습을 보여준다면, 격국은 그 사람이 사회를 살아가는 방식과 
            패턴을 나타내요. 쉽게 말해서 일주는 '나는 어떤 사람인가?'에 대한 답을 주고, 
            격국은 '나는 어떻게 살아가는가?'에 대한 답을 준답니다.
          </p>
          <br></br>
          <p className="cg-section-text">
            같은 일주를 가진 사람이라도 격국에 따라 사회생활 방식이나 인생의 흐름이 
            전혀 다르게 나타날 수 있어요. 이는 마치 같은 기본 성격을 가진 사람이라도, 처한 환경과 
            상황에 따라 다른 방식으로 살아가는 것과 비슷해요. 일주는 우리의 본질적인 성격을, 격국은 
            그 성격이 현실에서 어떻게 발현되고 적용되는지를 보여준다고 할 수 있죠. 예를 들어, 같은 
            '정직하고 성실한' 일주를 가진 두 사람이 있다고 해도, 한 사람은 식신격으로 전문가의 길을 걸을 수 있고, 
            다른 한 사람은 상관격으로 창의적인 분야에서 성공할 수 있답니다.
          </p>
        </div>

      </section>

      <button 
        className={`cg-floating-button ${isLoading ? 'cg-loading' : ''}`}
        onClick={handleTestButtonClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="cg-spinner"></span>
        ) : (
          isAuthenticated 
            ? '나의 사주격 알아보기' 
            : '무료 테스트 해보기'
        )}
      </button>
    </div>
  );
}

export default ClassGatePage;
