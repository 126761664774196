export const FORTUNE_TYPES = {
    ALL: '2025_all_luck',
    MONEY: '2025_money_luck',
    LOVE: '2025_love_luck',
    CAREER: '2025_career_luck',
    STUDY: '2025_study_luck',
    COMING: '2025_coming_luck',
    SUMMARY: '2025_summary_luck'
  };
  
  // 표시 순서 정의
  export const FORTUNE_TYPE_ORDER = {
    [FORTUNE_TYPES.ALL]: 0,
    [FORTUNE_TYPES.MONEY]: 1,
    [FORTUNE_TYPES.LOVE]: 2,
    [FORTUNE_TYPES.CAREER]: 3,
    [FORTUNE_TYPES.STUDY]: 4,
    [FORTUNE_TYPES.COMING]: 5,
    [FORTUNE_TYPES.SUMMARY]: 6

  };
  
  // 타입 유효성 검사 함수
  export const isValidFortuneType = (type) => {
    return Object.values(FORTUNE_TYPES).includes(type);
  };