import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { handleKakaoLogin } from '../utils/SocialAuth';
import './DayPillarGatePage.css';
import { dayPillarContentsData } from '../contents/day_pillar_contents/dayPillarContentsData';

function DayPillarCard({ dayPillar }) {
  return (
    <div className="dp-card">
      <div className="dp-image-wrapper">
        <img 
          src={dayPillar.image}
          alt={dayPillar.name} 
          className="dp-card-image"
        />
      </div>
      <h3 className="dp-card-name">{dayPillar.name}</h3>
    </div>
  );
}

function DayPillarGatePage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const invitation = searchParams.get('invitation');
    if (invitation) {
      localStorage.setItem('referral_code', invitation);
    }
  }, [searchParams]);

  const getRandomDayPillars = () => {
    const allDayPillars = [...dayPillarContentsData.common];
    const selected = [];
    const usedBranches = new Set();

    while (selected.length < 4 && allDayPillars.length > 0) {
      const randomIndex = Math.floor(Math.random() * allDayPillars.length);
      const dayPillar = allDayPillars[randomIndex];
      
      const earthlyBranch = dayPillar.name.charAt(1);
      
      if (!usedBranches.has(earthlyBranch)) {
        selected.push(dayPillar);
        usedBranches.add(earthlyBranch);
        allDayPillars.splice(randomIndex, 1);
      }
    }

    return selected;
  };

  const randomDayPillars = getRandomDayPillars();

  const handleTestButtonClick = async () => {
    if (isAuthenticated) {
      navigate('/day-pillar-router');
    } else {
      try {
        setIsLoading(true);
        localStorage.setItem('intended_redirect_url', '/day-pillar-router');
        await handleKakaoLogin();
      } catch (error) {
        console.error('Kakao login failed:', error);
      }
    }
  };

  return (
    <div className="dp-container">
      <section className="dp-intro">
        <h1 className="dp-main-title">나의 일주 알아보기</h1>
        <p className="dp-intro-subtitle">
          당신의 타고난 내면의 성향과 기질을 알려주는 일주를 확인해보세요
        </p>
        
        <div className="dp-examples">
          <div className="dp-cards-grid">
            {randomDayPillars.map((dayPillar) => (
              <DayPillarCard key={dayPillar.id} dayPillar={dayPillar} />
            ))}
          </div>
        </div>

        <div className="dp-content-section">
          <h2 className="dp-section-title">🌟 일주란 무엇인가요?</h2>
          <p className="dp-section-text">
            사주팔자에서 '일주'는 태어난 날을 기준으로 한 사주의 중심을 말해요. '일'은 태어난 날을, '주'는 천간과 
            지지라는 두 가지 요소를 나타내요. 예를 들어, 태어난 날이 갑자일이라면 '갑자'가 바로 일주가 되는 거예요.
            일주를 통해서는 그 사람의 성격, 기질, 생각하는 방식, 행동 패턴 등 내면의 본질적인 특성을 볼 수 있어요. 
            마치 우리가 태어날 때부터 가지고 있는 고유한 DNA처럼, 일주는 그 사람만의 독특한 특성을 나타내죠.
          </p>
        </div>

        <div className="dp-content-section">
          <h2 className="dp-section-title">🧭 왜 일주가 중요한가요?</h2>
          <p className="dp-section-text">
            일주를 보면 그 사람이 어떤 방식으로 세상을 바라보고, 어떻게 판단하고 결정을 내리는지도 알 수 있어요. 
            예를 들어, 어떤 사람은 감정적으로 결정을 내리는 반면, 다른 사람은 논리적으로 판단하는 경향이 있는데, 
            이런 성향이 일주에 나타나요. 또한 일주는 그 사람의 타고난 재능과 약점도 보여줘요. 이를 통해 어떤 
            분야에서 더 능력을 발휘할 수 있는지, 어떤 부분을 보완해야 하는지 파악할 수 있답니다. 이는 진로 
            선택이나 자기 계발에 큰 도움이 될 수 있어요.
          </p>
        </div>

        <div className="dp-content-section">
          <h2 className="dp-section-title">🌱 일주로 알 수 있는 것</h2>
          <h3 className="dp-section-subtitle">나의 성격과 대인관계</h3>
          <p className="dp-section-text">
            일주는 사람의 성격과 행동 양식을 분석할 수 있는 중요한 단서예요. 예를 들어, 천간에 위치한 글자가 
            강하면 리더십이 강한 성격을, 지지에 위치한 글자가 온화하면 대인관계가 좋은 성격을 나타낼 수 있어요. 
            또한 일주는 나의 배우자나 가까운 인간관계의 특징도 암시해요. 이를 통해 자신과 주변 사람들을 더 잘 
            이해할 수 있어요.
          </p>
        </div>

        <div className="dp-content-section">
          <h2 className="dp-section-title">🔮 일주를 통해 삶의 방향을 잡아보세요!</h2>
          <h3 className="dp-section-subtitle">일주 해석의 활용법</h3>
          <p className="dp-section-text">
            일주는 사주풀이의 출발점이면서 동시에 삶의 방향을 제시하는 나침반이에요. 자신의 일주를 알고 나면, 
            나만의 장점과 단점을 명확히 파악할 수 있어요. 이를 기반으로 더 나은 선택을 하거나, 나와 잘 맞는 
            환경과 사람들을 찾는 데 도움을 받을 수 있어요. 사주를 처음 접하는 분이라면, 일주부터 하나씩 
            알아보는 걸 추천해요!
          </p>
        </div>

        <div className="dp-content-section">
          <h2 className="dp-section-title">💡 격국과는 어떻게 다른가요?</h2>
          <h3 className="dp-section-subtitle">일주와 격국의 차이점</h3>
          <p className="dp-section-text">
            일주와 격국은 사주에서 서로 다른 관점으로 개인을 해석하는 중요한 요소예요. 일주는 우리의 내면을 
            들여다보는 창문이라고 할 수 있어요. 우리가 어떤 성향을 가졌는지, 어떤 생각을 하는지, 그리고 어떤 
            방식으로 행동하는지와 같은 타고난 특성들을 보여주죠. 쉽게 말해서 '나는 어떤 사람인가?'라는 질문에 
            대한 답을 제시해준답니다.
          </p>
          <br></br>
          <p className="dp-section-text">
            반면에 격국은 우리가 사회 속에서 어떻게 살아가는지를 보여주는 지도 같은 거예요. 같은 성격과 특성을 
            가진 사람이라도, 격국에 따라 전혀 다른 삶의 모습을 보일 수 있어요. 예를 들어, 비슷한 성격의 두 
            사람이 있다고 해도, 한 사람은 리더로서 활발하게 활동하고, 다른 사람은 조용히 자신의 일에 몰두하는 
            삶을 살 수 있는 거죠. 결국, 일주가 우리의 본질적인 모습을 보여준다면, 격국은 그런 우리의 특성이 
            현실 세계에서 어떻게 발현되고 실현되는지를 알려주는 나침반이라고 할 수 있어요.
          </p>
        </div>

      </section>

      <button 
        className={`dp-floating-button ${isLoading ? 'dp-loading' : ''}`}
        onClick={handleTestButtonClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="dp-spinner"></span>
        ) : (
          isAuthenticated 
            ? '나의 일주 알아보기' 
            : '무료 테스트 해보기'
        )}
      </button>
    </div>
  );
}

export default DayPillarGatePage;
