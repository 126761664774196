import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { handleKakaoLogin } from '../utils/SocialAuth';
import './TodayGatePage.css';

function TodayGatePage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const invitation = searchParams.get('invitation');
    if (invitation) {
      localStorage.setItem('referral_code', invitation);
    }
  }, [searchParams]);

  const handleTestButtonClick = async () => {
    if (isAuthenticated) {
      navigate('/today-result');
    } else {
      try {
        setIsLoading(true);
        localStorage.setItem('intended_redirect_url', '/today-result');
        await handleKakaoLogin();
      } catch (error) {
        console.error('Kakao login failed:', error);
      }
    }
  };

  return (
    <div className="tg-container">
      <section className="tg-intro">
        <h1 className="tg-main-title">오늘의 운세</h1>
        <p className="tg-intro-subtitle">
          당신의 사주를 바탕으로 오늘 하루의 운세를 알려드립니다
        </p>

        <div className="tg-content-section">
          <h2 className="tg-section-title">✨ 오늘 나의 운세는?</h2>
          <p className="tg-section-text">
            오늘의 운세는 사주팔자와 오늘의 날짜가 만나 만들어내는 특별한 기운을 해석한 결과입니다. 
            사주의 기본 틀인 원국과 함께, 일간(日干)을 중심으로 오늘의 천간(天干)과 지지(地支)가 어떻게 
            상호작용하는지를 분석해요. 여기에 절기(節氣)와 계절의 기운까지 고려하여, 
            그날의 운세를 상세하게 풀이합니다. 이를 통해 오늘 하루 어떤 기회가 찾아올지, 
            어떤 점을 조심해야 할지 알 수 있답니다.
          </p>
          <div className="tg-image-wrapper">
            <img 
              src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Crystal%20Ball.png"
              alt="오늘의 운세" 
              className="tg-main-image"
            />
          </div>
        </div>
      </section>

      <button 
        className={`tg-floating-button ${isLoading ? 'tg-loading' : ''}`}
        onClick={handleTestButtonClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="tg-spinner"></span>
        ) : (
          isAuthenticated 
            ? '오늘의 운세 보기' 
            : '무료로 오늘의 운세 보기'
        )}
      </button>
    </div>
  );
}

export default TodayGatePage;