import React from 'react';
import ChatBot from 'react-simple-chatbot';

class CustomChatBot extends ChatBot {
  constructor(props) {
    super(props);
  }

  // onNodeInserted 메서드 오버라이드
  onNodeInserted = event => {
    // 자동 스크롤 동작 완전히 비활성화
    // 스크롤은 NewYearPage의 scrollToBottom과 scrollDownByPixels가 처리
  };
}

// forwardRef로 감싸서 ref 전달
export default React.forwardRef((props, ref) => (
  <CustomChatBot ref={ref} {...props} />
));