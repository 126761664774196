import React, {useEffect, useState, useCallback} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './LandingPage.css';
import './NewYearGatePage.css'; //팝업창 디자인만 여기서 임포트함
import { useInView } from 'react-intersection-observer';
import { useAuth } from '../contexts/AuthContext';

import miriTalkImage1 from '../images/miri_talk1.jpg'
import miriTalkImage2 from '../images/miri_talk2.jpg'
import miriTalkImage3 from '../images/miri_talk3.jpg'

import miriSaveImage1 from '../images/miri_save1.jpg'
import miriSaveImage2 from '../images/miri_save2.jpg'
import miriSaveImage3 from '../images/miri_save3.jpg'

import miriTellerLanding from '../images/miri_teller_landing.png'

import * as PortOne from "@portone/browser-sdk/v2";
import { apiService } from '../utils/api';
import { handleKakaoLogin } from '../utils/SocialAuth';

// Components

function FeatureCard({ feature, index }) {
    const [ref, inView] = useInView({
      threshold: 0.2,
      triggerOnce: true
    });
  
    return (
      <div 
        ref={ref}
        className={`feature-card ${index % 2 === 0 ? 'left' : 'right'} ${inView ? 'visible' : ''}`}
      >
        <div className="feature-icon">{feature.icon}</div>
        <div className="feature-content">
          <h3 className="feature-title">{feature.title}</h3>
          <p className="feature-description">{feature.description}</p>
        </div>
      </div>
    );
  }

function TestimonialCard({ testimonial }) {
    const [ref, inView] = useInView({
      threshold: 0.2,
      triggerOnce: true
    });
  
    return (
      <div 
        ref={ref}
        className={`testimonial-card ${inView ? 'visible' : ''}`}
      >
        <div className="testimonial-rating">{"★".repeat(testimonial.rating)}</div>
        <p className="testimonial-text">{testimonial.text}</p>
        <div className="testimonial-info">
          <span className="testimonial-name">{testimonial.name}</span>
          <span className="testimonial-type">{testimonial.type}</span>
        </div>
      </div>
    );
  }

function TimeDigit({ value, label }) {
  const [animate, setAnimate] = useState(false);
  
  useEffect(() => {
    setAnimate(true);
    const timer = setTimeout(() => setAnimate(false), 300);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <div className="time-segment">
      <div className={`time-number ${animate ? 'flip' : ''}`}>
        {String(value).padStart(2, '0')}
      </div>
      <div className="time-label">{label}</div>
    </div>
  );
}

function NewFeatureCard({ feature, index }) {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  return (
    <div 
      ref={ref}
      className={`new-feature-card ${index % 2 === 0 ? 'left' : 'right'} ${inView ? 'visible' : ''}`}
    >
      <div className="new-feature-icon">
        <img 
          src={feature.imageUrl} 
          alt={feature.title}
          className="feature-emoji-image"
        />
      </div>
      <div className="feature-content">
        <h3 className="feature-title">{feature.title}</h3>
        <p className="feature-description">{feature.description}</p>
      </div>
    </div>
  );
}

function NewYearGatePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [showModal, setShowModal] = useState(false);
  const [ticketInfo, setTicketInfo] = useState(null);
  const { user, isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(null);
  const [marketingMessage, setMarketingMessage] = useState('');
  
  const ORIGINAL_PRICE = 30000; 
  const fortuneCategories = [
    { 
      icon: "💸", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Money%20Bag.webp",
      title: "재물운", 
      description: "2025년, 당신의 재물이 흐르는 방향은 어디일까요? 돈이 들어오는 시기와 나가는 시기, 투자해야 할 때와 지켜야 할 때를 정확히 짚어드립니다." 
    },
    { 
      icon: "💝", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Symbols/Heart%20On%20Fire.webp",
      title: "애정운", 
      description: "솔로라면 언제 인연이 찾아올지, 연인이 있다면 관계가 어떻게 발전할지, 결혼운은 어떨지 섬세하게 살펴드립니다." 
    },
    { 
      icon: "💼", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Briefcase.webp",
      title: "직장/명예운", 
      description: "승진? 이직? 창업? 2025년 당신의 커리어 흐름을 읽고, 최적의 선택지를 제시해드립니다." 
    },
    { 
      icon: "👨‍🎓", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Memo.webp",
      title: "학업/계약운", 
      description: "시험, 자격증, 계약... 중요한 결정의 순간에서 당신에게 가장 유리한 타이밍을 알려드립니다." 
    },
    { 
      icon: "🔮", 
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png",
      title: "2025년의 특별한 행운", 
      description: "매달 찾아올 특별한 행운의 시기, 조심해야 할 시기를 미리 알고 준비하세요." 
    }
  ];

  const features = [
    {
      id: 1,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Hundred%20Points.png",
      title: "3세대에 걸쳐 축적된 명리학의 정수",
      description: "3대에 걸쳐 축적된 역학 지식을 AI가 학습하여, 과학적이고 정확한 운세를 제시합니다.",
      icon: "🏛️"
    },
    {
      id: 2,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Robot.png",
      title: "과학적 알고리즘",
      description: "사주는 통계학입니다. 수십 년간의 데이터를 AI가 분석하여 정확도 높은 결과를 제공합니다.",
      icon: "🤖"
    },
    {
      id: 3,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ringed%20Planet.png",
      title: "전문적인 사주 분석",
      description: "태어난 월과 일간의 관계성, 팔자에 미치는 영향 등 심도 있는 분석으로 겉핥기식 풀이는 No!",
      icon: "📚"
    },
    {
      id: 4,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Books.webp",
      title: "방대한 데이터베이스",
      description: "명리학 고서부터 최신 논문까지, 수십 권의 전문 서적 데이터베이스가 AI에 탑재되어 있습니다.",
      icon: "💡"
    },
    {
      id: 5,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Hand%20gestures/Handshake.png",
      title: "AI 맞춤 상담",
      description: "상황과 고민에 특화된 AI 챗봇과 실시간 대화하며 당신만의 맞춤형 운세 상담을 받아보세요",
      icon: "🤝"
    },
    {
      id: 6,
      imageUrl:"https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Glowing%20Star.png",
      title: "쉽고 정확한 풀이",
      description: "어려운 사주 용어는 NO! 이해하기 쉽게 풀어서 설명해드립니다.",
      icon: "✨"
    }
  ];

  const testimonials = [
    {
      id: 1,
      name: "김*정님",
      age: "28세",
      rating: 5,
      text: "요즘 챗지피티로 사주보는 사람들도 있고 비슷한 서비스가 많은 것 같은데 써본 것 중에 이게 제일 잘 맞았어요😊 재밌게 잘 봤습니당",
      type: "신년운세"
    },
    {
      id: 2,
      name: "이*현님",
      age: "35세",
      rating: 5,
      text: "잘 맞는거 같아요~ 다른 사주 앱들도 많이 써봤는데 이게 제일 정확도 높은 거 같아요",
      type: "신년운세"
    },
    {
      id: 3,
      name: "박*린님",
      age: "42세",
      rating: 5,
      text: "전 사주를 진짜 많이 본 편이에요~ 압구정 홍대 등등 유명하다는 곳 다 가봤는데 미리에서 본거랑 그 분들이 말씀해주신 거랑 거의 똑같아서 놀랐…다음에 또 보려구용",
      type: "신년운세"
    },
    {
      id: 4,
      name: "최*우님",
      age: "31세",
      rating: 5,
      text: "풀이하면서 몇 번 소름 돋았어요!! 솔로된지 오래됐는데 곧 인연이 찾아온대서 용기도 얻었고 기분 좋네요 ㅎㅎ 결혼하고 싶어요…",
      type: "신년운세"
    },
    {
      id: 5,
      name: "정*아님",
      age: "27세",
      rating: 5,
      text: "취업이 너무 안돼서 기분이라도 풀려고 봤는데 최근에 가고 싶었던 곳 서류 합격 ㅋㅋㅋ뭐지 어떻게 맞췄지",
      type: "신년운세"
    },
    {
      id: 6,
      name: "강*준님",
      age: "39세",
      rating: 5,
      text: "창업을 고민하고 있었는데, 올해가 적기라는 분석이 와닿았어요. 구체적인 시기까지 짚어주셔서 많은 도움이 됐습니다.",
      type: "신년운세"
    }
  ];

  const processSteps = [
    {
      id: 1,
      imageUrl: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Objects/Memo.webp",
      title: "기본 정보 입력",
      description: "생년월일시를 입력하여 사주팔자를 구합니다."
    },
    {
      id: 2,
      imageUrl: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Hamsa.png",
      title: "AI 데이터 분석",
      description: "30년간의 데이터베이스와 AI가 당신의 운세를 분석합니다."
    },
    {
      id: 3,
      imageUrl: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Crystal%20Ball.png",
      title: "맞춤형 결과 제공",
      description: "AI가 당신만을 위한 상세한 운세와 조언을 제공합니다."
    },
    {
      id: 4,
      imageUrl: "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Symbols/Speech%20Balloon.webp",
      title: "AI 채팅 상담",
      description: "추가 질문과 고민에 대해 AI가 상세히 답변해드립니다."
    }
  ];

  // URL 파라미터 체크를 위한 useEffect 추가
  useEffect(() => {
    const showModalParam = searchParams.get('showModal');
    if (showModalParam === 'true') {
      handleNewYearFortuneClick();
    }
  }, [searchParams]); // searchParams가 변경될 때마다 실행

  const handleNewYearFortuneClick = async () => {
    setIsLoading(true);
    
    try {
      if (!isAuthenticated) {
        localStorage.setItem('intended_redirect_url', '/new-year-gate?showModal=true');
        
        // 로딩 스피너가 보이도록 약간의 지연 추가
        await new Promise(resolve => setTimeout(resolve, 500));
        
        // 카카오 로그인 처리
        await handleKakaoLogin();
        return;
      }

      // 기존 티켓 체크 로직
      const response = await apiService.get('/fortune/fortune-tickets/check/', {
        params: {
          categ_id: '2025년_신년운세'
        }
      });

      setTicketInfo({
        hasTicket: response.data.has_valid_ticket,
        ticketId: response.data.ticket_id,
        ticketsCount: response.data.tickets_count,  // 추가된 부분
        category: {
          categ_id: '2025년_신년운세',
          name: response.data.category.name,
          price: response.data.category.price,
          originalPrice: ORIGINAL_PRICE,
          discountRate: Math.round((1 - response.data.category.price / ORIGINAL_PRICE) * 100)
        }
      });

      // 테스트용 임시 데이터
      // setTicketInfo({
      //   hasTicket: true,
      //   ticketId: 1234,
      //   ticketsCount: 2,  // 테스트용 티켓 개수
      //   category: {
      //     name: "2025년 신년운세",
      //     price: 19000,
      //     originalPrice: ORIGINAL_PRICE,
      //     discountRate: Math.round((1 - 19000 / ORIGINAL_PRICE) * 100)
      //   }
      // });


      // setTicketInfo({
      //   hasTicket: false,
      //   ticketId: 1234,
      //   ticketsCount: 2,  // 테스트용 티켓 개수
      //   category: {
      //     name: "2025년 신년운세",
      //     categ_id: '2025년_신년운세',
      //     price: 19000,
      //     originalPrice: ORIGINAL_PRICE,
      //     discountRate: Math.round((1 - 19000 / ORIGINAL_PRICE) * 100)
      //   }
      // });
      setShowModal(true);
      
    } catch (error) {
      console.error('Error:', error);
      alert('오류가 발생했습니다.');
    } finally {
      // 로그인 리다이렉트가 아닌 경우에만 로딩 상태 해제
      if (isAuthenticated) {
        setIsLoading(false);
      }
    }
  };
  
  const handleUseTicket = async () => {
    try {
        navigate('/new-year-prospects');
    } catch (error) {
      console.error('티켓 사용 중 오류 발생:', error);
      alert('티켓 사용 중 오류가 발생했습니다.');
    }
  };

  // 모바일 기기 체크 함수
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  const handlePaymentClick = useCallback(async () => {
    try {
      const paymentId = `payment${Math.random().toString(36).slice(2)}`;
      
      // 결제 전보 저장
      const paymentInfo = {
        paymentId,
        categoryId: ticketInfo.category.categ_id,
        returnPath: '/new-year-gate'
      };
      
      // 세션 스토리지에 저장
      sessionStorage.setItem('pendingPaymentInfo', JSON.stringify(paymentInfo));

      console.log("TICKET INFO CHECK:", ticketInfo);

      // 기본 결제 설정
      const paymentConfig = {
        storeId: process.env.REACT_APP_PORTONE_STORE_ID,
        channelKey: process.env.REACT_APP_PORTONE_CHANNEL_KEY,
        paymentId: paymentId,
        orderName: ticketInfo.category.name,
        totalAmount: ticketInfo.category.price,
        currency: "CURRENCY_KRW",
        payMethod: "CARD",
        customer: {
          name: user?.username || "guest",
          email: user?.email || "고객",
        }
      };

      // 모바일인 경우 redirectUrl 추가
      if (isMobileDevice()) {
        console.log("[Payment] Processing mobile payment");
        paymentConfig.redirectUrl = `${window.location.origin}/payment-redirect`;
        await PortOne.requestPayment(paymentConfig);
      } else {
        // PC 브라우저 결제 처리
        console.log("[Payment] Processing browser payment");
        const response = await PortOne.requestPayment(paymentConfig);

        if (response.code !== undefined) {
          // 결제 오류 발생
          console.error("[Payment] Error:", response.message);
          throw new Error(response.message);
        }

        // 결제 성공 시 백엔드 검증 요청
        console.log("[Payment] Payment successful, verifying with backend");
        const verificationResponse = await apiService.post('/payments/verify/', {
          paymentId: paymentId,
          categoryId: ticketInfo.category.categ_id
        });

        if (verificationResponse.status === 200) {
          // 결제 성공 처리
          console.log("[Payment] Payment verified successfully");
          navigate('/new-year-prospects');
        } else {
          throw new Error('Payment verification failed');
        }
      }
      
    } catch (error) {
      console.error('[Payment] Failed:', error);
      alert('결제 중 오류가 발생했습니다.');
      setShowModal(false);
    }
  }, [ticketInfo, user, setShowModal, navigate]);

  // 테스트용 결제 함수
  const handleTestPaymentClick = useCallback(async () => {
    try {
      const paymentId = `payment${Math.random().toString(36).slice(2)}`;
      
      // 결제 정보 저장
      const paymentInfo = {
        paymentId,
        categoryId: ticketInfo.category.categ_id,
        returnPath: '/new-year-gate',
        isTest: true  // 테스트 결제 표시
      };
      
      sessionStorage.setItem('pendingPaymentInfo', JSON.stringify(paymentInfo));

      console.log("[Test Payment] Starting test payment");

      // 테스트용 결제 설정 DEV FIX
      const paymentConfig = {
        storeId: process.env.REACT_APP_PORTONE_STORE_ID,
        channelKey: process.env.REACT_APP_PORTONE_CHANNEL_KEY,
        paymentId: paymentId,
        orderName: ticketInfo.category.name,
        totalAmount: ticketInfo.category.price,
        currency: "CURRENCY_KRW",
        payMethod: "CARD",
        customer: {
          name: "테스트계정",  // 고정된 테스트 계정 정보
          email: "henry_kim@kakao.com",
        }
      };

      if (isMobileDevice()) {
        console.log("[Test Payment] Processing mobile test payment");
        paymentConfig.redirectUrl = `${window.location.origin}/payment-redirect`;
        await PortOne.requestPayment(paymentConfig);
      } else {
        console.log("[Test Payment] Processing browser test payment");
        const response = await PortOne.requestPayment(paymentConfig);

        if (response.code !== undefined) {
          console.error("[Test Payment] Error:", response.message);
          throw new Error(response.message);
        }

        // 테스트 결제 검증 엔드포인트 호출
        const verificationResponse = await apiService.post('/payments/verify/test/', {
          paymentId: paymentId,
          categoryId: ticketInfo.category.categ_id
        });

        if (verificationResponse.status === 200) {
          console.log("[Test Payment] Payment verified successfully");
          navigate('/new-year-prospects');
        } else {
          throw new Error('Test payment verification failed');
        }
      }
      
    } catch (error) {
      console.error('[Test Payment] Failed:', error);
      alert('테스트 결제 중 오류가 발생했습니다.');
      setShowModal(false);
    }
  }, [ticketInfo, setShowModal, navigate]);

  // 23시간 59분 13초로 설정 (86400초 = 24시간)
  const INITIAL_TIME = 10 * 60 + 13;

  useEffect(() => {
    if (!showModal) return;

    // 첫 방문 시점 체크
    const firstVisitTime = localStorage.getItem('newYearGateFirstVisit');
    const currentTime = new Date().getTime();
    
    if (!firstVisitTime) {
      // 첫 방문인 경우
      localStorage.setItem('newYearGateFirstVisit', currentTime.toString());
      setMarketingMessage('첫 가입 기념 특별 할인');
    } else {
      // 첫 방문으로부터 24시간이 지났는지 확인
      const timeDiff = currentTime - parseInt(firstVisitTime);
      const hoursPassed = timeDiff / (1000 * 60 * 60);
      
      if (hoursPassed <= 24) {
        setMarketingMessage('첫 가입 기념 특별 할인');
      } else {
        setMarketingMessage('런칭 프로모션 할인');
      }
    }

    // 타이머 설정
    const storedTimer = localStorage.getItem('specialOfferTimer');
    const storedExpiry = localStorage.getItem('specialOfferExpiry');
    
    let initialTime;
    if (storedTimer && storedExpiry && new Date().getTime() < parseInt(storedExpiry)) {
      initialTime = parseInt(storedTimer);
    } else {
      initialTime = INITIAL_TIME;
      localStorage.setItem('specialOfferTimer', INITIAL_TIME.toString());
      localStorage.setItem('specialOfferExpiry', (new Date().getTime() + INITIAL_TIME * 1000).toString());
    }

    setTimeLeft(initialTime);

    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        const newTime = prev - 1;
        localStorage.setItem('specialOfferTimer', newTime.toString());
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [showModal]);

  // 시간 포맷팅
  const formatTimeSegments = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return { hours, minutes, seconds };
  };

  return (
    <div className="home-page">
      {/* AI 소개 섹션 - 최상단에 추가 */}
      <section className="ai-intro-section">
        <div className="ai-intro-content">
          <h2 className="section-title">20년차 역술가가 만든 AI 알고리즘 사주 챗봇</h2>
          <p className="section-subtitle">서화도령은 이런 원리로 풀어줘요</p>

          <div className="ai-content-box">

            <div className="ai-persona-container">
              <div className="ai-persona-placeholder">
                <img 
                  src={miriTellerLanding} 
                  alt="미리 서화도령" 
                  className="ai-persona-image"
                />
              </div>
            </div>

            <div className="ai-intro-text">
              <p><span className="ny-highlight-text">매번 비슷하게 나오는 사주풀이 서비스. 실망하신적 있나요?</span></p>
              <p> 그렇다면 잘 오셨습니다.</p>
              <p>서화도령이 정말 <span className="ny-highlight-text">역술 명인</span>에게 상담받는 것 같은 퀄리티로 풀어드릴게요.</p>
            </div>

            <div className="ai-features-list">
              <div className="ai-feature-item">
                ✔️ 두루뭉술한 풀이가 아닌, 격국론을 기반으로 한 알고리즘 풀이
              </div>
              <div className="ai-feature-item">
                ✔️ 태어난 월과 일간의 관계성 분석
              </div>
              <div className="ai-feature-item">
                ✔️ &lt;적천수&gt;, &lt;궁퉁보감&gt;, &lt;자평진전&gt;과 같이 몇 백년을 이어온 고서부터 현대적인 이론의 논문까지
              </div>
            </div>

            <p className="ai-conclusion">
              이 모든 내용을 담아  <span className="ny-highlight-text">일목요연하게</span> 풀어드립니다.
            </p>
          </div>

          <div className="ai-difference-section">
            <h3 className="ai-difference-title">
              '에이, AI면 챗GPT로 대충 만든거 아니야?'
            </h3>
            <div className="ai-difference-emoji">
              <img 
                src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Thinking%20Face.png" 
                alt="Thinking Face" 
                className="ai-emoji-image"
              />
            </div>
            <div className="ai-difference-content">
              <p><span className="ny-highlight-text">아뇨, 미리는 그렇게 만든 서비스가 아닙니다.</span></p>
              <p>다른 사주 서비스에서 본 풀이가 서로 비슷하다고 느낀 적이 있다면, 아마 맞을 거예요.</p>
              <p>보통 간단한 알고리즘으로 구현했기 때문에 <span className="ny-highlight-text">겉핥기식 풀이</span>에 지나지 않거든요.<br />
              하지만 한 사람의 인생과 앞날을 짚어보는데 그런 식으로는 안 되겠죠?</p>
              <p>서화도령은 기업 CEO, 해외 정재계 인사, 정치인 등 지도계층을 주로 상담해오신 20년차 역술가 허유 선생님과 함께 만들었습니다.</p>

              <p><span className="ny-highlight-text">나만을 위한, 나에게만 해당되는 자세한 사주풀이</span>를 들려드리겠습니다.</p>
            </div>
          </div>
        </div>
      </section>

     {/* 새로운 섹션 */}
     {/* Hero Section */}
     {/*신년운세 CTA  */}
     <div className="cta-section">
        <div className="cta-content">
          <h2 className="section-title">2025년 신년운세에선 어떤걸 볼 수 있나요?</h2>
          <p className="section-subtitle">
            수십 년간 축적된 상담 데이터를 바탕으로 정확한 운세를 풀어드립니다.
          </p>
          
          <div className="fortune-categories">
            {fortuneCategories.map((fortune, index) => (
              <NewFeatureCard
                key={fortune.id}
                feature={{
                  imageUrl: fortune.imageUrl,
                  title: fortune.title,
                  description: fortune.description
                }}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>

      
      <div className="features-section">
        <div className="features-content">
          <h2 className="section-title">AI 운세 서비스 MIRI</h2>
          <p className="section-subtitle">천 개의 사주가 있다면, 천 개의 다른 이야기가 있습니다.</p>
          
          <div className="features-grid">
            {features.map((feature, index) => (
              <NewFeatureCard
              key={feature.id}
              feature={{
                imageUrl: feature.imageUrl,
                title: feature.title,
                description: feature.description
              }}
              index={index}
            />
            ))}
          </div>
        </div>
      </div>


      {/* // Testimonials Section in LandingPage component */}
      <div className="testimonials-section">
        <div className="testimonials-content">
          <h2 className="section-title">98% 고객이 추가 상담을 원하는 이유</h2>
          <p className="section-subtitle">실제 고객들의 생생한 후기로 증명된 서화도령의 정확도</p>
          
          <div className="testimonials-carousel">
            {testimonials.map((testimonial) => (
              <TestimonialCard 
                key={testimonial.id} 
                testimonial={testimonial}
              />
            ))}
          </div>
        </div>
      </div>

      {/* New Process Section */}
      <div className="process-section">
        <div className="process-content">
          <h2 className="section-title">MIRI만의 특별한 상담 과정</h2>
          <p className="section-subtitle">쉽고 정확한 4단계 프로세스</p>
          
          <div className="process-steps">
            {processSteps.map((step) => (
              <div key={step.id} className="process-step">
                <div className="new-step-icon">
                  <img 
                    src={step.imageUrl} 
                    alt={step.title}
                    className="step-emoji-image"
                  />
                </div>
                <h3 className="step-title">{step.title}</h3>
                <p className="step-description">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

    {/* 추가 질문 섹션 */}
    <section className="question-section">
        <h2 className="section-title">더 깊이 알고 싶은 순간 10가지 자유 질문</h2>
        <p className="section-subtitle">
          운세 풀이 후에도 궁금한 점이 남아있다면,<br />
          서화도령에게 직접 질문을 해보세요.
        </p>
        <div className="question-grid">
          <div className="question-card">
            <img src={miriTalkImage1} alt="개인화된 결과" className="question-image" />
            <div className="question-title">개인화된 맞춤 풀이결과</div>
          </div>
          <div className="question-card">
            <img src={miriTalkImage2} alt="풀이별 추가 질문" className="question-image" />
            <div className="question-title">운세풀이별 추가질문</div>
          </div>
          <div className="question-card">
            <img src={miriTalkImage3} alt="자유질문 가능" className="question-image" />
            <div className="question-title">자유질문 가능</div>
          </div>
        </div>
      </section>

      {/* 재확인 섹션 */}
      <section className="recheck-section">
        <h2 className="section-title">언제든 다시 확인하세요</h2>
        <p className="section-subtitle">
         중요한 순간마다 다시 읽고 싶은 소중한 가이드,<br />
          로그인만 하면 언제 어디서나 풀이 결과를 다시 확인할 수 있습니다.
        </p>
        <div className="question-grid">
          <div className="question-card">
            <img src={miriSaveImage1} alt="모바일 앱 미리보기 1" className="question-image" />
            <div className="question-title">운세 결과 저장</div>
          </div>
          <div className="question-card">
            <img src={miriSaveImage2} alt="모바일 앱 미리보기 2" className="question-image" />
            <div className="question-title">언제든 다시보기</div>
          </div>
          <div className="question-card">
            <img src={miriSaveImage3} alt="모바일 앱 미리보기 3" className="question-image" />
            <div className="question-title">결과도 공유 가능</div>
          </div>
        </div>
      </section>

      {/* 서화도령 소개 섹션 */}
      {/* <section className="profile-section">
        <h2 className="section-title">3대째 이어온 명리학의 지혜, 이제 AI로 만나세요</h2>
        <div className="profile-content">
          <p className="profile-quote">
            "할아버지께 배운 전통 명리학, 아버지와 연구한 현대 사주론, 그리고 제가 개발한 AI 알고리즘이 만났습니다. 사주는 운명을 읽는 것이 아니라, 더 나은 선택을 위한 나침반입니다. 수만 건의 데이터를 학습한 서화도령이 당신의 인생에 실질적인 도움이 되는 길잡이가 되어드리겠습니다."
          </p>
        </div>
      </section> */}

      {/* Floating Payment Button */}
      <button 
        className={`floating-payment-button ${isLoading ? 'ny-loading' : ''}`}
        onClick={handleNewYearFortuneClick}
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="ny-loading-spinner" />
        ) : (
          '2025년 운세 풀이 시작하기'
        )}
      </button>


      {showModal && (
        <section className="ticket-modal-overlay" role="dialog" aria-modal="true">
          <div className="ticket-modal">
            <div className="ticket-name">
              {ticketInfo.category.name} {ticketInfo.hasTicket ? '보기' : '구매'}
            </div>
            
            <main className="ticket-info">
              {ticketInfo.hasTicket ? (
                <p className="ticket-available">
                  🎉 사용권이 {ticketInfo.ticketsCount}개 있습니다 🎉
                </p>
              ) : (
                <>
                  <div className="ticket-price-container">
                    <div className="original-price">
                      <span className="strike-through">{ticketInfo.category.originalPrice.toLocaleString()}원</span>
                    </div>
                    <div className="discounted-price">
                      {ticketInfo.category.price.toLocaleString()}원
                    </div>
                    <div className="discount-label">
                      {ticketInfo.category.discountRate}% 할인
                    </div>
                  </div>
                  {timeLeft > 0 && (
                    <div className="special-offer">
                      <div className="offer-message">{marketingMessage}</div>
                      <div className="timer-container">
                        <div className="timer-label">이벤트 종료까지</div>
                        <div className="timer-digits">
                          {timeLeft && (
                            <>
                              <TimeDigit 
                                value={formatTimeSegments(timeLeft).hours} 
                                label="시간"
                              />
                              <span className="time-separator">:</span>
                              <TimeDigit 
                                value={formatTimeSegments(timeLeft).minutes} 
                                label="분"
                              />
                              <span className="time-separator">:</span>
                              <TimeDigit 
                                value={formatTimeSegments(timeLeft).seconds} 
                                label="초"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </main>

            {!ticketInfo.hasTicket && (
              <div className="ticket-policy-links">
                <div className="policy-item">
                  <span>상품 서비스 제공 기간 안내</span>
                  <a 
                    href="https://www.notion.so/mirilabs/1463a0ef8cc980edb49dc725d83a2c65?pvs=4#1463a0ef8cc98062a8c5d8c6836a82c7" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="policy-link"
                  >
                    보기
                  </a>
                </div>
                <div className="policy-item">
                  <span>환불정책 안내</span>
                  <a 
                    href="https://www.notion.so/mirilabs/1463a0ef8cc980edb49dc725d83a2c65?pvs=4#1463a0ef8cc980c09e28ca4d5ec297cd" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="policy-link"
                  >
                    보기
                  </a>
                </div>
              </div>
            )}
            
            <footer className="ticket-modal-buttons">
              {ticketInfo.hasTicket ? (
                <>
                  <button 
                    className="ticket-modal-button secondary"
                    onClick={() => setShowModal(false)}
                  >
                    취소
                  </button>
                  <button 
                    className="ticket-modal-button primary"
                    onClick={handleUseTicket}
                  >
                    사용하기
                  </button>
                </>
              ) : (
                <>
                  <button 
                    className="ticket-modal-button secondary"
                    onClick={() => setShowModal(false)}
                  >
                    취소
                  </button>
                  <button 
                    className="ticket-modal-button primary"
                    // onClick={handleTestPaymentClick}
                    onClick={handlePaymentClick}
                  >
                    할인된 가격으로 구매하기
                  </button>
                </>
              )}
            </footer>
          </div>
        </section>
      )}
      
    </div>
  );
}

export default NewYearGatePage;