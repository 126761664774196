import React, {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../utils/api';
import './DayPillarRouterPage.css';
import { dayPillarContentsData } from '../contents/day_pillar_contents/dayPillarContentsData';

function DayPillarRouterPage() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getDayPillarId = (dayPillarName) => {
      const pillar = Object.values(dayPillarContentsData.common).find(
        pillar => pillar.name.startsWith(dayPillarName)
      );
      return pillar?.id;
    };

    const checkDayPillarTest = async () => {
      try {
        const response = await apiService.get('fortune/persons/day-pillar-result/');
        const { dayPillarName, referral_code } = response.data;
        
        const dayPillarId = getDayPillarId(dayPillarName);
        
        const params = new URLSearchParams({
          testDone: 'true'
        });

        if (referral_code) {
          params.append('invitation', referral_code);
        }
        
        navigate(`/day-pillar/${dayPillarId}?${params.toString()}`, { replace: true });
        
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    checkDayPillarTest();
  }, [navigate]);

  if (error) return <div className="day-pillar-router__error">에러: {error}</div>;
  

  return (
    <div className="day-pillar-router">
      <div className="day-pillar-router__spinner"></div>
    </div>
  );
}

export default DayPillarRouterPage; 