import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { handleKakaoLogin } from '../utils/SocialAuth';
import './BasicGuidePage.css';
import { useInView } from 'react-intersection-observer';
import guideImg1 from '../images/guide_1.png'
import guideImg2 from '../images/guide_2.png'

function BasicGuidePage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const shareButtonsRef = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0.1,
  });

  const handleTestButtonClick = async () => {
    if (isAuthenticated) {
      navigate('/day-pillar-router');
    } else {
      try {
        setIsLoading(true);
        localStorage.setItem('intended_redirect_url', '/day-pillar-router');
        await handleKakaoLogin();
      } catch (error) {
        console.error('Kakao login failed:', error);
      }
    }
  };

  const handleScrollToBottom = () => {
    shareButtonsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="bg-container">
      <section className="bg-intro">
        <h1 className="bg-main-title">사주 초보 가이드</h1>
        <p className="bg-intro-text">
          사주를 보는 사람은 많지만, 어떻게 사주가 생겼는지 혹은 어떤 원리로 풀이하는지는 대부분 모르실 거예요.
          만약 궁금하시다면 이 사주 초보 가이드를 확인해보세요.
        </p>

        <div className="bg-content-section">
          <h2 className="bg-section-title">📜 사주는 어디서 시작되었나</h2>
          <p className="bg-section-text">
            사주의 역사는 고대 중국으로 거슬러 올라가요. 사람들은 하늘의 움직임을 관찰하며 자연의 변화와 인간의 삶이 연결되어 있다고 믿었어요. 
            예를 들어, 계절과 별자리의 흐름이 인간의 행동과 운명에도 영향을 미친다고 생각했죠.
            이 과정에서 탄생한 것이 바로 <strong>간지(干支)</strong> 체계입니다.
          </p>
          <ul className="bg-list">
            <li><strong>십간</strong>: 갑, 을, 병, 정, 무, 기, 경, 신, 임, 계의 10가지 하늘의 기운</li>
            <li><strong>십이지</strong>: 자, 축, 인, 묘, 진, 사, 오, 미, 신, 유, 술, 해의 12가지 땅의 에너지</li>
          </ul>
        </div>

        <div className="bg-content-section">
          <h2 className="bg-section-title">☯️ 철학적 배경: 음양오행</h2>
          <p className="bg-section-text">
            사주팔자는 단순히 별을 보는 천문학에서 그치지 않고, 동양 철학의 핵심인 <strong>음양오행</strong> 사상을 바탕으로 발전했어요.
          </p>
          <ul className="bg-list">
            <li><strong>음양</strong>: 세상의 모든 것은 대립하면서도 조화를 이루는 두 에너지로 구성됩니다.</li>
            <li><strong>오행</strong>: 목(木), 화(火), 토(土), 금(金), 수(水)의 다섯 가지 요소가 상호작용하며 세상의 변화를 이끈다고 봤어요.</li>
          </ul>
        </div>

        <div className="bg-content-section">
          <h2 className="bg-section-title">🔮 사주팔자라는 건 뭘까?</h2>
          <p className="bg-section-text">
            어르신들이 우스갯소리로 "팔자는 못 고친다", "팔자대로 사네"와 같은 말씀들을 하시죠?
            여기에서의 '팔자'는 사주팔자에서 따온 말이에요.
            사주팔자에서 사주(四柱)라는 건 4개의 기둥을 의미하고 팔자(八字)라는 건 8개 글자를 의미해요.
            즉, 사주는 4개 기둥에 세워진 8개의 글자를 뜻한답니다.
          </p>
          
          {/* 첫 번째 이미지 */}
          <img 
            src={guideImg1}
            alt="사주 구조" 
            className="bg-image"
          />

          <p className="bg-section-text">
            오른쪽에서부터 년주, 월주, 일주, 시주라고 해요.
          </p>
          <ul className="bg-list">
            <li>년주 : 태어난 해</li>
            <li>월주 : 태어난 월</li>
            <li>일주 : 태어난 날</li>
            <li>시주 : 태어난 시간</li>
          </ul>
        </div>

        <div className="bg-content-section">
          <h2 className="bg-section-title">⚡ 천간과 지지</h2>
          {/* 두 번째 이미지 */}
          <img 
            src={guideImg2}
            alt="천간과 지지 구조" 
            className="bg-image"
          />
          <p className="bg-section-text">
            사주팔자는 '천간'과 '지지'로 나뉘어요. 위 이미지에서 윗줄에 있는 글자들을 천간이라고 하고, 
            아랫줄에 있는 글자들을 지지라고 해요. 지지의 12가지 글자는 12지신과 매칭돼요. 흔히 "무슨 띠야?"라고 물어볼 때 말하는 그 동물들입니다.
            자, 축, 인, 묘, 진, 사, 오, 미, 신, 유, 술, 해는 각각 쥐, 소, 호랑이, 토끼, 용, 뱀, 말, 양, 원숭이, 닭, 개, 돼지에요.
          </p>
        </div>

        <div className="bg-content-section">
          <h2 className="bg-section-title">📊 사주는 데이터로 읽는 내 이야기</h2>
          <p className="bg-section-text">
            이제 사주에 대한 기초적인 지식은 알게 되셨어요! 지금까지의 이야기를 요약하면, 사주는 태어난 순간의 우주 에너지와 앞으로 펼쳐질 에너지의 관계를 토대로 
            내 인생과 앞날을 분석하는 도구에요. 사주를 통해 나를 더 깊이 이해하고, 삶의 방향성을 찾아보는 건 어떨까요? 😊
          </p>
        </div>
      </section>

      <section 
        ref={(element) => {
          shareButtonsRef.current = element;
          ref(element);
        }}
        className="bg-action-section"
      >
        <h2 className="bg-action-title">무료로 보는 사주 컨텐츠</h2>
        <div className="bg-action-buttons">
         <button 
            className="bg-action-button"
            onClick={() => navigate('/today-gate')}
          >
            🍀 오늘의 운세
          </button>
          <button 
            className="bg-action-button"
            onClick={() => navigate('/class-gate')}
          >
            🔮 사주 유형 테스트
          </button>
          <button 
            className="bg-action-button"
            onClick={() => navigate('/day-pillar-gate')}
          >
            🌟 60일주 테스트
          </button>
        </div>

        <h2 className="bg-action-title">2025년에 내 인생은 어떻게 펼쳐질까?</h2>
        <div className="bg-action-buttons">
          <button 
            className="bg-action-button bg-action-button--highlight"
            onClick={() => navigate('/new-year-gate')}
          >
            <img 
                src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png" 
                alt="" 
                className="basic-guide__button-icon--left"
            />
            2025 신년운세 보러 가기
            <img 
                src="https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Activities/Party%20Popper.png" 
                alt="" 
                className="basic-guide__button-icon--right"
            />
          </button>
        </div>
      </section>

      {!inView && (
        <button 
          className="bg-floating-button"
          onClick={handleScrollToBottom}
        >
          테스트 하러가기
        </button>
      )}
    </div>
  );
}

export default BasicGuidePage;
