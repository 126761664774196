// 모인 이미지 imports
import femaleSangkwan from './female-sangkwan.png';
import maleSangkwan from './male-sangkwan.png';
import femalePyungkwan from './female-pyungkwan.png';
import malePyungkwan from './male-pyungkwan.png';
import femaleYangin from './female-yangin.png';
import maleYangin from './male-yangin.png';
import femaleGunrok from './female-gunrok.png';
import maleGunrok from './male-gunrok.png';
import femaleSikshin from './female-sikshin.png';
import maleSikshin from './male-sikshin.png';
import femaleJungin from './female-jungin.png';
import maleJungin from './male-jungin.png';
import femalePyungin from './female-pyungin.png';
import malePyungin from './male-pyungin.png';
import femaleJungjae from './female-jungjae.png';
import maleJungjae from './male-jungjae.png';
import femalePyungjae from './female-pyungjae.png';
import malePyungjae from './male-pyungjae.png';
import femaleJunggwan from './female-junggwan.png';
import maleJunggwan from './male-junggwan.png';

// 썸네일 이미지 imports
import femaleSangkwanThumb from './female-sangkwan-thumb.png';
import maleSangkwanThumb from './male-sangkwan-thumb.png';
import femalePyungkwanThumb from './female-pyungkwan-thumb.png';
import malePyungkwanThumb from './male-pyungkwan-thumb.png';
import femaleYanginThumb from './female-yangin-thumb.png';
import maleYanginThumb from './male-yangin-thumb.png';
import femaleGunrokThumb from './female-gunrok-thumb.png';
import maleGunrokThumb from './male-gunrok-thumb.png';
import femaleSikshinThumb from './female-sikshin-thumb.png';
import maleSikshinThumb from './male-sikshin-thumb.png';
import femaleJunginThumb from './female-jungin-thumb.png';
import maleJunginThumb from './male-jungin-thumb.png';
import femalePyunginThumb from './female-pyungin-thumb.png';
import malePyunginThumb from './male-pyungin-thumb.png';
import femaleJungjaeThumb from './female-jungjae-thumb.png';
import maleJungjaeThumb from './male-jungjae-thumb.png';
import femalePyungjaeThumb from './female-pyungjae-thumb.png';
import malePyungjaeThumb from './male-pyungjae-thumb.png';
import femaleJunggwanThumb from './female-junggwan-thumb.png';
import maleJunggwanThumb from './male-junggwan-thumb.png';

export const classImages = {
  sangkwan: {
    female: {
      full: femaleSangkwan,
      thumbnail: femaleSangkwanThumb
    },
    male: {
      full: maleSangkwan,
      thumbnail: maleSangkwanThumb
    }
  },
  pyungkwan: {
    female: {
      full: femalePyungkwan,
      thumbnail: femalePyungkwanThumb
    },
    male: {
      full: malePyungkwan,
      thumbnail: malePyungkwanThumb
    }
  },
  yangin: {
    female: {
      full: femaleYangin,
      thumbnail: femaleYanginThumb
    },
    male: {
      full: maleYangin,
      thumbnail: maleYanginThumb
    }
  },
  gunrok: {
    female: {
      full: femaleGunrok,
      thumbnail: femaleGunrokThumb
    },
    male: {
      full: maleGunrok,
      thumbnail: maleGunrokThumb
    }
  },
  sikshin: {
    female: {
      full: femaleSikshin,
      thumbnail: femaleSikshinThumb
    },
    male: {
      full: maleSikshin,
      thumbnail: maleSikshinThumb
    }
  },
  jungin: {
    female: {
      full: femaleJungin,
      thumbnail: femaleJunginThumb
    },
    male: {
      full: maleJungin,
      thumbnail: maleJunginThumb
    }
  },
  pyungin: {
    female: {
      full: femalePyungin,
      thumbnail: femalePyunginThumb
    },
    male: {
      full: malePyungin,
      thumbnail: malePyunginThumb
    }
  },
  jungjae: {
    female: {
      full: femaleJungjae,
      thumbnail: femaleJungjaeThumb
    },
    male: {
      full: maleJungjae,
      thumbnail: maleJungjaeThumb
    }
  },
  pyungjae: {
    female: {
      full: femalePyungjae,
      thumbnail: femalePyungjaeThumb
    },
    male: {
      full: malePyungjae,
      thumbnail: malePyungjaeThumb
    }
  },
  junggwan: {
    female: {
      full: femaleJunggwan,
      thumbnail: femaleJunggwanThumb
    },
    male: {
      full: maleJunggwan,
      thumbnail: maleJunggwanThumb
    }
  }
};