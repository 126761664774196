import React, { useState, useEffect, useMemo } from 'react';
import './DisplayQuestions.css';

function DisplayQuestionsNewYearQnA({ triggerNextStep, questions, questionKey, category, onQuestionSelect, onDirectAsk, forceScrollRef  }) {
  const [clicked, setClicked] = useState(false);
  const [clickedMessage, setClickedMessage] = useState("");
  const [allQuestions, setAllQuestions] = useState([]);

  const defaultQuestions = useMemo(() => [], []);

  useEffect(() => {
    setAllQuestions([...questions, ...defaultQuestions]);
    setClicked(false);
    setClickedMessage("");
  }, []);

  const handleClick = (message) => {
    setClicked(true);
    setClickedMessage(message);

    // 클릭 시 forceScroll 활성화
    if (forceScrollRef) {
      forceScrollRef.current = true;
    }

    if (message !== "다음 풀이로 넘어갈게요🙌" && message !== '직접 질문할게요🙋') {
      if (onQuestionSelect) {
        onQuestionSelect(category, message); //질문 장전
      }
    }else{
      if (onDirectAsk) {
        onDirectAsk(category, message);
      }
    }

    // 상태 업데이트를 지연시켜 user-message가 먼저 렌더링되도록 함
    setTimeout(() => {
      let nextData = {}

      if (message === '직접 질문할게요🙋') {
        nextData = {
          trigger: 'make-system-message',
          value: {
            message: ["네 말씀해주세요"],
            next_trigger: 'user-input',
          }
        };
      }else {
        nextData = {
          trigger: 'user-input-processor',
          value: message
        }
      }

      triggerNextStep(nextData);
    }, 100);
  };

  return (
    <div className={clicked ? "outer-container clicked" : "outer-container"}>
      {clicked ? (
        <div className="user-message-container">
          <div className="user-message">
            {clickedMessage}
          </div>
        </div>
      ) : (
        <div className="display-button-container">
          {allQuestions.map((question, index) => (
            <button
              key={`${questionKey}-${index}`}
              className="display-styled-button"
              onClick={() => handleClick(question)}
            >
              {question}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default DisplayQuestionsNewYearQnA;