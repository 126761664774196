import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './RegistrationPage.css';
import { useAuth } from '../contexts/AuthContext';
import { TERMS_AND_CONDITIONS } from '../texts/Terms';
import { apiService } from '../utils/api'; 

// 목적지 URL을 저장하기 위한 상수
const REDIRECT_URL_KEY = 'intended_redirect_url';

function RegistrationPage() {
  const navigate = useNavigate();
  const { setUser, setUserMeta, setIsAuthenticated } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    gender: '',  // 추가
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    birthHour: '',
    birthMinute: '',
    relationship: 'self',
    is_birth_time_unknown: false,  // 추가
    referral_code: ''  // 추가
  });

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [termsAgreement, setTermsAgreement] = useState({
    terms: false,
    privacy: false,
    marketing: false
  });

  // 시간 값을 임시 저장할 ref 추가
  const savedTimeRef = React.useRef({
    hour: '',
    minute: ''
  });

  // 상단에 상태 추가
  const handleTermsChange = (type) => (e) => {
    setTermsAgreement(prev => ({
      ...prev,
      [type]: e.target.checked
    }));
    

  };
  // 뒤로가기 방지
  useEffect(() => {
    const preventNavigation = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', preventNavigation);
    
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', () => {
      window.history.pushState(null, null, window.location.pathname);
      alert('프로필 등록을 완료해주세요.');
    });

    return () => {
      window.removeEventListener('beforeunload', preventNavigation);
    };
  }, []);

// 폼 검증 함수에 시간 검증 추가
const validateForm = () => {
  const errors = {};
  if (!formData.name.trim()) {
    errors.name = '이름을 입력해주세요';
  }
  if (!formData.gender) {
    errors.gender = '성별을 선택해주세요';
  }
  if (!formData.birthYear || formData.birthYear.length !== 4) {
    errors.birthYear = '출생년도 4자리를 입력해주세요';
  }
  
  // 월 검증
  const month = parseInt(formData.birthMonth);
  if (!formData.birthMonth) {
    errors.birthMonth = '출생월을 입력해주세요';
  } else if (isNaN(month) || month < 1 || month > 12) {
    errors.birthMonth = '1-12 사이의 월을 입력해주세요';
  }

  // 일 검증
  const day = parseInt(formData.birthDay);
  if (!formData.birthDay) {
    errors.birthDay = '출생일을 입력해주세요';
  } else {
    // 각 월의 마지막 날짜 계산
    const year = parseInt(formData.birthYear);
    const lastDay = new Date(year, month, 0).getDate();
    
    if (isNaN(day) || day < 1 || day > lastDay) {
      errors.birthDay = `1-${lastDay} 사이의 일을 입력해주세요`;
    }
  }
  
  // 시간이 입력된 경우에만 검증
  if (formData.birthHour !== '') {
    const hour = parseInt(formData.birthHour);
    if (isNaN(hour) || hour < 0 || hour > 23) {
      errors.birthHour = '시간은 0-23 사이여야 합니다';
    }
  }
  
  // 분이 입력된 경우에만 검증
  if (formData.birthMinute !== '') {
    const minute = parseInt(formData.birthMinute);
    if (isNaN(minute) || minute < 0 || minute > 59) {
      errors.birthMinute = '분은 0-59 사이여야 합니다';
    }
  }
  
  setValidationErrors(errors);
  return Object.keys(errors).length === 0;
};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowTermsModal(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'birthYear') {
      if (value === '' || (value.length <= 4 && /^\d*$/.test(value))) {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    } else if (name === 'birthMonth') {
      const month = parseInt(value);
      if (value === '' || (month >= 0 && month <= 12)) {
        setFormData(prev => ({
          ...prev,
          [name]: value,
          birthDay: month === 0 ? prev.birthDay : ''
        }));
      }
    } else if (name === 'birthDay') {
      const day = parseInt(value);
      const month = parseInt(formData.birthMonth);
      const year = parseInt(formData.birthYear);
      const lastDay = new Date(year, month, 0).getDate();
      
      if (value === '' || (day >= 0 && day <= lastDay)) {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    } else if (name === 'birthHour') {
      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 23)) {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    } else if (name === 'birthMinute') {
      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
        setFormData(prev => ({
          ...prev,
          [name]: value
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleTermsSubmit = async () => {
    if (!termsAgreement.terms || !termsAgreement.privacy) {
      alert('필수 약관에 모두 동의가 필요합니다.');
      return;
    }

    setIsSubmitting(true);
    try {
      // localStorage에서 referral_code와 special_code 가져오기
      const referralCode = localStorage.getItem('referral_code');
      const specialCode = localStorage.getItem('special_code');

      const profileData = {
        profile: {
          name: formData.name,
          gender: formData.gender,
          relationship: formData.relationship,
          is_birth_time_unknown: formData.is_birth_time_unknown,
          birth_datetime: `${formData.birthYear}-${String(formData.birthMonth).padStart(2, '0')}-${String(formData.birthDay).padStart(2, '0')}T${formData.is_birth_time_unknown ? '00:00' : `${String(formData.birthHour).padStart(2, '0')}:${String(formData.birthMinute).padStart(2, '0')}`}:00`
        },
        agreements: {
          terms: termsAgreement.terms,
          marketing: termsAgreement.marketing,
          privacy: termsAgreement.privacy,
          agreed_at: new Date().toISOString()
        },
        referral_code: referralCode || null,
        special_code: specialCode || null
      };

      const response = await apiService.post('/first-register-profile/', profileData);
          
      if (response.status === 201 || response.status === 200) {

          // 성공 후 referral_code 삭제
          localStorage.removeItem('referral_code');
          
          localStorage.removeItem('special_code');
          console.log('Registration response:', response.data);

          const { user, meta_info } = response.data;
          setUserMeta(meta_info);
          alert('프로필이 성공적으로 등록되었습니다.');

          // 저장된 리다이렉트 URL 확인
          const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);
          if (redirectUrl) {
              localStorage.removeItem(REDIRECT_URL_KEY);
              navigate(redirectUrl);
          } else {
              navigate('/');
          }

        } else {
            throw new Error('프로필 등록에 실패했습니다.');
        }
    } catch (error) {
        alert('프로필 등록 중 오류가 발생했습니다.');
        console.error('Registration error:', error);
    } finally {
        setIsSubmitting(false);  // 로딩 종료
    }
  };


  // 시간 모름 체크박스 핸들러 추가
  const handleTimeUnknownChange = (e) => {
    const isChecked = e.target.checked;
    
    if (isChecked) {
      // 체크 시 현재 시간 값을 저장하고 0시 0분으로 설정
      savedTimeRef.current = {
        hour: formData.birthHour,
        minute: formData.birthMinute
      };
      
      setFormData(prev => ({
        ...prev,
        is_birth_time_unknown: true,
        birthHour: '0',
        birthMinute: '0'
      }));
    } else {
      // 체크 해제 시 저장된 시간 값을 복원
      setFormData(prev => ({
        ...prev,
        is_birth_time_unknown: false,
        birthHour: savedTimeRef.current.hour,
        birthMinute: savedTimeRef.current.minute
      }));
    }
  };

  // localStorage에서 레퍼럴 코드를 가져와서 자동 입력
  useEffect(() => {
    const savedReferralCode = localStorage.getItem('referral_code') || '';
    console.log("savedReferralCode:", savedReferralCode)
    
    setFormData(prev => ({
      ...prev,
      referral_code: savedReferralCode,
      relationship: 'self'  // 관계도 여기서 초기화
    }));
  }, []);

  return (
    <div className="registration-container">
      <div className="registration-form">
        <h1>생년월일 등록</h1>
        <form onSubmit={handleSubmit}>
        <div className="form-group name-gender-group">
          <div className="input-wrapper">
            <label>이름</label>
            <div className="name-gender-inputs">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="홍길동"
                className={validationErrors.name ? 'error' : ''}
              />
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className={validationErrors.gender ? 'error' : ''}
              >
                <option value="">성별</option>
                <option value="M">남성</option>
                <option value="F">여성</option>
              </select>
            </div>
            {(validationErrors.name || validationErrors.gender) && (
              <span className="error-message">
                {validationErrors.name || validationErrors.gender}
              </span>
            )}
          </div>
        </div>

          <div className="form-group birth-date">
            <label>생년월일</label>
            <div className="date-inputs">
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthYear"
                  value={formData.birthYear}
                  onChange={handleChange}
                  placeholder="1995"
                  maxLength="4"
                  className={validationErrors.birthYear ? 'error' : ''}
                />
                <span>년</span>
              </div>
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthMonth"
                  value={formData.birthMonth}
                  onChange={handleChange}
                  placeholder="1"
                  maxLength="2"
                  className={validationErrors.birthMonth ? 'error' : ''}
                />
                <span>월</span>
              </div>
              <div className="date-input-group">
                <input
                  type="number"
                  name="birthDay"
                  value={formData.birthDay}
                  onChange={handleChange}
                  placeholder="1"
                  maxLength="2"
                  className={validationErrors.birthDay ? 'error' : ''}
                />
                <span>일</span>
              </div>
            </div>
            {(validationErrors.birthYear || validationErrors.birthMonth || validationErrors.birthDay) && (
              <span className="error-message">생년월일을 모두 입력해주세요</span>
            )}
          </div>
          <div className="form-group birth-time">
            <label>태어난 시간</label>
            <div className="time-container">
              <div className="time-inputs">
                <div className="time-input-group">
                  <input
                    type="number"
                    name="birthHour"
                    value={formData.birthHour}
                    onChange={handleChange}
                    placeholder="10"
                    maxLength="2"
                    className={validationErrors.birthHour ? 'error' : ''}
                    disabled={formData.is_birth_time_unknown}
                  />
                  <span>시</span>
                </div>
                <div className="time-input-group">
                  <input
                    type="number"
                    name="birthMinute"
                    value={formData.birthMinute}
                    onChange={handleChange}
                    placeholder="30"
                    maxLength="2"
                    className={validationErrors.birthMinute ? 'error' : ''}
                    disabled={formData.is_birth_time_unknown}
                  />
                  <span>분</span>
                </div>
                <label className="time-unknown-label">
                  <input
                    type="checkbox"
                    checked={formData.is_birth_time_unknown}
                    onChange={handleTimeUnknownChange}
                  />
                  <span>시간 모름</span>
                </label>
              </div>
            </div>
            {formData.is_birth_time_unknown && (
              <span className="error-message" style={{ color: '#2e7d32' }}>
                시간 모름이 체크된 경우, 시간이 0시 0분으로 임의로 저장됩니다.
              </span>
            )}
            {validationErrors.birthHour && !formData.is_birth_time_unknown && (
              <span className="error-message">{validationErrors.birthHour}</span>
            )}
            {validationErrors.birthMinute && !formData.is_birth_time_unknown && (
              <span className="error-message">{validationErrors.birthMinute}</span>
            )}
          </div>

          <div className="form-group">
            <label>관계</label>
            <select 
              name="relationship"
              value="self"
              disabled
              className="disabled-select"
            >
              <option value="self">본인</option>
            </select>
          </div>

          {localStorage.getItem('referral_code') && localStorage.getItem('referral_code') !== 'null' && (
            <div className="referral-code-text">
              <small>초대코드: {localStorage.getItem('referral_code')}</small>
            </div>
          )}
          {localStorage.getItem('special_code') && localStorage.getItem('special_code') !== 'null' && (
            <div className="referral-code-text">
              <small>이벤트코드: {localStorage.getItem('special_code')}</small>
            </div>
          )}

          <button type="submit" className="submit-button">
            다음으로
          </button>
        </form>
      </div>

      {showTermsModal && (
        <div className="modal-overlay">
          <div className="terms-modal">
            <div className="terms-header">
              <h2>이용약관 동의</h2>
              <button 
                className="close-button"
                onClick={() => setShowTermsModal(false)}
              >
                ×
              </button>
            </div>
            
            <div className="terms-content">
              <div className="terms-section">
                <div className="agreement-item">
                  <div className="agreement-header">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={termsAgreement.terms}
                        onChange={handleTermsChange('terms')}
                      />
                      <span>서비스 이용약관 동의 (필수)</span>
                    </label>
                    <a 
                      href="https://mirilabs.notion.site/1463a0ef8cc980edb49dc725d83a2c65" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="terms-link"
                    >
                      보기
                    </a>
                  </div>
                </div>

                <div className="agreement-item">
                  <div className="agreement-header">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={termsAgreement.privacy}
                        onChange={handleTermsChange('privacy')}
                      />
                      <span>개인정보 수집 이용 동의 (필수)</span>
                    </label>
                    <a 
                      href="https://mirilabs.notion.site/1493a0ef8cc980b89cbbe998389dd11b" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="terms-link"
                    >
                      보기
                    </a>
                  </div>
                </div>

                <div className="agreement-item">
                  <div className="agreement-header">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={termsAgreement.marketing}
                        onChange={handleTermsChange('marketing')}
                      />
                      <span>마케팅 정보 수신 동의 (선택)</span>
                    </label>
                    <a 
                      href="https://mirilabs.notion.site/1493a0ef8cc980268eddfd313efe0077" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="terms-link"
                    >
                      보기
                    </a>
                  </div>
                  <span className="info-message">
                    무료 컨텐츠가 새로 나오면 알려드릴게요
                  </span>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button 
                className="cancel-button"
                onClick={() => setShowTermsModal(false)}
              >
                취소
              </button>
              <button 
                className={`submit-button ${isSubmitting ? 'loading' : ''}`}
                onClick={handleTermsSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="loading-spinner"></span>
                ) : (
                  '가입완료'
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegistrationPage;