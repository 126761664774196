import React from 'react';
import FortuneService from './FortuneService';

/**
 * 운세 결과를 저장하는 함수
 * @param {Object} params - 저장할 운세 데이터
 * @param {Object} params.person - 운세를 볼 대상자 정보
 * @param {Object} params.category - 운세 카테고리 정보
 * @param {Object|null} params.ticketId - 티켓 정보 (없으면 null)
 * @param {Array} params.displayContents - 표시할 운세 내용 타입 배열
 * @param {Array} params.qaContents - Q&A 내용 (질문과 답변 포함)
 */
export const saveFortuneResult = async ({
  // API콜을 3번함
  // FortuneService.createFortuneReading

  // FortuneService.saveFortuneDisplays

  // FortuneService.saveFortuneQAs

  person,
  category,
  ticketId,
  displayContents,
  qaContents
}) => {
 try {
    console.log('=== 운세 저장 시작 ===');
    console.log('1. createFortuneReading 호출 전:', {
      personId: person.id,
      categoryId: category.categ_id,
      ticketId: ticketId
    });

    // 1. 운세 읽기 기록 생성 + 티켓 소진도 저절로 됨
    const reading = await FortuneService.createFortuneReading(
      person.id,
      category.categ_id,
      ticketId
    );
    console.log('1. createFortuneReading 응답:', reading);

    if (!reading.id) {
      throw new Error('운세 읽기 ID를 받지 못했습니다.');
    }

    console.log('2. saveFortuneDisplays 호출 전:', 
      displayContents.map(content => ({
        type: content.type
      }))
    );

    // 2. 운세 표시 내용 저장
    const displayResult = await FortuneService.saveFortuneDisplays(
      reading.id, 
      displayContents.map(content => ({
        type: content.type
      }))
    );
    console.log('2. saveFortuneDisplays 응답:', displayResult);

    // 3. Q&A 내용을 타입별로 정리
    const qasByType = {};
    qaContents.forEach(qa => {
      if (!qasByType[qa.type]) {
        qasByType[qa.type] = [];
      }
      
      qasByType[qa.type].push({
        question: qa.question,
        answer: qa.answer
      });
    });

    const processedQAs = Object.entries(qasByType).map(([type, qaPairs]) => ({
      type,
      qaPairs  // 질문-답변 쌍의 배열
    }));
    console.log('3. saveFortuneQAs 호출 전:', processedQAs);

    // 4. 정리된 Q&A 저장
    const qaResult = await FortuneService.saveFortuneQAs(reading.id, processedQAs);
    console.log('3. saveFortuneQAs 응답:', qaResult);

    console.log('=== 운세 저장 완료 ===');
    return reading.share_id;

  } catch (error) {
    console.error('운세 결과 저장 실패:', error);
    throw error;
  }
};