import React, { useState, useRef, useEffect } from "react";
import { NavLink,useNavigate } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./NavBar.css";
import logoImage from '../images/miri_logo.png';
import { useAuth } from '../contexts/AuthContext';
import {handleAuthProtection} from '../utils/SocialAuth'

const NavBar = () => {
  const { isAuthenticated, logout, loading } = useAuth();  // loading 상태 추가
  const [showMenu, setShowMenu] = useState(false);
  const [error, setError] = useState(null);  // 에러 상태 추가
  const navigate = useNavigate();  // useNavigate 훅 추가
  const [showTestSubmenu, setShowTestSubmenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1450);

  // 외부 클릭 감지를 위한 ref 추가
  const submenuRef = useRef(null);

  // 외부 클릭 이벤트 핸들러
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setShowTestSubmenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // 반응형 처리를 위한 useEffect 추가
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1450);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleHomeClick = (e) => {
    console.log('handleHomeClick 실행');
    console.log('현재 경로:', window.location.pathname);
    console.log('현재 스크롤 위치:', document.documentElement.scrollTop);

    if (window.location.pathname === '/') {
      e.preventDefault();
    }

    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    
    closeMenuOnMobile();
  };

  const handleLogout = async () => {
    try {
      await logout();
      setShowMenu(false);  // 로그아웃 후 메뉴 닫기
    } catch (error) {
      setError('로그아웃 중 오류가 발생했습니다.');
      setTimeout(() => setError(null), 3000);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    const toggle = document.querySelector('.nav__toggle');
    toggle.classList.toggle('rotate');
  };
   
  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  const toggleTestSubmenu = (e) => {
    e.preventDefault();
    if (window.innerWidth < 1300) {
      setShowTestSubmenu(!showTestSubmenu);
    }
  };

  const handleProtectedRoute = (e, path) => {
    e.preventDefault();
    if (handleAuthProtection(isAuthenticated, navigate, path)) {
      navigate(path);
    }
    closeMenuOnMobile();
  };

  // 서브메뉴 아이템 클릭 핸들러 추가
  const handleSubmenuItemClick = () => {
    setShowTestSubmenu(false);
    closeMenuOnMobile();
  };

  // hover 이벤트 핸들러 추가
  const handleMouseEnter = () => {
    if (window.innerWidth >= 1300) {
      setShowTestSubmenu(true);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth >= 1300) {
      setShowTestSubmenu(false);
    }
  };

  return (
    <header className="header">
      <nav className="nav">
        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>

        <NavLink to="/" className="nav__logo" onClick={handleHomeClick}>
          <img src={logoImage} alt="서화사주 로고" className="nav__logo-image" />
        </NavLink>

        <div className={`nav__menu ${showMenu ? 'show-menu' : ''}`} id="nav-menu">
          <ul className="nav__list">
            {isMobile && (
              <li className="nav__item">
                <NavLink 
                  to="/" 
                  className="nav__link" 
                  onClick={closeMenuOnMobile}
                >
                  서비스 소개
                </NavLink>
              </li>
            )}

            <li className="nav__item">
              <NavLink 
                to="/basic-guide" 
                className="nav__link" 
                onClick={closeMenuOnMobile}
              >
                사주 초보 가이드
              </NavLink>
            </li>

            <li 
              className={`nav__item ${showTestSubmenu ? 'show-submenu' : ''}`} 
              ref={submenuRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <a 
                href="#" 
                className="nav__link nav__link-with-submenu"
                onClick={toggleTestSubmenu}
              >
                무료 컨텐츠
              </a>
              <ul className="nav__submenu">
                <li>
                  <NavLink 
                    to="/today-gate" 
                    className="nav__link nav__sublink" 
                    onClick={handleSubmenuItemClick}
                  >
                    오늘의 운세
                  </NavLink>
                </li>
                <li>
                  <NavLink 
                    to="/class-gate" 
                    className="nav__link nav__sublink" 
                    onClick={handleSubmenuItemClick}
                  >
                    사주 격 테스트
                  </NavLink>
                </li>
                <li>
                  <NavLink 
                    to="/day-pillar-gate" 
                    className="nav__link nav__sublink" 
                    onClick={handleSubmenuItemClick}
                  >
                    60일주 테스트
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="nav__item">
              <NavLink 
                to="/new-year-gate" 
                className="nav__link" 
                onClick={closeMenuOnMobile}
              >
                AI 신년 운세
              </NavLink>
            </li>

            {isAuthenticated && (
              <li className="nav__item">
                <NavLink 
                  to="/my-page" 
                  className="nav__link" 
                  onClick={closeMenuOnMobile}
                >
                  마이페이지
                </NavLink>
              </li>
            )}

            <li className="nav__item">
              {isAuthenticated ? (
                <span className="nav__link" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                  로그아웃
                </span>
              ) : (
                <a 
                  href="/"
                  className="nav__link"
                  onClick={(e) => handleProtectedRoute(e, '/')}
                >
                  로그인
                </a>
              )}
            </li>
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>
      </nav>
      {showMenu && <div className="nav__overlay" onClick={toggleMenu}></div>}
    </header>
  );
};

export default NavBar;