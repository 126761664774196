import React from 'react';
import './AdsTransitionDisplay.css';
import { useNavigate } from 'react-router-dom';

function AdsTransitionDisplay() {
  const navigate = useNavigate();

  // testimonials 데이터를 가져와서 사용
  const testimonials = [
    {
      id: 1,
      name: "김*정님",
      age: "28세",
      rating: 5,
      text: "요즘 챗지피티로 사주보는 사람들도 있고 비슷한 서비스가 많은 것 같은데 써본 것 중에 이게 제일 잘 맞았어요😊 재밌게 잘 봤습니당",
      type: "신년운세"
    },
    {
        id: 2,
        name: "최*우님",
        age: "31세",
        rating: 5,
        text: "풀이하면서 몇 번 소름 돋았어요!! 솔로된지 오래됐는데 곧 인연이 찾아온대서 용기도 얻었고 기분 좋네요 ㅎㅎ 결혼하고 싶어요…",
        type: "신년운세"
      },
      {
        id: 3,
        name: "정*아님",
        age: "27세",
        rating: 5,
        text: "취업이 너무 안돼서 기분이라도 풀려고 봤는데 최근에 가고 싶었던 곳 서류 합격 ㅋㅋㅋ뭐지 어떻게 맞췄지",
        type: "신년운세"
      },
    // ... 나머지 testimonials 데이터
  ];

  const handleContinueClick = () => {
    navigate('/new-year-gate'); // 원하는 경로로 수정 가능
  };

  return (
    <div className="transition-container">
      <div className="transition-header">
        <h1 className="transition-title">2025년 당신의 운명은?</h1>
        {/* 푸른 뱀 이미지 추가 예정 */}
        <div className="transition-stats">
          <p className="transition-detail">글자 수 16,000자의 상세한 풀이</p>
          <p className="transition-users">벌써 2천명 넘게 보고 갔어요!</p>
          <p className="transition-rating">유저 평균 리뷰 4.83점</p>
        </div>
      </div>

      <div className="transition-reviews">
        <div className="review-carousel">
          {testimonials.map((review) => (
            <div key={review.id} className="review-card">
              <div className="review-rating">{'★'.repeat(review.rating)}</div>
              <p className="review-text">{review.text}</p>
              <div className="review-info">
                <span className="review-name">{review.name}</span>
                <span className="review-age">{review.age}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="fortune-sections">
        <section className="fortune-section money">
          <h2 className="section-title">재물운</h2>
          <ul className="fortune-list">
            <li>💰 내가 평생 타고난 재물의 그릇은 얼마나 될까?</li>
            <li>💳 나의 소비 패턴과 저축 습관은?</li>
            <li>🏠 부자가 되기 위한 나만의 재테크 전략</li>
            <li>📈 투자나 사업에 유리한 시기는 언제일까?</li>
            <li>🛡️ 금전 리스크 관리 방안</li>
          </ul>
        </section>

        <section className="fortune-section love">
          <h2 className="section-title">애정/결혼운</h2>
          <ul className="fortune-list">
            <li>💏 나에게 꼭 맞는 연인상은 어떨까?</li>
            <li>✋ 내 연인관계에서 주의할 점</li>
            <li>💕 새로운 인연이 찾아올 시기</li>
            <li>🧎‍♂️‍➡️ 인연을 만나 유지할 가능성은</li>
            <li>💍 나의 결혼운은 어떨까?</li>
          </ul>
        </section>

        <section className="fortune-section career">
          <h2 className="section-title">직장/명예운</h2>
          <ul className="fortune-list">
            <li>📈 나는 이런 직업이 딱!</li>
            <li>🏅 내가 인정받는 필드와 승진의 기회</li>
            <li>🔄 혹시 나도 스카웃을…? 변화와 이직의 가능성</li>
            <li>🤝 직장동료들 사이에서 난 이런 모습</li>
          </ul>
        </section>

        <section className="fortune-section total">
          <h2 className="section-title">2025년 행운 총평</h2>
          <ul className="fortune-list">
            <li>💰 예상치 못한 횡재의 가능성</li>
            <li>🎯 귀인을 만날 수 있을까?</li>
            <li>🤗 주변 사람들과의 협력으로 만들어지는 행운</li>
            <li>🌅 새로운 시작과 변화의 가능성</li>
            <li>🚀 나의 운을 극대화하는 방법</li>
          </ul>
        </section>
      </div>

      <div className="transition-footer">
        <p className="additional-info">총 10개의 자유질문을 할수 있어요</p>
        <button 
          className="continue-button"
          onClick={handleContinueClick}
        >
          2025년 나의 운명 이어 보기
        </button>
      </div>
    </div>
  );
}

export default AdsTransitionDisplay;
